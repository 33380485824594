import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import ProgrammeForm from "./programme-form";

function Programme(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [programmeList, setProgrammeList] = useState([])
    const header = ["S/N", "Programme", "Code", "Department", "College", "School", "Study_Type", "Entry_Level", "Active", "Action"];
    const [formData, setFormData] = useState({
        PROG_ID: "",
        PROGRAMME: "",
        PROGRAMME_NATURE: "",
        PROG_CODE: "",
        COL_ID: "",
        SCH_ID: "",
        DEPT_ID: "",
        PROG_TYPE: "",
        STUDY_TYPE: "",
        ENTRY_LEVEL: "",
        DURATION: "",
        CAPACITY: "",
        APP_FEE: "",
        ACCEPT_FEE: "",
        APP_MODE: "",
        PROG_STATUS: "",
        APP_STATUS: "",
        ENTRY_REQUIREMENT: "",
        INSERTED_BY: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            PROG_ID: "",
            PROGRAMME: "",
            PROGRAMME_NATURE: "",
            PROG_CODE: "",
            COL_ID: "",
            SCH_ID: "",
            DEPT_ID: "",
            PROG_TYPE: "",
            STUDY_TYPE: "",
            ENTRY_LEVEL: "",
            DURATION: "",
            CAPACITY: "",
            APP_FEE: "",
            ACCEPT_FEE: "",
            APP_MODE: "",
            PROG_STATUS: "",
            APP_STATUS: "",
            ENTRY_REQUIREMENT: "",
        });
    }

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/academics/programme/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setProgrammeList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return programmeList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.PROG_CODE}</td>
                        <td className="text-xs font-weight-bold">{item.DEPARTMENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.COLLEGE_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.SCHOOL_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.STUDY_TYPE}</td>
                        <td className="text-xs font-weight-bold">{item.ENTRY_LEVEL}</td>
                        <td className={`text-xs font-weight-bold ${item.APP_STATUS?.toString() ==="Active" ? "text-success" : "text-danger"}`}>{item.STATUS?.toString() ==="1" ? "Active" : "InActive"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       PROG_ID: item.PROG_ID,
                                       DEPT_ID: item.DEPT_ID,
                                       PROGRAMME: item.PROGRAMME,
                                       PROG_CODE: item.PROG_CODE,
                                       PROGRAMME_NATURE: item.PROGRAMME_NATURE,
                                       COL_ID: item.COL_ID,
                                       SCH_ID: item.SCH_ID,
                                       PROG_TYPE: item.PROG_TYPE,
                                       STUDY_TYPE: item.STUDY_TYPE,
                                       ENTRY_LEVEL: item.ENTRY_LEVEL,
                                       DURATION: item.DURATION,
                                       CAPACITY: item.CAPACITY,
                                       APP_FEE: item.APP_FEE,
                                       ACCEPT_FEE: item.ACCEPT_FEE,
                                       APP_MODE: item.APP_MODE,
                                       PROG_STATUS: item.PROG_STATUS,
                                       APP_STATUS: item.APP_STATUS,
                                       ENTRY_REQUIREMENT: item.ENTRY_REQUIREMENT,
                                       INSERTED_BY: item.INSERTED_BY,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            ENTRY_REQUIREMENT: event,
        });
    }

    const onSubmit = async () => {
        if (formData.PROGRAMME.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter programme name", "error");
            return false;
        }

        if (formData.PROG_CODE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter programme code", "error");
            return false;
        }

        if (formData.PROGRAMME_NATURE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter programme nature", "error");
            return false;
        }

        if (formData.DEPT_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select department", "error");
            return false;
        }

        if (formData.COL_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select college", "error");
            return false;
        }

        if (formData.SCH_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select school", "error");
            return false;
        }

        if (formData.PROG_TYPE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter programme type", "error");
            return false;
        }

        if (formData.STUDY_TYPE.trim() === "") {
            showAlert("EMPTY FIELD", "Please select study type", "error");
            return false;
        }

        if (formData.ENTRY_LEVEL.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select entry level", "error");
            return false;
        }

        if (formData.DURATION.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select programme duration", "error");
            return false;
        }

        if (formData.APP_FEE.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter application fee", "error");
            return false;
        }

        if (formData.ACCEPT_FEE.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter acceptance fee", "error");
            return false;
        }

        if (formData.APP_MODE.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select application mode", "error");
            return false;
        }

        if (formData.APP_STATUS.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select application status", "error");
            return false;
        }


        if (formData.PROG_ID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/academics/programme/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Programme Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        resetFormData()

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/academics/programme/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Programme Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        resetFormData();
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Programme
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>resetFormData()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Programme
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Programme(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Programme(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgrammeForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                    descriptionHandler={descriptionHandler}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Programme);
