import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import GalleryForm from "./gallery-form";

function Gallery(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [galleryList, setGalleryList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const header = ["S/N", "Title", "Images", "Category", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        EntryID: "",
        ImageTitle: "",
        ImagePath: "",
        ImageData: "",
        CategoryID: "",
        CategoryName: "",
        FacultyCode: "General",
        InsertedBy: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}gallery/list`).then((result) => {
                if (result.data.Gallery.length > 0) {
                    setGalleryList(result.data.Gallery);
                }else{
                    setGalleryList([])
                }
            setCategoryList(result.data.Category);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}gallery/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return galleryList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.ImageTitle}</td>
                        <td className="text-xs font-weight-bold"> <img src={`${serverLink}public/uploads/gallery_uploads/${item.ImagePath}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td className="text-xs font-weight-bold">{item.CategoryName}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       CategoryID: item.CategoryID,
                                       CategoryName: item.CategoryName,
                                       FacultyCode: item.FacultyCode,
                                       ImageTitle: item.ImageTitle,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "} onClick={() => delete_item(item.EntryID)}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }


    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };


    const onSubmit = async () => {
        if (formData.ImageData.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select image", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}gallery/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {

                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', result.data.entry_id)
                            axios.patch(`${serverLink}gallery/uploadGalleryPhoto`, formData2)
                                .then(result => {
                                    toast.success("Gallery Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        ImageTitle: "",
                                        ImagePath: "",
                                        CategoryID: "",
                                        CategoryName: "",
                                        FacultyCode: "General",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}gallery/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', formData.EntryID)
                            axios.patch(`${serverLink}gallery/uploadGalleryPhoto`, formData2)
                                .then(result => {
                                    toast.success("Gallery Updated Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        ImageTitle: "",
                                        ImagePath: "",
                                        CategoryID: "",
                                        CategoryName: "",
                                        FacultyCode: "General",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("Slider Updated Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("closeModal").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                ImageTitle: "",
                                ImagePath: "",
                                CategoryID: "",
                                CategoryName: "",
                                FacultyCode: "General",
                            });
                        }
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Website Gallery
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list ">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               EntryID: "",
                                               ImageTitle: "",
                                               ImagePath: "",
                                               CategoryID: "",
                                               CategoryName: "",
                                               FacultyCode: "General",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Gallery
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Gallery</h3>
                                                <DataTable header={header} body={showTable()} title="Gallery" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GalleryForm
                    formData={formData}
                    categoryList={categoryList}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    onImageChange={onImageChange}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Gallery);
