import React from "react";

export default function SliderForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Slider Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Title</label>
                                <input
                                    name="Title"
                                    className="form-control"
                                    id="Title"
                                    value={props.formData.Title}
                                    onChange={props.onEdit}
                                    placeholder="Image Title"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Caption</label>
                                <input
                                    name="Caption"
                                    className="form-control"
                                    id="Caption"
                                    value={props.formData.Caption}
                                    onChange={props.onEdit}
                                    placeholder="Slider Caption"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Button Name</label>
                                <input
                                    name="BtnName"
                                    className="form-control"
                                    id="BtnName"
                                    value={props.formData.BtnName}
                                    onChange={props.onEdit}
                                    placeholder="Slider Button Name"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Button Link</label>
                                <input
                                    name="BtnLink"
                                    className="form-control"
                                    id="BtnLink"
                                    value={props.formData.BtnLink}
                                    onChange={props.onEdit}
                                    placeholder="Slider Button Link"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Image Position</label>
                                <select className="form-control" name="ImagePosition" id="ImagePosition" value={props.formData.ImagePosition} onChange={props.onEdit}>
                                    <option value="">Select Image Position</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Image</label>
                                <input
                                    type="file"
                                    className="form-control form-control-solid"
                                    name="ImagePath"
                                    id="ImagePath"
                                    onChange={props.onImageChange}
                                    accept="image/*"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                            <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}