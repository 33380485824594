import React from "react";

export default function GalleryForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Gallery Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Image Title</label>
                                <input
                                    name="ImageTitle"
                                    className="form-control"
                                    id="ImageTitle"
                                    value={props.formData.ImageTitle}
                                    onChange={props.onEdit}
                                    placeholder="Image Title"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Gallery Category</label>
                                <select className="form-control" name="CategoryID" id="CategoryID" value={props.formData.CategoryID} onChange={props.onEdit}>
                                    <option value="">Select Gallery Category</option>
                                    {
                                        props.categoryList.length > 0 && props.categoryList.map((item, index)=> {
                                            return (
                                                <option key={index} value={item.EntryID}>{item.CategoryName}</option>
                                            )
                                        })
                                    }

                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Image</label>
                                <input
                                    type="file"
                                    className="form-control form-control-solid"
                                    name="ImagePath"
                                    id="ImagePath"
                                    onChange={props.onImageChange}
                                    accept="image/*"
                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}