import React, {useEffect, useState} from "react";
import {StateData} from "../../../resources/state_data";

export default function VisitorsSubmissionReportDetails(props) {
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLGAList] = useState([]);
    const [wardList, setWardList] = useState([]);
    useEffect( () => {
        setStateList([...new Set(StateData.filter(e=>e.StateName === "Kaduna").map(item => item.StateName))])
    }, [""]);
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Submission Details</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="hr-text hr-text-left"><h2>Submission Details</h2></div>
                            <ul className="ml-3" style={{marginLeft: '10px'}}>
                                <li>Visitor Name: <b>{props.formData.VisitorName}</b> </li>
                                <li>Purpose:  <b>{props.formData.Purpose}</b></li>
                                <li>Subject:  <b>{props.formData.Subject}</b></li>
                                <li>Status:  <b>{props.formData.Status}</b></li>
                            </ul>
                            <div className="list-group-item mb-2">
                                <div className="row align-items-center">
                                    <div className="col-12"><b>Description:</b></div>
                                    <br/><br/>
                                    <div className="col ">
                                        <span className="text-reset " dangerouslySetInnerHTML={{__html: props.formData.Description}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="hr-text hr-text-left">Action</div>
                            <div className="list-group-item border-1 mb-3">
                                <div className="mb-3 col-lg-12 form-group">
                                    <label className="form-label">Status </label>
                                    <select
                                        className="form-select"
                                        name="Status"
                                        id="Status"
                                        value={props.formData.Status}
                                        onChange={props.onEdit}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="Processed">Processed</option>
                                        <option value="Treated">Treated</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}