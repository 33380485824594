import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {serverLink} from "../../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../../common/dataLoader/dataLoader";
import DataTable from "../../../common/data-table/data-table";
import {formatDateAndTime} from "../../../../resources/constants";
import GroupForm from "./group-form";

function GroupSettings(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [showTBL, setShowTBL] = useState(false)
    const [groupList, setGroupList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [groupMembersList, setGroupMembersList] = useState([])
    const [groupMembers, setGroupMembers] = useState([])
    const [changed, setChanged] = useState("")
    const header = ["S/N", "Group Name", "Added By", "Date Added", "Action"];
    const header2 = ["S/N","Staff ID","Staff Name", "Added By", "Date Added", "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        GroupName: "",
        InsertedBy: `${props.loginData[0]?.StaffID}`,
    })
    const [formData2, setFormData2] = useState({
        EntryID: "",
        StaffID: "",
        GroupID: "",
        InsertedBy: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);

    useEffect( () => {
        if (formData2.GroupID.toString() !== "" && groupMembersList.length > 0  && staffList.length > 0 ){
            getMembersData(groupMembersList, staffList, formData2.GroupID)
        }

    }, [formData2.GroupID.toString()]);


    const getMembersData = async (MembersList, staff_list, group) => {
        let row = [];
        if (group.toString() !== ""){
            if (staff_list.length > 0){
                staff_list.map((staff, index) => {
                    let check_member =  MembersList.filter(e=>e.GroupID.toString() === group.toString() && e.StaffID.toString() === staff.StaffID.toString());
                    if (check_member.length > 0){
                        row.push({
                            EntryID: check_member[0].EntryID,
                            GroupID: check_member[0].GroupID,
                            StaffID: staff.StaffID,
                            StaffName: staff.StaffName,
                            InsertedBy: check_member[0].InsertedBy,
                            InsertedDate: check_member[0].InsertedDate,
                        })
                    }else{
                        row.push({
                            EntryID: "",
                            GroupID: group,
                            StaffID: staff.StaffID,
                            StaffName: staff.StaffName,
                            InsertedBy: "",
                            InsertedDate: "",
                        })
                    }
                })
                console.log(row)
                setGroupMembers(row)
                setShowTBL(true)
            }
        }else{
            setShowTBL(false)
        }
    }


    const getData = async (group_id = '') => {
        await axios.get(`${serverLink}staff/report/group/data`)
            .then(async (result) => {
                setGroupList(result.data.group);
                setGroupMembersList(result.data.group_members);
                setStaffList(result.data.staff);
                setIsLoading(false)
                if (group_id !==''){
                  await  getMembersData(result.data.group_members, result.data.staff, group_id)
                }
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return groupList.length > 0 && groupList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.GroupName}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedBy}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       GroupName: item.GroupName,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable2= () => {
        try {
            return groupMembers.length > 0 &&  groupMembers.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.StaffID}</td>
                        <td className="text-xs font-weight-bold">{item.StaffName}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedBy}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedDate === "" ? "" : formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            {
                                item.EntryID.toString() === "" ?
                                    <button type="button" className={"btn btn-primary btn-sm  text-center"}
                                       onClick={() => {
                                           let sendData = {
                                               ...formData2,
                                               EntryID: item.EntryID,
                                               StaffID: item.StaffID,
                                               GroupID: item.GroupID,
                                           }
                                           onSubmit2(sendData);
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check"
                                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M5 12l5 5l10 -10"/>
                                        </svg>
                                    </button>
                                    :
                                    <button type="button" className={"btn btn-danger btn-sm text-center"}
                                       onClick={() => {
                                           let sendData = {
                                               ...formData2,
                                               EntryID: item.EntryID,
                                               StaffID: item.StaffID,
                                               GroupID: item.GroupID,
                                           }
                                           onSubmit2(sendData);
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-trash" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="4" y1="7" x2="20" y2="7"/>
                                            <line x1="10" y1="11" x2="10" y2="17"/>
                                            <line x1="14" y1="11" x2="14" y2="17"/>
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                                        </svg>
                                    </button>
                            }
                            </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onEdit2 = (e) => {
        setFormData2({
            ...formData2,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.GroupName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter group name", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/report/settings/group/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Group Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            GroupName: "",
                        });

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/report/settings/group/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Group Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            GroupName: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit2 = async (sendData) => {
        if (sendData.StaffID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select staff", "error");
            return false;
        }

        if (sendData.GroupID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select group", "error");
            return false;
        }

        setIsFormLoading2(true);
        await axios
            .post(`${serverLink}staff/report/settings/group/members/add`, sendData)
            .then(async (result) => {
                if (result.data.message === "success") {
                    toast.success("Staff successfully added to the selected group");
                    setIsFormLoading2(false);
                   await getData(sendData.GroupID);
                }else if (result.data.message === "removed") {
                    toast.success("Staff successfully removed from the selected group");
                    setIsFormLoading2(false);
                    await getData(sendData.GroupID);
                }
                else {
                    setIsFormLoading2(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading2(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">

                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <ul className="nav nav-tabs card-header-tabs nav-fill" data-bs-toggle="tabs" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a href="#tabs-menu" className="nav-link active"
                                                           data-bs-toggle="tab" aria-selected="true"
                                                           role="tab">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="icon icon-tabler icon-tabler-tournament"
                                                                 width="24" height="24" viewBox="0 0 24 24"
                                                                 strokeWidth="2" stroke="currentColor" fill="none"
                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                      fill="none"></path>
                                                                <circle cx="4" cy="4" r="2"></circle>
                                                                <circle cx="20" cy="10" r="2"></circle>
                                                                <circle cx="4" cy="12" r="2"></circle>
                                                                <circle cx="4" cy="20" r="2"></circle>
                                                                <path
                                                                    d="M6 12h3a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-3"></path>
                                                                <path
                                                                    d="M6 4h7a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-2"></path>
                                                                <path d="M14 10h4"></path>
                                                            </svg>
                                                            Group </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="#tabs-sub-menu" className="nav-link"
                                                           data-bs-toggle="tab" aria-selected="false"
                                                           role="tab"
                                                           tabIndex="-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="icon icon-tabler icon-tabler-users"
                                                                 width="24" height="24" viewBox="0 0 24 24"
                                                                 strokeWidth="2" stroke="currentColor" fill="none"
                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                      fill="none"></path>
                                                                <circle cx="9" cy="7" r="4"></circle>
                                                                <path
                                                                    d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                                                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                                                            </svg>
                                                            Group Members</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div className="tab-pane active show" id="tabs-menu" role="tabpanel">
                                                        <div className="page-header d-print-none">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <div className="page-pretitle">
                                                                    </div>
                                                                    <h2>Group</h2>
                                                                </div>
                                                                <div className="col-auto ms-auto d-print-none">
                                                                    <div className="btn-list">

                                                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                                                           onClick={()=>{
                                                                               setFormData({
                                                                                   ...formData,
                                                                                   EntryID: "",
                                                                                   GroupName: "",
                                                                               });
                                                                           }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                            Add Group
                                                                        </a>
                                                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                                                           data-bs-target="#modal-report" aria-label="Create new report">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <DataTable tableID="group" header={header} body={showTable()} title="Group List" />
                                                    </div>
                                                    <div className="tab-pane" id="tabs-sub-menu" role="tabpanel">
                                                        <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Group</h5></div>
                                                        <div className="form-group mb-3">
                                                            <select className="form-control" name="GroupID" id="GroupID" value={formData2.GroupID} onChange={onEdit2}>
                                                                <option value="">Select Group</option>
                                                                {
                                                                    groupList.length > 0 && groupList.map((item, index)=> {
                                                                        return (
                                                                            <option key={index} value={item.EntryID}>{item.GroupName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            showTBL ?
                                                                <DataTable tableID="group-members"  header={header2} body={showTable2()} title="Group Members List" />
                                                                :
                                                                <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="icon alert-icon" width="24"
                                                                                 height="24" viewBox="0 0 24 24"
                                                                                 strokeWidth="2" stroke="currentColor"
                                                                                 fill="none" strokeLinecap="round"
                                                                                 strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                                      fill="none"/>
                                                                                <circle cx="12" cy="12" r="9"/>
                                                                                <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                                <polyline points="11 12 12 12 12 16 13 16"/>
                                                                            </svg>
                                                                        </div>
                                                                        <div> Please select group to show group members </div>
                                                                    </div>
                                                                    <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GroupForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                    modalID="modal-report"
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(GroupSettings);
