import {projectCode} from "./constants";

const CryptoJS = require("crypto-js");

export const status = "Live";
export const serverLink =
    status === "Dev"
        ?
         "http://192.168.1.75:4480/"
        : "https://api.teamcoded.com.ng:4480/";

export const projectURL = "https://vmis.teamcoded.com.ng/";
export const projectPhone = " +234-8036424139, +234-8098909053, +234-8180599505";
export const projectEmail = "https://vmis.teamcoded.com.ng/";


export function encryptData(string, val = false) {
    let secret_key = val === false ? 'ODFel' : projectCode;
    let secret_iv = val === false ? 'ODFel' : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    let decrypted = CryptoJS.AES.encrypt(string, kee, { iv: ivv });

    let result = decrypted.toString();
    return btoa(result);
}

export function decryptData(string, val = false) {
    let secret_key = val === false ? 'ODFel' : projectCode;
    let secret_iv = val === false ? 'ODFel' : projectCode;
    // hash
    let kee = CryptoJS.SHA256(secret_key);
    let ivv = CryptoJS.SHA256(secret_iv).toString().substr(0, 16);

    kee = CryptoJS.enc.Utf8.parse(kee.toString().substr(0, 32));
    ivv = CryptoJS.enc.Utf8.parse(ivv);

    var decrypted = CryptoJS.AES.decrypt(atob(string), kee, { iv: ivv });

    var result = decrypted.toString(CryptoJS.enc.Utf8);
    return result;
}