import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import { serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";

import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime} from "../../../resources/constants";
import VisitorsSubmissionReportDetails from "./visitors-submission-report-details";

function VisitorsSubmissionReport(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [visitorsSubmissionList, setVisitorsSubmissionList] = useState([])
    const header = ["S/N", "Visitor Name", "Purpose", "Subject", "Status", "Attended by", "Date Submitted", "Action"];
    const initialFormData = {
        FormID: "",
        VisitorID: "",
        CustodianID: "",
        Purpose: "",
        Subject: "",
        Description: "",
        Status: "",
        InsertedDate: "",
        VisitorName: "",
        CustodianName: "",
        InsertedBy: `${props.loginData[0]?.UserID}`,
    }
    const [formData, setFormData] = useState(initialFormData)
    const resetFormData = () => {setFormData(initialFormData)}

    useEffect( () => {
        getStaffData();
    }, [""]);

    const  showTable = () => {
        try {
            return visitorsSubmissionList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.VisitorName}</td>
                        <td className="text-xs font-weight-bold">{item.Purpose}</td>
                        <td className="text-xs font-weight-bold">{item.Subject}</td>
                        <td className="text-xs font-weight-bold">{item.Status}</td>
                        <td className="text-xs font-weight-bold">{item.CustodianName ?? "Not Attended"}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       FormID: item.FormID,
                                       VisitorID: item.VisitorID,
                                       CustodianID: item.CustodianID,
                                       Purpose: item.Purpose,
                                       Subject: item.Subject,
                                       Description: item.Description,
                                       Status: item.Status,
                                       VisitorName: item.VisitorName,
                                       CustodianName: item.CustodianName,
                                       InsertedDate: item.InsertedDate,
                                   });
                               }}>
                                More Details
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    const getStaffData = async () => {
        await axios
            .get(`${serverLink}project/visitor/submissions`)
            .then((response) => {
                if (response.data.message === "success") {
                    setVisitorsSubmissionList(response.data.data);
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.Status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select Status", "error");
            return false;
        }

        let sendData = {...formData}
        setIsFormLoading(true);
        await axios
            .patch(`${serverLink}project/visitor/submission/action`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Submission Updated Successfully");
                    setIsFormLoading(false);
                    getStaffData();
                    document.getElementById("closeModal").click();
                    resetFormData();
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Visitors Submission
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="card-title">Visitors Submission Report</h3>
                                <div className="table-responsive">
                                    <DataTable header={header} body={showTable()} title="Visitor Report" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VisitorsSubmissionReportDetails
                    setFormData={setFormData}
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(VisitorsSubmissionReport);
