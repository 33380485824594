import React, {useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/dashboard";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import Logout from "../authentication/logout";
import PageNotFound from "../404/page-not-found";
import ManageStaff from "../human-resources/staff/manage-staff";
import News from "../website/news/news";
import Slider from "../website/slider/slider";
import Collage from "../academics/collage/collage";
import Course from "../academics/course/course";
import Department from "../academics/department/department";
import Programme from "../academics/programme/programme";
import NewStudentEnrolment from "../registration/new-student-enrolment/new-student-enrolment";
import CourseRegistration from "../registration/course-registration/course-registration";
import AcceptanceFeeReport from "../report/acceptance-fee-report/acceptance-fee-report";
import ManagementFeeReport from "../report/management-fee-report/management-fee-report";
import CourseRegistrationReport from "../report/course-registration-report/course-registration-report";
import ActiveStudentsReport from "../report/active-student-report/active-student-report";
import RegisteredStudentsNotEnrolled from "../report/registered-students-not-enrolled/registered-students-not-enrolled";
import StudentReportByProgramme from "../report/student-report-by-programme/student-report-by-programme";
import StudentClearance from "../registration/student-clearance/student-clearance";
import MenuSettings from "../settings/permission/menu/menu";
import GroupSettings from "../settings/permission/group/group";
import PermissionSettings from "../settings/permission/permission/permission";
import FinanceSettings from "../human-resources/finance/finance-settings";
import PostPayment from "../human-resources/finance/post-payment";
import PendingApplications from "../registration/admission/pending-application";
import ChangeStudentCourse from "../registration/change-of-course/change-student-course";
import AcademicSettings from "../settings/permission/academic-settings/academic-settings";
import StaffProfile from "../user/account/staff-profile";
import SearchStudent from "../human-resources/student-manager/search-student";
import StudentDashboard from "../human-resources/student-manager/student-dashboard";
import EmailStudent from "../human-resources/send-email/email-student";
import Gallery from "../website/gallery/gallery";
import GalleryCategory from "../website/gallery/gallery-category";
import DashboardRoot from "../dashboard/dashboard-root";
import Progression from "../registration/progression/progression";
import DefermentRequest from "../registration/deferment/deferment-request";
import DefermentRequestRegistrar from "../registration/deferment/deferment-request-registrar";
import DropOfCourseRequest from "../registration/drop-of-course/drop-of-course-request";
import DropOfCourseRequestRegistrar from "../registration/drop-of-course/drop-of-course-request-registrar";
import { browserName, isMobile } from 'react-device-detect';
import axios from "axios";
import {serverLink} from "../../resources/url";
import Block from "../human-resources/block/block";
import Office from "../human-resources/office/office";
import Departments from "../human-resources/department/department";
import ManageVisitor from "../visitors/visitors";
import Validation from "../authentication/validation";
import VisitorAccount from "../visitors/visitor-registration";
import VisitorForm from "../visitors/submit-form";
import VisitorsSubmissionReport from "../report/visitors-report/visitors-submission-report";
import VisitorsSubmissionAnalytics from "../report/visitors-report/visitors-submission-analytics";

export default function PageRoutes() {
    const getLocationData = async () => {
        await axios.get('https://geolocation-db.com/json/').then(async (result)=> {
            await getVisitorData(result.data);
        }).catch((e)=>{
            console.log("Error Getting User Details")
        })

    }

    const getVisitorData = async (locData) => {
        let sendData = {
            IPAddress: locData.IPv4,
            Country: locData.country_name,
            lat: locData.latitude,
            lng: locData.longitude,
            IsMobileRequest: isMobile ? "1" : "0",
            UserAgent: browserName,
        }
        console.log(sendData)

        await axios.post(`${serverLink}general/website/visit-hit`, sendData).then(data => {

        }).catch(err => {
            console.log("Error Adding User Details")
        });
    };

    // useEffect(() => {
    //     getLocationData();
    // }, []);

    return (<>
        <div className="wrapper">
            <Header/>
            <div className="page-wrapper">
                <Routes>
                    {/* Dashboard Redirect */}
                    <Route exact path="/" element={<DashboardRoot />} />
                    <Route path="/hr/manage-staff" element={<ManageStaff />} />
                    <Route path="/hr/manage-visitors" element={<ManageVisitor />} />
                    <Route path="/hr/manage-block" element={<Block/>} />
                    <Route path="/hr/manage-office" element={<Office/>} />
                    <Route path="/hr/manage-department" element={<Departments/>} />

                    {/*REPORT REDIRECT*/}
                    <Route path="/report/visitors-submission" element={<VisitorsSubmissionReport/>} />
                    <Route path="/report/analytics" element={<VisitorsSubmissionAnalytics/>} />

                    {/*ACADEMICS REDIRECT*/}
                    <Route path="/academics/collage" element={<Collage />} />
                    <Route path="/academics/course" element={<Course />} />
                    <Route path="/academics/department" element={<Department />} />
                    <Route path="/academics/programme" element={<Programme />} />

                    {/*REGISTRATION REDIRECT*/}
                    <Route path="/registration/student/new-student-enrolment" element={<NewStudentEnrolment />} />
                    <Route path="/registration/course/course-registration" element={<CourseRegistration />} />
                    <Route path="/registration/student/new-student-clearance" element={<StudentClearance />} />
                    <Route path="/registration/admission/pending-application" element={<PendingApplications />} />
                    <Route path="/registration/change-of-course/change-student-course" element={<ChangeStudentCourse />} />
                    <Route path="/registration/progression/run-progression" element={<Progression/>} />
                    <Route path="/registration/deferment/deferment-request" element={<DefermentRequest/>} />
                    <Route path="/registration/drop-of-course/request" element={<DropOfCourseRequest/>} />
                    <Route path="/registration/deferment/deferment-request-approval" element={<DefermentRequestRegistrar/>} />
                    <Route path="/registration/drop-of-course/request-approval" element={<DropOfCourseRequestRegistrar/>} />
                    {/*<Route path="/registration/progression/run-progression" element={<div class=" container mt-5 text-center mb-5 alert alert-warning alert-important"><h1>PAGE UNDER DEVELOPMENT!</h1></div>} />*/}

                    {/*REPORT REDIRECT*/}
                    <Route path="/report/payment/acceptance-fee" element={<AcceptanceFeeReport />} />
                    <Route path="/report/payment/management-fee" element={<ManagementFeeReport />} />
                    <Route path="/report/course/course-registration" element={<CourseRegistrationReport />} />
                    <Route path="/report/registration/active-students" element={<ActiveStudentsReport />} />
                    <Route path="/report/registration/registered-students-not-enrolled" element={<RegisteredStudentsNotEnrolled />} />
                    <Route path="/report/registration/student-report-by-programme" element={<StudentReportByProgramme />} />

                    {/*SETTINGS REDIRECT*/}
                    <Route path="/settings/permission/menu" element={<MenuSettings />} />
                    <Route path="/settings/permission/group" element={<GroupSettings />} />
                    <Route path="/settings/permission/permission" element={<PermissionSettings />} />
                    <Route path="/settings/academic-settings" element={<AcademicSettings />} />


                    {/*WEBSITE REDIRECT*/}
                    <Route path="/website/news/manage-news" element={<News />} />
                    <Route path="/website/slider/manage-slider" element={<Slider />} />
                    <Route path="/website/gallery/manage-gallery" element={<Gallery />} />
                    <Route path="/website/gallery/gallery-category" element={<GalleryCategory />} />


                    {/*WEBSITE REDIRECT*/}
                    <Route path="/user/account/staff-profile" element={<StaffProfile />} />
                    <Route path="/logout" element={<Logout/>} />

                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                <Footer/>
            </div>
        </div>
    </>)
}