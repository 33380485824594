export const StateData =
[
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Eziama"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Industrial Area"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Osusu I"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Osusu II"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"St.eugenes By okigwe rd."},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Uratta"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Old Aba gra"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Umuola"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Ariaria Market"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Ogbor I"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Ogbor II"},
 {"StateName":"Abia","LGAName":"Aba North","WardName":"Umuogor"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Eziukwu"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Asa"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Enyimba"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Ngwa"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Ohazu I"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Ohazu II"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Igwebuike"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Ekeoha"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Gloucester"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Mosque"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Aba River"},
 {"StateName":"Abia","LGAName":"Aba South","WardName":"Aba Town hall"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Ovukwu"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Ohaeke"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Ohafor I"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Ohafor II"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Arochukwu I"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Arochukwu II"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Ikwun Ihechiowa"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Eleoha Ihechiowa"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Ututu"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Isu"},
 {"StateName":"Abia","LGAName":"Arochukwu","WardName":"Arochukwu III"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Amankalu akoliufu"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Bende"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Ugwueke ezeukwu"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Igbere A"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Igbere B"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Item A"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Item B"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Item C"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Itumbauzo"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Ozuitem"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Umuhu ezechi"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Umu - imenyi"},
 {"StateName":"Abia","LGAName":"Bende","WardName":"Uzuakoli"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Oloko I"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Oloko II"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Ibere I"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Ibere II"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Oboro I"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Oboro II"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Oboro III"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Oboro Iv"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Ariam"},
 {"StateName":"Abia","LGAName":"Ikwuano","WardName":"Usaka"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Amasaa Nsulu"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Umunna Nsulu"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Isiala Nsulu"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Ngwa Ukwu I"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Ngwa Ukwu II"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Ihie"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Amasaa Ntigha"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Amapu Ntigha"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Umuoha"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa north","WardName":"Mbawsi umuomainta"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Amaise amaise anaba"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Ngwaobi"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Mbutu Ukwu"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Mbutu Ngwa"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Ehina Guru osokwa"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Akunekpu Eziama na obuba"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Omoba"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Ovungwu"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Ovuokwu"},
 {"StateName":"Abia","LGAName":"Isiala Ngwa south","WardName":"Okporo Ahaba"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Imenyi"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Ezere"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Isiala Amawu"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Isu Amawu"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Ogunduasa"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Umunnekwu"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Achara mgbugwu"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Ikeagha I"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Ikeagha II"},
 {"StateName":"Abia","LGAName":"Isuikwuato","WardName":"Umuanyi absu"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Abayi I"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Abayi II"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Mgboko Umuanunu"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Mgboko Itungwa"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Ahiaba"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Maboko Amairi"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Alaukwu Ohanze"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Akumaimo"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Ndiarata amairinabua"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Ntighauzo Amairi"},
 {"StateName":"Abia","LGAName":"Obingwa","WardName":"Ibeme"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Isiama Ohafia"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Ebem Ohafia"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Ndi Elu nkporo"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Ndi Etiti nkporo"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Amaeke Abiriba"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Amaogudu Abiriba"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Agboji Abiriba"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Ohafor Ohoafia"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Okanu Ohoafia"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Ania Ohoafia"},
 {"StateName":"Abia","LGAName":"Ohafia","WardName":"Ndi Agbo nkporo"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Amavo"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Amaitolu Mbutu umuojima"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Amasator"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Aro - ngwa"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Ama - asaa"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Oso - okwa"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Urtta"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Amator"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Umunneise"},
 {"StateName":"Abia","LGAName":"Osisioma","WardName":"Okpor - umuobo"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward One"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Two"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Three"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Four"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Five"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Six"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Seven"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Eight"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Nine"},
 {"StateName":"Abia","LGAName":"Ugwunagbo","WardName":"Ward Ten"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Ikwuriator East"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Ikwuriator West"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Azumini"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Umuigube Achara"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Akwete"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Obohia"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Ikwueke East"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Ikwueke west"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Nkporobe ohuru"},
 {"StateName":"Abia","LGAName":"Ukwa East","WardName":"Ikwuorie"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Asa North"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Obokwe"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Ogwe"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Asa South"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Obuzor"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Ipu West"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Ipu east"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Ipu south"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Ozaa Ukwu"},
 {"StateName":"Abia","LGAName":"Ukwa west","WardName":"Ozaa west"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Ibeku East I"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Ibeku East II"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Ndume"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Ibeku West"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Umuahia Urban I"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Umuahia Urban II"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Umuahia Urban III"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Nkwoachara"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Nkwoegwu"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Afugiri"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Umuhu"},
 {"StateName":"Abia","LGAName":"Umuahia North","WardName":"Isingwu"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Ezeleke ogbodiukwu"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Omaegwu"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Ohiaocha"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Ahiaukwu I"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Ahiaukwu II"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Old Umuahia"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Amakama"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Ubakala A"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Nsirimo"},
 {"StateName":"Abia","LGAName":"Umuahia south","WardName":"Ubakala B"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Amuda"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Umuaku"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Mbala achara"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Ezingodo"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Ndiawa umuelem I"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Eziama - ugwu"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Eziama - agbo"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Ubahu akawa arokpa"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Umuchieze I"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Umuchieze II"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Umuchieze III"},
 {"StateName":"Abia","LGAName":"Umu - nneochi","WardName":"Obinolu obiagu la"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Bille"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Borrong"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Demsa"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Dilli"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Dong"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Dwam"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Gwamba"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Kpasham"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Mbula Kuli"},
 {"StateName":"Adamawa","LGAName":"Demsa","WardName":"Nassarawo Demsa"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Beti"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Farang"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Fufore"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Gurin"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Karlahi"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Mayo Ine"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Pariya"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Ribadu"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Uki Tuki"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Wuro Bokki"},
 {"StateName":"Adamawa","LGAName":"Fufore","WardName":"Yadim"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Bakari Guso"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Gamu"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Ganye I"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Ganye II"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Gurum"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Jaggu"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Sangasumi"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Sugu"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Timdore"},
 {"StateName":"Adamawa","LGAName":"Ganye","WardName":"Yebbi"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Dakri"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Damare"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Gerei I"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Girei II"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Gereng"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Jera Bakari"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Jera Bonyo"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Modire Vinikilang"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Tambo"},
 {"StateName":"Adamawa","LGAName":"Gire 1","WardName":"Wuro Dole"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Boga Dingai"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Duwa"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Ga'anda"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Gabun"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Gombi North"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Gombi South"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Garkida"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Guyaku"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Tawa"},
 {"StateName":"Adamawa","LGAName":"Gombi","WardName":"Yang"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Banjiram"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Bobini"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Bodeno"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Chikila"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Dukul"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Dumna"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Guyuk"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Kola"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Lokoro"},
 {"StateName":"Adamawa","LGAName":"Guyuk","WardName":"Purokayo"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Bangshika"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Daksiri"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Garaha"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Gaya"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Hildi"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Hong"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Hushere Zum"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Kwarhi"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Mayo Lope"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Shangui"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Thilbang"},
 {"StateName":"Adamawa","LGAName":"Hong","WardName":"Uba"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Danaba"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Jada I"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Jada II"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Koma I"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Koma II"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Leko"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Mapeo"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Mayokalaye"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Mbulo"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Nyibango"},
 {"StateName":"Adamawa","LGAName":"Jada","WardName":"Yelli"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Dubwangun"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Gyawana"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Lafiya"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Lamurde"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Ngbakowo"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Mgbebongun"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Opalo"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Rigange"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Suwa"},
 {"StateName":"Adamawa","LGAName":"Lamurde","WardName":"Waduku"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Babel"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Duhu Shuwa"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Gulak"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Hyambula"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"K wuro Ngayandi"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Madagali"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Pallam"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Shelmi sukur vapura"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Wagga"},
 {"StateName":"Adamawa","LGAName":"Madagali","WardName":"Wula"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Belel"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Humbutudi"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Konkol"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Maiha Gari"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Manjekin"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Mayonguli"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Pakka"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Sorau A"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Sorau B"},
 {"StateName":"Adamawa","LGAName":"Maiha","WardName":"Tambajam"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Bajama"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Binyeri"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Gangfada"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Gengle"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Gorobi"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Mayo-belwa"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Mayo Farang"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Nassarawo Jereng"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Ndikong"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Ribadu"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Tola"},
 {"StateName":"Adamawa","LGAName":"Mayo - belwa","WardName":"Yoffo"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Bazza Margi"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Futudou futules"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Garta ghunchi"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Jigalambu"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Madzi"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Michika I"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Michika II"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Minkisi Wuro ngiki"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Moda dlaka ghenjuwa"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Munkavicita"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Sina kamale kwande"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Thukudou sufuku zah"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Sukumu tillijo"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Tumbara ngabili"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Vi boka"},
 {"StateName":"Adamawa","LGAName":"Michika","WardName":"Wamblimi tilli"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Bahuli"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Betso"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Digil"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Kolere"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Lokuwa"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Mayo Bani"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Mijilu"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Muchalla"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Sabon Layi"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Vimtim"},
 {"StateName":"Adamawa","LGAName":"Mubi North","WardName":"Yelwa"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Dirbishi gandira"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Duvu Chaba girburum"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Gella"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Gude"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Kwaja"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Lamorde"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Mujara"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Mugulbu Yadafa"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Nassarawo"},
 {"StateName":"Adamawa","LGAName":"Mubi South","WardName":"Nduku"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Bare"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Bolki"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Gamadio"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Imburu"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Kodomti"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Numan I"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Numan II"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Numan III"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Sabon Pegi"},
 {"StateName":"Adamawa","LGAName":"Numan","WardName":"Vulpi"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Bakta"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Bodwai"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Gundo"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Gwapopolok"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Jumbul"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Ketembere"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Kiri"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Libbo"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Shelleng"},
 {"StateName":"Adamawa","LGAName":"Shelleng","WardName":"Tallum"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Dirma"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Dumne"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Gudu Mboi"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Kilange Funa"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Kilange Hirna"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Sigire"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Suktu"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Song Gari"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Song Waje"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Waltandi"},
 {"StateName":"Adamawa","LGAName":"Song","WardName":"Zumo"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Dawo I"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Dawo II"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Gumti"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Kiri I"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Kiri II"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Kongin Baba I"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Kongin Baba II"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Toungo I"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Toungo II"},
 {"StateName":"Adamawa","LGAName":"Toungo","WardName":"Toungo III"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Alkalawa"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Ajiya"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Doubeli"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Gwadabawa"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Jambutu"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Karena"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Limawa"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Luggere"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Nassarawo"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Rumde"},
 {"StateName":"Adamawa","LGAName":"Yola North","WardName":"Yelwa"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Adarawo"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Bako"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Bole Yolde pate"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Namtari"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Ngurore"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Makama A"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Makama B"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Mbamba"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Mbamoi"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Toungo"},
 {"StateName":"Adamawa","LGAName":"Yola South","WardName":"Yolde Kohi"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Abak Urban 1"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Abak Urban 11"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Abak Urban 111"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Abak Urban 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Afaha Obong 1"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Afaha Obong 11"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Midim 1"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Midim 11"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Otoro 1"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Otoro 11"},
 {"StateName":"Akwa Ibom","LGAName":"Abak","WardName":"Otoro 111"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo 1"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo 11"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo 111"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo v"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo v1"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo v11"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo v111"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo ix"},
 {"StateName":"Akwa Ibom","LGAName":"Eastern Obolo","WardName":"Eastern Obolo x"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Urban 1"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Urban 11"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Urban 111"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Urban 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Central 1"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Central 11"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Central 111"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Central 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Central V"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Okon 1"},
 {"StateName":"Akwa Ibom","LGAName":"Eket","WardName":"Okon 11"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Ekpene Obo"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Edor"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Ebe Ekpi"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Ikpa"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Uquo"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Ebighi Okpono"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Etebi Idung assan"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Etebi Akwata"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Akpautong"},
 {"StateName":"Akwa Ibom","LGAName":"Esit Eket (uquo)","WardName":"Ntak Inyang"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Adiasim"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Afaha"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Ekpeyong 1"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Ekpeyong 11"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Ikpe Annang"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Odoro Ikot 1"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Odoro Ikot 11"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Okon"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Ukana East"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Ukana West 1"},
 {"StateName":"Akwa Ibom","LGAName":"Essien Udim","WardName":"Ukana West 11"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo 1"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo 11"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo 111"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo v"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo v1"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo v11"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo v111"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo ix"},
 {"StateName":"Akwa Ibom","LGAName":"Etim Ekpo","WardName":"Etim Ekpo x"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Etinan Urban 1"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Etinan Urban 11"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Etinan Urban 111"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Etinan Urban 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Etinan Urban v"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Southern Iman 1"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Southern Iman 11"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Southern Iman 111"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Southern Iman 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Northern Iman 1"},
 {"StateName":"Akwa Ibom","LGAName":"Etinan","WardName":"Northern Iman 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno V"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno Vi"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno V11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno V111"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno Ix"},
 {"StateName":"Akwa Ibom","LGAName":"Ibeno","WardName":"Ibeno X"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Ibesikpo 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Ibesikpo 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Ibesikpo 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Ibesikpo 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Ibesikpo V"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Asutan 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Asutan 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Asutan 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Asutan 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Ibesikpo Asutan","WardName":"Asutan V"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Eastern 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Eastern 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Western 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Western 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Southern 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Southern 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Northern 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Northern 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Central 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ibiono Central 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ibiono Ibom","WardName":"Ikpanya"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Urban 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Urban 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Achan Ika"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Achan 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Achan 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Ito 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Ito 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Ito 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Odoro 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ika","WardName":"Odoro 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ikono Middle 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ikono Middle 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ikono Middle 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ikono Middle 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ikono South"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Nkwot 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Nkwot 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ndiya ikot Idana"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ediene 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"Ediene 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikono","WardName":"1tak"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ikpa Ibekwe 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ikpa Ibekwe 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ukpum Ete 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ukpum Ete 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ukpum Okon"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Edemaya 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Edemaya 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Edemaya 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ikpa Nung asang 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Abasi","WardName":"Ikpa Nung asang 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene v"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene v1"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene v11"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene v111"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene ix"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene x"},
 {"StateName":"Akwa Ibom","LGAName":"Ikot Ekpene","WardName":"Ikot Ekpene x1"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Ikpe 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Ikpe 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Itu Mbonuso"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Nkari"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Iwerre"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Ikono North 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Ikono North 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Ikono North 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Odoro Ukwok"},
 {"StateName":"Akwa Ibom","LGAName":"Ini","WardName":"Usuk ukwok"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"Oku Iboku"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"Mbiase ayadehe"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"East Itam 1"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"East Itam 11"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"East Itam 111"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"East Itam 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"East Itam v"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"West Itam 1"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"West Itam 11"},
 {"StateName":"Akwa Ibom","LGAName":"Itu","WardName":"West Itam 111"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Enwang 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Enwang 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Ebughu 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Ebughu 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Udesi"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Efiat 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Efiat 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Ibaka"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Uda 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mbo","WardName":"Uda 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ukpum Minya 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ukpum Minya 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ukpum Minya 111"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ukpum Minya 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ibom 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ibom 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ibom 111"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ibom 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ibiaku 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ibiaku 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ibiaku 111"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ikono 1"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ikono 11"},
 {"StateName":"Akwa Ibom","LGAName":"Mkpat Enin","WardName":"Ikpa Ikono 111"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit 1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit 11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit 111"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit v"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit v1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit v11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit v111"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit ix"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Atai","WardName":"Eastern Nsit x"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Asang 1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Asang 11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Asang 111"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Asang 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Asang V"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Mbaiso 1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Mbaiso 11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Mbaiso 111"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Mbaiso 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ibom","WardName":"Mbaiso V"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ibiakpan obotim 11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ibiakpan Obotim 1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Itreto"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ndiya"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ubium North 1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ubium North 11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ubium North 111"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ubium South 1"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ubium South 11"},
 {"StateName":"Akwa Ibom","LGAName":"Nsit Ubium","WardName":"Ubium South 111"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Ikot Abia 1"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Ikot Abia 11"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Ikot Abia 111"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Obot Akara 1"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Obot Akara 11"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Obot Akara 111"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Nto Edino 1"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Nto Edino 11"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Nto Edino 111"},
 {"StateName":"Akwa Ibom","LGAName":"Obot Akara","WardName":"Nto Edino 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Okopedi 1"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Okopedi 11"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Nung Atai ube 1"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Nung Atai ube 11"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Akai mbukpo udung"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Eweme 1"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Eweme 11"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Offi 1"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Offi 11"},
 {"StateName":"Akwa Ibom","LGAName":"Okobo","WardName":"Ekeya"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Awa 1"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Awa 11"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Awa 111"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Awa 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Nung Idem 1"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Nung Idem 11"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Oniong East 1"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Oniong East 11"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Oniong East 111"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Oniong West 1"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Oniong West 11"},
 {"StateName":"Akwa Ibom","LGAName":"Onna","WardName":"Oniong West 111"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban 1"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban 11"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban 111"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban v"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban v1"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban v11"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban v111"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban ix"},
 {"StateName":"Akwa Ibom","LGAName":"Oron","WardName":"Oron Urban x"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ikot Ibritam 1"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ikot Ibritam 11"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ndot ikot Okoro 1"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ndot ikot Okoro 11"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ndot ikot Okoro 111"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ndot ikot Okoro v1"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ekparakwa"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Abak Midim 1"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Abak Midim 11"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Abak Midim 111"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Abak Midim 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ibesit nung Ikot 1"},
 {"StateName":"Akwa Ibom","LGAName":"Oruk Anam","WardName":"Ibesit nung Ikot 11"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko 1"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko 11"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko 111"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko v"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko v1"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko v11"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko v111"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko ix"},
 {"StateName":"Akwa Ibom","LGAName":"Udung Uko","WardName":"Udung Uko x"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Ukanafun Urban"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Southern Ukanafun 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Southern Ukanafun 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Northern Ukanafun 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Northern Ukanafun 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Northern Afaha 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Northern Afaha 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Southern Afaha, adat ifang 1"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Southern Afaha, adat ifang 11"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Southern Afaha, adat ifang 111"},
 {"StateName":"Akwa Ibom","LGAName":"Ukanafun","WardName":"Southern Afaha, adat ifang 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Centyral Uruan 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Central Uruan 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Centyral Uruan 111"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Northern Uruan 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Northern Uruan 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Southern Uruan 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Southern Uruan 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Southern Uruan 111"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Southern Uruan iv"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Southern Uruan v"},
 {"StateName":"Akwa Ibom","LGAName":"Uruan","WardName":"Southern Uruan v1"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Urue Offong 1"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Urue Offong 11"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Urue Offong 111"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Urue Offong 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Urue Offong v"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Oruko 1"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Oruko 11"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Oruko 111"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Oruko 1v"},
 {"StateName":"Akwa Ibom","LGAName":"Urue Offong oruko","WardName":"Oruko V"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Uyo Urban 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Uyo Urban 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Uyo Urban 111"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Etoi 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Etoi 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Offot 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Offot 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Ikono 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Ikono 11"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Oku 1"},
 {"StateName":"Akwa Ibom","LGAName":"Uyo","WardName":"Oku 11"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Achina I"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Achina II"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Agulueze Chukwu"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Akpo"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Amesi"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Ekwulobia I"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Ekwulobia II"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Ezinifite I"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Ezinifite II"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Igbo-ukwu I"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Igbo - ukwu II"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Ikenga"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Isuofia"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Nkpologwu"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Oreri"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Uga I"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Uga II"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Umuchu I"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Umuchu II"},
 {"StateName":"Anambra","LGAName":"Aguata","WardName":"Umuona"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Anaku"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Ifite Ogwari I"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Ifite Ogwari II"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Igbakwu"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Omasi"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Omor I"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Omor II"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Omor III"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Ume Rum"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Umueje Rumueje"},
 {"StateName":"Anambra","LGAName":"Ayamelum","WardName":"Umumbo"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Aguleri"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Aguleri II"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Enugwu-otu"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Eziagulu-otu"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Igbariam"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Nando I"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Nando II"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Nando III"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Nsugbe I"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Nsugbe II"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Otuocha I"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Otuocha II"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Umureli I"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Umuleri II"},
 {"StateName":"Anambra","LGAName":"Anambra East","WardName":"Umuoba-anam"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Ezi-anam"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Ifite-anam"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Nzam"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Olumbanasa - ode"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Olumbanasa-inoma"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Oroma Etiti-anam"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Umuenwelum-anam"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Umueze-anam I"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Umueze-anam II"},
 {"StateName":"Anambra","LGAName":"Anambra West","WardName":"Umuoba-anam"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Adazi Ani I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Adazi Ani II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Adazi Enu I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Adazi Enu II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Adazi Nnukwu I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Adazi Nnukwu II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Agulu I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Agulu II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Agulu III"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Agulu iv"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Agulu Uzoigbo"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Akwaeze"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Ichida I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Ichida II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Neni I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Neni II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Nri I"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Nri II"},
 {"StateName":"Anambra","LGAName":"Anaocha","WardName":"Obeledu"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Achalla I"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Achalla i1"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Achalla i1i"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Amansea"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Amanuke"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Awba-ofemmili"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Ebenebe I"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Ebenebe II"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Ebenebe III"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Isu Aniocha"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Mabakwu i (anezike)"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Ugbene"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Ugbenu"},
 {"StateName":"Anambra","LGAName":"Awka North","WardName":"Urum"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Agu Oka"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Amawbia I"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Amawbia II"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Amawbia III"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka I"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka II"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka III"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka iv"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka v"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka vi"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Awka vii"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Ezinato isiagu"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Mba-ukwu"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Nibo I"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Nibo II"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Nibo III"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Nise I"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Nise II"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Okpuno"},
 {"StateName":"Anambra","LGAName":"Awka South","WardName":"Umuawulu"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Akwa"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ifitedunu I"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ifitedunu II"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Nawgu I"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Nawgu II"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ukpo I"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ukpo II"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ukpo III"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ukwulu I"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Ukwulu II"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Umudioka I"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Umudioka II"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Umunnachi I"},
 {"StateName":"Anambra","LGAName":"Dunukofia","WardName":"Umunnachi II"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ichi"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ihembosi anaubahu"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Oraifite I"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Oraifite II"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Oraifite III"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ozubulu I"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ozubulu II"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ozubulu III"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ozubulu iv"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ozubulu v"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Amakwa II"},
 {"StateName":"Anambra","LGAName":"Ekwusigo","WardName":"Ihiteoha"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Abacha"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Abatete"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Eziowele"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Ideani"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Nkpor I"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Nkpor II"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Obosi"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Ogidi I"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Ogidi II"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Oraukwu"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Uke"},
 {"StateName":"Anambra","LGAName":"Idemili North","WardName":"Umuoji"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Akwukwu"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Alor I"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Alor II"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Awka-etiti I"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Awka-etiti II"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Nnobi I"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Nnobi II"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Nnobi III"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Nnokwa"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Oba I"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Oba II"},
 {"StateName":"Anambra","LGAName":"Idemili-south","WardName":"Ojoto"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Amamu I"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Amamu II"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Amorka"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Azia"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Ihite"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Isseke"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Ogbolo"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Okija I"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Okija II"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Okija III"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Okija iv"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Okija v"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Orsumoghu"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Ubuluisiuzo"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Uli I"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Uli II"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Uli III"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Lilu"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Uzoakwa"},
 {"StateName":"Anambra","LGAName":"Ihala","WardName":"Mbosi"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Abba I"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Abba II"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Abagana I"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Abagana II"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Abagana III"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Abagana iv"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Enugwu-agidi I"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Enugwu-agidi II"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Enugwu Ukwu I"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Enugwu Ukwu II"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Enugwu Ukwu III"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Enugwu Ukwu iv"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Nawfia I"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Nawfia II"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Nimo I"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Nimo II"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Nimo III"},
 {"StateName":"Anambra","LGAName":"Njikoka","WardName":"Nimo iv"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Nnewi-ichi I"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Nnewi-ichi II"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Otolo I"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Otolo II"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Otolo III"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Umudim I"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Umudim II"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Uruagu I"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Uruagu II"},
 {"StateName":"Anambra","LGAName":"Nnewi North","WardName":"Uruagu III"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Akwa-ihedi"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Amichi I"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Amichi II"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Amichi III"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Azuigbo"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ebenator"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ekwulumili"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ezinifite I"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ezinifite"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ezinifite II"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ezinifite III"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Osumenyi I"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Osumenyi II"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ukpor I"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ukpor II"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ukpor III"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ukpor Iv"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ukpor V"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Ukpor Vi"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Unubi"},
 {"StateName":"Anambra","LGAName":"Nnewi South","WardName":"Utuh"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Akili Ogidi obeagwe"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Akili - ozizor"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Atani I"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Atani II"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Iyiowa odekpe ohita"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Ochuche-umuodu ogbakuba amiyi"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Ogwuaniocha"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Ogwu-ikpele"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Okpoko I"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Okpoko II"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Okpoko III"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Okpoko Iv"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Okpoko V"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Okpoko Vi"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Ossomala"},
 {"StateName":"Anambra","LGAName":"Ogbaru","WardName":"Umunankwo mputu"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"American Quarters"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"G.r.a"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town I"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town II"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town III"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town iv"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town v"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town vi"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town vii"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Inland Town viii`"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Ogbe Umuonicha"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Trans Nkisi"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Water-side Central 1"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Water-side Central II"},
 {"StateName":"Anambra","LGAName":"Onitsha-north","WardName":"Woluwo Layout"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Bridge Head I"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Bridge Head II"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Bridge Head III"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge 1"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge II"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge III"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge 1v"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge V"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge Vi"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Fegge Vii"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu I"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu II"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu III"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu iv"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu V"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu Vi"},
 {"StateName":"Anambra","LGAName":"Onitsha -south","WardName":"Odoakpu Vii"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ajalli i (obinikpa and umueve)"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ajalli II (umuabiama and amaga)"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Amaetiti"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Amaokpala omogho"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Awa"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Awgbu I"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Awgbu II"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Nanka I"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Nanka II"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ndikelionwu"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ndiokolo ndiokpaleke"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ndiowu"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ndiukwuenu okpeze"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Oko I"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Oko II"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ndi Okpalaeze"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ufuma I"},
 {"StateName":"Anambra","LGAName":"Orumba North","WardName":"Ufuma II"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Agbudu"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Akpu"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Enugu umonyia"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Eziagu"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Ezira"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Ihite"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Isulo"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Nawfija"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Nkerehi"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Ogboji"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Ogbunka I"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Ogbunka II"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Owerre-ezukala I"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Owerre-ezukala II"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Umunze I"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Umunze II"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Umunze III"},
 {"StateName":"Anambra","LGAName":"Orumba south","WardName":"Umuomaku"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Awkuzu I"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Awkuzu II"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Awkuzu III"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Awkuzu iv"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nteje I"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nteje II"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nteje III"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nteje iv"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nteje v"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nkwelle Ezunaka I"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Nkwelle Ezunaka II"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Ogbunike I"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Ogbunike II"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Umunya I"},
 {"StateName":"Anambra","LGAName":"Oyi","WardName":"Umunya II"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Alkaleri"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Pali"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Gar"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Gwaram"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Maimadi"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Dan Kungibar"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Birin Gigara yankari"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Yuli Lim"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Futuk"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Yalo"},
 {"StateName":"Bauchi","LGAName":"Alkaleri","WardName":"Gwana mansur"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Majidadi A"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Majidadi B"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Makama sarki Baki"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Zungur liman katagum"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Mun munsal"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Dandango yamrat"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Birshi miri"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Kundum durum"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Kangyare turwun"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Galambi gwaskwaram"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Dan'iya Hardo"},
 {"StateName":"Bauchi","LGAName":"Bauchi","WardName":"Dawaki"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Bogoro A"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Bogoro B"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Bogoro C"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Bogoro D"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"B O i A"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"B O i B"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"B O i C"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Lusa A"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Lusa B"},
 {"StateName":"Bauchi","LGAName":"Bogoro","WardName":"Lusa C"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Garuza"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Gurbana"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Dambam"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Yanda"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Yame"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Dagauda"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Gargawa"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Zaura"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Jalam Central"},
 {"StateName":"Bauchi","LGAName":"Dambam","WardName":"Jalam East"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Darazo"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Tauya"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Gabarin"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Konkiyal"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Lago"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Sade"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Lanzai"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Yautare"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Gabciyari"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Wahu"},
 {"StateName":"Bauchi","LGAName":"Darazo","WardName":"Papa"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Bagel bajar"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Bundot"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Bununu Central"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Bununu South"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Baraza"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Dott"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Durr"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Polchi"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Wandi"},
 {"StateName":"Bauchi","LGAName":"Dass","WardName":"Zumbul lukshi"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Gamawa"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Kafin romi"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Gololo"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Kubdiya"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Alagarno jadori"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Tumbi"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Udubo"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Tarmasuwa"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Raga"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Gadiya"},
 {"StateName":"Bauchi","LGAName":"Gamawa","WardName":"Zindi"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Ganjuwa"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Gungura"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Kafin Madaki"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Kariya"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Kubi East"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Kubi West"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Miya east"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Miya west"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Nasarawa North"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Nasarawa south"},
 {"StateName":"Bauchi","LGAName":"Ganjuwa","WardName":"Yali"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Chinkani"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Sabon Sara"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Doguwa Central"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Doguwa south"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Giade"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Isawa"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"U. zum A"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Uzum B"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Zabi"},
 {"StateName":"Bauchi","LGAName":"Giade","WardName":"Zirrami"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Itas"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Mashema"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Gwarai"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Abdallawa magarya"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Buzawa"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Bilkicheri"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Bambal"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Gadau"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Kashuri"},
 {"StateName":"Bauchi","LGAName":"Itas gadau","WardName":"Zubuki"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Jama'are A"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Jama'are B"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Jama'are C"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Jama'are D"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Dogon Jeji A"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Dogon Jeji B"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Dogon Jeji C"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Hanafari"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Galdimari"},
 {"StateName":"Bauchi","LGAName":"Jama'are","WardName":"Jurara"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Tsakuwa Kofar gabas kofar kuka"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Nasarawa Bakin kasuwa"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Madangala"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Madara"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Buskuri"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Ragwam magonshi"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Gambaki bidir"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Chinade"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Bulkachuwa dagaro"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Yayu"},
 {"StateName":"Bauchi","LGAName":"Katagum","WardName":"Madachi gangai"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Badara"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Bara"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Beni A"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Beni B"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Dewu Central"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Dewu East"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Kirfi"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Shango"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Tubule"},
 {"StateName":"Bauchi","LGAName":"Kirfi","WardName":"Wanka"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Zadawa"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Beti"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Jarkasa"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Kukadi gundari"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Ajilin gugulin"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Tofu"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Hardawa"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Sarma akuyam"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Sirko"},
 {"StateName":"Bauchi","LGAName":"Misau","WardName":"Gwaram"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Ningi"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Dingis"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Nasaru"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Jangu"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Balma"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Kudu yamma"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Tiffi guda"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Burra kyata"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Sama"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Bashe"},
 {"StateName":"Bauchi","LGAName":"Ningi","WardName":"Kurmi"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Andubun"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Sambuwal"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Bukul bangire"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Disina"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Faggo"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Beli gagidaba"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Kilbori"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Shira"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Tsafi"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Tumfafi"},
 {"StateName":"Bauchi","LGAName":"Shira","WardName":"Zubo"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Kardam A"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Kardam B"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Lere North"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Lere south"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Tapshin"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Wai"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Ball"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Bula"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Dajin"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Dull"},
 {"StateName":"Bauchi","LGAName":"Tafawa Balewa","WardName":"Bununu"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Toro tulai"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Tilden Fulani"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Ribina"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Mara palama"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Rauta geji"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Jama'a zaranda"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Lame"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Wonu"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Zalau rishi"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Tama"},
 {"StateName":"Bauchi","LGAName":"Toro","WardName":"Rahama"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Baima North west"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Baima south east"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Dagu East"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Dagu West"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Gabanga"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Katanga"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Rangan"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Tiyin"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Kilbori"},
 {"StateName":"Bauchi","LGAName":"Warji","WardName":"Tudun Wada west"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Bursali"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Katagum"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Tashena gadai"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Makawa"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Sakwa"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Gumai"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Murmur North"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Murmur south"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Alangawari kafin larabawa"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Maiwa"},
 {"StateName":"Bauchi","LGAName":"Zaki","WardName":"Mainako"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Brass 1"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Brass Ward II"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Ewoama fantuo"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Okpoama"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Odioma diema"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Cape Farmosa"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Konsho"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Os-inibiri"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Minibie"},
 {"StateName":"Bayelsa","LGAName":"Brass","WardName":"Sangana"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Tarakiri"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oyiakiri I"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oyiakiri II"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oyiakiri III"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oyiakiri Iv"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oporomor I"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oporomor II"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oporomor III"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oporomor Iv"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Oporomor V"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Eduwini I"},
 {"StateName":"Bayelsa","LGAName":"Ekeremor","WardName":"Eduwini II"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Odi (north) I"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Odi (central) II"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Odi (south) III"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Kaiama"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Kaiama olobiri"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Sampou kalama"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Opokuma North"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Opokumasouth"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Seibokorogha (sabagreia) 1"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Okoloba (sabagreia) II"},
 {"StateName":"Bayelsa","LGAName":"Kolokuma opokuma","WardName":"Igbedi"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Ogbolomabiri 1"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Ogbolomabiri 11"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Ogbolomabiri 111"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Bassambiri 1"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Bassambiri 11"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Bassambiri 111"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Bassambiri 1v"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Igbeta-ewoama fantuo"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Okoroma 1"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Okoroma 11"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Mini"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Ikensi"},
 {"StateName":"Bayelsa","LGAName":"Nembe","WardName":"Oluasiri"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Ogbia"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Otuokpoti"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Ologi"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Anyama"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Okodi"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Otuasega"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Emeyal"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Imiringi"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Kolo"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Oloibiri"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Opume"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Otakeme"},
 {"StateName":"Bayelsa","LGAName":"Ogbia","WardName":"Otuabula"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Agbere"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Angalabiri"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Ofoni I"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Ofoni II"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Ebedebiri"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Ossiama"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Asamabiri"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Adoni"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Trofani"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Sagbama"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Agoro"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Toru-ebeni"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Adagbabiri"},
 {"StateName":"Bayelsa","LGAName":"Sagbama","WardName":"Osekwenike"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Oporoma 1"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Oporoma II"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Olodiama I"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Olodiama II"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Otuan"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"West Boma"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Central Boma I"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Central Boma II"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Amassoma I"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Amassoma II"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Amassoma III"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"East Boma I"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"East Boma II"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Foropa"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Apoi"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Ukubie"},
 {"StateName":"Bayelsa","LGAName":"Southern Ijaw","WardName":"Koluama"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Attissa I"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Attissa II"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Attissa III"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Epie I"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Epie 11"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Epie III"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Gbarain I"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Gbarain II"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Gbarain III"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Ekpetiama I"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Ekpetiama 11"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Biseni 1"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Biseni 11"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Okordia"},
 {"StateName":"Bayelsa","LGAName":"Yenagoa","WardName":"Zarama"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Akoge ogbilolo"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Apa"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Ekile"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Igumale I"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Igumale II"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Ijigban"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Ogege"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Royongo"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Ukwonyo"},
 {"StateName":"Benue","LGAName":"Ado","WardName":"Ulayi"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Egba"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Enungba"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Obagaji"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Odugbeho"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Ogbaulu"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Ogwule-kaduna"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Ogwule Ogbaulu"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Okokolo"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Oshigbudu"},
 {"StateName":"Benue","LGAName":"Agatu","WardName":"Usha"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Akpete ojantelle"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Auke"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Edikwu I"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Edikwu II"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Igah-okpaya"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Igoro"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Ikobi"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Oba"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Ofoke"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Oiji"},
 {"StateName":"Benue","LGAName":"Apa","WardName":"Ugbokpo"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Binev"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Etulo"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaade"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaakura"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaapen"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaatirkyaa"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaazagee"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaikyongo nyifon"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaityough"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbakyaan"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbaya"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Mbayaka"},
 {"StateName":"Benue","LGAName":"Buruku","WardName":"Shorov"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Gbk central Market"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Gboko East"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Gboko North west"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Gboko South"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Igyorov"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbaanku"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbaa Varakaa"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbadam"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbadim"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbakper"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbakwen"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbatan"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbatser"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Mbatyu"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Ukpekpe"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Yandev North"},
 {"StateName":"Benue","LGAName":"Gboko","WardName":"Yandev South"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Abinsi"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Kaambe"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Mbabai"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Mbadwem"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Mbawa"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Mbayer yandev"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Nyiev"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Nzorov"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Saghev"},
 {"StateName":"Benue","LGAName":"Guma","WardName":"Uvir"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Akpach'ayi"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Aliade Town"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Gbemacha"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Ikyogbajir"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Ikyonov"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbabur"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbaiase"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbaikyu"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbaikyaan"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbalom"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbasombo"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Mbayom"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Shough"},
 {"StateName":"Benue","LGAName":"Gwer East","WardName":"Ugee"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Avihijime"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Gaambe - ushin"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Gbaange tongov"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Ikyaghev"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Ityoughatee injaha"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Mbabuande"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Mbachohon"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Mbanyamshi"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Mbapa"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Merkyen"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Sagher ukusu"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Sengev"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Sengev yengev"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Tijime"},
 {"StateName":"Benue","LGAName":"Gwer West","WardName":"Isambe mbasev"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Ikurav Tiev I"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Ikurav Tiev II"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Iwar(tongov I)"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Katsina-ala Town"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Mbacher"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Mbajir"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Mbatula mberev"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Mbayongo"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Michihe"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Tiir(tongov II)"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Utange"},
 {"StateName":"Benue","LGAName":"Katsina-ala","WardName":"Yooyo"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Ikyurav mbatwer"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbagusa mbatser"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbaikyase"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbaiwarnyam"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbake"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbanor"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbatsen"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbavoa"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbawar"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Mbayegh mbaikyer"},
 {"StateName":"Benue","LGAName":"Konshisha","WardName":"Tse-agberagba"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Adikpo Metropolis"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Liev I"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Liev II"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Mbadura"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Mbagba mbaikyan"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Mbaikyor"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Mbaketsa"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Menev"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Tondov I"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Tondov II"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Usar"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Mbayoo"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Yaav"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Moon"},
 {"StateName":"Benue","LGAName":"Kwande","WardName":"Kumakwagh"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Mbadyul"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Mbagber"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Mbater"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Mbayam"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Mbavuur"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Nenzev"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Tombo"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Turan"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Ukembergya iswarev"},
 {"StateName":"Benue","LGAName":"Logo","WardName":"Yonov"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Agan"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Ankpa wadata"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Bar"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Central south Mission"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Clerks market"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Fildi"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Mbalagh"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Modern Market"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"North Bank I"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"North Bank II"},
 {"StateName":"Benue","LGAName":"Makurdi","WardName":"Wailomayo"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Adiko"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Adum West"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Ikwokwu"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Irabi"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Itogo"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Obarike"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Obeko"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Odiapa"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Ogore"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Okpokwu"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Okwutungbe"},
 {"StateName":"Benue","LGAName":"Obi","WardName":"Orihi"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ai-oodo I"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ai-oodo II"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ai-oono I"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ai-oono II"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ai-oono III"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ehaje I"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Ehaje II"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Itabono I"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Itabono II"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Olachagbaha"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Orokam I"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Orokam II"},
 {"StateName":"Benue","LGAName":"Ogbadibo","WardName":"Orokam III"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Adokpa"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Ainu"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Ibilla"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Idelle"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Iyeche"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Oboru oye"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Oju"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Okpokpo"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Okwudu"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Owo"},
 {"StateName":"Benue","LGAName":"Oju","WardName":"Ukpa ainu Ette"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Agadagba"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Awume Ehaje"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Awume Icho"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Ehatokpe"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Idekpa"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Ochobo"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Oglewu Ehaje"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Oglewu Icho"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Onyagede-ehaje (alle)"},
 {"StateName":"Benue","LGAName":"Ohimini","WardName":"Onyagede Icho (ogoli)"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Amejo"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Eke"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Ichama II"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Ojigo"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Ojoga"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Okonobo"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Okpaile ingle"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Okpoga Central"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Okpoga North"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Okpoga South"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Okpoga West"},
 {"StateName":"Benue","LGAName":"Okpokwu","WardName":"Ugbokolo"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Adoka-icho"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Adoka-haje"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Allan"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Entekpa"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Ewulo"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Okete"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Otobi"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Otukpo Town central"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Otukpo Town east"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Otukpo Town west"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Ugboju-ehaje"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Ugboju-icho"},
 {"StateName":"Benue","LGAName":"Otukpo","WardName":"Ugboju-otahe"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbaajir Akaa"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbaayo"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbachaver Ikyondo"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbaigba"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbaikyaa"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbaikyo mbayia"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbakwakem"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Mbanyagber"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Shitile"},
 {"StateName":"Benue","LGAName":"Tarka","WardName":"Tongov"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Aterayange"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Azendeshi"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Borikyo"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Ityuluv"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Kendev"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Kundav"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Lumbuv"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Mbatian"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Mbayenge"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Mbazun"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Tsaav"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Ugbaam"},
 {"StateName":"Benue","LGAName":"Ukum","WardName":"Uyam"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Atikyese"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Ikyov"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Lessel"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbaaka"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbaanyam"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbagwe"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbagba"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbagwaza"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbakuha"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Mbayegh"},
 {"StateName":"Benue","LGAName":"Ushongo","WardName":"Utange"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbadede"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbatyough"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbagbam"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbagbera"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbajor"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbakaange"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbakyaha"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbayongo"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Ningev"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Tsambe"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Vandeikya Township"},
 {"StateName":"Benue","LGAName":"Vandeikya","WardName":"Mbanyumangbagh"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Arege"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Banowa"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Fuguwa"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Jabullam"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Kudokurgu"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Mallamfatori Kessa"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Malam Kaunari"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Yau"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Yawa Kura"},
 {"StateName":"Borno","LGAName":"Abadam","WardName":"Yituwa"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Askira East"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Chul rumirgo"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Dille huyum"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Husara tampul"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Kopa multhafu"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Lassa"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Mussa"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Ngohi"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Ngulde"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Uba"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Uda uvu"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Wamdeo giwi"},
 {"StateName":"Borno","LGAName":"Askira uba","WardName":"Zadawa hausari"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Andara ajiri wulba"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Buduwa bula chirabe"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Dipchari jere dar-jamal kotembe"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Gulumba jukkuri batra"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Kasugula"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Kumshe nduguno"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Lawanti malam mastari abbaram"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Marka malge amchaka"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Mbuliya goniri siraja"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Sabsabwa soye bulongu"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Shehuri hausari mairi"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Wulbari ndine chachile"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Yabiri Kura yabiri gana chongolo"},
 {"StateName":"Borno","LGAName":"Bama","WardName":"Zangeri kash kash"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Balbaya"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Briyel"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Fikayel"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Gamadadi"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Jara Gol"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Jara Dali"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Limanti"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Teli"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Wuyo"},
 {"StateName":"Borno","LGAName":"Bayo","WardName":"Zara"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Buratai"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Dadin Kowa"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Dugja"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Garubula"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Gur"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Kenken"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Mandara Girau"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Miringa"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Sulumthla"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Yawi"},
 {"StateName":"Borno","LGAName":"Biu","WardName":"Zarawuyaku"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Chibok Garu"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Chibok Likama"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Chibok Wuntaku"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Gatamarwa"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Kautikari"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Korongilim"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Kuburmbula"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Mbalala"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Mboa Kura"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Shikarkir"},
 {"StateName":"Borno","LGAName":"Chibok","WardName":"Pemi"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Ajign (a)"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Ajign (b)"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Azur multe forfor"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Bego yerwa ngurna"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Damboa"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Gumsuri misakurbudu"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Kafa mafi"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Mulgwai kopchi"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Nguda wuyaram"},
 {"StateName":"Borno","LGAName":"Damboa","WardName":"Wawa korede"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Boboshe"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Dikwa"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Gajibo"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Ufaye gujile"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Muliye jemuri"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Mudu kaza"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Mallam Maja"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Ngudoram"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Magarta sheffri"},
 {"StateName":"Borno","LGAName":"Dikwa","WardName":"Sogoma afuye"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Ardimini"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Dabira"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Felo"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Gamowo"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Gazabure"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Gubio Town I"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Gubio Town II"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Kingowa"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Ngetra"},
 {"StateName":"Borno","LGAName":"Gubio","WardName":"Zowo"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Aduwa"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Gudumbali East"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Gudumbali West"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Guworam"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Guzamala East"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Guzamala West"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Kingarwa"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Mairari"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Moduri"},
 {"StateName":"Borno","LGAName":"Guzamala","WardName":"Wamiri"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Ashigashiya"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Bita izge"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Dure wala warabe"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Gavva agapalwa"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Guduf Nagadiyo"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Gwoza Town gadamayo"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Gwoza Wakane bulabulin"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Hambagda Liman kara new settlement"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Johode chikide kughum"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Kirawa jimini"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Kurana Bassa ngoshe - sama'a"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Ngoshe"},
 {"StateName":"Borno","LGAName":"Gwoza","WardName":"Pulka bokko"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Bilingwi"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Dzar Vinadum birni dlandi"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Gwanzang pusda"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Hizhi"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Kida"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Kwajaffa hang"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Kwaya-bur tanga Rumta"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Marama kidang"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Pama whitambaya"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Puba vidau lokoja"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Sakwa hema"},
 {"StateName":"Borno","LGAName":"Hawul","WardName":"Shaffa"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Alau"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Bale Galtimari"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Dala Lawanti"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Dusuman"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Gongulong"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Maimusari"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Mashamari"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Ngudaa addamari"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Old Maiduguri"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Tuba"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Mairi"},
 {"StateName":"Borno","LGAName":"Jere","WardName":"Gomari"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Afa dig maudori"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Benisheikh"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Borgozo"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Dogoma jalori"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Dongo"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Galangi"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Guwo"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Karagawaru"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Mainok"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Marguba"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Ngamdu"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Shettimari"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Tobolo"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Wajiro burgumma"},
 {"StateName":"Borno","LGAName":"Kaga","WardName":"Wassaram"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Moholo"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Jilbe A"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Jilbe B koma kaudi"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Jarawa sangaya"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Kala"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Kumaga"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Rann A"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Rann B daima"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Mada"},
 {"StateName":"Borno","LGAName":"Kala Balge","WardName":"Sigal karche"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Auno chabbol"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Dalori wanori"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Dawa East malari kangamari"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Jewu lamboa"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Kawuri"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Kelumiri ngalbi amari yale"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Konduga"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Mairamri yeleri bazamri"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Masba dalwa west"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Nyaleri sandia yejiwa"},
 {"StateName":"Borno","LGAName":"Konduga","WardName":"Sojiri Nguro - nguro"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Alagarno"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Baga"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Bundur"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Dogoshi"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Doro duguri"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Kauwa"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Kekeno"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Kukawa"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Moduari barwari"},
 {"StateName":"Borno","LGAName":"Kukawa","WardName":"Yoyo"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Gondi"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Gusi billa"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Guwal"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Kubuku"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Kurba"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Kwaya Kusar"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Peta"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Wada"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Wawa"},
 {"StateName":"Borno","LGAName":"Kwaya kusar","WardName":"Yimirthalang"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Abbari"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Anadua"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Gawa"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Koshebe"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Laje"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Limanti"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Loskuri"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Ma'afa"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Mafa"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Masu"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Mujigine"},
 {"StateName":"Borno","LGAName":"Mafa","WardName":"Tamsu Ngamdua"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Ardo Ram"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Ayi yasku"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Borno Yesu"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Furram"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Gaji Ganna I"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Gaji Ganna II"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Hoyo chin gowa"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Kalizoram banoram"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Kareram"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Kubti"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Magumeri"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Ngamma"},
 {"StateName":"Borno","LGAName":"Magumeri","WardName":"Ngubala"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Bolori I"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Bolori II"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Bulablin"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Fezzan"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Gamboru Liberty"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Gwange I"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Gwange II"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Gwange III"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Hausari zango"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Lamisula jabba Mari"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Limanti"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Mafoni"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Maisandari"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Shehuri north"},
 {"StateName":"Borno","LGAName":"Maiduguri M. c.","WardName":"Shehuri South"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Ala"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Alla lawanti"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Borsori"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Gumna"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Kabulawa"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Kirenowa"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Kulli"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Marte"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Mawulli"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Musune"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Ngeleiwa"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Njine"},
 {"StateName":"Borno","LGAName":"Marte","WardName":"Zaga"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Asaga"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Bogum"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Chamba"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Damasak"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Duji"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Gashagar"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Kareto"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Layi"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Zanna Umorti"},
 {"StateName":"Borno","LGAName":"Mobbar","WardName":"Zari"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Damakuli"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Kaguram"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Kumalia"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Mofio"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Mandala"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Mintar"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Monguno"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Ngurno"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Sure"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Wulo"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Yele"},
 {"StateName":"Borno","LGAName":"Monguno","WardName":"Zulum"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Sagir"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Fuye"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Gamboru B"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Gamboru C"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Logumane"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Ndufu"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Ngala Ward"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Old Gamboru A"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Tunokalia"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Warshele"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Wulgo"},
 {"StateName":"Borno","LGAName":"Ngala","WardName":"Wurge"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Alarge"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Badu"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Damaram"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Gajiram"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Gadai"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Jigalta"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Kuda"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Kurnawa"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Maiwa"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Miye"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Sabsabuwa"},
 {"StateName":"Borno","LGAName":"Nganzai","WardName":"Sugundure"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Bargu burashika"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Buma"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Gasi salifawa"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Gora"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Gwalasho"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Gwaskara"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Kombo"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Kubo"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Kwaba"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Shani"},
 {"StateName":"Borno","LGAName":"Shani","WardName":"Walama"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Adadama"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Afafanyi igonigoni"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Ebom"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Ediba"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Ekureku I"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Ekureku II"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Imabana I"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Imabana II"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Itigidi"},
 {"StateName":"Cross River","LGAName":"Abi","WardName":"Usumutong"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Akamkpa Urban"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Awi"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Eku"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Iko"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Ikpai"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Mbarakom"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Oban"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Ojuk North"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Ojuk South"},
 {"StateName":"Cross River","LGAName":"Akamkpa","WardName":"Uyanga"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Atimbo East"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Atimbo West"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Eneyo"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Idundu anyanganse"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Ikang Central"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Ikang North"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Ikang South"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Ikot Edem odo"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Ikot Eyo"},
 {"StateName":"Cross River","LGAName":"Akpabuyo","WardName":"Ikot Nakanda"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Abana"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Akpankanya"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Akwa"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Ambai Ekpa"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Amoto"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Archibong"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Atai Ema"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Efut Inwang"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Ekpot Abia"},
 {"StateName":"Cross River","LGAName":"Bakassi","WardName":"Odiong"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Abuochiche"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Afrike Ochagbe"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Afrike Okpeche"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Beten"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Gakem"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Ibiaragidi"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Nyanya"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Otukpuru"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Ugboro"},
 {"StateName":"Cross River","LGAName":"Bekwarra","WardName":"Ukpah"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Abayong"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Adim"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Agwagune okurike"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Akpet abini"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Biakpan"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Ehom"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Erei North"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Erei South"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Ikun etono"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Umon North"},
 {"StateName":"Cross River","LGAName":"Biase","WardName":"Umon South"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Abo"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Alankwe"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Beebo bumaji"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Boje"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Buda"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Buentsebe"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Bunyia okubuchi"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Ekpashi"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Kakwagom bawop"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Ogep osokom"},
 {"StateName":"Cross River","LGAName":"Boki","WardName":"Oku borum njua"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"I"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Ii"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Iii"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Iv"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"V"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Vi"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Vii"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Viii"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"Ix"},
 {"StateName":"Cross River","LGAName":"Calabar Municipality","WardName":"X"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"One (1)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Two (2)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Three (3)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Four (4)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Five (5)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Six (6)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Seven (7)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Eight (8)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Nine (9)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Ten (10)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Eleven (11)"},
 {"StateName":"Cross River","LGAName":"Calabar South","WardName":"Twelve (12)"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Abia"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Abijang"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Agbokim"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Ajassor"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Bendeghe Ekiem"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Effraya"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Etomi"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Itaka"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Mkpot ayuk Aba"},
 {"StateName":"Cross River","LGAName":"Etung","WardName":"Nsofang"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Abanyum"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Akparabong"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Ikom Urban I"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Ikom Urban II"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Nde"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Nnam"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Nta nselle"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Ofutop I"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Ofutop II"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Olulumo"},
 {"StateName":"Cross River","LGAName":"Ikom","WardName":"Yala nkum"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Basang"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Bebi"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Becheve"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Bendi I"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Bendi II"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Bishiri North"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Bishiri South"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Bisu"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Busi"},
 {"StateName":"Cross River","LGAName":"Obanliku","WardName":"Utanga"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Ababene"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Apiapum"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Iyamoyong"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Obubra Urban"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Ochon"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Ofat"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Ofodua"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Ofumbongha yala"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Osopong I"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Osopong II"},
 {"StateName":"Cross River","LGAName":"Obubra","WardName":"Ovonum"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Alege ubang"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Angiaba begiaka"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Begiading"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Ipong"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Obudu Urban I"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Obudu Urban II"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Ukpe"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Utugwang Central"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Utugwang North"},
 {"StateName":"Cross River","LGAName":"Obudu","WardName":"Utugwang South"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Adiabo efut"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Akamkpa"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Creek Town I"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Creek Town II"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Ekori anaku"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Eniong"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Eki"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Obomitiat mbiabo ediong"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Odot"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Odukpani Central"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Oniman-kiong"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Ikoneto"},
 {"StateName":"Cross River","LGAName":"Odukpani","WardName":"Ito idere ukwa"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Ekajuk I"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Ekajuk II"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Mbube East I"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Mbube East II"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Mbube West I"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Mbube West II"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Nkum Iborr"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Nkum Irede"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Ogoja Urban I"},
 {"StateName":"Cross River","LGAName":"Ogoja","WardName":"Ogoja Urban II"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Afrekpe ekpenti"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Ajere"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Assiga"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Biko Biko"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Idomi"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Ijiman"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Ijom"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Ikpakapit"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Inyima"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Mkpani agoi"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Abanakpai"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Nkpolo ukpawen"},
 {"StateName":"Cross River","LGAName":"Yakurr","WardName":"Ntan"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Echumofana"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Gabu"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Ijiraga"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Njrigom mfuma"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Okpoma"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Okuku"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Wanihem"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Wanikade"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Wanakom"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Yache"},
 {"StateName":"Cross River","LGAName":"Yala","WardName":"Yahe"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Obior"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Onicha Ugbo"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Obomkpa"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Onicha - olona"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Issele - azagba"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Issele Uku I"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Issele Uku II"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Idumuje - unor"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Ukwu - nzu"},
 {"StateName":"Delta","LGAName":"Aniocha North","WardName":"Ezi"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ogwashi - uku village"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ogwashi - uku I"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ogwashi - uku II"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ubulu - uku I"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ubulu - uku II"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ubulu - unor"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Nsukwa"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ejeme"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Isheagu-ewulu"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Aba - unor"},
 {"StateName":"Delta","LGAName":"Aniocha - south","WardName":"Ubulu Okiti"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Bomadi"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Kpakiama"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Syama"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Ogriagbene"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Kolafiogbene ekametagbene"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Ogbeinama okoloba"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Akugbene I"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Akugbene II"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Akugbene III"},
 {"StateName":"Delta","LGAName":"Bomadi","WardName":"Ogo - eze"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Torugbene"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Tamigbe"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Tuomo"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Seimbiri"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Ojobo"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Bulou - ndoro"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Ngbilebiri I"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Ngbilebiri II"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Ogbolubiri"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Obotebe"},
 {"StateName":"Delta","LGAName":"Burutu","WardName":"Ogulagha"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Abraka I"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Abraka II"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Abraka III"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon I"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon II"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon III"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon iv"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon v"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon vi"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon vii"},
 {"StateName":"Delta","LGAName":"Ethiope east","WardName":"Agbon viii"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Mosogar I"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Mosogar II"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Jesse I"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Jesse II"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Jesse III"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Jesse Iv"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Oghara I"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Oghara II"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Oghara III"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Oghara Iv"},
 {"StateName":"Delta","LGAName":"Ethiope west","WardName":"Oghara V"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Owa I"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Owa II"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Owa III"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Owa Iv"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Owa v"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Owa vi"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Akumazi"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Igbodo"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Ute - okpu"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Idumuesah"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Umunede"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Mbiri"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Ute - ogbeje"},
 {"StateName":"Delta","LGAName":"Ika North- east","WardName":"Otolokpo"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Agbor Town I"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Agbor Town II"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Ihuozomor (ozanogogo alisimie)"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Ihiuiyase I"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Ekuku - agbor"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Ihuiyase II"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Boji - boji I"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Boji - boji II"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Boji - boji III"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Abavo I"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Abavo II"},
 {"StateName":"Delta","LGAName":"Ika - south","WardName":"Abavo III"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Iyede I"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Iyede II"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Ellu"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Ovrode"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Iluelogbo"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Owhe akiehwe"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Emevor"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Okpe - isoko"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Ozoro I"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Ozoro II"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Ozoro III"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Oyede"},
 {"StateName":"Delta","LGAName":"Isoko North","WardName":"Otibio"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Oleh I"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Oleh II"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Aviara"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Uzere"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Emede"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Olomoro"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Igbide"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Erowa umeh"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Enhwe okpolo"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Irri I"},
 {"StateName":"Delta","LGAName":"Isoko South","WardName":"Irri II"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Ossissa"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Afor obikwele"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Abarra inyi onuaboh"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Okpai utchi beneku"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Aboh akarrai"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Onyia adiai otuoku umuolu"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Ase"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Ibedeni"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Ibrede igbuku onogbokor"},
 {"StateName":"Delta","LGAName":"Ndokwa East","WardName":"Ashaka"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Utagba ogbe"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Utagba uno I"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Utagba uno II"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Utagba uno III"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Onicha - ukwani"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Ogume I"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Ogume II"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Abbi I"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Abbi II"},
 {"StateName":"Delta","LGAName":"Ndokwa West","WardName":"Emu"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Orerokpe"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Oviri - okpe"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Oha I"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Oha II"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Aghalokpe"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Aragba town"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Mereje I"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Mereje II"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Mereje III"},
 {"StateName":"Delta","LGAName":"Okpe","WardName":"Ughoton"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Akwukwu"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ebu"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Illah"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ibusa I"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ibusa II"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ibusa III"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ibusa iv"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ibusa V"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Okpanam"},
 {"StateName":"Delta","LGAName":"Oshimili - north","WardName":"Ukala"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Ogbele akpako"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Anala-amakom"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Okwe"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Umuezei"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Umuaji"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Umuonaje"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Agu"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Ugbomanta Quarters"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"West End"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Cable Point I"},
 {"StateName":"Delta","LGAName":"Oshimili - south","WardName":"Cable Point II"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Abari"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Patani I"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Patani II"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Patani III"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Taware kolowara Aven"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Agoloma"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Toru-angiama"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Bolou - angiama"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Uduophori"},
 {"StateName":"Delta","LGAName":"Patani","WardName":"Odorubu adobu bolou Apelebri"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele Urban I"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele Urban II"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele urban III"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele urban iv"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele urban v"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele urban vi"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele urban vii"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Sapele urban viii"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Amuokpe"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Elume"},
 {"StateName":"Delta","LGAName":"Sapele","WardName":"Okokporo ugborhen"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Udu I"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Udu II"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Udu III"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Udu iv"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Opete assagba edjophe"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Ekete"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Ovwian I"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Ovwian II"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Orhuwerun"},
 {"StateName":"Delta","LGAName":"Udu","WardName":"Aladja"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Agbarha"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Ogor"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Orogun I"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Orogun II"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Ughelli I"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Ughelli II"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Ughelli III"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Evwreni"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Uwheru"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Agbarho I"},
 {"StateName":"Delta","LGAName":"Ughelli North","WardName":"Agbarho II"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Ewu I"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Ewu II"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Ewu III"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Olomu I"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Olomu II"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Effurun - otor"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Ekakpamre"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Jeremi I"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Jeremi II"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Jeremi III"},
 {"StateName":"Delta","LGAName":"Ughelli South","WardName":"Jeremi iv"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Umutu"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Akoku"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Ebedei"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Umukwata"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Eziokpor"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Amai"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Ezionum"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Umuebu"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Obiaruku I"},
 {"StateName":"Delta","LGAName":"Ukwuani","WardName":"Obiaruku II"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Effurun I"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Effurun II"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Enerhen I"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Enerhen II"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Ugborikoko"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Ugboroke"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Ugbomro ugbolokposo"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Army Barracks area"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Ekpan I"},
 {"StateName":"Delta","LGAName":"Uvwie","WardName":"Ekpan II"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Ogheye"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Gbokoda"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Ebrohimi"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Eghoro"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Koko I"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Koko II"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Opuama (egbema i)"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Isekelewu (egbema II)"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Ogbinbiri (egbema III)"},
 {"StateName":"Delta","LGAName":"Warri north","WardName":"Ogbudugbudu (egbema iv)"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Obodo omadino"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Ode-itsekiri"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Ogunu ekurede-urhobo"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Ugbuwangue ekurede-itsekiri"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"G.r.a."},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Bowen"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Pessu"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Okere"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Igbudu"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Edjeba"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Okumagba I"},
 {"StateName":"Delta","LGAName":"Warri South","WardName":"Okumagba II"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Ogbe - ijoh"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Isaba"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Oporoza"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Gbaramatu"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Ugborodo"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Akpikpa"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Madangho"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Orere"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Aja - udaibo"},
 {"StateName":"Delta","LGAName":"Warri South west","WardName":"Ogidigben"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Abakpa"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Amachi (ndebo)"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Amachi (ndegu)"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Amagu enyieba"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Amagu Unuhu"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Azumili azugwu"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Azuiyiokwu Layout"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Azuii Udene"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Edda"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Izzi Unuhu"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Ndiagu"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Okpoitumo Ndebor"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Okpoitumo Ndiegu"},
 {"StateName":"Ebonyi","LGAName":"Abakaliki","WardName":"Timber Shed"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Amata-akpoha"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Amogu Akpoha"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Ezeke Amasiri"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Poperi Amasiri"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Itim Afikpo"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Ibii oziza Afikpo"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Nkpoghoro Afikpo"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Ohaisu Afikpo A"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Ohaisu Afikpo B"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Ugwuegu Afikpo"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Uwana Afikpo 1"},
 {"StateName":"Ebonyi","LGAName":"Afikpo North","WardName":"Uwana Afikpo II"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Amangwu Edda"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Amaeke"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Amigbo Etiti edda"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Amiri"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Amaoso"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Ebunwana"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Ndioke Ekoli edda"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Nguzu"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Amato"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Oso"},
 {"StateName":"Ebonyi","LGAName":"Afikpo south","WardName":"Owutu"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Agalegu"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Egwudinagu"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Kpirikpiri"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Mbeke"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Ndiebo"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Ndiegu"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Echiaba"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Enyibichiri I"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Enyibichiri II"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Abofia"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Onuenyim"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"(abakpa)"},
 {"StateName":"Ebonyi","LGAName":"Ebonyi","WardName":"Urban New layout"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Amuda ama Wula"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Ekka"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Inyere"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Ndieguazu - umuoghara"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Nkomoro"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Ogboji"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Oshiegbe Umuez eokoha"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Omege Umuezeokoha"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Okposi Umuoghara"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Oriuzor"},
 {"StateName":"Ebonyi","LGAName":"Ezza North","WardName":"Umuezeoka"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Amaezekwe"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Amagu nsokara"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Amaeka"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Amana"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Amudo okoffia"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Amuzu"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Echare"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Ezzama"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Ikwuator idembia"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Onueke"},
 {"StateName":"Ebonyi","LGAName":"Ezza South","WardName":"Umunwagu idembia"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ama Inyima"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Echialike"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Eka Awoke"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ekpanwudele"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ekpelu"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Enyibichiri"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Etam"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Igbudu I"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Igbudu II"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Inyimagu I"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Inyimagu II"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndiagu Amagu I"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndiagu Amagu II"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndiagu Echara I"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndiagu Echara II"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndufu Amagu"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndufu Amagu I"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndufu Awke"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Ndufu Echara"},
 {"StateName":"Ebonyi","LGAName":"Ikwo","WardName":"Noye Alike"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Agba"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Amaezu"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Azuinyaba A"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Azuinyaba B"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Ezillo I"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Ezillo II"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Ezzagu(ogboji)"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Ezzagu(nkomor)"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Iyionu"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Nkalagu"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Nkalaha"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Ntezi"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Obeagu"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Ohofia"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Okpoto"},
 {"StateName":"Ebonyi","LGAName":"Ishielu","WardName":"Umuhuali"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Akaeze Ukwu"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Akaeze Ishiagu"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Amagu"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Amonye"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Ihenta Ogidi"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Iyioji Akaeze"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Ndiokoro Ukwu"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Ngwogwo"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Obinagu"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Okue"},
 {"StateName":"Ebonyi","LGAName":"Ivo","WardName":"Umobo"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Agbaja(mgbo)"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Agbaja Anyanwuigwe"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Agbaja Offia onwe"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Ezza Inyimagu igbuhu"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Ezza Inyimagu - igweled oha"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Ezza Inyimagu - iziogo"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Ezza Inyimagu ndiagu"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Igbeagu Nduogbu"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Igbeagu Ndi ettah"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Igbeagu III"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Mgbalaku Inyimagu I"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Mgbalaku Inyimagu II"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Ndieze Inyimagu mgbabeluzor"},
 {"StateName":"Ebonyi","LGAName":"Izzi","WardName":"Ndieze Inyimagu 11 ndiabor ishiagu"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Amaechi Okposi"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Enena Ezeraku"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Mgbom Okposi"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Obiozara"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Okposi Achara"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Okposi Okwu"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Ugbogologo ugwulangwu"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Uhuo Taru-ugwu-langwu"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Umic Hima"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Umunaga"},
 {"StateName":"Ebonyi","LGAName":"Ohaozara","WardName":"Umuobuna"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Effium I"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Effium II"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Ezzamgbo"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Ishi Ngbo I"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Ishi Ngbo II"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Ngbo"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Okposhi I"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Okposhi II"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Umuagara amechi"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Umu Ogudu oshia"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Umu Ogudu akpu I"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Umu Ogudu akpu II"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Wigbeke I"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Wigbeke II"},
 {"StateName":"Ebonyi","LGAName":"Ohaukwu","WardName":"Wigbeke III"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Abaomege"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Agbabor-isu"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Amanator-isu"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Ebia Oshiri"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Enuagu-onicha"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Isinkwo-ukamu"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Isi-onicha"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Obeagu-isu"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Oguduukwor Oshiri"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Okuzu-ukawu"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Ugwu-oshiri"},
 {"StateName":"Ebonyi","LGAName":"Onicha","WardName":"Umudomi-onicha"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Igarra I"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Igarra II"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Imoga Lampese bekuma ekpe"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Ibillo Ekpesa ekor ikiran-ile oke"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Makeke Ojah dangbala ojirami anyawoza"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Oloma Okpe ijaja kakuma anyara"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Somorika ogbe sasaro onumu eshawa ogugu igboshi-afe igboshi - ele aiyegunle"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Enwan atte ikpeshi egbigele"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Uneme-nekhua akpama Aiyetoro ekpedo erhurun uneme osu"},
 {"StateName":"Edo","LGAName":"Akoko Edo","WardName":"Ososo"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Otubu"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Oliha"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Ogida use"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Egor"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Uwelu"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Evbareke"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Uselu I"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Uselu II"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Okhoro"},
 {"StateName":"Edo","LGAName":"Egor","WardName":"Ugbowo"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Uneah"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Uwessan I"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Uwessan II"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Ikekato"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Otoruwo I"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Otoruwo II"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Ewu I"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Ewu II"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Opoji"},
 {"StateName":"Edo","LGAName":"Esan Central","WardName":"Ugbegun"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Egbele"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Obeidu"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Arue"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Uelen Okugbe"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Idumu-okojie"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Uzea"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Amedokhian"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Efandion"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Uwalor"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Ewoyi"},
 {"StateName":"Edo","LGAName":"Esan North east","WardName":"Ubierumu"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ewohimi I"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ewohimi II"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ewatto"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ohordua"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Emu"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ubiaja I"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ubiaja II"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Illushi I"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Illushi II"},
 {"StateName":"Edo","LGAName":"Esan South east","WardName":"Ugboha"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Ogwa"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Ujiogba"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Egoro idoa ukhun"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Emaudo Eguare ekpoma"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Ihunmudumu idumebo uke ujemen"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Iruekpen"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Emuhi Ukpenu ujoelen"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Urohi"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Uhiele"},
 {"StateName":"Edo","LGAName":"Esan West","WardName":"Illeh eko-ine"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Fugar I"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Fugar II"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Fugar III"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Ogbona"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Iraokhor"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Ekperi I"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Ekperi II"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"Ekperi III"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"South Uneme I"},
 {"StateName":"Edo","LGAName":"Etsako Central","WardName":"South Uneme II"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Agenebode"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Wanno I"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Wanno II"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Weppa"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Okpella I"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Okpella II"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Okpella III"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Okpella Iv"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Three Ibies"},
 {"StateName":"Edo","LGAName":"Etsako East","WardName":"Okpekpe"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Auchi"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Auchi I"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Auchi II"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Auchi III"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"South Ibie"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Jagbe"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Aviele"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Anwain"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Uzairue North west"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Uzairue North east"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Uzairue South west"},
 {"StateName":"Edo","LGAName":"Etsako west","WardName":"Uzairue South east"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Ekekhen idumu ogo egbike"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Idigun Idumedo"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Afuda idumuoka"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Uhe idumuogbo idumueke"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Udo"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Owu Okuta eguare ebelle"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Okalo Okpujie"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Amahor ugun"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Ewossa"},
 {"StateName":"Edo","LGAName":"Igueben","WardName":"Ekpon"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Iwogban uteh"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Oregbeni"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Ogbeson"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Aduwawa evbo modu"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"St. Saviour"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Gorretti"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Ugbekun"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Idogbo"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Obayantor"},
 {"StateName":"Edo","LGAName":"Ikpoba okha","WardName":"Ologbo"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Ogbe"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Gra etete"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Uzebu"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Urubi evbiemwen iwehen"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Ihogbe Isekhere oreoghene ibiwe ice road"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"New Benin I"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"New Benin II"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Oredo"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Ikpema eguadase"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Unueru ogboka"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Ogbelaka Nekpenekpen"},
 {"StateName":"Edo","LGAName":"Oredo","WardName":"Ibiwe Iwegie ugbague"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Aibiokula I"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Aibiokula I"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Aibiokula II"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Ugbeka"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Iyoba"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Ugboko"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Ukpato"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Urhonigbe North"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Urhonigbe South"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Ugu"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Evboesi"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Igbanke East"},
 {"StateName":"Edo","LGAName":"Orhionmwon","WardName":"Igbanke West"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Okada West"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Okada East"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Uhen"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Adolor"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Ofunmwegbe"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Oluku"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Uhiere"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Isiuwa"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Okokhuo"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Oghede"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Oduna"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Iguoshodin"},
 {"StateName":"Edo","LGAName":"Ovia North east","WardName":"Utoka"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Iguobazuwa East"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Iguobazuwa West"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Umaza"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Siluko"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Udo"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Ora"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Usen"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Ugbogui"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Ofunama"},
 {"StateName":"Edo","LGAName":"Ovia South west","WardName":"Nikorogha"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Emai I"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Emai II"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Ihievbe I"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Ihievbe II"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Uokha ake"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Igue ikao"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Ivbianion"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Otuo I"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Otuo II"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Ivbiadaobi"},
 {"StateName":"Edo","LGAName":"Owan East","WardName":"Warrake"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Ozalla"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Uhonmora"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Eme-ora oke"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Sabongida ora ogbeturu"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Avbiosi"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Ukhuse -osi"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Eruere"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Okpuje"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Uzebba I"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Uzebba II"},
 {"StateName":"Edo","LGAName":"Owan West","WardName":"Sobe"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Ehor"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Uhi"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Igieduma"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Irhue"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Umagbae North"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Umagbae South"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Isi North"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Isi South"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Ohuan"},
 {"StateName":"Edo","LGAName":"Uhunmwode","WardName":"Egbede"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado A idofin"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado B inisa"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado C idolofin"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado D ijigbo"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado E ijoka orereowu"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado F okeyinmi"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado G oke ila"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado H ereguru"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado I dallimore"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado J okesa"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado K irona"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado L igbehin"},
 {"StateName":"Ekiti","LGAName":"Ado Ekiti","WardName":"Ado M farm settlement"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon I"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon II"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon III"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon iv"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon v"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon vi"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon vii"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon viii"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon Ix"},
 {"StateName":"Ekiti","LGAName":"Efon","WardName":"Efon x"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Omuo - oke I"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Omuo Oke II"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Araromi"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Kota I"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Kota II"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Obadore I"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Obadore II"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Obadore III"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Obadore iv"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Ilasa I"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Ilasa II ikun araromi eda ile"},
 {"StateName":"Ekiti","LGAName":"Ekiti East","WardName":"Isinbode"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Aramoko I"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Aramoko II"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Aramoko III erio"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Erijiyan I"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Erijiyan II"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Ikogosi"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Ipole Iloro"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Okemesi I"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Okemesi II"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Okemesi III"},
 {"StateName":"Ekiti","LGAName":"Ekiti West","WardName":"Ido Ajinare"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe I"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe II"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe III"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe iv"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe v"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe vi"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ilawe vii"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Igbara Odo I"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Igbara Odo II"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ogotun I"},
 {"StateName":"Ekiti","LGAName":"Ekiti South west","WardName":"Ogotun II"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Odo - emure I"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Odo - emure II"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Odo - emure III"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Odo - emure iv"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Oke Emure I"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Oke Emure II"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Idamudu I"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Ida Mudu II"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Ogbontioro I"},
 {"StateName":"Ekiti","LGAName":"Emure","WardName":"Ogbontioro II"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Egbe iro"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Ode I"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Ode II"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Ode III"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Imesi"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Agbado"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Adegba I"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Adegba II"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Iluomoba"},
 {"StateName":"Ekiti","LGAName":"Gbonyin","WardName":"Ijan"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ido I"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ido II"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ifaki I"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ifaki II"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ilogbo"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Usi"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Osi"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Orin ora"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Igbole ifisin aaye"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ayetoro I"},
 {"StateName":"Ekiti","LGAName":"Ido osi","WardName":"Ayetoro II"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ijero Ward A"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ijero Ward B"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ijero Ward C"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ijero Ward D"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ipoti Ward A"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ipoti Ward B"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ipoti odo owa ward C"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ikoro Ward A"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Iloro Ward A"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Iloro ijunrin ward B"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ekamarun Ward A"},
 {"StateName":"Ekiti","LGAName":"Ijero","WardName":"Ekamarun Ward B"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Atiba aafin"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Okeruku"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Ugele aroku"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Agbado oyo"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Ogbonjana"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Oke - osun"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Idemo"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Ilapetu ijao"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Are Araromi"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Afao kajola ayetoro"},
 {"StateName":"Ekiti","LGAName":"Ikere","WardName":"Odose"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ikole West I"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ikole West II"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ikole East"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ikole North"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ikole South"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Araromi bolorunduro"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ijesa Isu"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Odo Ayedun I"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Odo Ayedun ayebode"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Oke Ayedun ---"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Ipao oke ako irele"},
 {"StateName":"Ekiti","LGAName":"Ikole","WardName":"Itapaji iyemero"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Ewu"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Eda Oniyo"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Obada"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Iye I"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Iye II"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Iye III"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Ijesamodu"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Iludun I"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Iludun II"},
 {"StateName":"Ekiti","LGAName":"Ilejemeje","WardName":"Ipere"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Afao"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Are"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Awo"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Igbemo"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Igede I"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Igede II"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Igede III"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Iropora esure eyio"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Iworoko"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Iyin I"},
 {"StateName":"Ekiti","LGAName":"Irepodun ifelodun","WardName":"Iyin II"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Odo Ise I"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Odo Ise II"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Odo Ise III"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Erinwa I"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Erinwa II"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Oraye I"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Oraye II"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Oraye III"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Orun I"},
 {"StateName":"Ekiti","LGAName":"Ise orun","WardName":"Orun II"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Otun I"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Otun II"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Otun III"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Igogo I"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Igogo II"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Erinmope I"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Erinmope II"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Ikun I"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Ikun II"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Osun"},
 {"StateName":"Ekiti","LGAName":"Moba","WardName":"Osan"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Itapa osin"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Oye I"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Oye II"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ayegbaju"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ilupeju I"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ilupeju II"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ire I"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ire II"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ayede North"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Ayede South itaji"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Isan ilafon ilemeso"},
 {"StateName":"Ekiti","LGAName":"Oye","WardName":"Omu Oke omu odo ijelu"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Oduma I"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Oduma II"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Oduma III"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Oduma Iv"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Nnenwe I"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Nnenwe II"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Nnenwe III"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Ndeabo"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Okpanku"},
 {"StateName":"Enugu","LGAName":"Aninri","WardName":"Mpu"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Awgu I"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Awgu II"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Mgbowo"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Agbogugu"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Ihe"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Obeagu"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Mgbidi mmaku"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Anikenano ugwueme"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Owelli amoli ugbo ogugu"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Isu Awa ogugu agbudu ituku"},
 {"StateName":"Enugu","LGAName":"Awgu","WardName":"Ogbaku"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Abakpa I"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Abakpa II"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Umuenwene"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Amorji"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Mbuluiyiukwu"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Mbuluowehe"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Mbulu-njodo West"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Umuchigbo"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Trans-ekulu"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Ibagwa-nike edem"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Ugwugo Nike"},
 {"StateName":"Enugu","LGAName":"Enugu East","WardName":"Mbulu-njodo East"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Asata Township"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Umunevo"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Ihewuzi"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Onu-asata"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Ogbette East"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Ogbette West"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"G.r.a"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Gui Newlayout"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"China Town"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"New Haven"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Independence Layout"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Udi Siding iva valley"},
 {"StateName":"Enugu","LGAName":"Enugu North","WardName":"Ogui Township"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Amechi I"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Amechi II"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Obeagu I"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Uwani East"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Uwani West"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Achara Layout east"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Achara Layout west"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Awkunanaw East"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Awkunanaw West"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Ugwuaji"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Obeagu II"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Akwuke"},
 {"StateName":"Enugu","LGAName":"Enugu South","WardName":"Maryland"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Olo amagu Umulokpa I"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Awha"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Aguobu-owa 1"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Aguobu-owa 11"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Umana Ndiagu"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Agba Umana"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Aguobu (umumba)"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Umumba Ndiumo"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Oghe I"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Oghe II"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Imezi Owa I"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Imezi Owa II"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Mgbagbu Owa I"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Mgbagbu Owa II"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Mgbagbu Owa III"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Iwollo"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Obinofia"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Okpogho"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Obe Agu umana"},
 {"StateName":"Enugu","LGAName":"Ezeagu","WardName":"Ulo amagu umulokpa II"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Aku I"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Aku II"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Aku III"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Aku Iv"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Aku V (idueme)"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ekwegbe I"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ekwegbe II"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ohaodo I"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ohaodo II"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ozalla I"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ozalla II"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Diogbe umunko"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ikolo ohebe"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Onyohor ochima idoha"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ejuoha udeme"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ukehe I"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ukehe II"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ukehe III"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ukehe Iv"},
 {"StateName":"Enugu","LGAName":"Igbo Etiti","WardName":"Ukehe V"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Essodo 1"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Essodo 11"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Essodo 111"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Ette 1"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Ette 11"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Ette Central"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuitodo I"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuitodo II"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuitodo III"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Ezzodo"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi I"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi II"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi III"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi Iv"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi V"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi Vi"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi Vii"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi Viii"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi Ix"},
 {"StateName":"Enugu","LGAName":"Igbo Eze north","WardName":"Umuozzi X"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Alor Agu"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Amabo hausa yoruba"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Ezema Ward"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Echara"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Iheaka (ugo akohi ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Iheaka (likki akutara ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Iheakpu (ezzi ngwu ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Iheakpu (ajuona ogbagu ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Itchi uwani I"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Itchi uwani II"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Nkalagu Obukpa"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Ovoko (ajuona ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Ovoko (umuelo ovoko agu ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Ovoko (umulolo ward)"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Uhunowerre"},
 {"StateName":"Enugu","LGAName":"Igbo Eze south","WardName":"Unadu"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Ikem I"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Ikem II"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Neke I"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Neke II"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Mbu I"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Mbu II"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Umualor"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Ehamufu I"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Ehamufu II"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Ehamufu III"},
 {"StateName":"Enugu","LGAName":"Isi Uzo","WardName":"Ehamufu Iv"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Amechi idodo oruku"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Amankanu ogbahu"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Akpawfu isienu amangunze"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Ihuokpara"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Nara I"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Nara II"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Nkerefi I"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Nkerefi II"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Amagunze"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Ugbawka I"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Ugbawka II"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Nomeh"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Mburubu"},
 {"StateName":"Enugu","LGAName":"Nkanu East","WardName":"Owo"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Agbani"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Amurri"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Akegbe-ugwu (okwuo)"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Amodu"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Obuoffia"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Obe"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Ozalla"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Obinagu Uwani (akpugo i)"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Akpugo II"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Akpugo III"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Akugbo Iv"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Akpugo Iv"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Umueze"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Ibite Akegbe ugwu"},
 {"StateName":"Enugu","LGAName":"Nkanu West","WardName":"Ndiuno Uwani (akpugo i)"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ogbozalla idi"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ibeku"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Alor-uno"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ede-ukwu"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ede-nta"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Edem-ani"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Akpa ozzi"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Agbemebe umabor"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Eha-uno"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Eha-ndiagu"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ibagwani ibagwaga Okpaligbo"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ejuona uwani"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Akalite"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Mkpunano"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Ihe"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Nnu"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Owerre umuoyo"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Obimo ikwoka"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Okpuje okutu anuka"},
 {"StateName":"Enugu","LGAName":"Nsukka","WardName":"Obukpa"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Oji-river I"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Oji-river II"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Oji-river III"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Oji-river Iv"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Ugwuoba I"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Ugwuoba II"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Ugwuoba III"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiagu I"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiagu II"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiagu III"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiuno I"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiuno II"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiuno III"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Achiuno Iv"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Inyi I"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Inyi II"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Inyi III"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Inyi Iv"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Awlaw"},
 {"StateName":"Enugu","LGAName":"Oji-river","WardName":"Akpugoeze"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Amala"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Ezimo"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Imilike"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Obollo-afor"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Obollo-etiti"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Obollo-eke"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Ogbodu-aba"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Orba I"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Orba II"},
 {"StateName":"Enugu","LGAName":"Udenu","WardName":"Udunedem"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Udi agbudu"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Nsude"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Umuaga"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Umuabi"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Obinagu"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Nachi"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Abia"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Ngwo Asa"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Ngwo Uno"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Ebe"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Awhum ukana"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Egede umuoka"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Akpa Kwume nze"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Affa oghu ikono"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Abor"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Okpatu"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Obioma"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Umulumgbe"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Eke"},
 {"StateName":"Enugu","LGAName":"Udi","WardName":"Amokwe"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Igga asaba"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Adaba-nkume"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Umulokpa"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Ukpata"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Ojo"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Adani"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Ogurugu"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Abbi"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Akpogu"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Uvuru"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Nimbo I"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Nimbo II"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Ugbene I"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Ugbene II"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Nrobo"},
 {"StateName":"Enugu","LGAName":"Uzo-uwani","WardName":"Nkpologu"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Abaji Central"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Abaji North east"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Abaji South east"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Agyana pandagi"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Rimba Ebagi"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Nuku"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Alu Mamagi"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Yaba"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Gurdi"},
 {"StateName":"Fct","LGAName":"Abaji","WardName":"Gawu"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Bwari Central"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Kuduru"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Igu"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Shere"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Kawu"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Ushafa"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Dutse Alhaji"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Byazhin"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Kubwa"},
 {"StateName":"Fct","LGAName":"Bwari","WardName":"Usuma"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Gwagwalada Centre"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Kutunku"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Staff Quarters"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Ibwa"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Dobi"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Paiko"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Tungan Maje"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Zuba"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Ikwa"},
 {"StateName":"Fct","LGAName":"Gwagwalada","WardName":"Gwako"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Kuje"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Chibiri"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Gaube"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Kwaku"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Kabi"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Rubochi"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Gwargwada"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Gudun Karya"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Kujekwa"},
 {"StateName":"Fct","LGAName":"Kuje","WardName":"Yenche"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Kwali Ward"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Yangoji"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Pai"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Kilankwa"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Dafa"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Kundu"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Ashara"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Gumbo"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Wako"},
 {"StateName":"Fct","LGAName":"Kwali","WardName":"Yebu"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"City Centre"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Garki"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Kabusa"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Wuse"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Gwarinpa"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Jiwa"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Gui"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Karshi"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Orozo"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Karu"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Nyanya"},
 {"StateName":"Fct","LGAName":"Municipal","WardName":"Gwagwa"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Akko"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Garko"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Kalshingi"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Kashere"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Kumo Central"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Kumo East"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Kumo North"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Kumo West"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Pindiga"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Tumu"},
 {"StateName":"Gombe","LGAName":"Akko","WardName":"Tukulma"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Bambam"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Bangu"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Dadiya"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Gelengu balanga"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Kindiyo"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Kulani degre sikkam"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Mwona"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Nyuwar jessu"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Swa ref w. waja"},
 {"StateName":"Gombe","LGAName":"Balanga","WardName":"Talasse dong reme"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Baganje North"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Baganje South"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Bare"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Billiri North"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Billiri South"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Kalmai"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Tal"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Tanglang"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Todi"},
 {"StateName":"Gombe","LGAName":"Billiri","WardName":"Tudu Kwaya"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Gombe Abba"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Hashidu"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Jamari"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Kunde"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Lafiya"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Malala"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Waziri North"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Waziri South central"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Wuro Tale"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Zange"},
 {"StateName":"Gombe","LGAName":"Dukku","WardName":"Zaune"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Ashaka magaba"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Bage"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Bajoga west"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Bajoga East"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Bodor tilde"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Jillahi"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Kupto"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Ribadu"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Tongo"},
 {"StateName":"Gombe","LGAName":"Funakaye","WardName":"Wawa wakkulutu"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Ajiya"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Bajoga"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Bolari East"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Bolari West"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Dawaki"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Herwagana"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Jeka Dafari"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Kumbiya - kumbiya"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Nasarawa"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Pantami"},
 {"StateName":"Gombe","LGAName":"Gombe","WardName":"Shamaki"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Awak"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Bule kaltin"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Kaltungo West"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Kaltungo East"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Kamo"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Tula Baule"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Tula Wange"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Tula - yiri"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Tungo"},
 {"StateName":"Gombe","LGAName":"Kaltungo","WardName":"Ture"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Bojude"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Daban Fulani"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Doho"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Dukul"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Gadam"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Jurara"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Komfulata"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Kwami"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Malam Sidi"},
 {"StateName":"Gombe","LGAName":"Kwami","WardName":"Malleri"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Barwo nasarawo"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Barwo Winde"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Birin Bolewa"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Birin Fulani east"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Birin Fulani west"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Gudukku"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Jigawa"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Nafada Central"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Nafada East"},
 {"StateName":"Gombe","LGAName":"Nafada","WardName":"Nafada West"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Bangunji"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Boh"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Burak"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Filiya"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Gundale"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Gwandum"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Kulishin"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Kushi"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Lalaipido"},
 {"StateName":"Gombe","LGAName":"Shongom","WardName":"Lapan"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Deba"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Difa lubo kinafa"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Gwani shinga wade"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Hinna"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Jagali North"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Jagali South"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Kanawa wajari"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Kuri lano lambam"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Kwadon liji kurba"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Nono kunwal w. birdeka"},
 {"StateName":"Gombe","LGAName":"Yalmaltu Deba","WardName":"Zambul kwali"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Enyiogugu"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Nguru-ahiato"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Nguru-nwenkwo"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Nguru-nweke"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Mbutu"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Uvuru 1"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Uvuru II"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Lorji"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Amuzu"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Umuhu"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Lagwa"},
 {"StateName":"Imo","LGAName":"Aboh Mbaise","WardName":"Ibeku"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Ogbe"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Otulu aguneze"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Oru-na-lude"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Nnarambia"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Okirika Nwenkwo"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Amuzi ihenworie"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Okirika Nweke"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Obohia ekwereazu"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Mpam"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Oparanadim"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Umunumo umuchieze"},
 {"StateName":"Imo","LGAName":"Ahiazu Mbaise","WardName":"Ogbor umueze"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Agbaja"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umukabia"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Nsu A ikpe"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Nsu B ihitte"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Nzerem ikpem"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umualumaku umuihim"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umunakanu"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umunumo"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umuezeala"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umueze I"},
 {"StateName":"Imo","LGAName":"Ehime Mbano","WardName":"Umueze II umueleke"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Amumara"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Itu"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Okpofe ezeagbogu"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Onicha I"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Onicha II"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Onicha III"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Onicha Iv"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Eziudo"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Udo-na-obizi"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Ihitte"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Oboama umunama"},
 {"StateName":"Imo","LGAName":"Ezinihitte Mbaise","WardName":"Chokoneze akpodim ife"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Akpulu"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Isiokpo"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Umuokwara umuezeaga"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Akwu Owerre"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Umuopia umukegwu"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Ndi Iheme arondizuogu"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Izuogu I"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Ndimoko"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Obodoukwu"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Osina"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Uzii umualoma"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Ezemazu ozu"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Ozuakoki umuago"},
 {"StateName":"Imo","LGAName":"Ideato North","WardName":"Izuogu II"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Amanator umueshi"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Dikenafai"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Isiekenesi I"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Isiekenesi II"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Ntueke"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Ugbelle"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Obiohia"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Ogboko I"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Ogboko II"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Umuakam umuago"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Umuchima"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Umuma Isiaku"},
 {"StateName":"Imo","LGAName":"Ideato South","WardName":"Umuobom"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Abueke"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Amakohia"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Amainyi"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Atonerim"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Awuchinumo"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Dimneze"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Ikperejere"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Okata"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Umuezegwu"},
 {"StateName":"Imo","LGAName":"Ihitte uboma (isinweke)","WardName":"Umuihi"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Akabo"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Amatta"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Iho"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Inyishi umudim"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Amaimo"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Amakohia"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Avuvu"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Atta I"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Atta II"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Ugirike okwu eziama"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Ngugo ikembara"},
 {"StateName":"Imo","LGAName":"Ikeduru (iho)","WardName":"Uzoagba"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Amaraku"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Umunkwo"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Ogbor"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Ibeme"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Osu-owerre I"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Osu-owerre II"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Osuama anara"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Ugiri oka"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Amauzari"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Obollo"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Umuozu"},
 {"StateName":"Imo","LGAName":"Isiala Mbano (umuelemai)","WardName":"Osu-achara"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Amandugba I"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Amandugba II"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Umundugba I"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Umundugba II"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Amurie Omanze I"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Amurie Omanze II"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Ekwe I"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Ekwe II"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Isu-njaba I"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Isu-njaba II"},
 {"StateName":"Imo","LGAName":"Isu (umundugba)","WardName":"Isu Njaba III"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Afara eziama"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Ogbaku"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Umunoha azara"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Ubomiri"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Ifakala"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Ogwa I"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Ogwa II"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Orodo A"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Orodo B"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Umunwoha mbieri umuawu"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Amaike Mbieri"},
 {"StateName":"Imo","LGAName":"Mbaitoli (nwaorieubi)","WardName":"Ezinihitie Mbieri"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Amala alulu oburu obokwe ntu"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Elelem obike"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Ozuzu"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Eziama okpala"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Imerienwe"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Nguru umuowa"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Ngor ihitte umukabia"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Ohekelem Nnorie"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Umuhu"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Obiangwu"},
 {"StateName":"Imo","LGAName":"Ngor Okpala (umuneke)","WardName":"Logara umuohiagu"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Atta I"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Atta II"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Atta III"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Amucha I"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Amucha II"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Nkume"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Okwudor"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Umuaka I"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Umuaka II"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Umuaka III"},
 {"StateName":"Imo","LGAName":"Njaba (nnenasa)","WardName":"Umuaka Iv"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Amano umudurumba Ward (amaigbo i)"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Umuanu Community ward (amaigbo II)"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Amaju Community ward (amaigbo III)"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Amamnaisi (amaigbo iv)"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Ezeobolo ofeahia duruoha umukabia (amaigbo ward v)"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Abba Ward"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Umuozu Ward"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Kara-na-orlu"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Dim-na Nume"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Abajah Ward I"},
 {"StateName":"Imo","LGAName":"Nwangele (onu-nwangele amaigbo)","WardName":"Abajah Ward II"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Onusa"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Nnanano (nkwerre II)"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Umukor (nkwerre III)"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Nkwerre Iv (umunubo umunachi)"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Nkwerre V"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Owerre Nkworji I"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Owerre Nkworji II"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Umudi umuwala"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Amaokpara"},
 {"StateName":"Imo","LGAName":"Nkwerre","WardName":"Eziama Obaire"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Alike"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Amanze umungwa"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Amuzi"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Avutu"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Ehume"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Odenkume umuosochie"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Okenalogho"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Okwuohia"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Umuariam achara"},
 {"StateName":"Imo","LGAName":"Obowo (otoko)","WardName":"Umunachi"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Awa"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Egwe egbuoma"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Izombe"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Mbala uba"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Ndeuloukwu umuowere"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Obudi aro"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Oguta A"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Oguta B"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Oru"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Ossemotor enuigbo"},
 {"StateName":"Imo","LGAName":"Oguta (oguta)","WardName":"Uwaorie"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Assa obile"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Awara ikwerede"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Egbema A"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Egbema B"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Egbema C"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Egbema E"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Egbema D"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Ekwuato"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Obitti mgbishi"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Ohoba"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Umuagwo"},
 {"StateName":"Imo","LGAName":"Ohaji egbema (mmahu-egbema)","WardName":"Umuapu"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Okigwe I"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Okigwe II"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Ihube"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Aku"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Ogii"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Ezinachi"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Umualumuoke"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Amuro"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Ndimoko Ofeimo ibinta okanachi umuowa ibu"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Umulolo"},
 {"StateName":"Imo","LGAName":"Okigwe (okigwe)","WardName":"Agbobu"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Eziama"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Umucheke"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Owerre-okwe"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Ezelu"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Ofeahia umuanumeze"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Aboh okohia"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Ozimo umuneze"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Umuna"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Okwelle I"},
 {"StateName":"Imo","LGAName":"Onuimo (okwe)","WardName":"Okwelle II"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Ohafor okporo umutanze"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Okaeke okporo"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Ebenese umueze nnachi Ihioma"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Okwua Bala ihioma"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Umuna"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Umudioka"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Ihite Owerre"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Amaifeke"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Umuzike umuowa"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Owerri Ebeiri"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Eziachi amike"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Ogberuru obibi"},
 {"StateName":"Imo","LGAName":"Orlu","WardName":"Orlu mgbee orlu Govt. station"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Umuhu Okabia"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Ebenator"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Okwufuruaku"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Okwuamaraihie I"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Okwuamaraihie II"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Okwuetiti"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Orsu Ihiteukwa"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Ihitenansa"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Assah Ubiri elem"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Eziawa"},
 {"StateName":"Imo","LGAName":"Orsu (awo idemili)","WardName":"Amaruru"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Awo-omamma I"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Awo-omamma II"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Awo-omamma III"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Awo-omamma Iv"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Amiri I"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Amiri II"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Akuma"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Amagu"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Akata"},
 {"StateName":"Imo","LGAName":"Oru-east","WardName":"Omuma"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Nempi elem"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Otulu"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Aji"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Ibiaso Egbe"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Amafuo"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Ohakpu"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Ozara"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Ubulu"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Mgbidi I"},
 {"StateName":"Imo","LGAName":"Oru West (mgbidi)","WardName":"Mgbidi II"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Aladinma I"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Aladinma II"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Ikenegbu I"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Ikenegbu II"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Azuzi I"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Azuzi II"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Azuzi III"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Azuzi Iv"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"Gra"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"New Owerri I"},
 {"StateName":"Imo","LGAName":"Owerri Urban (owerri)","WardName":"New Owerri II"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Awaka ihitte-ogada"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Naze"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Egbu"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Emii"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Emekuku I"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Emekuku II"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Orji"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Ihitta-oha"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Obibi-uratta I"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Obibi-uratta II"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Agbala obube ulakwo"},
 {"StateName":"Imo","LGAName":"Owerri North (orie uratta)","WardName":"Obibiezena"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Avu oforola"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Umuguma"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Okuku"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Emeabiam okolochi"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Eziobodo"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Ihiagwa"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Nekede"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Obinze"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Amakohia-ubi ndegwu Ohii"},
 {"StateName":"Imo","LGAName":"Owerri West (umuguma)","WardName":"Irete orogwe"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Auyo"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Auyakayi"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Ayama"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Ayan"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Gamafoi"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Gamsarka"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Gatafa"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Kafur"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Tsidir"},
 {"StateName":"Jigawa","LGAName":"Auyo","WardName":"Unik"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Babura"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Batali"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Dorawa"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Garu"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Gasakoli"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Insharuwa"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Jigawa"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Kanya"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Kuzunzumi"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Kyambo"},
 {"StateName":"Jigawa","LGAName":"Babura","WardName":"Takwasa"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Birnin Kudu"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Kangire"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Kantoga"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Kiyako"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Kwangwara"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Lafiya"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Sundimina"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Surko"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Unguwar Ya"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Wurno"},
 {"StateName":"Jigawa","LGAName":"Birnin Kudu","WardName":"Yalwan Damai"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Batu"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Birniwa"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Dangwaleri"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Diginsa"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Fagi"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Kachallari"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Karanka"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Kazura"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Machinamari"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Matamu"},
 {"StateName":"Jigawa","LGAName":"Birniwa","WardName":"Nguwa"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Ahoto"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Buji"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Churbun"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Gantsa"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Falageri"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Kawaya"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Kukuma"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"K lelen Kudu"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Madabe"},
 {"StateName":"Jigawa","LGAName":"Buji","WardName":"Y tukur"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Abaya"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Chamo"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Dundubus"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Duru"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Jigawar Tsada"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Kachi"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Karnaya"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Kudai"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Limawa"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Madobi"},
 {"StateName":"Jigawa","LGAName":"Dutse","WardName":"Sakwaya"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Buduru"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Doko"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Garki"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Gwarzon Garki"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Jirma"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Kanya"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Kargo"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Kore"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Muku"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Rafin Marke"},
 {"StateName":"Jigawa","LGAName":"Garki","WardName":"Siyori"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Gagarawa Gari"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Gagarawa Tasha"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Garin Chiroma"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Kore Balatu"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Madaka"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Maiaduwa"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Maikilili"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Medu"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Yalawa"},
 {"StateName":"Jigawa","LGAName":"Gagarawa","WardName":"Zarada"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Baikarya"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Danama"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Dantanoma"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Galagamma"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Garin Gambo"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Garin Alhaji barka"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Gusau"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Hammado"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Kofar Arewa"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Kofar Yamma"},
 {"StateName":"Jigawa","LGAName":"Gumel","WardName":"Zango"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Abunabo"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Adiyani"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Dawa"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Garbagal"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Guri"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Kadira"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Lafiya"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Margadu"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Matara Baba"},
 {"StateName":"Jigawa","LGAName":"Guri","WardName":"Musari"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Basirka"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Dingaya"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Fagam"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Farin Dutse"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Gwaram Tsohuwa"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Kila"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Kwandiko"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Maruta"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Sara"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Tsangarwa"},
 {"StateName":"Jigawa","LGAName":"Gwaram","WardName":"Zandan Nagogo"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Buntusu"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Dabi"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Darina"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"F yamma"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Guntai"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Gwiwa"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Korayel"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Rorau"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Shafe"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Yola"},
 {"StateName":"Jigawa","LGAName":"Gwiwa","WardName":"Zaumar Sainawa"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Atafi"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Dubantu"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Gagulmari"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Kasuwar Kuda"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Kasuwar Kofa"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Majema"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Matsaro"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Rumfa"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Sabon Garu"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Yankoli"},
 {"StateName":"Jigawa","LGAName":"Hadejia","WardName":"Yayari"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Aujara"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Gangawa"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Gauza Tazara"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Gunka"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Harbo Sabuwa"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Harbo Tsohuwa"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Idanuna"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Jabarna"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Jahun"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Kale"},
 {"StateName":"Jigawa","LGAName":"Jahun","WardName":"Kanwa"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Balangu"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Dumadumin Toka"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Gafaya"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Jabo"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Kafin Hausa"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Kazalewa"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Majawa"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Mezan"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Ruba"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Sarawa"},
 {"StateName":"Jigawa","LGAName":"Kafin Hausa","WardName":"Zago"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Arbus"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Askandu"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Dabuwaran"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Dakaiyawa"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Hadin"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Ja'e"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Jarkasa"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Kaugama"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Marke"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Unguwar Jibrin"},
 {"StateName":"Jigawa","LGAName":"Kaugama","WardName":"Yalo"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Ba'auzini"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Daba"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Dabaza"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Dandi"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Gada"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Kanti"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Maradawa"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Sabaru"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Unguwar Arewa"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Unguwar Gabas"},
 {"StateName":"Jigawa","LGAName":"Kazaure","WardName":"Unguwar Yamma"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Baturiya"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Bulunchai"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Doleri"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Fandum"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Gayin"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Kirika Samma"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Madachi"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Marma"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Tsheguwa"},
 {"StateName":"Jigawa","LGAName":"Kirika Samma","WardName":"Tarabu"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Abalago"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Andaza"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Fake"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Garko"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Guruduba"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Katanga"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Katuka"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Kiyawa"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Kwanda"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Maje"},
 {"StateName":"Jigawa","LGAName":"Kiyawa","WardName":"Tsurma"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Balarabe"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Dankumbo"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Fulata"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Galadi"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Jajeri"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Kukayasku"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Madana"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Maigatari Arewa"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Maigatari Kudu"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Matoya"},
 {"StateName":"Jigawa","LGAName":"Maigatari","WardName":"Turbus"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Arki"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Dunari"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Fateka Akurya"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Garin Gabas"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Maira Kumi-bara musa"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Maka Ddari"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Malam Madori"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Shaiya"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Tagwaro"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Tashena"},
 {"StateName":"Jigawa","LGAName":"Malam Madori","WardName":"Tonikutara"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Dangyatin"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Harbo"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Hantsu"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Koya"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Miga"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Sabon Gari takanebu"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Sansani"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Tsakuwawa"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Yanduna"},
 {"StateName":"Jigawa","LGAName":"Miga","WardName":"Zareku"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Chai-chai"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Dabi"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Kafin Babushe"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Karshi"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Kyarama"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Ringim"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Sankara"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Sintilmawa"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Tofa"},
 {"StateName":"Jigawa","LGAName":"Ringim","WardName":"Yandutse"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Amaryawa"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Baragumi"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Dansure"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Fara"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Gora"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Kwaita"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Roni"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Sankau"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Tunas"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Yanzaki"},
 {"StateName":"Jigawa","LGAName":"Roni","WardName":"Zugai"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Albasu"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Amanga"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Dangwanki"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Danladi"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Danzomo"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Jeke"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Shabaru"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Sule-tankarkar"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Takatsaba"},
 {"StateName":"Jigawa","LGAName":"Sule-tankarkar","WardName":"Yandamo"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Ajaura"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Chakwaikwaiwa"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Chukuto"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Gujungu"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Kiri"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Kwalam"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Maje"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Majiya"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"S garin Yaya"},
 {"StateName":"Jigawa","LGAName":"Taura","WardName":"Taura"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Achilafiya"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Belas"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Dawan-gawo"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Gwarta"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Gurjiya"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Karkarna"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Kuda"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Ringim"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Yankwashi"},
 {"StateName":"Jigawa","LGAName":"Yankwashi","WardName":"Zunbumba"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Magajin Gari I"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Magajin Gari II"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Magajin Gari III"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Gayam"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Kuyelo"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Kazage"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Kakangi"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Tabanni"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Dogon Dawa"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Kutemesi"},
 {"StateName":"Kaduna","LGAName":"Birnin Gwari","WardName":"Randagi"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Chikun"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Gwagwada"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Kakau"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Kujama"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Kunai"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Kuriga"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Narayi"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Nasarawa"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Rido"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Sabon Tasha"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"Ung. Yelwa"},
 {"StateName":"Kaduna","LGAName":"Chikun","WardName":"S ggarin Arewa tirkaniya"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Giwa"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Kakangi"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Gangara"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Shika"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Danmahawayi"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Yakawada"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Idasu"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Kidandan"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Galadimawa"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Kadage"},
 {"StateName":"Kaduna","LGAName":"Giwa","WardName":"Pan Hauya"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Turunku"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Zangon Aya"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Gwaraji"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Birnin Yero"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Igabi"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Rigachikun"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Afaka"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Sabon Birnin daji"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Kerawa"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Kwarau"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Gadan Gayan"},
 {"StateName":"Kaduna","LGAName":"Igabi","WardName":"Rigasa"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Ikara"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Janfala"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"K kogi"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Saulawa"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Pala"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Saya-saya"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Auchan"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Rumi"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Paki"},
 {"StateName":"Kaduna","LGAName":"Ikara","WardName":"Kuya"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Nduyah"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Sambam"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Fada"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Sabchem"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Sabzuro"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Dura bitaro"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Daddu"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Chori"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Nok"},
 {"StateName":"Kaduna","LGAName":"Jaba","WardName":"Fai"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Kafanchan A"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Kafanchan B"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Maigizo A"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Kaninkon"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Jagindi"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Godogodo"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Gidan Waya"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Atuku"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Asso"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Bedde"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Kagoma"},
 {"StateName":"Kaduna","LGAName":"Jema'a","WardName":"Takau B"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Agunu"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Awon"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Doka"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Gumel"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Gidan Tagwai"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Kwaturu"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Ankwa"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Katari"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Bishini"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Kachia Urban"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Sabon Sarki"},
 {"StateName":"Kaduna","LGAName":"Kachia","WardName":"Kurmin Musa"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Shaba"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Gaji"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Unguwan Liman"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Maiburji"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Kabala Costain doki"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Gabasawa"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Unguwan Sarki"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Badarawa"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Unguwan Dosa"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Kawo"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Hayin Banki"},
 {"StateName":"Kaduna","LGAName":"Kaduna North","WardName":"Unguwan Shanu"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Makera"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Barnawa"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Kakuri Gwari"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Television"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Kakuri Hausa"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Tudun Wada north"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Tudun Wada south"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Tudun Wada west"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Tudun Nuwapa"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Sabon Gari south"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Sabon Gari north"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Ung. Sanusi"},
 {"StateName":"Kaduna","LGAName":"Kaduna South","WardName":"Badiko"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Kagarko North"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Kagarko South"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Kushe"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Jere North"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Jere South"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Iddah"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Aribi"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Kurmin Jibrin"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Katugal"},
 {"StateName":"Kaduna","LGAName":"Kagarko","WardName":"Kukui"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Kajuru"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Tantatu"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Buda"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Kufana"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Afogo"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Kasuwan Magani"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Kallah"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Rimau"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Idon"},
 {"StateName":"Kaduna","LGAName":"Kajuru","WardName":"Maro"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Fada"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Kukum"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Kpak"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Agban"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Kadarko"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Mallagum"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Manchok"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Bondon"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Kaura"},
 {"StateName":"Kaduna","LGAName":"Kaura","WardName":"Zankan"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Kauru West"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Makami"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Dawaki"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Kwassam"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Bital"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Geshere"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Damakasuwa"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Badurum Sama"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Kamaru"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Pari"},
 {"StateName":"Kaduna","LGAName":"Kauru","WardName":"Kauru East"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Kubau"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Dutsen Wai"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Pambegua"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Zuntu"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Damau"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Karreh"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Anchau"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Haskiya"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Kargi"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Mah"},
 {"StateName":"Kaduna","LGAName":"Kubau","WardName":"Zabi"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Kudan"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Hunkuyi"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Sabon Gari hunkuyi"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Garu"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Zabi"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Doka"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Likoro"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Taban Sani"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Kauran Wali north"},
 {"StateName":"Kaduna","LGAName":"Kudan","WardName":"Kauran Wali south"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Sabon Birnin"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Yar Kasuwa"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Garu"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Kayarda"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Lere"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Ramin Kura"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Saminaka"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Lazuru"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Abadawa"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Dan Alhaji"},
 {"StateName":"Kaduna","LGAName":"Lere","WardName":"Gure kahugu"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Makarfi"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Tudun Wada"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Gazara"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Danguziri"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Gimi"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Nassarwan Doya"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Mayere"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Gubuchi"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Gwanki"},
 {"StateName":"Kaduna","LGAName":"Makarfi","WardName":"Dandamisa"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Samaru"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Jama'a"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Bomo"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Basawa"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Chikaji"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Muchia"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Jushin Waje"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Hanwa"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Dogarawa"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Unguwan Gabas"},
 {"StateName":"Kaduna","LGAName":"Sabon Gari","WardName":"Zabi"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Gwantu"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Fadan Karshi"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Ayu"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Ninzam North"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Ninzam South"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Bokana"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Aboro"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Ninzam West"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Wasa Station"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Arak"},
 {"StateName":"Kaduna","LGAName":"Sanga","WardName":"Nandu"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Maigana"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Kinkiba"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Gimba"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Kwassallo"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Richifa"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Gamagira"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Dan Wata"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Turawa"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Soba"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Garun Gwanki"},
 {"StateName":"Kaduna","LGAName":"Soba","WardName":"Rahama"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Gora"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Zonzon"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Zaman Dabo"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Unguwar Gaiya"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Zonkwa"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Madakiya"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Unguwar Rimi"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Gidan Jatau"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Kamantan"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Kamuru Ikulu north"},
 {"StateName":"Kaduna","LGAName":"Zangon Kataf","WardName":"Zango Urban"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Kwarbai A"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Kwarbai B"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Ung. Juma"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Limancin-kona"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Kaura"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Tudun Wada"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Gyallesu"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Ung. Fatika"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Tukur Tukur"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Dambo"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Wucicciri"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Dutsen Abba"},
 {"StateName":"Kaduna","LGAName":"Zaria","WardName":"Kufena"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Ajingi"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Balare"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Chula"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Dabin Kanawa"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Dundun"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Gafasa"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Gurduba"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Kunkurawa"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Toranke"},
 {"StateName":"Kano","LGAName":"Ajingi","WardName":"Ungawar Bai"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Albasu Central"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Bataiya"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Chamarana"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Daho"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Fanda"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Faragai"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Gagarame"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Hungu"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Saya-saya"},
 {"StateName":"Kano","LGAName":"Albasu","WardName":"Tsangaya"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Bagwai"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Dangada"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Gadanya"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Gogori"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Kiyawa"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Kwajali"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Rimin Dako"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Romo"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Sare-sare"},
 {"StateName":"Kano","LGAName":"Bagwai","WardName":"Wuro Bagga"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Anadariya"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Baguda"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Bebeji"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Damau"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Durmawa"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Gargai"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Gwarmai"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Kofa"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Kuki"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Rahama"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Ranka"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Rantan"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Tariwa"},
 {"StateName":"Kano","LGAName":"Bebeji","WardName":"Wak"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Badume"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Bichi"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Danzabuwa"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Fagolo"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Kaukau"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Kwamarawa"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Kyalli"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Muntsira"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Saye"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Waire"},
 {"StateName":"Kano","LGAName":"Bichi","WardName":"Yallami"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Barkum"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Bunkure"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Bono"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Chirin"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Gafan"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Gurjiya"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Gwamma"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Kulluwa"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Kumurya"},
 {"StateName":"Kano","LGAName":"Bunkure","WardName":"Sanda"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Adakawa"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Bakin Ruwa"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Dala"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Dogon Nama"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Gobirawa"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Gwammaja"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Kabuwaya"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Kantudu"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Kofar Mazugal"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Kofar Ruwa"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Madigawa"},
 {"StateName":"Kano","LGAName":"Dala","WardName":"Yalwa"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Ajumawa"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Danbatta East"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Danbatta West"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Fagwalawa"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Goron Maje"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Gwanda"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Gwarabjawa"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Kore"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Saidawa"},
 {"StateName":"Kano","LGAName":"Danbata","WardName":"Sansan"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Dabar Kwari"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Danbagiwa"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Dawaki"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Dawakiji"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Dosan"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Gano"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Gurjiya"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Jido"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Tamburawa"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Tsakuwa"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Unguwar Duniya"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Yanbarau"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Yankatsari"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Yargaya"},
 {"StateName":"Kano","LGAName":"Dawaki Kudu","WardName":"Zogarawa"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Dawaki East"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Dawaki West"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Dawanau"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Dan Guguwa"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Ganduje"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Gargari"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Jalli"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Kwa"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Marke"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Tattarawa"},
 {"StateName":"Kano","LGAName":"Dawaki Tofa","WardName":"Tumfafi"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Dariya"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Dogon Kawo"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Duguwa"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Falgore"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Maraku"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Ragada"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Ririwai"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Tagwaye"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Unguwar Natsohuwa"},
 {"StateName":"Kano","LGAName":"Doguwa","WardName":"Zainabi"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Fagge A"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Fagge B"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Fagge C"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Fagge D"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Fagge E"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Kwachiri"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Rijiyar Lemo"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Sabongari East"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Sabongari West"},
 {"StateName":"Kano","LGAName":"Fagge","WardName":"Yammata"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Gabasawa"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Garun Danga"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Joda"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Karmaki"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Mekiya"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Tarauni"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Yantar Arewwa"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Yautar Kudu"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Yumbu"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Zakirai"},
 {"StateName":"Kano","LGAName":"Gabasawa","WardName":"Zugachi"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Dal"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Garin Ali"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Garko"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Gurjiya"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Kafin Malamai"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Katumari"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Kwas"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Raba"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Sarina"},
 {"StateName":"Kano","LGAName":"Garko","WardName":"Zakarawa"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Chiromawa"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Dorawar-sallau"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Fankurun"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Garun Babba"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Garun Malam"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Jobawa"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Kadawa"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Makwaro"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Yad Akwari"},
 {"StateName":"Kano","LGAName":"Garun Malam","WardName":"Yalwan yadakwari"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Balan"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Gaya Arewa"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Gaya Kudu"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Gamarya"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Gamoji"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Kademi"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Kazurawa"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Maimakawa"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Shagogo"},
 {"StateName":"Kano","LGAName":"Gaya","WardName":"Wudilawa"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Babawa"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Gawo"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Gezawa"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Jogana"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Ketawa"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Mesar-tudu"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Sararin-gezawa"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Tsamiya-babba"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Tumbau"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Wangara"},
 {"StateName":"Kano","LGAName":"Gezawa","WardName":"Zango"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Dandago"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Diso"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Dorayi"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Galadanchi"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Goron Dutse"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Gwale"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Gyaranya"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Kabuga"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Mandawari"},
 {"StateName":"Kano","LGAName":"Gwale","WardName":"Sani Mai magge"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Getso"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Gwarzo"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Jama' A"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Kara"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Kutama"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Lakwaya"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Madadi"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Mainika"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Sabon Birni"},
 {"StateName":"Kano","LGAName":"Gwarzo","WardName":"Unguwar Tudu"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Dugabau"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Durun"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Gammo"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Garo"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Godiya"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Gude"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Hauwade"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Kabo"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Kanwa"},
 {"StateName":"Kano","LGAName":"Kabo","WardName":"Masanawa"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Chedi"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Dan'agundi"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Gandun Albasa"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Jakara"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Kankarofi"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Shahuchi"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Sharada"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Sheshe"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Tudun Nufawa"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Tudun Wazirchi"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Yakasai"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Zaitawa"},
 {"StateName":"Kano","LGAName":"Kano Municipal","WardName":"Zango"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Daura"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Kafin Dafga"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Karaye"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Kurugu"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Kwanyawa"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Tudun Kaya"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Turawa"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Unguwar Hajji"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Yammedi"},
 {"StateName":"Kano","LGAName":"Karaye","WardName":"Yola"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Durba"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Fammar"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Fassi"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Kadigawa"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Kahu"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Kibiya I"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Kibiya II"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Nariya"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Tarai"},
 {"StateName":"Kano","LGAName":"Kibiya","WardName":"Unguwar Gai"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Ba'awa"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Badafi"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Bargoni"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Bauda"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Dangora"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Dansohiya"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Dashi"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Galadimawa"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Kiru"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Kogo"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Maraku"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Tsaudawa"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Yako"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Yalwa"},
 {"StateName":"Kano","LGAName":"Kiru","WardName":"Zuwo"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Challawa"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Chiranchi"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Danbare"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Danmaliki"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Guringawa"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Kumbotso"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Kureken Sani"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Mariri"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Na'ibawa"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Panshekara"},
 {"StateName":"Kano","LGAName":"Kumbotso","WardName":"Unguwar Rimi"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Bumai"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Garin Sheme"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Gwarmai"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Kasuwar Kuka"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Kunchi"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Matan Fada"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Ridawa"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Shamakawa"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Shuwaki"},
 {"StateName":"Kano","LGAName":"Kunchi","WardName":"Yandadi"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Dalili"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Dan Hassan"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Dukawa"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Gundutse"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Karfi"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Kosawa"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Kura"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Kurunsumau"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Rigar Duka"},
 {"StateName":"Kano","LGAName":"Kura","WardName":"Tanawa"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Burji"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Cinkoso"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Galinja"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Gora"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Kafin Agur"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Kanwa"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Kaura Mata"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Kubaraci"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Kwankwaso"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Madobi"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Rikadawa"},
 {"StateName":"Kano","LGAName":"Madobi","WardName":"Yakun"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Babbar Riga"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Durma"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Jibga"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Kadandani"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Koguna"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Koren Tatso"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Maitsidau"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Makoda"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Satame"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Tangaji"},
 {"StateName":"Kano","LGAName":"Makoda","WardName":"Wailare"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Azore"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Gandurwawa"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Kantama"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Kunya"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Kuru"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Kwarkiya"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Minjibir"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Sarbi"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Tsakiya"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Tsakuwa"},
 {"StateName":"Kano","LGAName":"Minjibir","WardName":"Wasai"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Dakata"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Gama"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Gawuna"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Gwagwarwa"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Giginyu"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Hotoro North"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Hotoro South"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Kawaji"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Kaura Goje"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Tudun Murtala"},
 {"StateName":"Kano","LGAName":"Nasarawa","WardName":"Tudun Wada"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Dawaki"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Lausu"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Madachi"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Rano"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Rurum Sabon-gari"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Rurum Tsohon-gari"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Saji"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Yalwa"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Zinyau"},
 {"StateName":"Kano","LGAName":"Rano","WardName":"Zurgu"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Butu-butu"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Dawaki Gulu"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Doka Dawa"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Dugurawa"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Gulu"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Jili"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Karofin Yashi"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Rimin Gado"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Sakaratsa"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Tamawa"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Yalwan Danziyal"},
 {"StateName":"Kano","LGAName":"Rimin Gado","WardName":"Zango Dan abdu"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Beli"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Falgore"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Fulatan"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Gwangwan"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Jajaye"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Rogo Ruma"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Rogo Sabon gari"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Ruwan Bago"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Zarewa"},
 {"StateName":"Kano","LGAName":"Rogo","WardName":"Zoza"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Alajawa"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Dutsen-bakoshi"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Faruruwa"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Goron Dutse"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Kadamu"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Kokiya"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Leni"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Shakogi"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Shanono"},
 {"StateName":"Kano","LGAName":"Shanono","WardName":"Tsaure"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Gala"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Gani"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Garfa"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Gediya"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Kanawa"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Magami"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Masu"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Rimi"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Rumo"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Sitti"},
 {"StateName":"Kano","LGAName":"Sumaila","WardName":"Sumaila"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Bagwaro"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Durbunde"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Fajewa"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Falali"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Faruruwa"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Kachako"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Karfi"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Kuka"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Takai"},
 {"StateName":"Kano","LGAName":"Takai","WardName":"Zuga"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Babban Giji"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Darmanawa"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Daurawa"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Gyadi-gyadi Arewa"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Gyadi-gyadi Kudu"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Hotoro (nnpc)"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Kauyen alu"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Tarauni"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Unguwar Gano"},
 {"StateName":"Kano","LGAName":"Tarauni","WardName":"Unguwa uku"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Dindere"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Doka"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Gajida"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Ginsawa"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Jauben Kudu"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Janguza"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Kwami"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Lambu"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Langel"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Tofa"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Unguwar Rimi"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Wangara"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Yalwa Karama"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Yanoko"},
 {"StateName":"Kano","LGAName":"Tofa","WardName":"Yarimawa"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Daddarawa"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Dunbulun"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Gozaki"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Gurun"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Kabagiwa"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Tatsan"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Tsanyawa"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Yanganau"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Yankamaye"},
 {"StateName":"Kano","LGAName":"Tsanyawa","WardName":"Zarogi"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Baburi"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Burumburum"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Dalawa"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Jandutse"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Jita"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Karefa"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Nata'ala"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Sabon Gari"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Tsohogari"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Shuwaki"},
 {"StateName":"Kano","LGAName":"Tudun Wada","WardName":"Yaryasa"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Bachirawa"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Gayawa"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Kadawa"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Karo"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Panisau"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Rangaza"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Rijiyar Zaki"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Tudun Fulani"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Ungogo"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Yadakunya"},
 {"StateName":"Kano","LGAName":"Ungogo","WardName":"Zango"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Amarawa"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Danlasan"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Garin Dau"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Gogel"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Imawa"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Jemagu"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Jigawa"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"J galadima"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Katarkawa"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Madari Mata"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Tamburawar Gabas"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Tangar"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"Warawa"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"'yan Dalla"},
 {"StateName":"Kano","LGAName":"Warawa","WardName":"'yangizo"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Achika"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Dagumawa"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Dankaza"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Darki"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Indabo"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Kausani"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Lajawa"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Sabon Gari"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Utai"},
 {"StateName":"Kano","LGAName":"Wudil","WardName":"Wudil"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Bakori A"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Bakori B"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Kurami yankwani"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Dawan Musa"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Tsiga"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Kabomo"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Barde kwantakwaran"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Jargaba"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Kakumi"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Guga"},
 {"StateName":"Katsina","LGAName":"Bakori","WardName":"Kandarawa"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Batagarawa A"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Batagarawa B"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Dandagoro"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Ajiwa"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Tsanni"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Jino"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Barawa"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Bakiyawa"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Kayauki"},
 {"StateName":"Katsina","LGAName":"Batagarawa","WardName":"Yargamji"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Batsari"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Manawa"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Wagini"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Abadau kagara"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Yauyau mallamawa"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Kandawa"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Dan. Alh yangaiya"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Ruma"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Darini magaji abu"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Karare"},
 {"StateName":"Katsina","LGAName":"Batsari","WardName":"Madogara"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Yanmaulu"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Babban Mutum"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Taramnawa bare"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Muduri"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Baure"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Unguwar Rai"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Kagara faski"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Garki"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Yanduna"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Mai Bara"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Hui"},
 {"StateName":"Katsina","LGAName":"Baure","WardName":"Agala"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Kamri"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Bindawa"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Tama daye"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Faru dallaji"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Shibdawa"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Jibawa r,bade"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Doro"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Baure"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Yangora"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Giremawa"},
 {"StateName":"Katsina","LGAName":"Bindawa","WardName":"Gaiwa"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Charanchi"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Safana"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Radda"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Majen Wayya"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Ganuwa"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Tsakatsa"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Koda"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Kuraye"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Banye"},
 {"StateName":"Katsina","LGAName":"Charanchi","WardName":"Doka"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Mahuta A"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Mahuta B"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Mahuta C"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Dandume A"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Dandume B"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Tumburkai A"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Tumburkai B"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Dantankari"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Nasarawa"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Magaji Wando a"},
 {"StateName":"Katsina","LGAName":"Dandume","WardName":"Magaji Wando b"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Jiba"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Tandama"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Danja A"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Danja B"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Tsangamawa"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Dabai"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Yakaji A"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Yakaji B"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Kahutu A"},
 {"StateName":"Katsina","LGAName":"Danja","WardName":"Kahutu B"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Mara"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Dan Alkima"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Dan Musa a"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Dan Musa b"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Dan Ali"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Mai Dabino a"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Mai Dabino b"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Dandire A"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Dandire B"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Yan-tumaki A"},
 {"StateName":"Katsina","LGAName":"Dan Musa","WardName":"Yan-tumaki B"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Sarkin Yara a"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Sarkin Yara b"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Ubandawaki A"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Unbadawaki B"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Kusugu"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Mazoji A"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Mazoji B"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Tudun Wada"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Sabon Gari"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Madobi A"},
 {"StateName":"Katsina","LGAName":"Daura","WardName":"Madobi B"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Dan Aunai"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Ruwankaya A"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Ruwankaya B"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Dutsi A"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Dutsi B"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Yamel A"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Yamel B"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Sirika A"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Sirika B"},
 {"StateName":"Katsina","LGAName":"Dutsi","WardName":"Kayawa"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Dutsin-ma A"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Dutsin-ma B"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Shema"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Karofi A"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Karofi B"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Kuki A"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Kuki B"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Kutawa"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Bagagadi"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Makera"},
 {"StateName":"Katsina","LGAName":"Dutsin-ma","WardName":"Dabawa"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Yankara"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Sheme"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Mairuwa"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Daudawa"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Tafoki"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Ruwan Godiya"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Yarmalamai"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Faskari"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Sabonlayi galadima"},
 {"StateName":"Katsina","LGAName":"Faskari","WardName":"Maigora"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Dandutse"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Sabon Gari"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Unguwar Rabiu"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Ung Ibrahim"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Unguwar Musa"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Dukke"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Makera"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Goya"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Mai Gamji"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Maska"},
 {"StateName":"Katsina","LGAName":"Funtua","WardName":"Tudun Iya"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Dugul"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Yandoma"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Ingawa"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Agayawa"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Jobe kandawa"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Kurfeji yankaura"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Manomawa kafi"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Bidore yaya"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Bareruwa ruruma"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Daunaka b.kwari"},
 {"StateName":"Katsina","LGAName":"Ingawa","WardName":"Dara"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Farfaru"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Yangaiya"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Jibia A"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Jibia B"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Mazanya magama"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"G baure mallamawa"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Faru"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Bugaje"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Gangara"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Riko"},
 {"StateName":"Katsina","LGAName":"Jibia","WardName":"Kusa"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Yartalata rigoji"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Dutsin Kura kanya"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Kafur"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Masari"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Yari Bori"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Sabuwar Kasa"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Gozaki"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Dantutture"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Mahuta"},
 {"StateName":"Katsina","LGAName":"Kafur","WardName":"Gamzago"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Yandaki"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Dankaba"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Abdallawa"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Baawa"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Gafiya"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Girka"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Kaita"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Yanhoho"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Dankama"},
 {"StateName":"Katsina","LGAName":"Kaita","WardName":"Matsai"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Gatakawa S gari mabai"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Zango zabaro"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Pauwa A&b"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Kukasheka"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Hurya"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Kankara A&b"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Garagi"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Burdugau"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Wawar Kaza"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Dan Murabu"},
 {"StateName":"Katsina","LGAName":"Kankara","WardName":"Dan'maidaki"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Galadima A"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Galadima B"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Kafinsoli"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Tafashiya nasarawa"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Kunduru gyaza"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Kafin Dangi fakuwa"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Sukuntuni"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Rimaye"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Gachi"},
 {"StateName":"Katsina","LGAName":"Kankia","WardName":"Magam tsa"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Gabas I"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Gabas II"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Kangiwa"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Yamma 1"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Yamma 1i"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Kudu I"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Kudu II"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Arewa (a)"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakilin Arewa (b)"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Shinkafi 'a"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Shinkafi B"},
 {"StateName":"Katsina","LGAName":"Katsina","WardName":"Wakili Kudu III"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Tsauri A"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Tsauri B"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Kurfi A"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Kurfi B"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Rawayau A"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Rawayau B"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Birchi"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Barkiyya"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Wurma A"},
 {"StateName":"Katsina","LGAName":"Kurfi","WardName":"Wurma B"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Kusada"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Dudunni"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Kaikai"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Mawashi"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Boko"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Kofa"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Bauranya A"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Bauranya B"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Yashe 'a"},
 {"StateName":"Katsina","LGAName":"Kusada","WardName":"Yashe B"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Bumbum A"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Bumbum B"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Danyashe"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Mai'adua A"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Mai'adua B"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Mai'adua C"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Mai Koni A"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Mai Koni B"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Natsalle"},
 {"StateName":"Katsina","LGAName":"Mai'adua","WardName":"Koza"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Malum Fashi A"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Malum Fashi B"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Yaba"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Rawan Sanyi"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Karfi"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Borin Dawa"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Yarmama"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Gorar Dansaka"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Dansarai"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Makaurachi"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Dayi"},
 {"StateName":"Katsina","LGAName":"Malufashi","WardName":"Na-alma"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Bujawa gewayau"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Duwan makau"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Mani"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Bagiwa"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Tsagem takusheyi"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Hamcheta"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Kwatta"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Machika"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Muduru"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Magami"},
 {"StateName":"Katsina","LGAName":"Mani","WardName":"Jani"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Mashi"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Jigawa"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"S rijiya"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Bamble"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Gallu"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Sonkaya"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Doguru A"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Doguru B"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Karau"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Tamilo A"},
 {"StateName":"Katsina","LGAName":"Mashi","WardName":"Tamilo B"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Matazu 'a"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Matazu B"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Mazoji A"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Mazoji B"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Sayaya"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Kogari"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Gwarjo"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Karaduwa"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Dissi"},
 {"StateName":"Katsina","LGAName":"Matazu","WardName":"Rinjin Idi"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Musawa"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Jikamshi"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Danjanku karachi"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Kira"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Yaradau tabanni"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Tuge"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Gingin"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Kurkujan A"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Dangani"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Garu"},
 {"StateName":"Katsina","LGAName":"Musawa","WardName":"Kurkujan B"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Rimi"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Abukur"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Majengobir"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Remawa iyatawa"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Kadandani"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Sabon Garin alarain"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Tsagero"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Fardami"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Makurda"},
 {"StateName":"Katsina","LGAName":"Rimi","WardName":"Masabo kurabau"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Sabuwa A"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Sabuwa B"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Gazari"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Maibakko"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Damari"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Machika"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Dugun Mu'azu"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Gamji"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Rafin Iwa"},
 {"StateName":"Katsina","LGAName":"Sabuwa","WardName":"Sayau"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Safana"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Tsaskiya"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Runka A"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Babban Duhu A"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Babban Duhu B"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Runka B"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Zakka A"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Zakka B"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Baure A"},
 {"StateName":"Katsina","LGAName":"Safana","WardName":"Baure B"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Sandamu"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Karkarku"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Kwasarawa"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Daneji A"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Daneji B"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Fago A"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Fago B"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Rade A"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Rade B"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Katsayal"},
 {"StateName":"Katsina","LGAName":"Sandamu","WardName":"Kagare"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Yardaje"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Kanda"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Zango"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Sara"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Dargage"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Rogogo cidari"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Garni"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Gwamba"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Kawarin Kudi"},
 {"StateName":"Katsina","LGAName":"Zango","WardName":"Kawarin Malawamai"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Aliero dangaladima I"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Aliero dangaladima II"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Aliero S fada I"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Aliero S fada II"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Danwarai"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Jiga Birni"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Jiga Makera"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Kashin Zama"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Rafin Bauna"},
 {"StateName":"Kebbi","LGAName":"Aliero","WardName":"Sabiyal"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Biu"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Chibike"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Daura sakkwabe jarkuka"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Gorun Dikko"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Falde"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Feske jaffeji"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Gumumdai rafin Tsaka"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Kangiwa"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Laima jantullu"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Sarka dantsoho"},
 {"StateName":"Kebbi","LGAName":"Arewa","WardName":"Yeldu"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Alwasa gotomo"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Dikko"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Felande"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Galadima"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Gulma"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Gwazange kisawa u Gyaga"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Kokani North"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Kokani South"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Lailaba"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Sauwa kaurar Sani"},
 {"StateName":"Kebbi","LGAName":"Argungu","WardName":"Tungar Zazzagawa rumbuki sarkawa"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Augie North"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Augie South"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Bagaye mera"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Bayawa North"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Bayawa South"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Birnin Tudu gudale"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Bubuce"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Dundaye kwaido zagi illela"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Tiggi awade"},
 {"StateName":"Kebbi","LGAName":"Augie","WardName":"Yola"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Bagudo tuga"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Bahindi boki-doma"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Bani tsamiya kali"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Illo sabon gari yantau"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Kaoje gwamba"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Kende kurgu"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Lafagu gante"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Lolo giris"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Matsinka geza"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Sharabi kwanguwai"},
 {"StateName":"Kebbi","LGAName":"Bagudo","WardName":"Zagga kwasara"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Nassarawa - I"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Nassarawa II"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Birnin Kebbi mafara"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Birnin Kebbi dangaladima"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Kola tarasa"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Makera"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Maurida karyo ung. mijin-nana"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Godongaji"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Zauro"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Gawasu"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Kardi yamama"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Laga"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Gulumbe"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Ambursa"},
 {"StateName":"Kebbi","LGAName":"Birnin Kebbi","WardName":"Ujariyo"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Bunza Marafa"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Bunza Dangaladima"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Gwade"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Maidahini"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Raha"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Sabon Birni"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Salwai"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Tilli hilema"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Tunga"},
 {"StateName":"Kebbi","LGAName":"Bunza","WardName":"Zogrima"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Bani Zumbu"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Buma"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Dolekaina"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Fana"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Maihausawa"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Kyangakwai"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Geza"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Kamba kamba"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Kwakkwaba"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Maigwaza"},
 {"StateName":"Kebbi","LGAName":"Dandi","WardName":"Shiko"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Bajida"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Bangu garinisa"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Birnin Tudu"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Mahuta"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Gulbin Kuka maijarhula"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Inga(bulu) Maikende"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Kangi"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Fakai zussun"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Marafa"},
 {"StateName":"Kebbi","LGAName":"Fakai","WardName":"Penin Amana penin gaba"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Cheberu bada"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Dalijan"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Dodoru"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Gulmare"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Gwandu Marafa"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Gwandu Sarkin fawa"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Kambaza"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Maruda"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Malisa"},
 {"StateName":"Kebbi","LGAName":"Gwandu","WardName":"Masama Kwasgara"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Alelu gehuru"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Dangamaji"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Dunbegu bausara"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Gindi nassarawa kyarmi galbi"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Jandutsi birnin Malam"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Jega Firchin"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Jega Kokani"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Jega Magaji B"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Jega Magaji A"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Katanga fagada"},
 {"StateName":"Kebbi","LGAName":"Jega","WardName":"Kimba"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Badariya magarza"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Dangoma gayi"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Diggi"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Etene"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Kalgo"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Kuka"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Mutubari"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Nayilwa"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Wurogauri"},
 {"StateName":"Kebbi","LGAName":"Kalgo","WardName":"Zuguru"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Koko Magaji"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Illela s gari"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Koko Fircin"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Dada alelu"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Jadadi"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Lani manyan tafukka shiba"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Besse"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Takware"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Dutsin Mari dulmeru"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Zariya Kalakala amiru"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Madacci firini"},
 {"StateName":"Kebbi","LGAName":"Koko besse","WardName":"Maikwari Karamar damba bakoshi"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Andarai Kurunkwu zongun liba"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Giwa Tazo zara"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Gumbin Kure"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Karaye dogondaji"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Kawara s sara yarkamba"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Kuben Rigidiga"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Liba danwa kuka Kogo"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Maiyama"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Mungadi botoro"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Sambawa mayalo"},
 {"StateName":"Kebbi","LGAName":"Maiyama","WardName":"Sarandosa gubba"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Birnin Yauri"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Gafara Machupa"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Garin Baka makarin"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Kwakwaran"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Libata kwangia"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Kambuwa danmaraya"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Makawa Uleira"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Ngaski"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Utono hoge"},
 {"StateName":"Kebbi","LGAName":"Ngaski","WardName":"Wara"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Adai"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Dankolo"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Doka bere"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Gelwasa"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Janbirni"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Maza maza"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Makuku"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Sakaba"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Tudun Kuka"},
 {"StateName":"Kebbi","LGAName":"Sakaba","WardName":"Fada"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Atuwo"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Binuwa gebe bunkuji"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Dugu Tsoho dugu raha"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Kawara ingu sargo"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Rafin Kirya tefki tara"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Sokage golongo hundeji"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Sawashi"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Shanga"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Takware"},
 {"StateName":"Kebbi","LGAName":"Shanga","WardName":"Yarbesse"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Aljannare"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Bandan"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Barbarejo"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Bakuwai"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Dakingari"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Dendane"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Daniya shema"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Ginga"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Giro"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Kwaifa"},
 {"StateName":"Kebbi","LGAName":"Suru","WardName":"Suru"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Ayu"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Bena"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Dan Umaru mairairai"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Danko maga"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Kanya"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Kyabu kandu"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Ribah machika"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Waje"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Wasagu"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Yalmo shindi"},
 {"StateName":"Kebbi","LGAName":"Wasagu danko","WardName":"Gwanfi kele"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Chulu koma"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Gungun Sarki"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Jijima"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Tondi"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Yelwa Central"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Yelwa East"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Yelwa North"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Yelwa South"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Yelwa West"},
 {"StateName":"Kebbi","LGAName":"Yauri","WardName":"Zamare"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Bedi"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Ciroman Dabai"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Isgogo dago"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Manga ushe"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Rafin Zuru"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Rikoto"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Rumu daben seme"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Senchi"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Tadurga"},
 {"StateName":"Kebbi","LGAName":"Zuru","WardName":"Zodi"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Okunchi ozuri onieka"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Ogaminana"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Iruvucheba"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Idanuhua"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Adavi-eba"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Kuroko I"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Kuroko II"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Ino Ziomi ipaku osisi"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Ikaraworo idobanyere"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Nagazi Farm centre"},
 {"StateName":"Kogi","LGAName":"Adavi","WardName":"Ege iruvochinomi"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Ebiya North"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Ebiya South"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Abodi patesi"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Ichuwa upaja"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Badoko"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Ogigiri"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Adogo"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Achagana"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Odonu unosi"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Omgbo"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Adogu apamira ogodo Uhuovene"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Obangede ohunene ukoko Inye're"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Old Ajaokuta"},
 {"StateName":"Kogi","LGAName":"Ajaokuta","WardName":"Ganaga township"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ankpa Township"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ankpa Suburb I"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ankpa Suburb II"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ankpa I"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ankpa II"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Enjema I"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Enjema II"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Enjema III"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Enjema iv"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ojoku I"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ojoku II"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ojoku III"},
 {"StateName":"Kogi","LGAName":"Ankpa","WardName":"Ojoku Iv"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Akuba I"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Akuba II"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Ayede akakana"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Ozongulo kpanche"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Ikende"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Gboloko"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Kpata"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Eforo"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Mozum"},
 {"StateName":"Kogi","LGAName":"Bassa","WardName":"Ozugbe"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Dekina Town"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Iyale"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Emewe"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Odu I"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Odu II"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Abocho"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Ogbabede"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Adumu Egume"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Ojikpadala"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Anyigba"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Okura Olafia"},
 {"StateName":"Kogi","LGAName":"Dekina","WardName":"Ogane Inigu"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Odeke"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Ujeh"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Iyano"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Akpanyo"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Unale"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Ojila"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Ejule"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Ayah"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Analo"},
 {"StateName":"Kogi","LGAName":"Ibaji","WardName":"Onyedega"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Igalaogba"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Owoli Apa"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Igecheba"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Ukwaja"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Ogegele"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Ede"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Sabon Gari"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Ega"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Ugwoda"},
 {"StateName":"Kogi","LGAName":"Idah","WardName":"Ichala"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Avrugo"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Ekwuloko"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Odolu"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Oji-aji"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Akpanya"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Ubele"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Ajaka I"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Ajaka II"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Oforachi I"},
 {"StateName":"Kogi","LGAName":"Igalamela odolu","WardName":"Oforachi II"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Aiyegunle"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Aiyetoro I"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Aiyetoro II"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Iyah ayeh"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Odokoro"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Aiyere arimah"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Ogidi"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Ileteju origa"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Ogale aduge"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Egbeda Egga okedayo"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Iyara"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Iffe ikoyi okejumu"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Iyamoye"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Ekinrin Ade"},
 {"StateName":"Kogi","LGAName":"Ijumu","WardName":"Ibgolayere ilaere"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Asuta"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Odo-akete"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Okekoko"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Odolu"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Aiyewa"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Aiyeteju"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Otu"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Egbeda"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Okedayo"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Akutupa-kiri"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Aiyetoro-kiri"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Iluke"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Olle oke-ofin"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Odo-ape"},
 {"StateName":"Kogi","LGAName":"Kabba bunu","WardName":"Okebukun"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Ukwo-koton Karfe"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Odaki-koton Karfe"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Kotonkarfe South east"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Girinya"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Irenodu"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Akpasu"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Tawari"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Gegu-beki North"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Gegu-beki South"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Chikara North"},
 {"StateName":"Kogi","LGAName":"Kogi . k. k.","WardName":"Chikara South"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Lokoja - a"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Lokoja - b"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Lokoja - c"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Lokoja - d"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Lokoja - e"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Kupa North east"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Kupa South west"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Oworo"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Kakanda"},
 {"StateName":"Kogi","LGAName":"Lokoja","WardName":"Eggan"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Odole - 1"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Odole - 2"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Ileteju - 1"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Illeteju - 2"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Okeagi ilai"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Orokere"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Takete Idde otafun"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Aiyedayo aiyedaro"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Agbafogun"},
 {"StateName":"Kogi","LGAName":"Mopa Moro","WardName":"Aiyede okagi"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Igo"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Aloma"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Ejule Allah"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Itobe okokenyi"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Ugwolawo - I"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Ugwolawo - 2"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Aloji"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Ofoke"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Ochadamu"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Ogbonicha"},
 {"StateName":"Kogi","LGAName":"Ofu","WardName":"Iboko efakwu"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Eni"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Oshobane"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Okibo"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Okesi"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Ileteju"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Aiyeromi"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Ugugu"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Obinoyin"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Obatgben"},
 {"StateName":"Kogi","LGAName":"Ogori Mangogo","WardName":"Oturu Opowuroye"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Obaiba I"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Obaiba II"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Okuehu"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Ohueta"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Oboroke Eba"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Obaroke Uvete"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Eika ohizenyi"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Okaito usungwen"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Ohuepe omavi uboro"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Obangede uhuodo"},
 {"StateName":"Kogi","LGAName":"Okehi","WardName":"Oboroke Uvete - II"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Bariki"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Obessa"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Onyukoko"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Idoji"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Orietesu"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Otutu"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Okene-eba agassa ahache"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Obehira Uvetta"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Obehira Eba"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Abuga ozuja"},
 {"StateName":"Kogi","LGAName":"Okene","WardName":"Upogoro odenku"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Imane I"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Imane II"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Ogugu I"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Ogugu II"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Ogugu III"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Olamaboro I"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Olamaboro II"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Olamaboro III"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Olamaboro Iv"},
 {"StateName":"Kogi","LGAName":"Olamaboro","WardName":"Olamaboro V"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Abejukolo I"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Abejukolo II"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Opoda ofejiji"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Bagana"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Okpatala"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Akpacha"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Bagaji"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Icheke Ajopachi"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Ogodu"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Oji-aji"},
 {"StateName":"Kogi","LGAName":"Omala","WardName":"Olla"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Ife Olukotun I"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Ife Olukotun II"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Ponyan"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Alu igbagun oranre"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Ejuku"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Jege oke agi Ogbom isao"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Makutu I"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Makutu II"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Itedo"},
 {"StateName":"Kogi","LGAName":"Yagba East","WardName":"Ilafin idofin odo - ogba"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Ejiba"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Odo Eri okoto"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Odo Ere oke ere"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Isaulu Esa okoloke okunran"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Iyamerin igbaruku"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Odo Ara omi ogga"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Ogbe"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Oke Egbe I"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Oke Egbe II"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Oke Egbe III"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Oke Egbe iv"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Odo Egbe I"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Odo Egbe II"},
 {"StateName":"Kogi","LGAName":"Yagba West","WardName":"Odo egbe"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Yowere sosoki"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Adigbongbo awe orimaro"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Elebue agbona fata"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Onire odegiwa alapa"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Yowere 11 okeweru"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Gambari aiyekale"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Efue berikodo"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Owode gbogun"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Ballah otte"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Ogbondoroko reke"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Ago oja oshin sapati laduba"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Afon"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Ila-oja"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Ogele"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Budo-egba"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Okesho"},
 {"StateName":"Kwara","LGAName":"Asa","WardName":"Odo-ode aboto"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Boriya shiya"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Gure gwasoro"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Gwedebereru babane"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Gwanara"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Ilesha"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Kenu taberu"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Kpaura yakiru"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Kiyoru bwen"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Okuta"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Shinawu tunbuyan"},
 {"StateName":"Kwara","LGAName":"Baruten","WardName":"Yashikira"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Lafiagi 1"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Lafiagi 11"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Lafiagi 111"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Lafiagi 1v"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Tsaragi 1"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Tsaragi 11"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Tsaragi 111"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Tsonga 1"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Tsonga 11"},
 {"StateName":"Kwara","LGAName":"Edu","WardName":"Tsonga 111"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Eruku"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Isapa"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Koro"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Obbo-aiyeggunle 1"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Obbo-aiyeggunle 11"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Obbo-ile"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Osi 1"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Osi 11"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Opin"},
 {"StateName":"Kwara","LGAName":"Ekiti","WardName":"Oke-opin etan"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Oke-ode 1"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Oke-ode 11"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Oke-ode 111"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Ora"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Ile-ire"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Agunjin"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Oro-ago"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Omupo"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Share 1"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Share 11"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Share 111"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Share 1v"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Share V"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Igbaja 1"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Igbaja 11"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Igbaja 111"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Idofian 1"},
 {"StateName":"Kwara","LGAName":"Ifelodun","WardName":"Idofian 11"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Agbeyangi gbadamu osin"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Gambari 1"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Bologun Gambari 11"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Ibagun"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Apado"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Iponrin"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Magaji Are 1"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Magaji Are 11"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Marafa pepele"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Maya ile-apa"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Oke Oyi oke ose alalubosa"},
 {"StateName":"Kwara","LGAName":"Ilorin East","WardName":"Zango"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Akanbi -1"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Akanbi -11"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Akanbi -111"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Akanbi -1v"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Akanbi -v"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Balogun-fulani I"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Balogun-fulani 11"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Balogun-fulani 111"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Okaka 1"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Okaka 11"},
 {"StateName":"Kwara","LGAName":"Ilorin-south","WardName":"Oke-ogun"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Adewole"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Ajikobi"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Baboko"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Badari"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Balogun Alanamu central"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Magaji Ngeri"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Oloje"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Ogidi"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Ojuekun zarumi"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Oko-erin"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Ubandawaki"},
 {"StateName":"Kwara","LGAName":"Ilorin-west","WardName":"Warrah egbe Jila oshin"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Ajase Ipo 1"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Ajase Ipo 11"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Arandun"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Esie ijan"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Ipetu rore aran-orin"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Omu-aran 1 (aran)"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Omu-aran 11 (ihaye)"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Omu-aran 111 (ifaja)"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Oro 1"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Oro 11"},
 {"StateName":"Kwara","LGAName":"Irepodun","WardName":"Oko"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Alla"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Edidi"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Isanlu 1"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Isanlu 11"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Ijara Isin"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Iwo"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Owu Isin"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Oke Onigbin"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Sabaja pamo"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Oke Aba"},
 {"StateName":"Kwara","LGAName":"Isin","WardName":"Olla"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Adena"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Bani"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Gwanabe 1"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Gwanabe 11"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Gwari A (gwaria)"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Kaiama 1"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Kaiama 11"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Kaiama 111"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Kemanji"},
 {"StateName":"Kwara","LGAName":"Kaiama","WardName":"Wajibe"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Jebba"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Bode-saadu"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Okemi"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Lanwa"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Ejidongari"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Okutala"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Babadudu"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Oloru"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Pakunmo"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Womi ayaki"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Abati alara"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Shao"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Logun jehunkunnu"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Malete gbugudu"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Ajanaku"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Megida"},
 {"StateName":"Kwara","LGAName":"Moro","WardName":"Arobadi"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Balogun"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Shawo South west"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Shawo Central"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Shawo South east"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Essa - a"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Essa - b"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Essa - c"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Ojomu North north west"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Ojomu central 1"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Ojomu central 11"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Ojomu south east"},
 {"StateName":"Kwara","LGAName":"Offa","WardName":"Igboidun"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Aiyedun"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Ekan"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Imoji ilale erinmope"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Iloffa"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Imode egosi"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Idofin Igbana 1"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Idofin Igbana 11"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Idofin odo-ashe"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Odo-owa 1"},
 {"StateName":"Kwara","LGAName":"Oke - ero","WardName":"Odo-owa 11"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Erin-ile South"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Ilemona"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Igbona"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Irra"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Inaja ahogbada"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Ikotun"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Ojoku"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Ijagbo"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Igosun"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Ipee"},
 {"StateName":"Kwara","LGAName":"Oyun","WardName":"Erin-ile North"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Patigi 1"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Patigi 11"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Patigi 111"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Patigi 1v"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Kpada 1"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Kpada 11"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Kpada 111"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Lade 1"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Lade 11"},
 {"StateName":"Kwara","LGAName":"Patigi","WardName":"Lade 111"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Isale idimangoro"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Iloro onipetesi"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Oniwaya papa-uku"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Agbotikuyo dopemu"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Oyewole papa Ashafa"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Okekoto"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Keke"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Darocha"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Tabon Tabon oko oba"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Orile Agege oko oba"},
 {"StateName":"Lagos","LGAName":"Agege","WardName":"Isale Odo"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Ago Hausa"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Awodi-ora"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Wilmer"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Olodi"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Tolu"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Temidire I"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Ojo Road"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Layeni"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Alaba Oro"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Mosafejo"},
 {"StateName":"Lagos","LGAName":"Ajeromi ifelodun","WardName":"Temidire II"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Shasha akowonjo"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Egbeda alimosho"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Idimu isheri Olofin"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Ikotun ijegun"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Egbe agodo"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Igando egan"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Ipaja North"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Ipaja South"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Ayobo ijon Village (camp david)"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Pleasure oke-odo"},
 {"StateName":"Lagos","LGAName":"Alimosho","WardName":"Abule-egba aboru meiran alagbado"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Amuwo-odofin Housing estate, mile 2"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Festac 1"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Festac II"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Kirikiri"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Amuwo"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Ijegun"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Satellite"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Irede"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Ibeshe"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Igbologun"},
 {"StateName":"Lagos","LGAName":"Amuwo-odofin","WardName":"Festac III"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Apapa I (marine rd. & environs)"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Apapa II (liverpool rd. and environs)"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Apapa III (creek rd. tincan snake island"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Apapa Iv (pelewura crescent and environs)"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Ijora-oloye"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Olodan St. olojowou st alh.dogo olatokunbo st. iganmu"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Gaskiya & environs"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Afolabi Alasia str. and environs"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Malu Road and environs"},
 {"StateName":"Lagos","LGAName":"Apapa","WardName":"Sari And environs"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Posukoh"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Awhanjigoh"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Ibereko"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Apa"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Keta-east"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Iworo Gbanko"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Ajido"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Ilogbo-araromi"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Ikoga"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Ajara"},
 {"StateName":"Lagos","LGAName":"Badagry","WardName":"Iya-afin"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Etita ebode"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Lagbade"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Popo-oba"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Oke-balogun"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Ajaganabe"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Ise igbogun"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Oriba ladaba"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Abomiti"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Agbowa"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Agbowa Ikosi"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Ago Owu"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Orugbo"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Ilara"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Ibonwon"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Odoragunsin"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Poka"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Odomola"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Ejirin"},
 {"StateName":"Lagos","LGAName":"Epe","WardName":"Itoikin"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Victoria Island I"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Victoria Island II"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Ilasan Housing estate"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Lekki ikate And environs"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Ilado eti-osa And environs"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Ajah sangotedo"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Ado langbasa badore"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Ikoyi I"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Ikoyi II"},
 {"StateName":"Lagos","LGAName":"Eti-osa","WardName":"Obalende"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"Ibeju I"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"N2, (ibeju II)"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"Orimedu I"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"02, (orimedu II)"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"03, (orimedu III)"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"P1, (iwerekun i)"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"Iwerekun II"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"S1, (lekki i)"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"Lekki II"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"S2, (siriwon igbekodo i)"},
 {"StateName":"Lagos","LGAName":"Ibeju lekki","WardName":"S,2a (siriwon igbekodo II)"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Ijaye"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Old Ifako karaole"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"New Ifako oyemekun"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Fagba akute Road"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Iju Isaga"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Iju-obawole"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Pamada abule-egba"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Ijaiye ojokoro"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Ijaiye agbado kollington"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Alakuko kollington"},
 {"StateName":"Lagos","LGAName":"Ifako-ijaye","WardName":"Ajegunle akinde animashaun"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Anifowoshe ikeja"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Ojodu agidingbi omole"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Alausa oregun olusosun"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Airport onipetesi inilekere"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Ipodo seriki Aro"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Adekunle Vill. adeniyi jones ogba"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Oke-ira aguda"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Onigbongbon"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Gra police Barracks"},
 {"StateName":"Lagos","LGAName":"Ikeja","WardName":"Wasimi opebi allen"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Isele I"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Isele II"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Isele III"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Aga ijimu"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Ipakodo"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Imota 1"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Imota II"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Isiu"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Igbogbo I"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Igbogbo II"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Baiyeku oreta"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Ibeshe"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Ijede J"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Ijede II"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Agura iponmi"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Odogunyan"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Erikorodu"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Agbala"},
 {"StateName":"Lagos","LGAName":"Ikorodu","WardName":"Olorunda igbaga"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Oworonshoki"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Ifako soluyi"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Anthony ajao Estate mende maryland"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Ojota ogudu"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Ketu alapere agidi orisigun kosofe ajelogo akanimodo"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Ikosi Ketu mile 12 agiliti maidan"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Isheri-olowo-ira shangisha magodo Phase i & II"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Agboyi I"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Agboyi II"},
 {"StateName":"Lagos","LGAName":"Kosofe","WardName":"Owode Onirin ajegunle odo-ogun"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Olowogbowo elegbata"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Oluwole"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Idumota oke"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Oju-oto"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Oko-awo"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Agarawu obadina"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Iduntafa"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Ilupesi"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Isale-agbede"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Olosun"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Olushi kakawa"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Popo-aguda"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Anikantamo"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Oko-faji"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Eiyekole"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Onikan"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Sandgrouse"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Epetedo"},
 {"StateName":"Lagos","LGAName":"Lagos Island","WardName":"Lafiaji ebute"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Otto iddo"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Epetedo"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Olaleye Village"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Maroko ebute Metta"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Oyingbo Market ebute metta"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Glover ebute Metta"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Oko-baba"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Oyadiran Estate abule-oja"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Alagomeji"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Iwaya"},
 {"StateName":"Lagos","LGAName":"Lagos Mainland","WardName":"Yaba igbobi"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Alakara"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Idi-oro odi-olowu"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Babalosa"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Ojuwoye"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Ilupeju"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Olateju"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Kayode fadeyi"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Mushin atewolara"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Papa-ajao"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Ilasamaja"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Babalosa idi-araba"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Idi-araba"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Itire"},
 {"StateName":"Lagos","LGAName":"Mushin","WardName":"Ilupeju Industrial estate"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Ojo Town"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Okokomaiko"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Ajangbadi"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Ijanikin"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Iba"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Ilogbo"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Irewe"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Tafi"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Etegbin"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Idoluwo"},
 {"StateName":"Lagos","LGAName":"Ojo","WardName":"Sabo"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Oshodi bolade"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Orile-oshodi"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Isolo"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Ajao Estate"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Ilasamaja"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Mafoluku"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Sogunle"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Sogunle alasia"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Okota"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Ishagatedo"},
 {"StateName":"Lagos","LGAName":"Oshodi isolo","WardName":"Oke-afa ejigbo"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Onipanu"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Palmgrove ijebutedo"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Alade"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Bajulaiye"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Mafowoku pedro"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Lad-lak bariga"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Ilaje akoka"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Igbobi fadeyi"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Fola Agoro bajulaiye igbari-akoka"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Gbagada Phase i obanikoro pedro"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Gbagada Phase II bariga apelehin"},
 {"StateName":"Lagos","LGAName":"Somolu","WardName":"Abule-okuta ilaje bariga"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Orile"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Aguda"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Ijeshatedo"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Akinhanmi cole"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Yaba ojuelegba"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Igbaja stadium"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Shitta ogunlana Drive"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Adeniran ogunsanya"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Iponri Housing estate eric moore"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Coker"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Itire"},
 {"StateName":"Lagos","LGAName":"Surulere","WardName":"Ikate"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Anchobaba"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Agyaga"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Gwanje Gwanje"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Ancho Nighaan"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Andaha"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Nunku"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Gudi"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Moroa"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Akwanga West"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Akwanga East"},
 {"StateName":"Nasarawa","LGAName":"Akwanga","WardName":"Ningo bohar"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Tunga"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Makwangiji"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Madaki"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Galadima"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Jangaru"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Kanje abuni"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Ribi"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Azara"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Wuse"},
 {"StateName":"Nasarawa","LGAName":"Awe","WardName":"Akiri"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Alagye I"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Alagye II"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Rukubi I"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Rukubi II"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Agbashi"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Doka I"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Doka II"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Akpanaja"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Ungwan Madaki"},
 {"StateName":"Nasarawa","LGAName":"Doma","WardName":"Sarkin Dawaki"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Aso kodape"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Agada bagaji"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Karshi I"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Karshi II"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Kafin Shanu betti"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Tattara kondoro"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Gitata"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Gurku kabusu"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Uke"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Panda kare"},
 {"StateName":"Nasarawa","LGAName":"Karu","WardName":"Karu"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Iwagu"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Amiri"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Obene"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Oki"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Kadarko"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Kwara"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Aloshi"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Agaza"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Giza Madaki"},
 {"StateName":"Nasarawa","LGAName":"Keana","WardName":"Giza Galadima"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Angwan Iya I"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Angwan Iya II"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Tudun Kofa t.v"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Gangare Tudu"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Keffi Town east kofar goriya"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Yara"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Ang.rimi"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Sabon Gari"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Jigwada"},
 {"StateName":"Nasarawa","LGAName":"Keffi","WardName":"Liman Abaji"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Agwada"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Koya kana"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Bassa"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Kokona"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Kofar Gwari"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Ninkoro"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Hadari"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Dari"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Amba"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Garaku"},
 {"StateName":"Nasarawa","LGAName":"Kokona","WardName":"Yelwa"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Adogi"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Agyaragun Tofa"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Bakin Rijiya akurba sarkin pada"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Arikya"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Assakio"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Ashigie"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Chiroma"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Gayam"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Keffin wambai"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Makama"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Shabu kwandere"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Wakwa"},
 {"StateName":"Nasarawa","LGAName":"Lafia","WardName":"Zanwa"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Udenin Gida"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Akum"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Udenin"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Loko"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Tunga bakono"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Guto aisa"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Nasarawa North"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Nasarawa East"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Nasarawa central"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Nasarawa Main town"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Ara I"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Ara II"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Laminga"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Kanah ondo apawu"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa","WardName":"Odu"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Nasarawa Eggon"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Ubbe"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Igga burumburum"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Umme"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Mada Station"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Lizzin Keffi ezzen"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Lambaga arikpa"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Kagbu Wana"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Ikka Wangibi"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Ende"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Wakama"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Aloce ginda"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Alogani"},
 {"StateName":"Nasarawa","LGAName":"Nasarawa Eggon","WardName":"Agunji"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Agwatashi"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Deddere riri"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Obi"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Tudun Adabu"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Duduguru"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Gwadenye"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Kyakale"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Gidan Ausa I"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Gidan Ausa II"},
 {"StateName":"Nasarawa","LGAName":"Obi","WardName":"Adudu"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Gwargwada"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Gadagwa"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Bugakarmo"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Umaisha"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Dausu"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Kanyehu"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Ugya"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Toto"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Shafan Abakpa I"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Shafan Kwatto I"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Shege I"},
 {"StateName":"Nasarawa","LGAName":"Toto","WardName":"Katakpa I"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Arum"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Mangar"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Gitta"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Nakere"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Konvah"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Wayo"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Wamba West"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Wamba East"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Kwara"},
 {"StateName":"Nasarawa","LGAName":"Wamba","WardName":"Jimiya"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Baro"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Boku"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Ekobadeggi"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Ekossa"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Ekowugi"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Ekowuna"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Etsugaie"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Dauaci"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Kutiriko"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Magaji"},
 {"StateName":"Niger","LGAName":"Agaie","WardName":"Tagagi"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Adehe"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Agwata"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Busuru"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Gallah"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Kashini"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Kokoli"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Mago"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Papiri"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Rofia"},
 {"StateName":"Niger","LGAName":"Agwara","WardName":"Suteku"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Bariki"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Ceniyan"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Dokodza"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Kyari"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Landzun"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Masaba I"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Masaba II"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Masaga I"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Masaga II"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Mayaki Ndajiya"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Nassarafu"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Umaru majigi I"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Umaru Majigi II"},
 {"StateName":"Niger","LGAName":"Bida","WardName":"Wadata"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Babanna"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Dugga"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Karabonde"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Konkoso"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Malale"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"New Bussa"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Kabe pissa"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Shagunu"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Wawa"},
 {"StateName":"Niger","LGAName":"Borgu","WardName":"Riverine"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Beji"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Bosso Central I"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Bosso Central II"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Chanchaga"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Garatu"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Kampala"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Kodo"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Maikunkele"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Maitumbi"},
 {"StateName":"Niger","LGAName":"Bosso","WardName":"Shata"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Limawa A"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Limawa B"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Makera"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Minna Central"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Minna South"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Nassarawa A"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Nassarawa B"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Nassarawa C"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Sabon Gari"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Tudun Wada north"},
 {"StateName":"Niger","LGAName":"Chanchaga","WardName":"Tudun Wada south"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Enagi"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Etsu Tasha"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Fazhi"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Gazhe I"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Gazhe II"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Gbangban"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Gonagi"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Guzan"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Rokota"},
 {"StateName":"Niger","LGAName":"Edatti","WardName":"Sakpe"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Batagi"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Batako"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Edokota"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Edozhigi"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Etsu Audu"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Gbadafu"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Gogata"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Lemu"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Nuwankota"},
 {"StateName":"Niger","LGAName":"Gbako","WardName":"Sammajiko"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Bonu"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Diko"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Gawu"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Izom"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Kabo"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Kwaka"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Lambata"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Lefu"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Shako"},
 {"StateName":"Niger","LGAName":"Gurara","WardName":"Tufa"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Bakeko"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Badeggi"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Bisanti"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Dzwafu"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Edotsu"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Essa"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Gbakogi"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Katcha"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Kataregi"},
 {"StateName":"Niger","LGAName":"Katcha","WardName":"Sidi Saba"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Arewa"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Central"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Gabas"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Kudu"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Magajiya"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Masuga"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Madara"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Nagwamatse"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Rafin Gora"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Tunganwawa"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Tungan Kawo"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Usalle"},
 {"StateName":"Niger","LGAName":"Kontagora","WardName":"Yamma"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Arewa yamma"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Birnin Maza tashibo"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Ebbo gbacinku"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Evuti kpada"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Gulu anguwa Vatsa"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Gupa abugi"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Gurdi zago"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Kudu gabas"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Muye egba"},
 {"StateName":"Niger","LGAName":"Lapai","WardName":"Takuti shaku"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Busu kuchi"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Batati"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Dassun"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Doko"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Dabban"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Egbako"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Gaba"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Jima"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Kutigi"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Kusotachi"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Lagun"},
 {"StateName":"Niger","LGAName":"Lavun","WardName":"Mambe"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Auna Central"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Auna East central"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Auna East"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Auna South east"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Auna South"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Ibelu Central"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Ibelu East"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Ibelu North"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Ibelu West"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Nasko"},
 {"StateName":"Niger","LGAName":"Magama","WardName":"Nassarawa"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Bangi"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Beri"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Bobi"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Gulbin - boka"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Galma wamba"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Inkwai"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Igwama"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Kakihum"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Kontokoro"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Kumbashi"},
 {"StateName":"Niger","LGAName":"Mariga","WardName":"Maburya"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Babban rami"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Dapangi makera"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Ibbi"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Kaboji"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Kasanga"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Kwatachi"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Kulho"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Mashegu"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Mazakuka likoro"},
 {"StateName":"Niger","LGAName":"Mashegu","WardName":"Saho-rami"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Bokani"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Gbajibo muwo"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Gbara"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Ja'agi"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Jebba North"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Kpaki takuma"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Kudu"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Labozhi"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Mokwa"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Muregi"},
 {"StateName":"Niger","LGAName":"Mokwa","WardName":"Rabba ndayako"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Beni"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Dandaudu"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Dangunu"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Daza"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Fuka"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Gini"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Guni"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Kabula"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Kazai"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Kuchi"},
 {"StateName":"Niger","LGAName":"Munya","WardName":"Sarkin Pawa"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Adunu"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Chimbi"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Gwam"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Ishau"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Jere"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Kafin Koro"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Kwagana"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Kwakuti"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Nikuchi T mallam"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Paiko Central"},
 {"StateName":"Niger","LGAName":"Paikoro","WardName":"Tutungo Jedna"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kagara Gari"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kakuri"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kongoma Central"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kongoma West"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kusherki North"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kushirki South"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Kundu"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Sabon Gari"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Tegina Gari"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Tegina West"},
 {"StateName":"Niger","LGAName":"Rafi","WardName":"Yakila"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Danrangi"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Dugge"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Dukku"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Genu"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Jama'are"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Rijau"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Shambo"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"T bunu"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"T magajiya"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Ushe"},
 {"StateName":"Niger","LGAName":"Rijau","WardName":"Warari"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Allawa"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Bangajiya"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Bassa kukoki"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Egwa gwada"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Erana"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Galkogo"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Gurmana"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Gussoro"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Kato"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Kushaka kurebe"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Kwaki chukwuba"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Manta"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Pina"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"She"},
 {"StateName":"Niger","LGAName":"Shiroro","WardName":"Ubandoma"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Bagmama A"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Bagama B"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Hashimi A"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Hashimi B"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Iku South I"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Iku South II"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Kurmin Sarki"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Magajiya"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Maje North"},
 {"StateName":"Niger","LGAName":"Suleja","WardName":"Wambai"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Dogon Kurmi"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Garam"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Ija Gwari"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Ija Koro"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Iku"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"New Bwari"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Wuse West"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Wuse east"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Zuma East"},
 {"StateName":"Niger","LGAName":"Tafa","WardName":"Zuma West"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Akare"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Barwa"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Gwarjiko"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Kanwuri"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Kodo"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Kwata"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Lokogoma"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Maito"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Sabon Gari"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Tukunji yamigi"},
 {"StateName":"Niger","LGAName":"Wushishi","WardName":"Zungeru"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ikereku"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ikija Ikereku"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ago Oko"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Elega"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ilugun iberekodo"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Gbagura"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ika"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Lafenwa"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Sabo"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Oke-ago Owu"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Totoro sokori"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ita Osin olomore"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Olorunda Ijale"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Imala - idiemi"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Isaga Ilewo"},
 {"StateName":"Ogun","LGAName":"Abeokuta North","WardName":"Ibara Orile onisasa"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ake I"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ake II"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ake III"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Keesi emere"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ijemo"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Itoko"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ijaye idi-aba"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Erunbe oke Ijeun"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ago-egun ijesa"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Sodeke sale-ijeun I"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Sodeke sale-ijeun II"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Imo isabo"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Igbore ago Oba"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ibara I"},
 {"StateName":"Ogun","LGAName":"Abeokuta South","WardName":"Ibara II"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ota I"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ota II"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ota III"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Sango"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ijoko"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Atan"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Iju"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ilogbo"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ado Odo I"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ado Odo II"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ere"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Alapoti"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Ketu-adie-owe"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Igbesa"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Agbara I"},
 {"StateName":"Ogun","LGAName":"Ado Odo-ota","WardName":"Agbara ejila Awori"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Ido Foi"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Aye Toro I"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Aye Toro II"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Sunwa"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Iboro joga"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Imasai"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Ebute Igbooro"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Ohunbe"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Igua"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Ijoun"},
 {"StateName":"Ogun","LGAName":"Egbado North","WardName":"Ibese"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Ilaro I"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Ilaro II"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Ilaro III"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Iwoye"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Idogo"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Oke Odan"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Owode I"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Owode II"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Ilobi erinja"},
 {"StateName":"Ogun","LGAName":"Egbado South","WardName":"Ajilete"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Abalabi"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Asa yobo"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Arigbajo"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Itori"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Elere onigbedu"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Papalanto"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Wasimi"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Mosan"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Owowo"},
 {"StateName":"Ogun","LGAName":"Ewekoro","WardName":"Obada-oko"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Ifo I"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Ifo II"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Ifo III"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Agbado"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Iseri"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Ajuwon akute"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Oke-aro ibaragun robiyan"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Ososun"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Sunren"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Coker"},
 {"StateName":"Ogun","LGAName":"Ifo","WardName":"Ibogun"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ijebu Mushin I"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ijebu Mushin II"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ijebu Ife I"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ijebu Ife II"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Owu"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ikija"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Itele"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ogbere"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Imobi I"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Imobi II"},
 {"StateName":"Ogun","LGAName":"Ijebu East","WardName":"Ajebandele"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Atikori"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Japara ojowo"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Omen"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Osun"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Oke Agbo"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Oke Sopin"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Oru awa ilaporu"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Ago Iwoye I"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Ago Iwoye II"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Ako-onigbagbo gelete"},
 {"StateName":"Ogun","LGAName":"Ijebu North","WardName":"Mamu etiri"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Atan imuku"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Odosimadegun odosebora"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Imewiro ododeyo imomo"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Odesenlu"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Igede itamarun"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Oju Ona"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Isoyin"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Ilese"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Oke-eri ogbogbo"},
 {"StateName":"Ogun","LGAName":"Ijebu North east","WardName":"Erunwon"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Isoku ososa"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Odo-esa"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Itantebo"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Ijade imepe I"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Ijade imepe II"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Porogun I"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Porogun II"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Ijasi Idepo"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Odo-egbo oliworo"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Isiwo"},
 {"StateName":"Ogun","LGAName":"Ijebu Ode","WardName":"Itamapako"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ikenne I"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ikenne II"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Iperu I"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Iperu II"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Iperu III"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ogere I"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ogere II"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ilisan I"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ilisan II"},
 {"StateName":"Ogun","LGAName":"Ikenne","WardName":"Ilisan irolu"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Imeko"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Oke Agbede moriwi matale"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Idofa"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Iwoye jabata"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Ilara alagbe"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Afon"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Otapele"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Kajola agberiodo"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Olorunda gbomo"},
 {"StateName":"Ogun","LGAName":"Imeko afon","WardName":"Idi Ayin"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Ipokia I"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Ipokia II"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Agosasa"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Ijofin idosa"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Tube"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Agada"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Mauni I"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Mauni II"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Ajegunle"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Ifonyintedo"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Idiroko"},
 {"StateName":"Ogun","LGAName":"Ipokia","WardName":"Ihunbo Ilase"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Mokoloki"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Ofada"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Owode"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Ajura"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Moloko-asipa"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Onidundu"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Oba"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Egbeda"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Kajola"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Obafemi"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Ajebo"},
 {"StateName":"Ogun","LGAName":"Obafemi owode","WardName":"Alapako-oni"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Odeda"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Balogun Itesi"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Olodo"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Alagbagba"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Ilugun"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Osiele"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Obantoko"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Alabata"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Obete"},
 {"StateName":"Ogun","LGAName":"Odeda","WardName":"Opeji"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Imosan"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Imodi"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Okun-owa"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Odogbolu I"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Odogbolu II"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Aiyepe"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Ososa"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Idowa"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Ibefun"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Ilado"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Ogbo moraika ita-epo I"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Ala igbile"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Jobore ibido ikise"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Omu"},
 {"StateName":"Ogun","LGAName":"Odogbolu","WardName":"Ogbo moraika ita Epo II"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Iwopin"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Oni"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Ibiade"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Lukogbe ilusin"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Abigi"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Efire"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Ayede lomiro"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Ayila itebu"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Makun irokun"},
 {"StateName":"Ogun","LGAName":"Ogun Water side","WardName":"Ode-omi"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Ayegbami"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Igan ajina"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Moborode oke-ola"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Odofin imagbo petekun dawara"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Ilara"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Akaka"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Ipara"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Orile-oko"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Ode I"},
 {"StateName":"Ogun","LGAName":"Remo North","WardName":"Ode II"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Oko epe itula I"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Oko epe itula II"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Ayegbami ijokun"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Sabo 1"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Sabo II"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Isokun oyebajo"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Ijagba"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Latawa"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Ode -lemo"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Ogijo Likosi"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Surulere"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Isote"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Simawa iwelepe"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Agbowa"},
 {"StateName":"Ogun","LGAName":"Sagamu","WardName":"Ibido ituwa alara"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Edo"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Ekan"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Ikado I"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Ikado II"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Ilepa I"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Ilepa II"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Isowopo I"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Isowopo II"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Iyometa I"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Iyometa II"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Oorun I"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Oorun II"},
 {"StateName":"Ondo","LGAName":"Akoko North east","WardName":"Oyinmo"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Arigidi iye I"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Arigidi II"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Okeagbe"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Oyin oge"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Ajowa igasi eriti gedegede"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Ogbagi"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Oke-irun surulere"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Odo-irun oyinmo"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Ese afin"},
 {"StateName":"Ondo","LGAName":"Akoko North west","WardName":"Erusu karamu ibaramu"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Epinmi I"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Epinmi II"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Ifira"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Ipe I"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Ipe II"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Ipesi"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Isua I"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Isua II"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Isua III"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Isua Iv"},
 {"StateName":"Ondo","LGAName":"Akoko South east","WardName":"Sosan"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka I ibaka sabo"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka II a ikanmu"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka II b okia korowa simerin uba"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka III a agba"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka III b owase ikese iwonrin ebinrin idorin"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka Iv owake ebo ayegunle"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka V a owalusin ayepe"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oka V b oka odo okela bolorunduro"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Ikun"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Supare I"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Supare II"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Akungba I"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Akungba II"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oba I"},
 {"StateName":"Ondo","LGAName":"Akoko South west","WardName":"Oba II"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Agamo oke-oore akomowa"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Ayede ogbese"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Ayetoro"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Igbatoro"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Igoba isinigbo"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Iluabo eleyewo bolorunduro"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Isimija irado"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Moferere"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Oba-ile"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Odo-oja ijigbo"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Oke-afa owode"},
 {"StateName":"Ondo","LGAName":"Akure North","WardName":"Oke Iju"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Aponmu"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Gbogi isikan I"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Gbogi isikan II"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Ijomu obanla"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Lisa"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Oda"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Odopetu"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Oke Aro uro I"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Oke Aro uro II"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Oshodi isolo"},
 {"StateName":"Ondo","LGAName":"Akure South","WardName":"Owode imuagun"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Apoi I"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Apoi II"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Apoi III"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Apoi Iv"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Apoi V"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Arogbo I"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Arogbo II"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Arogbo III"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Ukparama I"},
 {"StateName":"Ondo","LGAName":"Ese-odo","WardName":"Ukparama II"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Ala-elefosan"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Alade atosin"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Idale-lemikan"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Idale-logbosere"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Ijomu isurin"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Irowo"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Isalu Ehinpeti"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Isalu Jigbokin"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Ofosu onisere"},
 {"StateName":"Ondo","LGAName":"Idanre","WardName":"Owena aponmulona"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Ero ibuji mariwo"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Igbaka-oke I"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Igbaka-oke II"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Ijare 1"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Ijare 11"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Obo ikota olo-gbo"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Ilara 1"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Ilara 11"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Ipogun ibule"},
 {"StateName":"Ondo","LGAName":"Ifedore","WardName":"Isarun Erigi"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Aheri"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Etikan"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Mahin 1"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Mahin 11"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Mahin 111"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Mahin 1v"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Ugbo 1"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Ugbo 11"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Ugbo 111"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Ugbo 1v"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Ugbo V"},
 {"StateName":"Ondo","LGAName":"Ilaje","WardName":"Ugbo V1"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Ileoluji 1"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Ileoluji 11"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Ileoluji III"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Ileoluji 1v"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Ileoluji V"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Ileoluji Vi"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Oke-igbo 1"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Oke-igbo 11"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Oke-igbo 111"},
 {"StateName":"Ondo","LGAName":"Ileoluji okeigbo","WardName":"Oke-igbo 1v"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Ajagba 1"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Ajagba 11"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Akotogbo 1"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Akotogbo 11"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Iyansan omi"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Irele 1"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Irele 11"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Irele III"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Irele 1v"},
 {"StateName":"Ondo","LGAName":"Irele","WardName":"Irele V"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Agbabu"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Ago-alaye"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Ajue"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Araromi Obu"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Ayesan"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Ebijan"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Koseru"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Odigbo"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Oniparaga"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Ore 1"},
 {"StateName":"Ondo","LGAName":"Odigbo","WardName":"Ore 11"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Ayeka igbodigo"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Erinje"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Igbotako 1"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Igbotako 11"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Iju-odo erekiti"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Ikoya oloto"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Ilutitun 1"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Ilutitun II"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Ilutitun 111"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Ode Aye 1"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Aye 11"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Okitipupa 1"},
 {"StateName":"Ondo","LGAName":"Okitipupa","WardName":"Okitipupa 11"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Ateru otasan igba"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Asantan Oja"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Bolorunduro 1"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Epe"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Fagbo"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Obada"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Oboto"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Orisunmibare"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Owena Bridge"},
 {"StateName":"Ondo","LGAName":"Ondo East","WardName":"Tepo"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Enuowa obalalu"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Gbaghengha gbongbo ajagba Alafia"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Ifore odosida loro"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Ilunla bagbe odowo I"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Ilunla bagbe odowo II"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Litaye obunkekere igbindo"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Lodasa iparuku lijoka"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Odojomu erinketa legiri"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Okeagunla Okerowo okekuta"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Oke-otunba oke-diba sokoti"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Okelisa Okedoko ogbodu"},
 {"StateName":"Ondo","LGAName":"Ondo West","WardName":"Orisunmibare araromi"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Afo"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Idoani 1"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Idoani 11"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Idogun"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Ifon 1"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Ifon 11"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Ijagba"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Ikaro elegbeka"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Imeri"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Imoru arimogija"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Okeluse"},
 {"StateName":"Ondo","LGAName":"Ose","WardName":"Ute"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Ehinogbe"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Igboroko 1"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Igboroko 11"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Ijebu 1"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Ijebu 11"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Iloro"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Ipele"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Isaipen"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Isuada ipenmen idasan obasooto"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Iyare"},
 {"StateName":"Ondo","LGAName":"Owo","WardName":"Uso emure Ile"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Iwara"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Igangan"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Ipole"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Iperindo"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Eti-oni"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Ayegunle"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Forest Reserve 1"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Forest Reserve II"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Faforiji"},
 {"StateName":"Osun","LGAName":"Atakumosa East","WardName":"Ajebandele"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Osu I"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Osu II"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Osu III"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Ibodi"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Ifelodun"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Ita Gunmodi"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Oke Bode"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Isa Obi"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Muroko"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Ifewara I"},
 {"StateName":"Osun","LGAName":"Atakumosa West","WardName":"Ifewara II"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Balogun"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Otun Balogun"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Olufi"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Otun-olufi"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Ijegbe oke-eso oke-owu Ijugbe"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Lagere amola"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Gbongan Rural"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Ode-omu Rural"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Obalufon"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Anlugbua"},
 {"StateName":"Osun","LGAName":"Ayedaade","WardName":"Araromi-owu"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Oluponna 1"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Oluponna 1i"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Oluponna 1ii"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Oke-osun"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Ileogbo I"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Ileogbo II"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Ileogbo III"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Ileogbo iv"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Kuta I"},
 {"StateName":"Osun","LGAName":"Ayedire","WardName":"Kuta II"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Oke-omi Otan"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Oke Ode otan"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Oke-otan"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Gbeleru Obaala I"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Gbeleru Obaala II"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Obala Iloro"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Eripa"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Oke-irun"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Iresi I"},
 {"StateName":"Osun","LGAName":"Boluwaduro","WardName":"Iresi II"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Oloti Iragbiji"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Oja - oba"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"College egbada Road"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Isale-oyo"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Agba"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Ororuwo"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Ada I"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Ada II"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Isale Asa iree"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Oke Esa oke ogi"},
 {"StateName":"Osun","LGAName":"Boripe","WardName":"Oke Aree"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Olaba atapara"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Abogunde sagba"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Ologun agbaakin"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Olusokun"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Alusekere"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Sabo agbongbe I"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Sabo agbongbe II"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Isibo buari-isola"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Apaso"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Jagun jagun Ede"},
 {"StateName":"Osun","LGAName":"Ede North","WardName":"Bara Ejemu"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Babanla agate"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Kuye"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Asunmo"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Alajue I"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Alajue II"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Olodan"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Babasanya"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Sekona"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Oloki akoda"},
 {"StateName":"Osun","LGAName":"Ede South","WardName":"Loogun"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Awo abudo"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ara I"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ara II"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ido-osun"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ira Gberi I"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ira Gberi II"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Iwoye idoo origo"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ikotun"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Ojo aro"},
 {"StateName":"Osun","LGAName":"Egbedore","WardName":"Okin Ni olorunsogo ofatedo"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Elejigbo A"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Elejigbo B osolo"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Elejigbo C mapo"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Elejigbo D ejemu"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Elejigbo ayegbogbo"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Ola aye agurodo"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Ifeodan A owu-ile"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Ifeodan B masifa"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Ilawo isoko isundunrin"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Inisa I aato igbon"},
 {"StateName":"Osun","LGAName":"Ejigbo","WardName":"Inisa II afaake ayegunle"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Ilare 1"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Ilare 1i"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Ilare III"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Ilare Iv"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Iremo ajebandele"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Iremo II (eleyele)"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Iremo III"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Iremo Iv"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Iremo V"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"Akarabata"},
 {"StateName":"Osun","LGAName":"Ife Central","WardName":"More Ojaja"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Oyi"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Ayetoro"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Isinmi"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Balogun"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Obaale"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Aworo oke-ila Rural"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Asaoni"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Co-operative"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Akesin"},
 {"StateName":"Osun","LGAName":"Ifedayo","WardName":"Temidire"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"More"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Ilode I"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Ilode II"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Okerewe I"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Okerewe II"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Okerewe III"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Yekemi"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Modakeke I"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Modakeke II"},
 {"StateName":"Osun","LGAName":"Ife East","WardName":"Modakeke III"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Eesa Ikirun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Amola Ikirun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Owode Ikirun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Isale oke Afo"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Ikirun Rural"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Station Road, ikirun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Okeba Ikirun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Olonde Ikirun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Iba I"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Iba II"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Obagun"},
 {"StateName":"Osun","LGAName":"Ifelodun","WardName":"Ekoende eko Ajala"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Asipa akinlalu"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Edunabon I"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Edunabon II"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Famia"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Yakoyo"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Ipetumodu I"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Ipetumodu II"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Moro"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Oyere I"},
 {"StateName":"Osun","LGAName":"Ife North","WardName":"Oyere - II"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Ayesan"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Ikija I"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Ikija II"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Aare"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Mefoworade"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Oke Owena"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Olode"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Osi"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Kere"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Abiri Ogudu"},
 {"StateName":"Osun","LGAName":"Ife South","WardName":"Aye"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Ejigbo I"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Ejigbo II"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Ejigbo III"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Isedo I"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Isedo II"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Iperin"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Eyindi"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Oke Ola"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Oke Ede"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Eyindi iperin"},
 {"StateName":"Osun","LGAName":"Ila","WardName":"Ajaba edemosi aba Orangun"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Okesa"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Imo"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Ifosan oke-eso"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Itisin ogudu"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Ijamo"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Upper & lower ijoka"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Iloro roye"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Isare"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Ilerin"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Bolorunduro"},
 {"StateName":"Osun","LGAName":"Ilesa East","WardName":"Biladu"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Itakogun"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Lower Egbedi"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Upper And lower igbogi"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Omofe idasa"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Isokun"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Ikoti araromi"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Ilaje"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Isida adeti"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Ereja"},
 {"StateName":"Osun","LGAName":"Ilesa West","WardName":"Ayeso"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Olobu A"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Olubu B"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Olobu C"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Olobu D"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Bara A"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Bara B"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Elerin A"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Elerin B"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Elerin C"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Elerin D"},
 {"StateName":"Osun","LGAName":"Irepodun","WardName":"Elerin E"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire A"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire B"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire C"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire D"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire E"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire F"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire G"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire H"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire I"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire J"},
 {"StateName":"Osun","LGAName":"Irewole","WardName":"Ikire K"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Olukoyi (oja-osun)"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Osa Ikoyi (oloke)"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Asalu Ikoyi"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Oranran Ward"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Idogun Ward"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Alapomu i (odo-osun)"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Alapomu II"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Asalu (mogimogi)"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Oosa Adifa"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Awala I"},
 {"StateName":"Osun","LGAName":"Isokan","WardName":"Awala II"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Isale Oba I"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Isale Oba II"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Isale Oba III"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Isale Oba iv"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Molete I"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Molete II"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Molete III"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Oke-adan I"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Oke-adan II"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Oke-adan III"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Gidigbo I"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Gidigbo II"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Gidigbo III"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Oke-oba I"},
 {"StateName":"Osun","LGAName":"Iwo","WardName":"Oke-oba II"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Ibokun (township)"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Ipetu-ile adaowode"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Ilahun ikinyinwa"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Ilase idominasi"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Eesun ido-oko"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Imesi-ile"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Esa-oke"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Otan-ile"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Esa - odo"},
 {"StateName":"Osun","LGAName":"Obokun","WardName":"Ilare"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Oba ojomu"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Baale"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Igbaye"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Faji opete"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Ekosin iyeku"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Ore agbeye"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Ijabe ila Odo"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Okua ekusa"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Asi asaba"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Olunisa"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Olukotun"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Esa Otun baale ode"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Jagun Osi bale ode"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Oloyan Elemosho esa"},
 {"StateName":"Osun","LGAName":"Odo-otin","WardName":"Osolo oparin ola"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Telemu"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Asamu ilemowu"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Ogbaagba I"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Ogbaagba II"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Ikire Ile iwara"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Isero ikonifin"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Obamoro ile Ogo"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Bode-osi"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Ajagba iwo Oke"},
 {"StateName":"Osun","LGAName":"Ola-oluwa","WardName":"Asa Ajagunlase"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Agowande"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Balogun"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Akogun"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Atelewo"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Owoope"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Owode I"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Owode II"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Ayetoro"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Oba-ile"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Oba Oke"},
 {"StateName":"Osun","LGAName":"Olorunda","WardName":"Ilie"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Erin-oke"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Erin-ijesa"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ijebu-jesa"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Iwoye"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ikeji-ile"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ira"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ijeji Arakeji owena"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Apoti Dagbaja"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ipetu Ijesa I"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ipetu-ijesa II"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Ijeda Iloko"},
 {"StateName":"Osun","LGAName":"Oriade","WardName":"Erinmo iwaraja"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu A"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu B"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu C"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu D"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu E"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu F"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu G"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu H"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu I"},
 {"StateName":"Osun","LGAName":"Orolu","WardName":"Olufon Orolu J"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Ataoja A"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Ataoja B"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Ataoja C"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Ataoja D"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Ataoja E"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Otun Hagun b"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Alagba"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Are-ago"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Jagun A"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Jagun B'"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Baba Kekere"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Otun Jagun A"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Eketa"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Otun Balogun A"},
 {"StateName":"Osun","LGAName":"Osogbo","WardName":"Ekerin"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Ilora I"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Ilora II"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Ilora III"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Fiditi I"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Fiditi II"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Awe I"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Awe II"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Akinmorin jobele"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Iware"},
 {"StateName":"Oyo","LGAName":"Afijio","WardName":"Imini"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Ikereku"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Olanla oboda labode"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Arulogun eniosa aroro"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Olode amosun onidundu"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Ojo-emo moniya"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Akinyele isabiyi irepodun"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Iwokoto talontan idi-oro"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Ojoo ajibode laniba"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Ijaye ojedeji"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Ajibade alabata elekuru"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Olorisa-oko okegbemi mele"},
 {"StateName":"Oyo","LGAName":"Akinyele","WardName":"Iroko"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Oke-afin 1"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Oke-afin II"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Aremo"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Bashorun"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Agunpopo I"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Agunpopo II"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Agunpopo III"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Ashipa I"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Ashipa II"},
 {"StateName":"Oyo","LGAName":"Atiba","WardName":"Ashipa III"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Tede I"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Tede II"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Irawo Ile"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Irawo Owode"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Ofiki"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Alaga"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Ago Are I"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Ago Are II"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Owo agunrege sabe"},
 {"StateName":"Oyo","LGAName":"Atisbo","WardName":"Basi"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Erunmu"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Ayede alugbo koloko"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Owobaale kasumu"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Olodan ajinogbo"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Olodo kumapayi I"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Olodo II"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Olodo III"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Osegere awaye"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Egbeda"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Olode alakia"},
 {"StateName":"Oyo","LGAName":"Egbeda","WardName":"Olubadan Estate"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward I n2"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward II, n3"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward III, n4"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Iv, n5a"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward V, n5b"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Vi, n6a part I"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Vii, n6a part II"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Viii, n6a part III"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Ix, (n6b part i)"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward X, n6b part II"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Xi, nw8"},
 {"StateName":"Oyo","LGAName":"Ibadan North","WardName":"Ward Xii, nw8"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward I. ei"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward 2 ni (part II)"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Iii e3"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Iv e4"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward V e5a"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Vi e5b"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Vii e6"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Viii e7 I"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Ix e7ii"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward X e8"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Xi e9 I"},
 {"StateName":"Oyo","LGAName":"Ibadan North east","WardName":"Ward Xii e9 II"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward I ni (part i)"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 2 ni (part II)"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 3 nw1"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 4 nw2"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 5 nw3 (part i)"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 6 nw3 (part i)"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 7 nw4"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 8 nw5"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 9 nw6"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 10 nw7"},
 {"StateName":"Oyo","LGAName":"Ibadan North west","WardName":"Ward 11 nw7"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"C1"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 1"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 2a"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S. 2b"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 3"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 4a"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 4b"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S s5"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 6a"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S. 6b"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 7a"},
 {"StateName":"Oyo","LGAName":"Ibadan South-east","WardName":"S 7b"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 1 c2"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 2 sw1"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 3 sw2"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 4 sw3a & 3b"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 5 sw4"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 6 sw5"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 7 sw6"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 08 sw7"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 9 sw8 (1)"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 10 sw8 II"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 11 sw9(1)"},
 {"StateName":"Oyo","LGAName":"Ibadan South west","WardName":"Ward 12 sw9 II"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Idere I (molete)"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Idere II (ominigbo oke - oba)"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Idere III (koso apa)"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Iberekodo I (pataoju)"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Iberekodo agbooro ita Baale"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Idofin Isaganun"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Igbole pako"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Isale-oba"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Okeserin I & II"},
 {"StateName":"Oyo","LGAName":"Ibarapa Central","WardName":"Oke-odo"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Oke -oba"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Anko"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Isaba"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Aborerin"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"New Eruwa"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Sango"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Oke-imale"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Isale Togun"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Oke Otun"},
 {"StateName":"Oyo","LGAName":"Ibarapa East","WardName":"Itabo"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Ayete I"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Ayete II"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Igangan I"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Igangan II"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Igangan III"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Igangan Iv"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Ofiki I"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Ofiki II"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Tapa I"},
 {"StateName":"Oyo","LGAName":"Ibarapa North","WardName":"Tapa II"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Aba Emo ilaju alako"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Akufo idigba araromi"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Akinware akindele"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Apete ayegun awotan"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Batake idi-iya"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Erinwusi koguo odetola"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Fenwa oganla elenusonso"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Ido onikede okuna Awo"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Omi Adio omi onigbagbo bakatari"},
 {"StateName":"Oyo","LGAName":"Ido","WardName":"Ogundele alaho siba idi-ahun"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Agoro"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Ajagunna"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Atipa"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Iba I"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Iba II"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Iba III"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Iba Iv"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Iba V"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Ikolaba"},
 {"StateName":"Oyo","LGAName":"Irepo","WardName":"Laha ajana"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Ado-awaye"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Akinwumi osoogun"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Ekunle I"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Ekunle II"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Faramora"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Ijemba oke-ola oke-oja"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Isalu I"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Isalu II"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Koso I"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Koso II"},
 {"StateName":"Oyo","LGAName":"Iseyin","WardName":"Ladogan oke Eyin"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Babaode"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Igbojaiye"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Ipapo"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Komu"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Okaka I"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Okaka II"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Oke-amu"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Otu 1"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Otu II"},
 {"StateName":"Oyo","LGAName":"Itesiwaju","WardName":"Owode ipapo"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Agbaakin I"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Agbaakin II"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Iwere-ile I"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Iwere-ile II"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Iwere-ile III"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Iwere-ile Iv"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Sabi Gana I"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Sabi Gana II"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Sabi Gana III"},
 {"StateName":"Oyo","LGAName":"Iwajowa","WardName":"Sabi Gana iv"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Ayetoro-oke I"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Elero"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Gbelekale I & II"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Iba-ogan"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Ijo"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Ilaji Oke iwere-oke"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Imoba oke-ogun"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Isemi-ile imia ilua"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Isia"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Kajola"},
 {"StateName":"Oyo","LGAName":"Kajola","WardName":"Olele"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Ajara opeodu"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Apatere kuffi ogunbode ogo"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Arulogun Ehin kelebe"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Ejioku igbon ariku"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Lagelu Market kajola gbena"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Lagun"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Lalupon I"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Lalupon II"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Lalupon III"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Ofa-igbo"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Ogunjana olowode ogburo"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Ogunremi ogunsina"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Oyedeji olode kutayi"},
 {"StateName":"Oyo","LGAName":"Lagelu","WardName":"Sagbe pabiekun"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Abogunde"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Aaje ogunbado oke Agbede"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Aguodo Masifa"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Isale Afon"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Isale Alaasa"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Isale Ora saja"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Jagun"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Okelerin"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Osupa"},
 {"StateName":"Oyo","LGAName":"Ogbomoso North","WardName":"Sabo tara"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Akata"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Alapata"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Arowomole"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Ibapon"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Ijeru I"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Ijeru II"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Ilogbo"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Isoko"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Lagbedu"},
 {"StateName":"Oyo","LGAName":"Ogbomoso South","WardName":"Oke-ola farm Settlement"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Ajaawa I"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Ajaawa II"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Ayede"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Ayetoro"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Idewure"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Lagbedu"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Mowolowo iwo-ate"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Odo-oba"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Opete"},
 {"StateName":"Oyo","LGAName":"Ogo-oluwa","WardName":"Otamokun"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Aboke (aboyun ogun)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Elerugba elehinke sagbo (aperu)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Ikolaba obadimo"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Onigbeti I (iyamopo)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Onigbeti II saagbon agoro (santo)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Onigbeti III & iv (agbeni)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Opa ogunniyi"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Seriki I & abosino (okin)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Seriki II (agbele)"},
 {"StateName":"Oyo","LGAName":"Olorunsogo","WardName":"Waro apata-alaje"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Ayegun"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Idi-iroko ikereku"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Idi-osan egbeda-atuba"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Muslim ogbere"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Odo-ona Nla"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Okanhinde latunde"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Olomi olunde"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Olonde aba-nla"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Onipe"},
 {"StateName":"Oyo","LGAName":"Oluyole","WardName":"Orisunbare ojo-ekun"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Akanran olorunda"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Araromi aperin"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Badeku"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Gbada Efon"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Odi Odeyale odi aperin"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Ogbere"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Ogbere Tioya"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Ojoku ajia"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Olorunsogo"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Olode gbedun ojebode"},
 {"StateName":"Oyo","LGAName":"Ona-ara","WardName":"Oremeji agugu"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Alepata"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Aare"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Bonni"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Igbope iyeye I"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Igbope iyeye II"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Igi Isubu"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Onibode I"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Onibode II"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Onibode III"},
 {"StateName":"Oyo","LGAName":"Oorelope","WardName":"Onigboho alomo okere"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire I"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire II"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire III"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire iv"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire v"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire vi"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire vii"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire viii"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire ix"},
 {"StateName":"Oyo","LGAName":"Ori Ire","WardName":"Ori Ire x"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Agboye molete"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Ajagba"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Alaodi modeke"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Apaara"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Apinni"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Balogun"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Jabata"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Oke Apo"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Oluajo"},
 {"StateName":"Oyo","LGAName":"Oyo East","WardName":"Owode araromi"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Akeetan"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Ajokidero akewugberu"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Fasola soku"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Iseke"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Isokun"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Iyaji"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Ojongbodu"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Opapa"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Owode"},
 {"StateName":"Oyo","LGAName":"Oyo West","WardName":"Pakoyi idode"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Agbonle"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Ago Amodu I"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Ago Amodu II"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Ogbooro I"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Ogbooro II"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Oje Owode I"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Oje Owode II"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Sepeteri I"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Sepeteri II"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Sepeteri III"},
 {"StateName":"Oyo","LGAName":"Saki East","WardName":"Sepeteri Iv"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Aganmu kooko"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Ajegunle"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Bagii"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Ekokan-mua"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Iya"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Ogidigbo kinnikinni"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Oke-oro"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Okere I"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Okere II"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Sangote booda baabo ilus"},
 {"StateName":"Oyo","LGAName":"Saki West","WardName":"Sepeteri bapon"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Baya-oje"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Igbon gambari"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Iresaadu I (iresaapa)"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Iresaadu II (arolu)"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Iresaadu III (iresaadu)"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Iresaadu Iv (iregba)"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Iwofin"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Oko I (oko"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Oko II (ilajue)"},
 {"StateName":"Oyo","LGAName":"Surulere","WardName":"Oko III (mayin)"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Barakin Ladi"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Gassa sho"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Gindin Akwati"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Heipang"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Kapwis"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Kurra Falls"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Lobiring"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Marit mazat"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Rafan"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Tafan"},
 {"StateName":"Plateau","LGAName":"Barikin Ladi","WardName":"Zabot"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Buhit"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Buji"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Gabia"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Gurum"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Jengre"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Kadamo"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Kakkek"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Kasuru"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Kimakpa"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Kishika"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Mafara"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Rimi"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Ta'agbe"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Tahu"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Zabolo"},
 {"StateName":"Plateau","LGAName":"Bassa","WardName":"Zobwo"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Bokkos"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Butura"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Daffo"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Damwai"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Kwatas"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Mangor"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Mbar mangar"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Manguna"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Mushere Central"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Mushere west"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Richa"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Sha"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Tangur"},
 {"StateName":"Plateau","LGAName":"Bokkos","WardName":"Toff"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Federe"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Fobur A"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Fobur B"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Fursum"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Jarawan Kogi"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Mai Gemu"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Maijuju"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Shere East"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Shere West"},
 {"StateName":"Plateau","LGAName":"Jos East","WardName":"Zandi"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Abba Na shehu"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Ali Kazaure"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Garba Daho"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Gangare"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Ibrahim Katsina"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Jenta Adamu"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Jenta Apata"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Jos Jarawa"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Naraguta A"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Naraguta B"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Sarkin Arab"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Tafawa Balewa"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Tudun Wada - kabong"},
 {"StateName":"Plateau","LGAName":"Jos North","WardName":"Vanderpuye"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Bukuru"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Du"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Giring"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Gyel A"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Gyel B"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Kuru A"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Kuru B"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Shen"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Turu"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Vwang"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Zawan A"},
 {"StateName":"Plateau","LGAName":"Jos South","WardName":"Zawan B"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Birbyang"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Dengi"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Dugub"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Gagdib"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Garga"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Gumsher"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Gwamlar"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Jarmai"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Jom"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Kanam"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Kantana"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Kunkyam"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Munbutbo"},
 {"StateName":"Plateau","LGAName":"Kanam","WardName":"Namaran"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Ampang-east"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Amper Chika A"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Amper Chika B"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Amper Seri"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Dawaki"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Garram"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Kabwir Pada"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Kabwir gyangyang"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Langshi"},
 {"StateName":"Plateau","LGAName":"Kanke","WardName":"Nemel"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Funyalang"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Jat"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Keller"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Kuffen"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Kwallak"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Kwande"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Lipchok"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Mban zamko"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Nyer"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Pajat"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Pil Gani"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Pishe yashi"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Reak"},
 {"StateName":"Plateau","LGAName":"Langtang North","WardName":"Waroh"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Dadin Kowa"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Fajul"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Gamakai"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Lashel"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Mabudi"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Magama"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Sabon Gida"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Talgwang"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Timbol"},
 {"StateName":"Plateau","LGAName":"Langtang South","WardName":"Turaki"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Ampang West"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Chanso"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Gindiri 1"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Gindiri 11"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Jannaret"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Jipal chakfem"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Kadunu"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Kerang"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Kombun"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Langai"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Mangu 1"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Mangu 11"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Mangun"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Mangu Halle"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Pan Yam"},
 {"StateName":"Plateau","LGAName":"Mangu","WardName":"Pushit"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Baltep"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Garkawa Central"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Garkawa North"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Garkawa North east"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Koenoem A"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Koenoem B"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Lalin"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Piapung A"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Piapung B"},
 {"StateName":"Plateau","LGAName":"Mikang","WardName":"Tunkus"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Chip"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Dok-pai"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Fier"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Jiblik"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Kadung"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Kangshu"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Lankang"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Pankshin Central"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Pankshin chigwong"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Pankshin south (belning)"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Tal"},
 {"StateName":"Plateau","LGAName":"Pankshin","WardName":"Wokkos"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Bwall"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Doemak-goechim"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Doemak-koplong"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Dokan Kasuwa"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Kurgwi"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Kwa"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Kwalla Moeda"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Kwalla Yitla'ar"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Kwande"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Kwang"},
 {"StateName":"Plateau","LGAName":"Qua'an Pan","WardName":"Namu"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Attakar"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Bum"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Danto"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Jol kwi"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Ra-hoss"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Rim"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Riyom"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Sharubutu"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Sopp"},
 {"StateName":"Plateau","LGAName":"Riyom","WardName":"Ta-hoss"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Derteng"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Kalong"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Kurungbau (a)"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Kurungbau (b)"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Moekat"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Pangshom"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Poeship"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Shendam Central (a)"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Shendam Central (b)"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Shimankar"},
 {"StateName":"Plateau","LGAName":"Shendam","WardName":"Yelwa"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Bashar"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Danbiram"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Gudus"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Kadarko"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Kumbong"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Kumbur"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Kuyambana"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Mavo"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Nyalum kampani"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Saluwe"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Wase Tofa"},
 {"StateName":"Plateau","LGAName":"Wase","WardName":"Yola Wakat"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Abua I"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Abua II"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Abua III"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Abua iv"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Emughan I"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Emughan II"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Otapha"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Okpeden"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Agada"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Emelego"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Emago-kugbo"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Akani"},
 {"StateName":"Rivers","LGAName":"Abua-odual","WardName":"Anyu"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Ahoada I"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Ahoada II"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Ahoada III"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Ahoada iv"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Uppata I"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Uppata II"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Uppata III"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Uppata iv"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Uppata v"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Uppata vi"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Akoh I"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Akoh II"},
 {"StateName":"Rivers","LGAName":"Ahoada East","WardName":"Akoh III"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Ediro I"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Ediro II"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Joinkrama"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Okarki"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Igbuduya I"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Igbuduya II"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Igbuduya III"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Igbuduya Iv"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Ubie I"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Ubie II"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Ubie III"},
 {"StateName":"Rivers","LGAName":"Ahoada West","WardName":"Ubie Iv"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Manuel I"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Manuel II"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Manuel III"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Briggs I"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Briggs II"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Briggs III"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Georgewill III"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Georgewill II"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Georgwill III"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Jack I"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Jack II"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Jack III"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Alise Group"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Obonoma"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Kula I"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"Kula II"},
 {"StateName":"Rivers","LGAName":"Akuku Toru","WardName":"North south Group"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Ngo town"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Unyen Gala"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Agwut-obolo"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Ekede"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Ikuru town"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Unyeada I"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Unyeada II"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Samanga"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Asarama"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Ataba I"},
 {"StateName":"Rivers","LGAName":"Andoni","WardName":"Ataba II"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma South"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma South east"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma east I"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma east II"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma south west"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma north east"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma West"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma North west I"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma North west II"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Buguma East west"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Isia Group I"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"Isia Group II"},
 {"StateName":"Rivers","LGAName":"Asari-toru","WardName":"West Cental group"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward I oro-igwe"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward II court ada allison"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward III orosiriri"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward Iv new layout"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward V finima"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward Vi abalamabie"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward Vii dema abbey"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward III dan jumbo beresiri"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward Ix nanabie"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward X oloma ayaminima"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward Xi peterside"},
 {"StateName":"Rivers","LGAName":"Bonny","WardName":"Ward Xii kalaibiama"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bakana I"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bakana II"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bakana III"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bakana iv"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bakana V"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bakana vi"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Tomble I"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Tomble II"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Tomble III"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Tomble Iv"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Degema I"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Degema II"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Degema III"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Obuama"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bukuma"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Bille"},
 {"StateName":"Rivers","LGAName":"Degema","WardName":"Ke old Bakana"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Alesa"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Alode"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Ogale"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Ebubu"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Ekporo"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Eteo"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Onne"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Agbonchia"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Akpajo"},
 {"StateName":"Rivers","LGAName":"Eleme","WardName":"Aleto"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Ogbakiri I"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Ogbakiri II"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Emohua I"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Emohua II"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Obelle"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Ibaa"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Rundele"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Rumuekpe"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Elele Alimini"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Omudioga Akpadu"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Egbeda"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Ubimini"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Odegu I"},
 {"StateName":"Rivers","LGAName":"Emohua","WardName":"Odegu II"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Afara"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Egbu"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Igbo I"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Igbo II"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Igbo III"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Egwi opiro"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Nihi"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Odufor"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Obibi akwukabi"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Obite"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Okehi"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Ozuzu"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Ulakwo"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Igbodo"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Akwa odogwa"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Akpoku umuoye"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Ndashi"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Mba"},
 {"StateName":"Rivers","LGAName":"Etche","WardName":"Owu"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Bodo I"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Bodo II"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Bodo III"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"B-dere"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"K-dere I"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"K-dere II"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Bomu I"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Bomu II"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Kpor lewe gbe"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Mogho"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Nweol gioko barako"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Biara I"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Biara II"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Derken deeyor nweribiara"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Yeghe I"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Yeghe II"},
 {"StateName":"Rivers","LGAName":"Gokana","WardName":"Bera"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Isiokpo I"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Isiokpo II"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Elele I"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Elele II"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Omerelu"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Apani"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Umuanwa"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Ubima"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Omagwa"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Omademe ipo"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Ozuaha"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Igwuruta"},
 {"StateName":"Rivers","LGAName":"Ikwerre","WardName":"Aluu"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Bori"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Zaakpori"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Llueku nyokuru"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Sogho"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Okwali"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Kaani"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Bargha"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Taabaa"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Beeri"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Bane"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Kono kwawa"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Wiiyaa Kara"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Boue"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Uegwere"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Gwara kaa eeken"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Opuoko kalaoko"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Sii betem kbaabbe"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Baen kpean duburo"},
 {"StateName":"Rivers","LGAName":"Khana","WardName":"Lorre luebe kpaa"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Oro-igwe"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuodara"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuokwu (2b)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuodomaya (3a)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Elelenwo (3b)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Worji"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuokoro"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuomasi"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumueme (7a)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumueme (7b)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumueme (7c)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuigbo (8a)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumukwuta (8b)"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rukpoku"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Choba"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Ozuoba ogbogoro"},
 {"StateName":"Rivers","LGAName":"Obio akpor","WardName":"Rumuolumeni"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Omoku town I"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Omoku town II"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Omuku Town (obieti)"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Omoku Town iv (usomini)"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Omoku Town v"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Usomini I (north)"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Usomini II (south)"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Igburu"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Egi I"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Egi II"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Egi III"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Egi iv"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Egbema I"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Egbema II"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Ndoni I"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Ndoni II"},
 {"StateName":"Rivers","LGAName":"Ogba egbema ndoni","WardName":"Ndoni III"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ogu I"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ogu II"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ogu III"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ogu iv"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ogu v"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ogu vi"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Bolo I"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Bolo II"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Bolo III"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Bolo iv"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Wakama"},
 {"StateName":"Rivers","LGAName":"Ogu bolo","WardName":"Ele"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika I"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika II"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika III"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika iv"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika v"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika Vi"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Okrika Vii"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Ogan"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Kalio"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Ogoloma I"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Ogoloma II"},
 {"StateName":"Rivers","LGAName":"Okrika","WardName":"Ogoloma III"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Eberi-dikeomuuo Community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Ariraniiri owu-ahia Community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Obiohia Community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Ohimogho Community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Oyoro"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Ofeh Community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Umuogba I community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Umuogba II community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Obibi ajuloke Community"},
 {"StateName":"Rivers","LGAName":"Omuma","WardName":"Umuajuloke Community"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Kalaibiama I"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Kalaibiama II"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Diepiri"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Ukonu"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Dappaye Ama-kiri I"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Dappaye Ama-kiri II"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Jaja"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Queens Town kalama"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Nkoro I"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Nkoro II"},
 {"StateName":"Rivers","LGAName":"Opobo nekoro","WardName":"Nkoro III"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Okoloma"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Obeakpu"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Ogberu"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Umuagbai"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Azuogu"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Obete"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Asa"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Oyigba West"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Oyigbo Central"},
 {"StateName":"Rivers","LGAName":"Oyigbo","WardName":"Komkom"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Oromineke ezimgbu"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Orogbum"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Oroabali"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Ogbunabali"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Port Harcourt township"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Port Harcourt township vi"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Port Harcourt vii"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Ochiri rumukalagbor"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Oroworukwo"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Nkpolu Oroworukwo"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Rumuwoji (one)"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Rumuwoji (two)"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Rumuwoji (three)"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Mgbundukwu (one)"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Mgbundukwu (two)"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Rumuobiekwe Ward"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Diobu"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Nkpolu Oroworukwo two"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Elekahia"},
 {"StateName":"Rivers","LGAName":"Port Harcourt","WardName":"Abuloma amadi-ama"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward I (botem gbeneo)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward II (kpite)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward III (korokoro)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward Iv koroma horo"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward V (kira borobara)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward Vi (gio kporghor gbam)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward Vii (nonwa)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward Viii (barryira bara-ale sime)"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward Ix nanabie"},
 {"StateName":"Rivers","LGAName":"Tai","WardName":"Ward X (ban-ogoi)"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Inname"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Samama"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Binji"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Jammali"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"T kose"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Gawazzai"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Maikulki"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Bunkari"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Soro Gabas"},
 {"StateName":"Sokoto","LGAName":"Binji","WardName":"Soro Yamma"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Bodinga tauma"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Bagarawa"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Mazan Gari jirga miyo"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Sifawa lukuyawa"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Badau darhela"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Dingyadi badawa"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Tulluwa kulafasa"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Danchadi"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Bangi dabaga"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Kwacciyar Lalle"},
 {"StateName":"Sokoto","LGAName":"Bodinga","WardName":"Takatuku madorawa"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Dange"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Wababe salau"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Bodai jurga"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Fajaldu"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Shuni"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Rudu amanawa"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Tsafanade"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Tuntube tsehe"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Giere gajara"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Rikina"},
 {"StateName":"Sokoto","LGAName":"Dange shuni","WardName":"Ruggar Gidado"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Gada"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kaddi"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kadadin Buda (kaddi)"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Gilbadi"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Tsitse"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kaffe"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Dukamaje ilah"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kyadawa holai"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kiri"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kadassaka"},
 {"StateName":"Sokoto","LGAName":"Gada","WardName":"Kwarma"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Goronyo"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Giyawa"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Boyekai"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Kagara"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Kwakwazo"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Kojiyo"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Rimawa"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Shinaka"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"S gari Dole dan tasakko"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Birjingo"},
 {"StateName":"Sokoto","LGAName":"Goronyo","WardName":"Takakume"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Karfen Sarki"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Chilas makuya"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Bachaka"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Gwazange boto"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Kurdula"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Balle"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Awulkiti"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Karfen Chana"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Maraken Bori"},
 {"StateName":"Sokoto","LGAName":"Gudu","WardName":"Tulun Doya"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Asara Kudu"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Asara arewa"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Atakwanyo"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Chimmola kudu"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Chimola Arewa"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Gwadabawa"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Gidan Kaya"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Gigane"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Mammande"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Salame"},
 {"StateName":"Sokoto","LGAName":"Gwadabawa","WardName":"Huchi"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Illela"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Kalmalo"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Damba"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Darna Sabon gari"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Darne Tsolawo"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"G Hamma"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Araba"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Garu"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"R. Gati"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"Tozai"},
 {"StateName":"Sokoto","LGAName":"Illela","WardName":"G Katta"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Isa North"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Isa South"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Turba"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Tsabren Sarkin darai"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Tidibale"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Bargaja"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Yanfako"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Tozai"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Gebe A"},
 {"StateName":"Sokoto","LGAName":"Isa","WardName":"Gebe B"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Kware"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Bankanu R, kade"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Basansan"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"S birni G. karma"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"H Ali marabawa"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"G Modibbo g. akwara"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"G. Rugga"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Durbawa"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Tunga Mallamawa"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Kabanga"},
 {"StateName":"Sokoto","LGAName":"Kware","WardName":"Tsaki Walake'e"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Kebbe East"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Kebbe West"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Margai - a"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Margai - b"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Kuchi"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Nasagudu"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Sangi"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Fakku"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Ungushi"},
 {"StateName":"Sokoto","LGAName":"Kebbe","WardName":"Girkau"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Rabah"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Riji maikujera"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Rara"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Gwaddodi gidan Bu wai"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Gawakuke"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Gandi A"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Gandi B"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Tsamiya"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Yar Tsakuwa"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Kurya"},
 {"StateName":"Sokoto","LGAName":"Rabah","WardName":"Tursa"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"S birni East"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"S birni West"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Tsamaye"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Unguwar Lalle"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Tara"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Gatawa"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Kuruwa"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Gangara"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Kalgo"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Makuwana"},
 {"StateName":"Sokoto","LGAName":"S birni","WardName":"Lajinge"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Jaredi"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Horo Birni"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Dandin Mahe"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Kambama"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Gangam"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Lambara"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Shagari"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Sanyinnawal"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Kajiji"},
 {"StateName":"Sokoto","LGAName":"Shagari","WardName":"Mandera"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Gande East"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Gande West"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Katami North"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Katami South"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Kubodu North"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Kubodu South"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Labani"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Marafa East"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Marafa West"},
 {"StateName":"Sokoto","LGAName":"Silame","WardName":"Silame"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Waziri A"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Waziri B"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Waziri C"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Magajin Gari A"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Magajin Gari b"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"S adar g igwai"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Magajin Rafi A"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"Magajin Rafi B"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"S musulmi A"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"S musulmi B"},
 {"StateName":"Sokoto","LGAName":"Sokoto North","WardName":"S adar Gandu"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"R dorowa A"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"R dorowa B"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"T wada A"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"T wada B"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"S zamfara A"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"S zamfara B"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"Gagi A"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"Gagi B"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"Gagi C"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"Sarkin Adar kwanni"},
 {"StateName":"Sokoto","LGAName":"Sokoto South","WardName":"S a k atiku"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Romon Sarki"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Bakaya sabon Birni"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Bashire maikada"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Bagida lukkingo"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Tambuwal shinfiri"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Faga alasan"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Dogondaji sala"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Sanyinna"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Barkeji nabaguda"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Jabo kagara"},
 {"StateName":"Sokoto","LGAName":"Tambuwal","WardName":"Saida goshe"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Tangaza"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Ruwa-wuri"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Raka"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Salewa"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Gidan Madi"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Sutti"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Kwacce-huru"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Kalanjeni"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Magonho"},
 {"StateName":"Sokoto","LGAName":"Tangaza","WardName":"Sakkwai"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Duma"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Lofa"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Tsamiya"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Barayar Giwa"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Fura Girke"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Lambar Tureta"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Tureta Gari"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Kuruwa"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Gidan Kare bimasa"},
 {"StateName":"Sokoto","LGAName":"Tureta","WardName":"Kwarare"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Arkilla"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Bado kasarawa"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Gumbi wajake"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Kalambaina girabshi"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"G hamidu g kaya"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"G bubu g yaro"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Wamakko"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Dundaye gumburawa"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"K kimba gedewa"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Gwamatse"},
 {"StateName":"Sokoto","LGAName":"Wamakko","WardName":"Kammata"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Marafa"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Magarya"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Dankemu"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Dimbiso"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Kwargaba"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Chacho marnona"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Dinawa"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Achida"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Alkammu gyelgyel"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Lahodu g bango"},
 {"StateName":"Sokoto","LGAName":"Wurno","WardName":"Kwasare sisawa"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Fakka"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Bingaje"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Binji"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Torankawa"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Ruggar Iya"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Yabo A"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Yabo B"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Kilgori"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Birniruwa"},
 {"StateName":"Sokoto","LGAName":"Yabo","WardName":"Bakale"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Alim Gora"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Ardo Kola"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Iware"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Jauro Yinu"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Lamido Borno"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Mayo Ranewo"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Sarkin Dutse"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Sunkani"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Tau"},
 {"StateName":"Taraba","LGAName":"Ardo - kola","WardName":"Zongon Kombi"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Badakoshi"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Bali a"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Bali B"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Gang Dole"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Ganglari"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Gangtiba"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Gang Mata"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Kaigama"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Maihula"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Suntai"},
 {"StateName":"Taraba","LGAName":"Bali","WardName":"Takalafiya"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Akate"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Asibiti"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Fada"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Gayama"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Gindin Dutse"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Gyatta Aure"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Bikadarko"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Mararraba"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Nyita"},
 {"StateName":"Taraba","LGAName":"Donga","WardName":"Suntai"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Galumjina"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Gangumi"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Garbabi"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Gashaka"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Gayam"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Jamtari"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Mai-idanu"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Mayo Selbe"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Serti A"},
 {"StateName":"Taraba","LGAName":"Gashaka","WardName":"Serti B"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Gassol"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Gunduma"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Mutum Biyu I"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Mutum Biyu II"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Nam Nai"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Sabon Gida"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Sarkin Shira"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Sendirde"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Tutare"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Wurojam"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Wuryo"},
 {"StateName":"Taraba","LGAName":"Gassol","WardName":"Yarima"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Dampar I"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Dampar II"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Dampar III"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Ibi Nwonyo I"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Ibi Nwonyo II"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Ibi Rimi uku I"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Ibi Rimi uku II"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Sarkin Kudu I"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Sarkin Kudu II"},
 {"StateName":"Taraba","LGAName":"Ibi","WardName":"Sarkin Kudu III"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Abbare Yelwa"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Barade"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Kachalla Sembe"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Kona"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Majidadi"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Mayo Goi"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Sarkin Dawaki"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Sintali"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Turaki A"},
 {"StateName":"Taraba","LGAName":"Jalingo","WardName":"Turaki B"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Amar"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Andamin"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Bachama"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Bikwin"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Darofai"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Didango"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Jen Ardido"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Jen Kaigama"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Karim A"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Karim B"},
 {"StateName":"Taraba","LGAName":"Karim-lamido","WardName":"Kwanchi"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Abong"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Akwento boko"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Ashuku eneme"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Baissa"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Bente galea"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Bissaula"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Didan"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Ndaforo geanda"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Njuwande"},
 {"StateName":"Taraba","LGAName":"Kurmi","WardName":"Nyido tosso"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Abbare I"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Abbere II"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Donadda"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Garin Dogo"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Garin Magaji"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Jimlari"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Kunini"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Lau I"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Lau II"},
 {"StateName":"Taraba","LGAName":"Lau","WardName":"Mayo Lope"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Gembu A"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Gembu B"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Kabri"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Kakara"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Magu"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Mayo-ndaga"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Mbamnga"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Ndum-yaji"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Nguroje"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Titong"},
 {"StateName":"Taraba","LGAName":"Sardauna","WardName":"Warwar"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Bete"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Chanchanji"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Dutse"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Fete"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Gahweton"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Bikashibila"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Manya"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Rogo"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Shibong"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Tikari"},
 {"StateName":"Taraba","LGAName":"Takum","WardName":"Yukuben"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Bika"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Fikyu"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Jenuwa"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Kpambo"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Kpambo Puri"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Kwambai"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Kwesati"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Lissam I"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Lissam II"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Lumbu"},
 {"StateName":"Taraba","LGAName":"Ussa","WardName":"Rufu"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Akwana"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Avyi"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Bantaje"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Chonku"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Hospital"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Jibu"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Kente"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Puje"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Rafin Kada"},
 {"StateName":"Taraba","LGAName":"Wukari","WardName":"Tsokundi"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Bikassa I"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Bikassa II"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Nyaja I"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Nyaja II"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Pantisawa I"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Pantisawa II"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Pupule I"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Pupule II"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Pupule III"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Sumbu I"},
 {"StateName":"Taraba","LGAName":"Yorro","WardName":"Sumbu II"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Bitako"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Bubong"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Dinding"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Lamma"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Monkin A"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Monkin B"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Yakoko"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Zing Ai"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Zing Aii"},
 {"StateName":"Taraba","LGAName":"Zing","WardName":"Zing B"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Dagona"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Gwio-kura"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Katuzu"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Lawan Audu lawan al - wali"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Lawan Fannami"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Lawan Musa"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Sarkin Hausawa"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Tagali sugum"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Usur dawayo"},
 {"StateName":"Yobe","LGAName":"Bade","WardName":"Zangon Musa zango umaru"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Bayamari"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Damnawa juluri"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Danani"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Dapchi"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Garun Dole garin alkali"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Guba"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Guji metalari"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Kaliyari"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Kurnawa"},
 {"StateName":"Yobe","LGAName":"Bursari","WardName":"Masaba"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Bindigari fawari"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Damakasu"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Damaturu Central"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Gabir maduri"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Kalallawa gabai"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Kukareta warsala"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Maisandari waziri Ibrahim estate"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Murfa Kalam"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Nayinawa"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Njiwaji gwange"},
 {"StateName":"Yobe","LGAName":"Damaturu","WardName":"Sasawa kabaru"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Daya chana"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Fika anze"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Gadaka shembire"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Gudi dozi godo woli"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Janga boza fa. sawa t. nanai"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Mubi fusami garin wayo"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Ngalda dumbulwa"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Shoye garin Aba"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Turmi maluri"},
 {"StateName":"Yobe","LGAName":"Fika","WardName":"Zangaya mazawaun"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Abakire ngenlshengele shamka"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Alagarno"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Borno Kiji ngarho bebbende"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Damagum Town"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Daura bulanyiwa dubbul bauwa"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Dogon Kuka gishiwari gununu"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Fune ngelzarma milbiyar lawan Kalam"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Gaba Tasha aigada dumbulwa"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Gudugurka marmar I"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Jajere banellewa babbare"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Kayeri"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Kollere kafaje"},
 {"StateName":"Yobe","LGAName":"Fune","WardName":"Mashio"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Asheikri"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Balle gallaba meleri"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Damakarwa kusur"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Dejina fukurti"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Futchimiram"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Gumsa"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Hausari"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Kawuri"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Ma'anna dagambi"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Shame Kura dilawa"},
 {"StateName":"Yobe","LGAName":"Geidam","WardName":"Zurgu Ngilewa borko"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Bunigari lawanti"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Buniyadi North south"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Dadingel"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Goniri"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Gotala gotumba"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Gujba"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Mallam Dunari"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Mutai"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Ngurbuwa"},
 {"StateName":"Yobe","LGAName":"Gujba","WardName":"Wagir"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Bara"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Borno Kiji tetteba"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Bularafa"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Bumsa"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Dokshi"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Gabai"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Gagure"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Garin Tuwo"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Gulani"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Kushimaga"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Njibulwa"},
 {"StateName":"Yobe","LGAName":"Gulani","WardName":"Ruhu"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Buduwa saminaka"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Dumbari"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Gidgid bayam"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Gorgoram"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Jaba"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Jakusko"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Jawur katamma"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Lafiya Loi-loi"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Muguram"},
 {"StateName":"Yobe","LGAName":"Jakusko","WardName":"Zabudum dachia"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Bukarti"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Fajiganari"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Garin Gawo"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Gasma"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Jaji maji"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Karasuwa Galu"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Karauswa Garu guna"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Wachakal"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Waro"},
 {"StateName":"Yobe","LGAName":"Karasawa","WardName":"Yajiri"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Bogo"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Damai"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Dole"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Falimaram"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Kom-komma"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Kuka-yasku"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Lamisu"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Machina-kwari"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Maskandare"},
 {"StateName":"Yobe","LGAName":"Machina","WardName":"Taganama"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Chilariye"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Dadiso chukuriwa"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Dawasa g.baba"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Dazigau"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Degubi"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Kukuri chiromari"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Langawa darin"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Nangere"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Pakarau Kare-kare pakarau fulani"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Tikau"},
 {"StateName":"Yobe","LGAName":"Nangere","WardName":"Watinani"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Bulabulin"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Bulanguwa"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Dabule"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Dumsai dogon-kuka"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Garbi bambori"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Hausari"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Kanuri"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Maja-kura"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Mirba-kabir mirba Sagir"},
 {"StateName":"Yobe","LGAName":"Nguru","WardName":"Nglaiwa"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Bare-bare bauya lalai Dumbulwa"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Bolewa A"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Bolewa B"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Danchuwa bula"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Dogo Nini"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Dogo Tebo"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Hausawa"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Mamudo"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Ngojin alaraba"},
 {"StateName":"Yobe","LGAName":"Potiskum","WardName":"Yerimaram garin Daye badejo nahuta"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Babangida"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Barkami bulturi"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Biriri churokusko"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Jumbam"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Koka sungul"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Koriyel"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Lantaiwa"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Mafa"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Mandadawa"},
 {"StateName":"Yobe","LGAName":"Tarmuwa","WardName":"Shekau"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Bultuwa mar yaro"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Daratoshia"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Degaltura ngamzai"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Dekwa"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Dilala kalgi"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Mairari"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Mozogun kujari"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Ngirabo"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Wadi kafiya"},
 {"StateName":"Yobe","LGAName":"Yunusari","WardName":"Zajibiri dumbal"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Alanjirori"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Gumshi"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Guya"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Jebuwa"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Kajimaram sumbar"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Kaska tulotulowa"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Kumagannam"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Mai-malari"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Mayori"},
 {"StateName":"Yobe","LGAName":"Yusufari","WardName":"Yusufari"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Bagega"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Barayar-zaki"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Dan Galadima"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Galadima"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Magaji"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Matseri"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Sabon Birini"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Waramu"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Wuya"},
 {"StateName":"Zamfara","LGAName":"Anka","WardName":"Yar'sabaya"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Bakura"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Birnin Tudu"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Damri"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Dankadu"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Dan Manau"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Yar Kufoji"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Dakko"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Nasarawa"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Rini"},
 {"StateName":"Zamfara","LGAName":"Bakura","WardName":"Yar Geda"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Birnin Magaji"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Danfami Sabon birini"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Gora"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Gusami Gari"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Gusami Hayi"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Kiyawa"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Modomawa East"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Modomawa West"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Nasarawa Godal east"},
 {"StateName":"Zamfara","LGAName":"Birnin Magaji","WardName":"Nasarawa Godal west"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Adabka"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Bukkuyum"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Gwashi"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Kyaram"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Masama"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Nasarawa"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Ruwan Jema"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Yashi"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Zarummai"},
 {"StateName":"Zamfara","LGAName":"Bukkuyum","WardName":"Zauma"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Bingi North"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Bingi South"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Bungudu"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Furfuri kwai-kwai"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Gada karakkai"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Nahuce"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Kwatarkwashi"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Rawayya Bela"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Samawa"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Sankalawa"},
 {"StateName":"Zamfara","LGAName":"Bungudu","WardName":"Tofa"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Bardoki"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Birnin Magaji"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Birnin Tudu"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Falale"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Felfeldu gamo"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Gayari"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Gyalange"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Illelar Awal"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Magajin Gari"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Shiyar Rafi"},
 {"StateName":"Zamfara","LGAName":"Gummi","WardName":"Ubandawaki"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Galadima"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Madawaki"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Mada"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Magami"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Mayana"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Rijiya"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Ruwan Bore"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Sabon Gari"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Tudun Wada"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Wanke"},
 {"StateName":"Zamfara","LGAName":"Gusau","WardName":"Wonaka"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Banga"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Dan - isa"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Gabake"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Galadima Dan galadima"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Kagara"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Kungurki"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Kurya Madaro"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Kyam Barawa"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Sakajiki"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"S baura s mafara"},
 {"StateName":"Zamfara","LGAName":"Kaura Namoda","WardName":"Yankaba"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Birnin Kaya dosara"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Damaga damagiwa"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Faru magami"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Gora"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Gidan Goga"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Janbako"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Kaya"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Maradun North"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Maradun South"},
 {"StateName":"Zamfara","LGAName":"Maradun","WardName":"Tsibiri"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Bindin"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Bingi"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Dan Gulbi"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Dan Kurmi"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Dan Sadau"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Kanoma"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Kuyan Bana"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Maru"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Mayanchi"},
 {"StateName":"Zamfara","LGAName":"Maru","WardName":"Ruwan Dorawa"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Badarawa"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Chiki"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Galadi"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Jangeru"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Katuru"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Kware"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Kurya"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Shinkafi North"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Shinkafi South"},
 {"StateName":"Zamfara","LGAName":"Shinkafi","WardName":"Shanawa"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Garbadu"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Gwaram"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Jangebe"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Kagara"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Morai"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Ruwan Bore"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Ruwan Gizo"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Sauna R gora"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Shiyar Galadima"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Shiyar Kayaye matusgi"},
 {"StateName":"Zamfara","LGAName":"Talata Mafara","WardName":"Take Tsaba makera"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Kwaren Ganuwa"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Yan Kuzo B"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Yandoton Daji"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Yankuzo A"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Danjibga kunchin - kalgo"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Dauki"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Keta kizara"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Tsafe"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Chediya"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Yan Waren daji"},
 {"StateName":"Zamfara","LGAName":"Tsafe","WardName":"Bilbis"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Boko"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Dauran birnin-tsaba"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Dole"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Galadima yanruwa"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Kanwa"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Kwashbawa"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Mashem"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Kuturu mayasa"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Rukudawa"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Yan Buki dutsi"},
 {"StateName":"Zamfara","LGAName":"Zurmi","WardName":"Zurmi"},
 {"StateName":"Non-Nigerian","LGAName":"Non-Nigerian","WardName":"Non-Nigerian"}
]

