import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {serverLink} from "../../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../../common/dataLoader/dataLoader";
import DataTable from "../../../common/data-table/data-table";
import MenuForm from "./menu-form";
import SubMenuForm from "./sub-menu-form";
import SubSubMenuForm from "./sub-sub-menu-form";
import {formatDateAndTime} from "../../../../resources/constants";

function MenuSettings(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [IsFormLoading2, setIsFormLoading2] = useState(false)
    const [IsFormLoading3, setIsFormLoading3] = useState(false)
    const [optionSubMenuList, setOptionSubMenuList] = useState([])
    const [menuList, setMenuList] = useState([])
    const [subMenuList, setSubMenuList] = useState([])
    const [subSubMenuList, setSubSubMenuList] = useState([])
    const header = ["S/N", "Menu Name", "Updated By", "Updated Date", "Action"];
    const header2 = ["S/N","Menu Name","Sub Menu Name", "Updated By", "Updated Date", "Action"];
    const header3 = ["S/N", "Menu Name","Sub Menu Name", "Sub Sub Menu Name",  "Menu Link", "Visibility", "Updated By", "Updated Date",  "Action"];
    const [formData, setFormData] = useState({
        EntryID: "",
        MenuName: "",
        InsertedBy: `${props.loginData[0]?.StaffID}`,
    })
    const [formData2, setFormData2] = useState({
        EntryID: "",
        SubMenuName: "",
        MainMenuID: "",
        InsertedBy: `${props.loginData[0]?.StaffID}`,
    })
    const [formData3, setFormData3] = useState({
        EntryID: "",
        SubSubMenuName: "",
        SubSubMenuLink: "",
        MainMenuID: "",
        Visibility: "",
        SubMenuID: "",
        InsertedBy: `${props.loginData[0]?.StaffID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/report/menu/data`)
            .then((result) => {
                setMenuList(result.data.menu);
                setSubMenuList(result.data.subMenu);
                setSubSubMenuList(result.data.subSubMenu);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }

    const  showTable = () => {
        try {
            return menuList.length > 0 && menuList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.MenuName}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedBy}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       MenuName: item.MenuName,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable2= () => {
        try {
            return subMenuList.length > 0 &&  subMenuList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.MenuName}</td>
                        <td className="text-xs font-weight-bold">{item.SubMenuName}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedBy}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report2"
                               onClick={() => {
                                   setFormData2({
                                       ...formData2,
                                       EntryID: item.EntryID,
                                       SubMenuName: item.SubMenuName,
                                       MainMenuID: item.MainMenuID,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };
    const  showTable3 = () => {
        try {
            return subSubMenuList.length > 0 &&  subSubMenuList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.MenuName}</td>
                        <td className="text-xs font-weight-bold">{item.SubMenuName}</td>
                        <td className="text-xs font-weight-bold">{item.SubSubMenuName}</td>
                        <td className="text-xs font-weight-bold">{item.SubSubMenuLink}</td>
                        <td className="text-xs font-weight-bold">{item.Visibility?.toString() ==="1" ? "Show" : "Hide"}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedBy}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report3"
                               onClick={() => {
                                   let main_menu_id;
                                   let sub_menu_data = subMenuList.filter(e => e.EntryID.toString() === item.SubMenuID.toString());
                                   if (sub_menu_data.length > 0) {
                                       main_menu_id = sub_menu_data[0].MainMenuID;
                                   }
                                   let newList = subMenuList.filter(e => e.MainMenuID.toString() === main_menu_id.toString());
                                   setOptionSubMenuList(newList);
                                   setFormData3({
                                       ...formData3,
                                       EntryID: item.EntryID,
                                       SubSubMenuName: item.SubSubMenuName,
                                       SubSubMenuLink: item.SubSubMenuLink,
                                       MainMenuID: main_menu_id,
                                       Visibility: item.Visibility,
                                       SubMenuID: item.SubMenuID,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onEdit2 = (e) => {
        setFormData2({
            ...formData2,
            [e.target.id]: e.target.value,
        });
    }

    const onEdit3 = (e) => {
        if (e.target.id === "MainMenuID"){
            let main_menu = e.target.value;
            let newList = subMenuList.filter(e => e.MainMenuID.toString() === main_menu);
            setOptionSubMenuList(newList);
        }
        setFormData3({
            ...formData3,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.MenuName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter menu name", "error");
            return false;
        }

        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/report/settings/menu/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Main Menu Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            MenuName: "",
                        });

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/report/settings/menu/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Main Menu Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            MenuName: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit2 = async () => {
        if (formData2.MainMenuID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select main menu", "error");
            return false;
        }

        if (formData2.SubMenuName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub menu name", "error");
            return false;
        }

        if (formData2.EntryID === "") {
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .post(`${serverLink}staff/report/settings/sub-menu/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Sub Menu Added Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal2").click();
                        setFormData2({
                            ...formData2,
                            EntryID: "",
                            SubMenuName: "",
                            MainMenuID: "",
                        });

                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData2,
            }
            setIsFormLoading2(true);
            await axios
                .patch(`${serverLink}staff/report/settings/sub-menu/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Sub Menu Updated Successfully");
                        setIsFormLoading2(false);
                        getData();
                        document.getElementById("closeModal2").click();
                        setFormData2({
                            ...formData2,
                            EntryID: "",
                            SubMenuName: "",
                            MainMenuID: "",
                        });
                    } else {
                        setIsFormLoading2(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading2(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    const onSubmit3 = async () => {
        if (formData3.SubMenuID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select sub menu", "error");
            return false;
        }

        if (formData3.SubSubMenuName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub sub menu name", "error");
            return false;
        }

        if (formData3.SubSubMenuLink.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter sub sub menu link", "error");
            return false;
        }

        if (formData3.Visibility.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select visibility", "error");
            return false;
        }

        if (formData3.EntryID === "") {
            let sendData = {
                ...formData3,
            }
            setIsFormLoading3(true);
            await axios
                .post(`${serverLink}staff/report/settings/sub-sub-menu/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Sub Sub Menu Added Successfully");
                        setIsFormLoading3(false);
                        getData();
                        document.getElementById("closeModal3").click();
                        setFormData3({
                            ...formData3,
                            EntryID: "",
                            SubSubMenuName: "",
                            SubSubMenuLink: "",
                            Visibility: "",
                            SubMenuID: "",
                        });

                    } else {
                        setIsFormLoading3(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading3(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData3,
            }
            setIsFormLoading3(true);
            await axios
                .patch(`${serverLink}staff/report/settings/sub-sub-menu/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Sub Sub Menu Updated Successfully");
                        setIsFormLoading3(false);
                        getData();
                        document.getElementById("closeModal3").click();
                        setFormData3({
                            ...formData3,
                            EntryID: "",
                            SubSubMenuName: "",
                            SubSubMenuLink: "",
                            Visibility: "",
                            SubMenuID: "",
                        });
                    } else {
                        setIsFormLoading3(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading3(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">

                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <ul className="nav nav-tabs card-header-tabs nav-fill"
                                                    data-bs-toggle="tabs" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a href="#tabs-menu" className="nav-link active"
                                                           data-bs-toggle="tab" aria-selected="true"
                                                           role="tab">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="icon icon-tabler icon-tabler-category"
                                                                 width="24" height="24" viewBox="0 0 24 24"
                                                                 strokeWidth="2" stroke="currentColor" fill="none"
                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                      fill="none"></path>
                                                                <path d="M4 4h6v6h-6z"></path>
                                                                <path d="M14 4h6v6h-6z"></path>
                                                                <path d="M4 14h6v6h-6z"></path>
                                                                <circle cx="17" cy="17" r="3"></circle>
                                                            </svg>
                                                            Main Menu </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="#tabs-sub-menu" className="nav-link"
                                                           data-bs-toggle="tab" aria-selected="false"
                                                           role="tab"
                                                           tabIndex="-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="icon icon-tabler icon-tabler-list-details"
                                                                 width="24" height="24" viewBox="0 0 24 24"
                                                                 strokeWidth="2" stroke="currentColor" fill="none"
                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                      fill="none"></path>
                                                                <path d="M13 5h8"></path>
                                                                <path d="M13 9h5"></path>
                                                                <path d="M13 15h8"></path>
                                                                <path d="M13 19h5"></path>
                                                                <rect x="3" y="4" width="6" height="6" rx="1"></rect>
                                                                <rect x="3" y="14" width="6" height="6" rx="1"></rect>
                                                            </svg>
                                                            Sub Menu</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a href="#tabs-sub-sub-menu" className="nav-link"
                                                           data-bs-toggle="tab" aria-selected="false"
                                                           role="tab"
                                                           tabIndex="-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="icon icon-tabler icon-tabler-menu"
                                                                 width="24" height="24" viewBox="0 0 24 24"
                                                                 strokeWidth="2" stroke="currentColor" fill="none"
                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z"
                                                                      fill="none"></path>
                                                                <line x1="4" y1="8" x2="20" y2="8"></line>
                                                                <line x1="4" y1="16" x2="20" y2="16"></line>
                                                            </svg>
                                                            Sub Menu</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div className="tab-pane active show" id="tabs-menu"
                                                         role="tabpanel">
                                                        <div className="page-header d-print-none">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <div className="page-pretitle">
                                                                    </div>
                                                                    <h2>Main Menu</h2>
                                                                </div>
                                                                <div className="col-auto ms-auto d-print-none">
                                                                    <div className="btn-list">

                                                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                                                           onClick={()=>{
                                                                               setFormData({
                                                                                   ...formData,
                                                                                   EntryID: "",
                                                                                   MenuName: "",
                                                                               });
                                                                           }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                            Add Main Menu
                                                                        </a>
                                                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                                                           data-bs-target="#modal-report" aria-label="Create new report">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <DataTable tableID="menu" header={header} body={showTable()} title="Main Menu List" />
                                                    </div>
                                                    <div className="tab-pane" id="tabs-sub-menu"
                                                         role="tabpanel">
                                                        <div className="page-header d-print-none">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <div className="page-pretitle">
                                                                    </div>
                                                                    <h2>Sub Menu</h2>
                                                                </div>
                                                                <div className="col-auto ms-auto d-print-none">
                                                                    <div className="btn-list">

                                                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report2"
                                                                           onClick={()=>{
                                                                               setFormData2({
                                                                                   ...formData2,
                                                                                   EntryID: "",
                                                                                   SubMenuName: "",
                                                                                   MainMenuID: "",
                                                                               });
                                                                           }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                            Add Sub Menu
                                                                        </a>
                                                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                                                           data-bs-target="#modal-report" aria-label="Create new report">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <DataTable tableID="sub-menu"  header={header2} body={showTable2()} title="Main Menu List" />
                                                    </div>
                                                    <div className="tab-pane" id="tabs-sub-sub-menu"
                                                         role="tabpanel">
                                                        <div className="page-header d-print-none">
                                                            <div className="row align-items-center">
                                                                <div className="col">
                                                                    <div className="page-pretitle">
                                                                    </div>
                                                                    <h2>Sub Sub Menu</h2>
                                                                </div>
                                                                <div className="col-auto ms-auto d-print-none">
                                                                    <div className="btn-list">

                                                                        <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report3"
                                                                           onClick={()=>{
                                                                               setFormData3({
                                                                                   ...formData3,
                                                                                   EntryID: "",
                                                                                   SubSubMenuName: "",
                                                                                   SubSubMenuLink: "",
                                                                                   Visibility: "",
                                                                                   SubMenuID: "",
                                                                               });
                                                                           }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                            Add Sub Sub Menu
                                                                        </a>
                                                                        <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                                                           data-bs-target="#modal-report" aria-label="Create new report">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                                                 strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                <line x1="12" y1="5" x2="12" y2="19"/>
                                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <DataTable  tableID="sub-sub-menu"  header={header3} body={showTable3()} title="Main Menu List" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MenuForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                    modalID="modal-report"
                />
                <SubMenuForm
                    formData={formData2}
                    menuList={menuList}
                    onEdit={onEdit2}
                    onSubmit={onSubmit2}
                    IsFormLoading={IsFormLoading2}
                    setFormData={setFormData2}
                    modalID="modal-report2"
                />
                <SubSubMenuForm
                    formData={formData3}
                    menuList={menuList}
                    optionSubMenuList={optionSubMenuList}
                    onEdit={onEdit3}
                    onSubmit={onSubmit3}
                    IsFormLoading={IsFormLoading3}
                    setFormData={setFormData3}
                    modalID="modal-report3"
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(MenuSettings);
