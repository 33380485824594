import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../common/sweetalert/sweetalert";
import {  serverLink} from "../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../common/dataLoader/dataLoader";

import DataTable from "../common/data-table/data-table";
import {formatDateAndTime} from "../../resources/constants";
import VisitorsFrom from "./visitors-from";

function ManageVisitor(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [departmentList, setDepartmentList] = useState([])
    const [designationList, setDesignationList] = useState([])
    const [officeList, setOfficeList] = useState([])
    const [visitorsList, setVisitorsList] = useState([])
    const header = ["S/N", "Visitor Name", "Gender", "Phone", "Email", "State", "LGA", "Ward", "Last Login", "Action"];
    const initialFormData = {
        VisitorID: "",
        Title: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Gender: "",
        PhoneNumber: "",
        EmailAddress: "",
        State: "",
        Lga: "",
        Ward: "",
        Status: "",
        ImagePath: "",
        InsertedBy: `${props.loginData[0]?.UserID}`,
    }
    const [formData, setFormData] = useState(initialFormData)
    const resetFormData = () => {setFormData(initialFormData)}

    useEffect( () => {
        getStaffData();
    }, [""]);

    const  showTable = () => {
        try {
            return visitorsList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.FirstName+ ' ' + item.MiddleName+ ' ' + item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.Gender}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.EmailAddress}</td>
                        <td className="text-xs font-weight-bold">{item.State}</td>
                        <td className="text-xs font-weight-bold">{item.Lga}</td>
                        <td className="text-xs font-weight-bold">{item.Ward}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.LastLoginDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       VisitorID: item.VisitorID,
                                       Title: item.Title,
                                       FirstName: item.FirstName,
                                       MiddleName: item.MiddleName,
                                       Surname: item.Surname,
                                       Gender: item.Gender,
                                       PhoneNumber: item.PhoneNumber,
                                       EmailAddress: item.EmailAddress,
                                       State: item.State,
                                       Lga: item.Lga,
                                       Ward: item.Ward,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };


    const getStaffData = async () => {
        await axios
            .get(`${serverLink}project/visitor/list`)
            .then((response) => {
                if (response.data.message === "success") {
                    setVisitorsList(response.data.data);
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.Title.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select title", "error");
            return false;
        }
        if (formData.FirstName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }
        if (formData.Surname.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter surname", "error");
            return false;
        }
        if (formData.PhoneNumber.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter phone number", "error");
            return false;
        }
        if (formData.EmailAddress.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter email address", "error");
            return false;
        }
        if (formData.Gender.toString() === "") {
            showAlert("EMPTY FIELD", "Please enter gender", "error");
            return false;
        }
        if (formData.State.toString() === "") {
            showAlert("EMPTY FIELD", "Please select state", "error");
            return false;
        }
        if (formData.Lga.toString() === "") {
            showAlert("EMPTY FIELD", "Please select LGA", "error");
            return false;
        }

        if (formData.Ward.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select Ward", "error");
            return false;
        }


        if (formData.VisitorID === "") {
            let sendData = {...formData}
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}project/visitor/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Visitor Added Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        resetFormData();
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("VISITOR EXIST", "Visitor already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {...formData}
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}project/visitor/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Visitor Updated Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        resetFormData();
                    } else if (result.data.message === "exist") {
                        showAlert("VISITOR EXIST", "Visitor already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Visitor
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           resetFormData();
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Visitor
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Visitors Report</h3>
                                                <div className="table-responsive">
                                                <DataTable header={header} body={showTable()} title="Visitor Report" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <VisitorsFrom
                    setFormData={setFormData}
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    departmentList={departmentList}
                    designationList={designationList}
                    officeList={officeList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageVisitor);
