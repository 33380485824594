import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime, MailTemplates, sendEmail} from "../../../resources/constants";
import JoditEditor from "jodit-react";

function EmailStudent(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [studentList, setStudentList] = useState([])
    const [emails, setEmails] = useState([])
    const [studentData, setStudentData] = useState([])
    const header = ["S/N","Check", "STUDENTID", "Name","Gender",  "Nationality", "State_Of_Origin", "DATE_OF_BIRTH", "Programme", "Action"];
    const [formData, setFormData] = useState({
        Job: "",
        StaffID: "",
        msgTitle: "",
        msgSubject: "Welcome Message",
        msgTo: "",
        msgBody: "",
        staff_name: "",
        InterViewDate: "",
        InterviewTime: "",
        InterviewVenue: "",
    })
    const resetFormData = () => {
        return setFormData({
            ...formData,
            Job: "",
            StaffID: "",
            msgTitle: "Interview Panel Member Invitation",
            msgSubject: "Interview Panel Member Invitation",
            msgTo: "",
            msgBody: "",
            staff_name: "",
            InterViewDate: "",
            InterviewTime: "",
            InterviewVenue: "",
        });
    }

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/report/registration/student/active`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">
                            <input type="checkbox"
                                   id="checkItem"
                                   data_id={item.STUDENTID}
                                   data={JSON.stringify(item)}
                                   className="checkItem"
                                   name="checkItem"
                                   value={item.STUDENTID}
                                   onChange={onCheck} />
                        </td>
                        <td className="text-xs font-weight-bold">{item.STUDENTID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.NATIONALITY}</td>
                        <td className="text-xs font-weight-bold">{item.STATE_OF_ORIGIN}</td>
                        <td className="text-xs font-weight-bold">{item.DOB !== "" ? formatDateAndTime(item.DOB, "date") : "N/A"}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.ADMISSION_YEAR}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onSubmit = async () => {
        sendEmail("adammusa89@gmail.com", formData.msgSubject, formData.msgTitle, "Adam Musa Yau", formData.msgBody, "")
    }

    const onEdit = (e) => {
        if (e.target.id === "Job"){
            document.getElementById("body").style.display = "none"
            if (e.target.value !== ""){
                // let index = e.nativeEvent.target.selectedIndex;
                // let date = e.nativeEvent.target[index].getAttribute('date');
                // let time = e.nativeEvent.target[index].getAttribute('time');
                // let venue = e.nativeEvent.target[index].getAttribute('venue');
                // let position = e.nativeEvent.target[index].getAttribute('position');

                setFormData({
                    ...formData,
                    InterviewVenue: "Kadpoly",
                    InterViewDate: "32546324",
                    InterviewTime: "time",
                    Job: "HOD",
                    msgBody: `<div>I am directed to inform you that you have been nominated and invited to sit on the interview panel for non-academic recruitment slated for :
                                                      <br/>
                                                      Position: <b>HOD</b><br/>
                                                      Date: <b>${formatDateAndTime("2002-02-02", 'date')}</b><br/>
                                                      Time: <b>10:00</b><br/>
                                                      Venue: <b>Kadpoly</b><br/><br/>
                                                      
                                                      Best Regards.<br/><br/>
                                                      Human Resource Department <br/>
                                                      Baze University,<br/>
                                                      Abuja,<br/>
                                                      Nigeria.<br/>
                                                      </div>`
                })
               // document.getElementById("body").style.display = "block"
            }
        }else{
            setFormData({
                ...formData,
                [e.target.id]: e.target.value
            })
        }

    }

    const onCheck = (e) => {
        let student_row = e.target.getAttribute("data");
        let studentID = e.target.getAttribute("data_id");

        if (e.target.checked){
            setStudentData(prevState => [...prevState, JSON.parse(student_row)])
        }else {
            let newEntries = studentData.filter(e=> e.STUDENTID !== studentID)
            setStudentData(newEntries)
        }
        console.log(studentData)
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Send Email to Students
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                   onClick={()=>{
                                       const email = MailTemplates("2", formData);
                                       setFormData({
                                           ...formData,
                                           msgTitle: formData.msgTitle,
                                           msgSubject: formData.msgSubject,
                                           msgTo: "adammusa89@gmail.com",
                                           InterviewVenue: "Kadpoly",
                                           InterViewDate: "32546324",
                                           InterviewTime: "time",
                                           Job: "HOD",
                                           msgBody: email.body
                                       })
                                   }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-forward" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"/>
                                        <path d="M3 6l9 6l9 -6"/>
                                        <path d="M15 18h6"/>
                                        <path d="M18 15l3 3l-3 3"/>
                                    </svg>
                                    Send Email
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title"> Send Email to Students</h3>
                                                <DataTable header={header} body={showTable()} title="Active Students Report" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Email Template</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <label className='form-label' >Subject</label>
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" placeholder='enter email subject' onChange={onEdit} id="msgSubject" value={formData.msgSubject} required />
                                    </div>

                                    <br />

                                    <div className="form-group" id="body" >
                                        {
                                            formData?.msgBody !== "ew" ?
                                                <>
                                                    <label className='form-label' >Email Body</label>
                                                    <JoditEditor
                                                        value={formData.msgBody}
                                                        tabIndex={1}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData, msgBody: e
                                                            })
                                                        }}
                                                    />
                                                </>
                                                :
                                                <></>
                                        }

                                    </div>

                                    <hr />
                                    {
                                        IsFormLoading ?
                                            <button className="btn btn-success float-right w-100" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                Loading...
                                            </button>
                                            :
                                            <div className="input-group mb-3">
                                                <button type='button' className='btn btn-md btn-success w-100' onClick={onSubmit}>
                                                    Send Email
                                                </button>
                                            </div>
                                    }
                                </form>
                            </div>
                            <div className="modal-footer">
                                <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                                    Cancel
                                </a>
                                {
                                    IsFormLoading ?
                                        <button type="button" className="btn btn-primary ms-auto">
                                            <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                        </button>
                                        :
                                        <button type="button" onClick={onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Send
                                        </span>
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(EmailStudent);
