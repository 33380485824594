import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import axios from "axios";
import { encryptData, serverLink} from "../../resources/url";
import {setgeneralDetails, setPermissionDetails, setVisitorData, setVisitorSubmissionData} from "../../actions/actions";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {StateData} from "../../resources/state_data";
import {useNavigate} from "react-router";

function VisitorAccount(props) {
    const navigate = useNavigate();
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [locationData, setLocationData] = useState({})
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLGAList] = useState([]);
    const [wardList, setWardList] = useState([]);
    const initialValue = {
        VisitorID: "",
        Title: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Gender: "",
        PhoneNumber: "",
        EmailAddress: props.visitorDetail,
        State: "",
        Lga: "",
        Ward: "",
        Status: "",
        ImagePath: "",
        InsertedBy: `1`,
    }
    const [formData, setFormData] = useState(initialValue)
    const resetFormData = () => {setFormData({...initialValue, EmailAddress: ""})}
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.Title.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select title", "error");
            return false;
        }
        if (formData.FirstName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }
        if (formData.Surname.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter surname", "error");
            return false;
        }
        if (formData.PhoneNumber.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter phone number", "error");
            return false;
        }
        if (formData.EmailAddress.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter email address", "error");
            return false;
        }
        if (formData.Gender.toString() === "") {
            showAlert("EMPTY FIELD", "Please enter gender", "error");
            return false;
        }
        if (formData.State.toString() === "") {
            showAlert("EMPTY FIELD", "Please select state", "error");
            return false;
        }
        if (formData.Lga.toString() === "") {
            showAlert("EMPTY FIELD", "Please select LGA", "error");
            return false;
        }

        if (formData.Ward.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select Ward", "error");
            return false;
        }


        if (formData.VisitorID === "") {
            let sendData = {...formData}
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}project/visitor/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        setIsFormLoading(false);
                        window.sessionStorage.setItem("userPermission", 'allow');
                        window.sessionStorage.setItem("isLoggedIn", true);
                        props.setOnPermissionDetails([{permissionRole: 'visitor'}]);
                        props.setOnSubmissionDetails([]);
                        props.setOnVisitorDetails([{...sendData, VisitorID: result.data.entry_id, LastLoginDate: new Date( Date.now())}]);
                        toast.success("Account Created Successful");
                        setTimeout(()=>{
                            navigate("/visitor/form");
                        }, 2000)
                        // resetFormData();
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("VISITOR EXIST", "Visitor already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {...formData}
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}project/visitor/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Visitor Updated Successfully");
                        setIsFormLoading(false);
                        resetFormData();
                    } else if (result.data.message === "exist") {
                        showAlert("VISITOR EXIST", "Visitor already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }
    useEffect(()=>{
        setStateList([...new Set(StateData.filter(e=>e.StateName === "Kaduna").map(item => item.StateName))])
    }, [""])

    return (
        <>
            <div className="page-wrapper">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <h2 className="page-title">
                                    Create Account
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-cards">
                            <div className="col-lg-8">
                                <div className="card card-lg">
                                    <div className="card-body">
                                        <div className="markdown">
                                            <div className="row">
                                                <div className="hr-text hr-text-left pt-0 mt-0">Personal Details</div>
                                                <div className="mb-3 form-group col-md-12">
                                                    <label className="form-label">Title</label>
                                                    <select
                                                        name="Title"
                                                        className="form-control"
                                                        id="Title"
                                                        value={formData.Title}
                                                        onChange={onEdit}>

                                                        <option value="">Select Title</option>
                                                        <option>Mr</option>
                                                        <option>Mrs</option>
                                                        <option>Mss</option>
                                                        <option>Ms</option>
                                                        <option>Dr</option>
                                                        <option>Capt</option>
                                                        <option>Prof</option>
                                                        <option>HRH</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 form-group col-md-4">
                                                    <label className="form-label">First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="FirstName"
                                                        id="FirstName"
                                                        value={formData.FirstName}
                                                        onChange={onEdit}
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                                <div className="mb-3 form-group col-md-4">
                                                    <label className="form-label">Middle Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="MiddleName"
                                                        id="MiddleName"
                                                        value={formData.MiddleName}
                                                        onChange={onEdit}
                                                        placeholder="Middle Name"
                                                    />
                                                </div>
                                                <div className="mb-3 form-group col-md-4">
                                                    <label className="form-label">Surname</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Surname"
                                                        id="Surname"
                                                        value={formData.Surname}
                                                        onChange={onEdit}
                                                        placeholder="Surname"
                                                    />
                                                </div>
                                                <div className="mb-3 col-lg-12 form-group">
                                                    <label className="form-label">Gender</label>
                                                    <select
                                                        className="form-select"
                                                        name="Gender"
                                                        id="Gender"
                                                        value={formData.Gender}
                                                        onChange={onEdit}
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 form-group col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="PhoneNumber"
                                                        id="PhoneNumber"
                                                        value={formData.PhoneNumber}
                                                        onChange={onEdit}
                                                        placeholder="Phone Number"
                                                    />
                                                </div>
                                                <div className="mb-3 form-group col-md-6">
                                                    <label className="form-label">Email Address</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="EmailAddress"
                                                        id="EmailAddress"
                                                        value={formData.EmailAddress}
                                                        onChange={onEdit}
                                                        placeholder="Email Address"
                                                    />
                                                </div>
                                                <div className="hr-text hr-text-left">Location Details</div>
                                                <div className="mb-3 form-group col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="StateID">
                                                            State of Origin
                                                        </label>
                                                        <select
                                                            name="State"
                                                            id="State"
                                                            className="form-control"
                                                            required
                                                            value={formData.State}
                                                            onChange={(e)=>{
                                                                setFormData({...formData, [e.target.id]: e.target.value, nationality: e.target.value === 'Non-Nigerian' ? 'Non-Nigerian' : 'Nigeria'});
                                                                setLGAList([...new Set(StateData.filter(p => p.StateName === e.target.value).map(item => item.LGAName))]);
                                                                setWardList([]);
                                                            }}>
                                                            <option value="">Select Option</option>
                                                            {
                                                                stateList.length > 0 &&
                                                                stateList.map((state,i) => {
                                                                    return <option key={i} value={state}>{state}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mb-3 form-group col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="LgaID">
                                                            Local Government
                                                        </label>
                                                        <select
                                                            name="Lga"
                                                            id="Lga"
                                                            className="form-control"
                                                            required
                                                            value={formData.Lga}
                                                            onChange={(e)=>{
                                                                setFormData({...formData, [e.target.id]: e.target.value});
                                                                setWardList([...new Set(StateData.filter(p => p.StateName === formData.State && p.LGAName === e.target.value).map(item => item.WardName))]);
                                                            }}>
                                                            <option value="">Select Option</option>
                                                            {
                                                                lgaList.length > 0 &&
                                                                lgaList.map((lga,i) => {
                                                                    return <option key={i} value={lga}>{lga}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mb-3 form-group col-md-4">
                                                    <div className="form-group">
                                                        <label className='form-label'>Ward <span className="text-danger">*</span></label>
                                                        <select id="Ward" className="form-control" value={formData.Ward} onChange={(e)=> setFormData({...formData, [e.target.id]: e.target.value})}>
                                                            <option value="">Select Option</option>
                                                            {
                                                                wardList.length > 0 &&
                                                                wardList.map((ward,i) => {
                                                                    return <option key={i} value={ward}>{ward}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="form-group mt-4 col-md-4 offset-sm-4">
                                                    <button type="button" onClick={onSubmit} className="btn btn-primary ms-auto form-control">
                                                        {
                                                            IsFormLoading ?
                                                                <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                                                :
                                                                <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                                        }

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="icon icon-tabler icon-tabler-info-octagon" width="24"
                                                     height="24" viewBox="0 0 24 24" stroke-width="2"
                                                     stroke="currentColor" fill="none" stroke-linecap="round"
                                                     stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path
                                                        d="M9.103 2h5.794a3 3 0 0 1 2.122 .879l4.101 4.1a3 3 0 0 1 .88 2.125v5.794a3 3 0 0 1 -.879 2.122l-4.1 4.101a3 3 0 0 1 -2.123 .88h-5.795a3 3 0 0 1 -2.122 -.88l-4.101 -4.1a3 3 0 0 1 -.88 -2.124v-5.794a3 3 0 0 1 .879 -2.122l4.1 -4.101a3 3 0 0 1 2.125 -.88z"></path>
                                                    <path d="M12 9h.01"></path>
                                                    <path d="M11 12h1v4h1"></path>
                                                </svg>
                                            </div>
                                            <div>
                                                <h3 className="lh-1">Note</h3>
                                            </div>
                                        </div>
                                        <div className="text-muted mb-3">
                                            Before submitting, take a moment to review the information you've provided. Make sure all details provided are accurate.
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        visitorDetail: state.generalDetails,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {

        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
        setOnSubmissionDetails: (p) => {
            dispatch(setVisitorSubmissionData(p));
        },
        setOnVisitorDetails: (p) => {
            dispatch(setVisitorData(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        }
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(VisitorAccount);
