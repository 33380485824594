import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import StudentClearanceForm from "./student-clearance-form";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";

function StudentClearance(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [studentList, setStudentList] = useState([])
    const header = ["S/N", "APPID", "Name", "Email", "Phone", "Gender", "Programme", "Admission_Year", "Action"];
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        STUDENT_NAME: "",
        GENDER: "",
        TELEPHONE: "",
        EMAIL: "",
        PROG_ID: "",
        ADMISSION_YEAR: "",
        GENERATE_PAYMENT: "0",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            APPID: "",
            STUDENTID: "",
            STUDENT_NAME: "",
            GENDER: "",
            TELEPHONE: "",
            EMAIL: "",
            PROG_ID: "",
            ADMISSION_YEAR: "",
            GENERATE_PAYMENT: "0",
        });
    }
    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/student/not/cleared`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.APPID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.EMAIL}</td>
                        <td className="text-xs font-weight-bold">{item.TELEPHONE}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.ADMISSION_YEAR}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       PROG_ID: item.PROG_ID,
                                       APPID: item.APPID,
                                       STUDENTID: item.STUDENTID,
                                       PROGRAMME: item.PROGRAMME,
                                       STUDENT_NAME: item.STUDENT_NAME,
                                       EMAIL: item.EMAIL,
                                       TELEPHONE: item.TELEPHONE,
                                       GENDER: item.GENDER,
                                       ADMISSION_YEAR: item.ADMISSION_YEAR,
                                   });
                               }}>
                                Clear Student
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.APPID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Application Number Not Found", "error");
            return false;
        }

        let sendData = {
            ...formData,
        }
        setIsFormLoading(true);
        await axios.post(`${serverLink}staff/registration/student/clearance`, sendData)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Student Cleared For registration Successfully");
                    setIsFormLoading(false);
                    document.getElementById("closeModal").click();
                    setTimeout(()=>{
                        window.location.reload();
                    }, 3000)
                    resetFormData()
                } else {
                    setIsFormLoading(false);
                    showAlert(
                        "ERROR",
                        "Something went wrong clearing the student. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsFormLoading(false);
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });
    }


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Student Clearance
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <DataTable header={header} body={showTable()} title="Student Clearance" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <StudentClearanceForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(StudentClearance);
