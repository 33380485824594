import React, {useEffect, useState} from "react";
import {StateData} from "../../resources/state_data";

export default function VisitorsFrom(props) {
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLGAList] = useState([]);
    const [wardList, setWardList] = useState([]);
    useEffect( () => {
        setStateList([...new Set(StateData.filter(e=>e.StateName === "Kaduna").map(item => item.StateName))])
    }, [""]);
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Visitors Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="hr-text hr-text-left pt-0 mt-0">Personal Details</div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Title</label>
                                <select
                                    name="Title"
                                    className="form-control"
                                    id="Title"
                                    value={props.formData.Title}
                                    onChange={props.onEdit}>

                                    <option value="">Select Title</option>
                                    <option>Mr</option>
                                    <option>Mrs</option>
                                    <option>Mss</option>
                                    <option>Ms</option>
                                    <option>Dr</option>
                                    <option>Capt</option>
                                    <option>Prof</option>
                                    <option>HRH</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="FirstName"
                                    id="FirstName"
                                    value={props.formData.FirstName}
                                    onChange={props.onEdit}
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="MiddleName"
                                    id="MiddleName"
                                    value={props.formData.MiddleName}
                                    onChange={props.onEdit}
                                    placeholder="Middle Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Surname</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="Surname"
                                    id="Surname"
                                    value={props.formData.Surname}
                                    onChange={props.onEdit}
                                    placeholder="Surname"
                                />
                            </div>
                            <div className="mb-3 col-lg-6 form-group">
                                <label className="form-label">Gender</label>
                                <select
                                    className="form-select"
                                    name="Gender"
                                    id="Gender"
                                    value={props.formData.Gender}
                                    onChange={props.onEdit}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="MaritalStatus">
                                        Marital Status
                                    </label>
                                    <select
                                        name="MaritalStatus"
                                        id="MaritalStatus"
                                        className="form-control"
                                        required
                                        value={props.formData.MaritalStatus}
                                        onChange={props.onEdit}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="Married">Married</option>
                                        <option value="Single">Single</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="PhoneNumber"
                                    id="PhoneNumber"
                                    value={props.formData.PhoneNumber}
                                    onChange={props.onEdit}
                                    placeholder="Phone Number"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="EmailAddress"
                                    id="EmailAddress"
                                    value={props.formData.EmailAddress}
                                    onChange={props.onEdit}
                                    placeholder="Email Address"
                                />
                            </div>
                            <div className="hr-text hr-text-left">Location Details</div>
                            <div className="mb-3 form-group col-md-4">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="StateID">
                                        State of Origin
                                    </label>
                                    <select
                                        name="State"
                                        id="State"
                                        className="form-control"
                                        required
                                        value={props.formData.State}
                                        onChange={(e)=>{
                                            props.setFormData({...props.formData, [e.target.id]: e.target.value, nationality: e.target.value === 'Non-Nigerian' ? 'Non-Nigerian' : 'Nigeria'});
                                            setLGAList([...new Set(StateData.filter(p => p.StateName === e.target.value).map(item => item.LGAName))]);
                                            setWardList([]);
                                        }}>
                                        <option value="">Select Option</option>
                                        {
                                            stateList.length > 0 &&
                                            stateList.map((state,i) => {
                                                return <option key={i} value={state}>{state}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="LgaID">
                                        Local Government
                                    </label>
                                    <select
                                        name="Lga"
                                        id="Lga"
                                        className="form-control"
                                        required
                                        value={props.formData.Lga}
                                        onChange={(e)=>{
                                            props.setFormData({...props.formData, [e.target.id]: e.target.value});
                                            setWardList([...new Set(StateData.filter(p => p.StateName === props.formData.State && p.LGAName === e.target.value).map(item => item.WardName))]);
                                        }}>
                                        <option value="">Select Option</option>
                                        {
                                            lgaList.length > 0 &&
                                            lgaList.map((lga,i) => {
                                                return <option key={i} value={lga}>{lga}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <div className="form-group">
                                    <label className='form-label'>Ward <span className="text-danger">*</span></label>
                                    <select id="Ward" className="form-control" value={props.formData.Ward} onChange={(e)=> props.setFormData({...props.formData, [e.target.id]: e.target.value})}>
                                        <option value="">Select Option</option>
                                        {
                                            wardList.length > 0 &&
                                            wardList.map((ward,i) => {
                                                return <option key={i} value={ward}>{ward}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {/*data-bs-dismiss="modal"*/}
                        <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                            {
                                props.IsFormLoading ?
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                    :
                                    <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                            }

                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}