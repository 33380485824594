import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import Chart from "react-google-charts";
import Skeleton from "../common/skeleton/skeleton";
import {formatDateAndTime, MaleAvatar, WomanAvatar} from "../../resources/constants";

function Dashboard(props) {
    const user = props.loginData[0];
    const userLogin = user.userLoginHistory[0];
    const [IsLoading, setIsLoading] = useState(true)
    const [programmeCategory, setProgrammeCategory] = useState([])

    useEffect(() => {
        getDashboardAnalyticsData();
    }, [""]);

    const getDashboardAnalyticsData = async () => {
        await axios.get(`${serverLink}staff/report/dashboard/data`)
            .then((result) => {
                if (result.data.ActiveStudents.length > 0) {
                    let result0 = [];
                    user.userLoginCount.map((e, i) => {
                        result0.push([e.Days+"\n"+formatDateAndTime(e.LogDate, 'date'), e.Count]);
                    })

                    setProgrammeCategory(result0)

                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const data = [["Days", ""], ...programmeCategory];
    const options = {
        chart: {
            title: "Portal Usage",
            subtitle: "Count",
            width: '100%',
        },
    };

    return (
        IsLoading ?

            <Skeleton/>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    Overview
                                </div>
                                <h2 className="page-title">
                                    Dashboard
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <span className="avatar avatar-lg img-thumbnail rounded" style={{backgroundImage: user?.Gender === "Male" ? `url(${MaleAvatar})` : `url(${WomanAvatar})`}}/>
                                            </div>
                                            <div className="col">
                                                <h2 className="fw-bold">{user?.FirstName} {user?.MiddleName} {user?.Surname} </h2>
                                                <div className="list-inline-item">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon me-2 text-muted" width="24" height="24"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path
                                                            d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"/>
                                                        <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"/>
                                                        <path d="M12 12l0 .01"/>
                                                        <path d="M3 13a20 20 0 0 0 18 0"/>
                                                    </svg>
                                                    <span className="text-reset">{user?.Designation} </span>
                                                </div>
                                                 <div className="list-inline-item">
                                                     <svg xmlns="http://www.w3.org/2000/svg"
                                                          className="icon me-2 text-muted" width="24" height="24"
                                                          viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                          fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                         <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                         <path d="M5 12l-2 0l9 -9l9 9l-2 0"/>
                                                         <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/>
                                                         <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/>
                                                     </svg>
                                                     <span className="text-reset">{user?.DepartmentName} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-title">Login info</div>

                                                <div className="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon icon-tabler icon-tabler-location" width="24"
                                                         height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                         stroke="currentColor" fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path
                                                            d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"/>
                                                    </svg> &nbsp;
                                                     IP Address: <strong>{userLogin?.IP}</strong>
                                                </div>
                                                <div className="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon me-2 text-muted" width="24" height="24"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"/>
                                                        <path
                                                            d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"/>
                                                    </svg>
                                                    Country: <strong><span className="fa fa-flag"/>
                                                    {userLogin?.Country}</strong>
                                                </div>
                                                <div className="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon me-2 text-muted" width="24" height="24"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"/>
                                                        <path d="M12 7v5l3 3"/>
                                                    </svg>
                                                    date/time: <strong>{formatDateAndTime(userLogin?.InsertedDate, 'date_and_time')}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {
                                                    programmeCategory.length > 0 &&
                                                    <Chart
                                                        chartType="Bar"
                                                        width="100%"
                                                        height="400px"
                                                        data={data}
                                                        options={options}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header"><h4>Login History</h4></div>
                                    <div className="card-body p-0">
                                        <div className="card-table table-responsive">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>IP Address</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    user.userLoginHistory.map((item, index) => {
                                                        let date = new Date(item.InsertedDate); // Current date and time
                                                        let formattedTime = date.toLocaleString('en-NG', { hour: 'numeric', minute: 'numeric', hour12: true });
                                                        let hours = date.getHours();
                                                        let minutes = date.getMinutes();
                                                        let seconds = date.getSeconds();
                                                        let time = hours + ":" + minutes + ":" + seconds;
                                                        return (
                                                            <tr key={index}>
                                                                <td>{formatDateAndTime(item.InsertedDate, 'date')}</td>
                                                                <td>{formattedTime}</td>
                                                                <td>{item.IP}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

export default connect(mapStateToProps, null)(Dashboard);
