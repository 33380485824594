import React, {useState} from "react";

export default function OfficeForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Office Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Office Name</label>
                                <input
                                    name="OfficeName"
                                    className="form-control"
                                    id="OfficeName"
                                    value={props.formData.OfficeName}
                                    onChange={props.onEdit}
                                    placeholder="Office Name"
                                />

                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Block</label>
                                <select className="form-control" name="BlockID" id="BlockID" value={props.formData.BlockID} onChange={props.onEdit}>
                                    <option value="">Select Block</option>
                                    {
                                        props.blockList.length > 0 && props.blockList.map((item, index)=> {
                                            return (
                                                <option key={index} value={item.BlockID}>{item.BlockName}</option>
                                            )
                                        })
                                    }

                                </select>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {/*data-bs-dismiss="modal"*/}
                        <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                            {
                                props.IsFormLoading ?
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                    :
                                    <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                            }

                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}