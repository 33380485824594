import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import Skeleton from "../common/skeleton/skeleton";
import Dashboard from "./dashboard";
import StaffDashboard from "./StaffDashboard";

function DashboardRoot(props) {

    const [IsLoading, setIsLoading] = useState(true)
    const [userType, setUserType] = useState(props.permissionData.length > 0 ? props.permissionData : []);
    return (
        userType.length < 1 ?
            <Skeleton/>
            :
            userType[0]?.permissionRole.includes('admin') ?
                <Dashboard/>
                :
                <StaffDashboard/>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        permissionData: state.permissionData,
    };
};

export default connect(mapStateToProps, null)(DashboardRoot);
