import React from "react";
import DepartmentSelectOption from "../../common/dynamic-select-option/department-select-option";

export default function CourseForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Course Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Course Title</label>
                                <input
                                    type="text"
                                    name="COURSE_TITLE"
                                    className="form-control"
                                    id="COURSE_TITLE"
                                    value={props.formData.COURSE_TITLE}
                                    onChange={props.onEdit}
                                    placeholder="Collage Title"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Course Code</label>
                                <input
                                    type="text"
                                    name="COURSE_CODE"
                                    className="form-control"
                                    id="COURSE_CODE"
                                    value={props.formData.COURSE_CODE}
                                    onChange={props.onEdit}
                                    placeholder="Course Code"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Credit Unit</label>
                                <input
                                    type="number"
                                    name="CREDIT_UNIT"
                                    className="form-control"
                                    id="CREDIT_UNIT"
                                    value={props.formData.CREDIT_UNIT}
                                    onChange={props.onEdit}
                                    placeholder="Course Credit Unit"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Department</label>
                                <DepartmentSelectOption formData={props.formData} setFormData={props.setFormData}/>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Level</label>
                                <select className="form-control" name="LEVEL" id="LEVEL" value={props.formData.LEVEL} onChange={props.onEdit}>
                                    <option value="">Select Course Level</option>
                                    <option value="1">ND-1</option>
                                    <option value="2">ND-2</option>
                                    <option value="3">HND-1</option>
                                    <option value="4">HND-2</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Semester</label>
                                <select className="form-control" name="SEMESTER" id="SEMESTER" value={props.formData.SEMESTER} onChange={props.onEdit}>
                                    <option value="">Select Course Semester</option>
                                    <option value="1">First Semster</option>
                                    <option value="2">Second Semester</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}