import React from "react";
import {formatDate} from "../../../resources/constants";

export default function ManageStaffForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Staff Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Title</label>
                                <select
                                    name="Title"
                                    className="form-control"
                                    id="Title"
                                    value={props.formData.Title}
                                    onChange={props.onEdit}>

                                    <option value="">Select Title</option>
                                    <option>Mr</option>
                                    <option>Mrs</option>
                                    <option>Mss</option>
                                    <option>Ms</option>
                                    <option>Dr</option>
                                    <option>Capt</option>
                                    <option>Prof</option>
                                    <option>HRH</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="FirstName"
                                    id="FirstName"
                                    value={props.formData.FirstName}
                                    onChange={props.onEdit}
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Middle Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="MiddleName"
                                    id="MiddleName"
                                    value={props.formData.MiddleName}
                                    onChange={props.onEdit}
                                    placeholder="Middle Name"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Surname</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="Surname"
                                    id="Surname"
                                    value={props.formData.Surname}
                                    onChange={props.onEdit}
                                    placeholder="Surname"
                                />
                            </div>
                            <div className="mb-3 col-lg-12 form-group">
                                <label className="form-label">Gender</label>
                                <select
                                    className="form-select"
                                    name="Gender"
                                    id="Gender"
                                    value={props.formData.Gender}
                                    onChange={props.onEdit}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Phone Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="PhoneNumber"
                                    id="PhoneNumber"
                                    value={props.formData.PhoneNumber}
                                    onChange={props.onEdit}
                                    placeholder="Phone Number"
                                />
                            </div>
                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Email Address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="EmailAddress"
                                    id="EmailAddress"
                                    value={props.formData.EmailAddress}
                                    onChange={props.onEdit}
                                    placeholder="Email Address"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Password</label>
                                <div className="input-group input-group-flat">
                                    <input
                                        type={props.showPassword ? "text" : "password"}
                                        className="form-control"
                                        name="Password"
                                        id="Password"
                                        value={props.formData.Password}
                                        onChange={props.onEdit}
                                        placeholder="Password"
                                        autoComplete="off"/>
                                    <span className="input-group-text">
                                          <a href="#" onClick={() => props.setShowPassword(!props.showPassword)}
                                             className="input-group-link">
                                              {
                                                  props.showPassword ?
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                           stroke="currentColor" fill="none" strokeLinecap="round"
                                                           strokeLinejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <line x1="3" y1="3" x2="21" y2="21"/>
                                                          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"/>
                                                          <path
                                                              d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"/>
                                                      </svg>
                                                      :
                                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                                                           width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                           stroke="currentColor" fill="none" strokeLinecap="round"
                                                           strokeLinejoin="round">
                                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                          <circle cx="12" cy="12" r="2"/>
                                                          <path
                                                              d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/>
                                                      </svg>

                                              }
                                          </a>
                                        </span>
                                </div>
                            </div>
                            <div className="hr-text hr-text-left">Administrative Details</div>
                            <div className="mb-3 form-group col-md-4">
                                <label className="form-label">Designation</label>
                                <select
                                    className="form-select"
                                    name="Designation"
                                    id="Designation"
                                    value={props.formData.Designation}
                                    onChange={props.onEdit}
                                >
                                    <option value="">Select Designation</option>
                                    {
                                        props.designationList.length > 0 && props.designationList.map((e, i) => {
                                            return (
                                                <option key={i} value={e.EntryID}>{e.DesignationName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 col-lg-4 form-group">
                                <label className="form-label">Department</label>
                                <select
                                    className="form-select"
                                    name="DepartmentID"
                                    id="DepartmentID"
                                    value={props.formData.DepartmentID}
                                    onChange={props.onEdit}
                                >
                                    <option value="">Select Department</option>
                                    {
                                        props.departmentList.length > 0 && props.departmentList.map((e, i) => {
                                            return (
                                                <option key={i} value={e.DepartmentID}>{e.DepartmentName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="mb-3 col-lg-4 form-group">
                                <label className="form-label">Office</label>
                                <select
                                    className="form-select"
                                    name="OfficeID"
                                    id="OfficeID"
                                    value={props.formData.OfficeID}
                                    onChange={props.onEdit}
                                >
                                    <option value="">Select Office</option>
                                    {
                                        props.officeList.length > 0 && props.officeList.map((e, i) => {
                                            return (
                                                <option key={i} value={e.OfficeID}>{e.OfficeName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="hr-text hr-text-left">Account Status</div>
                            <div className="mb-3 col-lg-12 form-group">
                                <label className="form-label">Staff Status </label>
                                <select
                                    className="form-select"
                                    name="Status"
                                    id="Status"
                                    value={props.formData.Status}
                                    onChange={props.onEdit}
                                >
                                    <option value="">Select Option</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {/*data-bs-dismiss="modal"*/}
                        <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                            {
                                props.IsFormLoading ?
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                    :
                                    <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                            }

                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}