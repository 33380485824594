import React from "react";
import CollagesSelectOption from "../../common/dynamic-select-option/collages-select-option";
import SchoolsSelectOption from "../../common/dynamic-select-option/schools-select-option";
import DepartmentSelectOption from "../../common/dynamic-select-option/department-select-option";
import JoditEditor from "jodit-react";

export default function ProgrammeForm(props) {
    const editorConfig = {
        readonly: false,
        height: 200
    };
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Programme Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Programme Name</label>
                                <input
                                    type="text"
                                    name="PROGRAMME"
                                    className="form-control"
                                    id="PROGRAMME"
                                    value={props.formData.PROGRAMME}
                                    onChange={props.onEdit}
                                    placeholder="Programme Name"
                                />
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Programme Code</label>
                                <input
                                    type="text"
                                    name="PROG_CODE"
                                    className="form-control"
                                    id="PROG_CODE"
                                    value={props.formData.PROG_CODE}
                                    onChange={props.onEdit}
                                    placeholder="Programme Code"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Programme Nature</label>
                                <input
                                    type="text"
                                    name="PROGRAMME_NATURE"
                                    className="form-control"
                                    id="PROGRAMME_NATURE"
                                    value={props.formData.PROGRAMME_NATURE}
                                    onChange={props.onEdit}
                                    placeholder="Programme Nature"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">School</label>
                                <SchoolsSelectOption formData={props.formData} setFormData={props.setFormData}/>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">College</label>
                                <CollagesSelectOption formData={props.formData} setFormData={props.setFormData}/>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Department</label>
                                <DepartmentSelectOption formData={props.formData} setFormData={props.setFormData}/>
                            </div>


                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Programme Type</label>
                                <input
                                    type="text"
                                    name="PROG_TYPE"
                                    className="form-control"
                                    id="PROG_TYPE"
                                    value={props.formData.PROG_TYPE}
                                    onChange={props.onEdit}
                                    placeholder="Programme Type"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Study Type</label>
                                <select className="form-control" name="STUDY_TYPE" id="STUDY_TYPE" value={props.formData.STUDY_TYPE} onChange={props.onEdit}>
                                    <option value="">Select Study Type</option>
                                    <option value="ND">ND</option>
                                    <option value="HND">HND</option>
                                    <option value="Pre-HND">Pre-HND</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label"> Entry Level</label>
                                <select className="form-control" name="ENTRY_LEVEL" id="ENTRY_LEVEL" value={props.formData.ENTRY_LEVEL} onChange={props.onEdit}>
                                    <option value="">Select Entry Level</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Duration</label>
                                <select className="form-control" name="DURATION" id="DURATION" value={props.formData.DURATION} onChange={props.onEdit}>
                                    <option value="">Select Duration</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Capacity</label>
                                <input
                                    type="number"
                                    name="CAPACITY"
                                    className="form-control"
                                    id="CAPACITY"
                                    value={props.formData.CAPACITY}
                                    onChange={props.onEdit}
                                    placeholder="Capacity"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Application Fee</label>
                                <input
                                    type="number"
                                    name="APP_FEE"
                                    className="form-control"
                                    id="APP_FEE"
                                    value={props.formData.APP_FEE}
                                    onChange={props.onEdit}
                                    placeholder="Application Fee"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Acceptance Fee</label>
                                <input
                                    type="number"
                                    name="ACCEPT_FEE"
                                    className="form-control"
                                    id="ACCEPT_FEE"
                                    value={props.formData.ACCEPT_FEE}
                                    onChange={props.onEdit}
                                    placeholder="Acceptance Fee"
                                />

                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Mode of Entry</label>
                                <select className="form-control" name="APP_MODE" id="APP_MODE" value={props.formData.APP_MODE} onChange={props.onEdit}>
                                    <option value="">Select Mode of Entry</option>
                                    <option value="Internal">Internal</option>
                                    <option value="Transfer">Transfer</option>
                                    <option value="Conversion">Conversion</option>
                                    <option value="Direct-Entry">Direct-Entry</option>
                                    <option value="UTME">UTME</option>
                                </select>
                            </div>

                            <div className="mb-3 form-group col-md-6">
                                <label className="form-label">Application Status</label>
                                <select className="form-control" name="APP_STATUS" id="APP_STATUS" value={props.formData.APP_STATUS} onChange={props.onEdit}>
                                    <option value="">Select Application Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Pending">Pending</option>
                                </select>
                            </div>


                            <div className="mb-3 col-md-12 form-group">
                                <label className="form-label">Entry Requirements </label>
                                <JoditEditor
                                    value={props.formData.ENTRY_REQUIREMENT}
                                    config={editorConfig}
                                    onBlur={props.descriptionHandler}
                                    onChange={(newContent) => {}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}