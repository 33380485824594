import React from "react";
import {ThreeDots} from "react-loader-spinner";

function DataLoader() {
    return (
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-25px 0 0 -25px'
        }}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="grey"
                ariaLabel="loading"

            />
        </div>
    );
}

export default DataLoader;