import React from "react";

export default function StudentClearanceForm(props) {
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Student Clearance Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="hr-text hr-text-left"><h2>Student Details</h2></div>
                            <ul className="ml-3" style={{marginLeft: '10px'}}>
                                <li className="list-check">Application ID:  <b>{props.formData.APPID}</b></li>
                                <li>Student Name: <b>{props.formData.STUDENT_NAME}</b> </li>
                                <li>Email Address:  <b>{props.formData.EMAIL}</b></li>
                                <li>Phone Number:  <b>{props.formData.TELEPHONE}</b></li>
                                <li>Programme:  <b>{props.formData.PROGRAMME}</b></li>
                                <li>Admission Year:  <b>{props.formData.ADMISSION_YEAR}</b></li>
                            </ul>

                            <div className="alert alert-important alert-warning alert-dismissible" role="alert">
                                <div className="d-flex">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon" width="24"
                                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M12 9v2m0 4v.01"/>
                                            <path
                                                d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"/>
                                        </svg>
                                    </div>
                                    <div>
                                        Are you sure you want to clear the above student for registration?
                                    </div>
                                </div>
                                <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {
                            props.IsFormLoading ?
                                <button type="button" className="btn btn-primary ms-auto">
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                </button>
                                :
                                <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                                            <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Clear Student
                                        </span>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}