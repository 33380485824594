import React, {useEffect, useState} from "react";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import Select from "react-select";

function ProgrammeSelectOption(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [itemData, setItemData] = useState([])
    const [formData, setFormData] = useState({
        Item: "",
        ItemObject: "",
    })
    const getData = async () => {
        await axios.get(`${serverLink}staff/academics/programme/option/list`)
            .then((result) => {
                let rows = [];
                if (result.data.length > 0) {
                    result.data.map((row) => {
                        rows.push({ value: row.PROG_ID, label: row.PROGRAMME })
                    });
                    setItemData(rows);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }
    useEffect(()=> {
        getData();
    }, [""])

    const onItemChange = (e) => {
        props.setFormData({
            ...props.formData,
            PROG_ID:e.value,
        })
        setFormData({
            ...formData,
            Item: e.value,
            ItemObject: e,
        })
    }

    return (
        <Select
            name="PROG_ID"
            value={!props.formData.PROG_ID ? formData.ItemObject : itemData.filter((e,i)=> e.value.toString() === props.formData.PROG_ID.toString())[0]}
            onChange={onItemChange}
            options={itemData}
            placeholder="select Programme"
        />
    )
}

export default ProgrammeSelectOption;