import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import NewsForm from "./news-form";
import {formatDateAndTime} from "../../../resources/constants";
import {useLocation} from "react-router";

function News(props) {
    let location = useLocation();
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [newList, setNewsList] = useState([])
    const header = ["S/N", "Title", "Authors", "Image", "Date", "Viewers", "Status", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        EntryID: "",
        Title: "",
        ImagePath: "",
        ImageData: "",
        Description: "",
        ViewCount: "",
        Status: "",
        InsertedBy: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })


    useEffect( () => {
        getData();
    }, [""]);

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, [location]);


    const getData = async () => {
        await axios.get(`${serverLink}news/list/all`)
            .then((result) => {
                if (result.data.length > 0) {
                    setNewsList(result.data);
                }else{
                    setNewsList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}news/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return newList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.Title}</td>
                        <td className="text-xs font-weight-bold">{item.InsertedBy}</td>
                        <td className="text-xs font-weight-bold"> <img src={`${serverLink}public/uploads/news_uploads/${item.ImagePath}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                        <td className="text-xs font-weight-bold">{item.ViewCount}</td>
                        <td className="text-xs font-weight-bold">{item.Status.toString() ==="1" ? "Published" : "Draft"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       Title: item.Title,
                                       ImagePath: item.ImagePath,
                                       Description: item.Description,
                                       ViewCount: item.ViewCount,
                                       InsertedBy: item.InsertedBy,
                                       Status: item.Status,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "} onClick={() => delete_item(item.EntryID)}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Description: event,
        });
    }

    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };


    const onSubmit = async () => {
        if (formData.Title.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter news title", "error");
            return false;
        }
        if (formData.Description.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter news description", "error");
            return false;
        }
        if (formData.Status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select news status", "error");
            return false;
        }


        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}news/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {

                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', result.data.entry_id)
                            axios.patch(`${serverLink}news/uploadNewsPhoto`, formData2)
                                .then(result => {
                                    toast.success("News Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        Title: "",
                                        ImagePath: "",
                                        ImageData: "",
                                        Description: "",
                                        ViewCount: "",
                                        Status: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }

                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("NEWS EXIST", "News with the same title already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}news/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImageData !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', formData.EntryID)
                            axios.patch(`${serverLink}news/uploadNewsPhoto`, formData2)
                                .then(result => {
                                    toast.success("News Updated Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    setFormData({
                                        ...formData,
                                        EntryID: "",
                                        Title: "",
                                        ImagePath: "",
                                        ImageData: "",
                                        Description: "",
                                        ViewCount: "",
                                        Status: "",
                                    });
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }else{
                            toast.success("News Updated Successfully");
                            setIsFormLoading(false);
                            getData();
                            document.getElementById("closeModal").click();
                            setFormData({
                                ...formData,
                                EntryID: "",
                                Title: "",
                                ImagePath: "",
                                ImageData: "",
                                Description: "",
                                ViewCount: "",
                                Status: "",
                            });
                        }
                    } else if (result.data.message === "exist") {
                        showAlert("NEWS EXIST", "News with the same title already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    {/*Agent*/}
                                </div>
                                <h2 className="page-title">
                                    Manage News
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               EntryID: "",
                                               Title: "",
                                               ImagePath: "",
                                               ImageData: "",
                                               Description: "",
                                               ViewCount: "",
                                               Status: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add News
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">All News</h3>
                                                {/*<div className="table-responsive">*/}
                                                {/*<ReportTable data={data} columns={columns} />*/}
                                                <DataTable header={header} body={showTable()} title="All News" />
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewsForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    onImageChange={onImageChange}
                    descriptionHandler={descriptionHandler}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(News);
