import React, {useState, useEffect} from "react";
import {connect} from "react-redux";

import axios from "axios";
import { encryptData, serverLink} from "../../resources/url";
import {
    setgeneralDetails,
    setLoginDetails,
    setPermissionDetails,
    setVisitorData,
    setVisitorSubmissionData
} from "../../actions/actions";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";


function Validation(props) {
    const navigate = useNavigate();
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [locationData, setLocationData] = useState({})

    const [formData, setFormData] = useState({
        VisitorID: "",
        Title: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Gender: "",
        PhoneNumber: "",
        EmailAddress: "",
        State: "",
        Lga: "",
        Ward: "",
        Status: "",
        ImagePath: "",
        InsertedBy: `${props.loginData[0]?.UserID}`,
        username: "",
        password: "",
        userIP: "",
        userCountry: "",
        lat: "",
        lng: "",
    })

    const getLocationData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setLocationData(res.data)
    }
    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }
    const onValidateClicked = async () => {
        let sendData = {...formData };
        if (formData.username.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter your email address or phone number", "error");
            return false;
        }
            setIsFormLoading(true);

            await axios.post(`${serverLink}project/visitor_portal_login`, sendData)
                .then(data => {
                    const response = data.data.message;
                    const userData = data.data.userData;
                    const permissionData = data.data.permissionData;
                    const visitationData = data.data.visitationData;
                    setIsFormLoading(false);
                    if (response === "invalid_login") {
                        toast.info("Account not validated, create new account.");
                        setTimeout(()=>{
                            props.setOnGeneralDetails(formData.username)
                            navigate("/visitor/account");
                        }, 2000)
                        return false;
                    } else if (response === "error") {
                        showAlert("Oops!", "Something went wrong. Please try again!", "error")
                        return false;
                    } else if (response === "success") {
                        window.sessionStorage.setItem("userPermission", 'allow');
                        window.sessionStorage.setItem("isLoggedIn", true);
                        props.setOnPermissionDetails(permissionData);
                        props.setOnSubmissionDetails(visitationData);
                        props.setOnVisitorDetails(userData);
                        toast.success("Account Validated Successful");
                        setTimeout(()=>{
                            navigate("/visitor/form");
                        }, 2000)
                        return false;
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                        return false;
                    }

                }).catch(err => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }

    return (
        <>
            <div className=" d-flex flex-column">
            <div className="page page-center">
                <div className="container container-tight py-4">
                    <div className="text-center mb-4">
                        <a href="#" className="navbar-brand navbar-brand-autodark">VMIS</a>
                    </div>
                    <form className="card card-md" action="./" method="get" autoComplete="off" noValidate>
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Account Validation</h2>
                            <p className="text-muted mb-4">Enter your email address or your phone number to validate your account.</p>
                            <div className="mb-3">
                                <label className="form-label">Email address / Phone Number</label>
                                <input type="email" value={formData.username} onChange={onEdit} id="username" name="username" className="form-control" placeholder="Enter email or Phone Number"/>
                            </div>
                            <div className="form-footer">
                                <button type="button" className="btn btn-primary w-100" onClick={onValidateClicked}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler icon-tabler-discount-check-filled" width="24"
                                         height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path
                                            d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                                            stroke-width="0" fill="currentColor"/>
                                    </svg>
                                   Validate
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="text-center text-muted mt-3">
                        Forget it, <a href="/">send me back</a> to the home screen.
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {

        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
        setOnSubmissionDetails: (p) => {
            dispatch(setVisitorSubmissionData(p));
        },
        setOnVisitorDetails: (p) => {
            dispatch(setVisitorData(p));
        },
        setOnGeneralDetails: (p) => {
            dispatch(setgeneralDetails(p));
        }
    };
};


export default connect(mapStateToProps, mapDisptachToProps)(Validation);
