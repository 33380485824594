import axios from "axios";
import {projectEmail, projectURL, serverLink} from "./url";
import React from "react";

const CryptoJS = require("crypto-js");
export const projectName = "VMIS";
export const projectCode = "ODFel";
export const projectLogo = "https://teamcoded.com.ng/farmer_logo.png";

export const MaleAvatar = require("../images/male-avatar.jpg");
export const WomanAvatar = require("../images/woman-avatar.jpg");

export const formatDateAndTime = (date, option) => {
    if (date !== null) {
        const user_date = new Date(date);
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const monthNamesShort = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day =
            user_date.getDate() < 10
                ? "0" + user_date.getDate()
                : user_date.getDate();
        const hour =
            user_date.getHours() < 10
                ? "0" + user_date.getHours()
                : user_date.getHours();
        const min =
            user_date.getMinutes() < 10
                ? "0" + user_date.getMinutes()
                : user_date.getMinutes();
        const sec =
            user_date.getSeconds() < 10
                ? "0" + user_date.getSeconds()
                : user_date.getSeconds();

        let date_string = "";
        if (option === "date_and_time")
            date_string = `${day}-${monthNames[user_date.getMonth()]
            }-${user_date.getFullYear()} : ${hour}:${min}:${sec}`;
        else if (option === "date")
            date_string = `${day}-${monthNames[user_date.getMonth()]
            }-${user_date.getFullYear()}`;
        else if (option === "day") date_string = day;
        else if (option === "full_month")
            date_string = monthNames[user_date.getMonth()];
        else if (option === "short_month")
            date_string = monthNamesShort[user_date.getMonth()];
        else if (option === "year_only") date_string = user_date.getFullYear();

        return date_string;
    } else {
        return "--";
    }
};

export const formatDate = (date) => {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
};

export const currencyConverter = (amount) => {
    const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
    });
    return formatter.format(amount);
};
// export const yearsServed = [];
// export const yearsJoined = () => {
//     for (let i = 0; i < 20; i++) {
//         if (i < 10) {
//             const withoutMinus = String(num).slice(1);
//             return '-' + withoutMinus.padStart(totalLength, '0');
//         }
//         yearsServed.push(i);
//     }
// }

const date = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

export const currentDate = `${year}-${month}-${day}`;

export const generate_token = (length) =>{
    //edit the token allowed characters
    let a = "1234567890".split("");
    let b = [];
    for (let i=0; i<length; i++) {
        let j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

export const MenuIcon = (menu_name) => {
    switch (menu_name) {
        case "Academics":
            return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school"
                          width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                          fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"/>
                <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"/>
            </svg>;
            break;
        case "Human Resources":
            return <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                        viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                        strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="9" cy="7" r="4" />
                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
            </svg>;
            break;
        case "Registration":
            return <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                        viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                        strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline
                points="9 11 12 14 20 6"/><path
                d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"/></svg>;
            break;

            case "Report":
            return <svg xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-list" width="24" height="24"
                        viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="9" y1="6" x2="20" y2="6"/>
                <line x1="9" y1="12" x2="20" y2="12"/>
                <line x1="9" y1="18" x2="20" y2="18"/>
                <line x1="5" y1="6" x2="5" y2="6.01"/>
                <line x1="5" y1="12" x2="5" y2="12.01"/>
                <line x1="5" y1="18" x2="5" y2="18.01"/>
            </svg>;
            break;

            case "Settings":
            return <svg xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-settings" width="24"
                        height="24" viewBox="0 0 24 24" strokeWidth="2"
                        stroke="currentColor" fill="none" strokeLinecap="round"
                        strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path
                    d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"/>
                <circle cx="12" cy="12" r="3"/>
            </svg>;
            break;

            case "User":
            return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-circle"
                        width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"/>
            </svg>;
            break;

            case "Website":
            return <svg xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-world" width="24" height="24"
                        viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9"/>
                <line x1="3.6" y1="9" x2="20.4" y2="9"/>
                <line x1="3.6" y1="15" x2="20.4" y2="15"/>
                <path d="M11.5 3a17 17 0 0 0 0 18"/>
                <path d="M12.5 3a17 17 0 0 1 0 18"/>
            </svg>;
            break;

        default:
            return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-category" width="24"
                        height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                        strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 4h6v6h-6z"></path>
                <path d="M14 4h6v6h-6z"></path>
                <path d="M4 14h6v6h-6z"></path>
                <circle cx="17" cy="17" r="3"></circle>
            </svg>;
    }
}


export const CheckPermission = (allow) => {
    if (allow === true) {

    }else{
        window.location.href = "/"
    }


}

export const isValidUrl = (urlString)=> {
    try {
        return Boolean(new URL(urlString));
    }
    catch(e){
        return false;
    }
}

export  const BankList = [
    { "id": "1", "name": "Access Bank" ,"code":"044" },
    { "id": "2", "name": "Citibank","code":"023" },
    { "id": "3", "name": "Diamond Bank","code":"063" },
    { "id": "4", "name": "Dynamic Standard Bank","code":"" },
    { "id": "5", "name": "Ecobank Nigeria","code":"050" },
    { "id": "6", "name": "Fidelity Bank Nigeria","code":"070" },
    { "id": "7", "name": "First Bank of Nigeria","code":"011" },
    { "id": "8", "name": "First City Monument Bank","code":"214" },
    { "id": "9", "name": "Guaranty Trust Bank","code":"058" },
    { "id": "10", "name": "Heritage Bank Plc","code":"030" },
    { "id": "11", "name": "Jaiz Bank","code":"301" },
    { "id": "12", "name": "Keystone Bank Limited","code":"082" },
    { "id": "13", "name": "Providus Bank Plc","code":"101" },
    { "id": "14", "name": "Polaris Bank","code":"076" },
    { "id": "15", "name": "Stanbic IBTC Bank Nigeria Limited","code":"221" },
    { "id": "16", "name": "Standard Chartered Bank","code":"068" },
    { "id": "17", "name": "Sterling Bank","code":"232" },
    { "id": "18", "name": "Suntrust Bank Nigeria Limited","code":"100" },
    { "id": "19", "name": "Union Bank of Nigeria","code":"032" },
    { "id": "20", "name": "United Bank for Africa","code":"033" },
    { "id": "21", "name": "Unity Bank Plc","code":"215" },
    { "id": "22", "name": "Wema Bank","code":"035" },
    { "id": "23", "name": "Zenith Bank","code":"057" }
];

export function escape_string (str) {
    return str.replace(/[\0\x08\x09\x1a\n\r"'\\\%]/g, function (char) {
        switch (char) {
            case "\0":
                return "\\0";
            case "\x08":
                return "\\b";
            case "\x09":
                return "\\t";
            case "\x1a":
                return "\\z";
            case "\n":
                return "\\n";
            case "\r":
                return "\\r";
            case "\"":
            case "'":
            case "\\":
            case "%":
                return "\\"+char; // prepends a backslash to backslash, percent,
                                  // and double/single quotes
        }
    });
}

export const sendEmail = (email, subject, title, name, body, signature) => {
    const sendEmail = {
        logo: projectLogo,
        from: projectEmail,
        to: email,
        subject: subject,
        title: title,
        name: name,
        body: body,
        signature: signature,
    };
    try {
        axios.post(`${serverLink}mail/send_email`, sendEmail).then((r) => {
            console.log("email sent");
        });
    } catch (e) {
        console.log(e)
    }

    return "sent";
};

export const MailTemplates = (type, item) => {
    if (type === '1') {
        const message = {
            from: projectEmail,
            subject: 'INVITATION TO INTERVIEW',
            title: "Interview Invitation",
            name: item.FirstName,
            body: `<div>
                  we are pleased to invite you to an interview following your application for the position of ${item?.Position}<br/>The interview is set to take place as follows. 
                  <br/><br/>Date: ${formatDateAndTime(item?.InterViewDate, "date")} <br/>Vanue : ${item?.InterviewVenue} <br/>Time: ${item?.InterviewTime} <br/>For further inquiries, please contact hr@bazeuniversity.edu.ng
                  </div>`
        }
        return message;
    }
    else if (type === '3') {
        const message = {
            from: projectEmail,
            subject: 'NOTICE OF REJECTION',
            title: "Rejection Notice",
            name: item.FirstName,
            body: `<div>
      Dear Applicant, <br/>  we have reviewed your application for the position of <b>${item.Position}</b>, we are sorry to inform you that due to the large applications we received and the performances of other applicants, we regret to inform you that your application is not successful <br/> Sorry for any inconvenience this may have caused <br/> <br/> For further inquiries, please contact hr@bazeuniversity.edu.ng
      </div>`
        }
        return message;
    }
    else if (type === '2') {
        const message = {
            from: projectEmail,
            subject: 'OFFER OF APPOINTMENT',
            title: "Appointment Offer",
            name: item.FirstName,
            body: `<div>Congratulations, <br/> we are pleased to notify you that you have been offered appointment as <b>System Analyst</b> in the Department of <b>IT Directorate</b> with Kaduna Polytechnic. Kindly Follow this link to fill the enrolment form before reporting at the HR <a href="https://odfel.kadunapoly.edu.ng">link</a> .<br/>You are to report at the Human Resource Department for documentation within two weeks from the date of this email. <br/>Failure to report within the specified period would render this offer void. <br/><br/>For further enquiries, please context hr@kadunapoly.edu.ng </div>`
        }
        return message;
    }
    else if (type === '4') {
        const message = {
            from: projectEmail,
            subject: 'STAFF DETAILS',
            title: "Staff details",
            name: item.FirstName,
            body: `<div>
      Welcome to Baze University!. Your  Employee Identification Number is ${item.NewStaffID}.<br/><br/>Kindly find below valuable resources: <br/><br/>
      1. Staff Portal<br/>
      UserID: ${item.NewStaffID}<br/>
      Password: 123456<br/>
      Url : <a href="https://portal.bazeuniversity.edu.ng/" target="_blank" >https://portal.bazeuniversity.edu.ng/</a>
      You would be required to change your password before you can login.
      <br/><br/>
      2. Baze Website : <br/> <a href="https://bazeuniversity.edu.ng/" target="_blank">https://bazeuniversity.edu.ng/</a>
      <br/><br/>
      3. Baze Email : <br/> Your Baze University email address would be sent to you shortly after receiving this email, please do ensure you use it judisiously as it would be the primary gateway of correspondence with the university.
      <br/><br/>
      3. Network Connectivity Details : <br/> Your Login details would also be sent shortly after this email, please note that our internet services are censored and monitored for quality assurance purposes.
      <br/><br/>
      <br/>If you did not recieve these emails above, are unable to login, or have any challenges using our systems, kindly call the Administrator on extension 105 or send email to servicedesk@bazeuniversity.edu.ng.
      <br/><br/>
      Furthermore, Academic teaching staff are required to create turnitin accounts for the University e-Learning Portal (Room A24) and Turnitin accounts (Faculty Offices). 
      <br/><br/>Thank you and have a wonderful time at Baze University. 
      <br/><br/>Faisal Salisu/Rakeeba Bode<br/>For Baze IT Service Desk <div/>`

        }
        return message;
    }
    else if (type === '5') {
        const message = {
            from: projectEmail,
            subject: 'REQUEST FOR BAZE EMAIL, NETWORK PORTAL AND ID CARD',
            title: "Details Request",
            name: 'Service Desk',
            body: `<div>Good Day, <br/> Kindly create a baze university official email, network login details and initiate ID Card printing for the staff whose details appear below,
      <br/>
      <div class="table">
      <table style="width: 100%">
        <thead>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd; padding: 12px;">
            <td>Staff ID: ${item.staff_id}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>First Name: ${item.FirstName}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
            <td>Middle Name: ${item.MiddleName}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>Surname: ${item.Surname}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
            <td>Department: ${item.Department}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>Position: ${item.PositionOffered}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
            <td>BloodGroup: ${item.social_media[0]?.BloodGroup}</td>
          </tr>
          <tr style="border: 1px solid #ddd">
            <td>Next of Kin Phone: ${item.next_of_kin[0]?.Phone}</td>
          </tr>
          <tr style="background-color: #f2f2f2; border: 1px solid #ddd">
          <td>Image: <br/> 
          <img style="width:80px" src="${item.Passport}"  /><br/>
          <a href="${item.Passport}" target="_blank" >${item.Passport}</a>  </td>
        </tr>
        </thead>
      </table>
      </div> 
      <br/>Thank you
      <br/>
      Baze University HR
      </div>`
        }
        return message;
    }
    else if (type === 'account creation') {
        const message = {
            from: projectEmail,
            subject: 'Account Created',
            title: "Account Created",
            name: item.FirstName,
            body: `<div>Your Account have beean created, please proceed to login with your email and password.</div>`

        }
        return message;
    } else if (type === 'forgot password') {
        const message = {
            from: projectEmail,
            subject: 'Baze University HR',
            title: "Password Recovery",
            name: item.FirstName,
            body: `<div>You have requested to change your password, kindly follow this link to recover your password <br/> 
                <a href="${projectURL}/jobs?q=${item.Token}" >${projectURL}/jobs?q=${item.Token}</a>.</div>
                `

        }
        return message;
    } else if (type === 'registration') {
        const message = {
            from: projectEmail,
            subject: 'Baze University HR',
            title: "Account Registration",
            name: item.FirstName,
            body: `<div>Your account have been created, please proceed to login and continue your application<br/>Thank You! </div>`

        }
        return message;
    }
    else if (type === 'submit application') {
        const message = {
            from: projectEmail,
            subject: 'Baze University HR',
            title: "Application Recieved",
            name: item.FirstName,
            body: `<div><p>Dear Applicant</p> 
      <p>We have received your application for position of <strong>${item.Position}</strong></p>
      </div>.
      <br/>
      <div><p>Your Application would be passed to the screening commitee, you would recieve emails as the screening progresses. Please do not reply to this email as you would not get any reply</p></div>. 
      <br/> <div>Best of luck...</div><br/>
      <div>@Baze Univerity HR</div>`

        }
        return message;
    }
    else if (type === 'withdraw application') {
        const message = {
            from: projectEmail,
            subject: 'Baze University HR',
            title: "Application Withdrawn",
            name: item.FirstName,
            body: `<div><p>Dear Applicant</p> 
      <p>We have received your request to withdraw your application for position of <strong>${item.Position}</strong></p>
      </div>.
      <br/>
      <div><p>We application would be cancelled and would not be processef further. Thank you!</p></div>. 
      <br/> <div>Best of luck...</div><br/>
      <div>@Baze Uniersity HR</div>`

        }
        return message;
    }
    else if (type === 'admission') {
        const message = {
            from: projectEmail,
            subject: 'OFFER OF ADMISSION',
            title: "Congratulations! Your Offer of Admission to Kaduna Polytechnic ODFeL",
            body: `<div style="font-family: Arial, sans-serif; text-align: justify">
             I am delighted to inform you that you have been admitted to ${projectName}; to embark on an academic journey in your preferred area of study.<br/>
             On behalf of ${projectName}; I extend my warmest congratulations to you on this remarkable achievement.<br/>
            In order to secure your admission, it is essential that you complete the payment for admission acceptance fee.<br/>
            Kindly <a href='${projectURL}admission/check-admission-status'>click here</a> to pay your admission acceptance fee.<br/>
             <h3 style="padding-top: 15px"><strong>Student Portal (Admission Letter, Enrolment, Course Registration, Payments, etc.)</strong></h3>
                                    <ul>
                                            <li>URL: <a href='${projectURL}/admission/check-admission-status'>Student Portal</a> </li>
                                            <li>Kindly use your registered email and password to access your portal.</li>
                                    </ul>
                                     
            Best wishes,<br/><br/>
            
            ${item.Staff}<br/>
            ${item.Designation}<br/>
            ${projectName}<br/>
            ${formatDateAndTime(Date.now(), "date")}<br/><hr/>
            For further enquiries, please contact +234-8036424139, +234-8098909053, +234-8180599505 or email odfel@kadunapoly.edu.ng </div>`
        }
        return message;
    }
};