import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {decryptData, encryptData, serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import ManageStaffForm from "./manage-staff-form";

import DataTable from "../../common/data-table/data-table";

function ManageStaff(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [departmentList, setDepartmentList] = useState([])
    const [designationList, setDesignationList] = useState([])
    const [officeList, setOfficeList] = useState([])
    const [staffList, setStaffList] = useState([])
    const header = ["S/N", "Staff Name", "Gender", "Phone", "Email", "Designation", "Department", "Office", "Status", "Action"];
    const initialFormData = {
        CustodianID: "",
        Title: "",
        FirstName: "",
        MiddleName: "",
        Surname: "",
        Gender: "",
        PhoneNumber: "",
        EmailAddress: "",
        Designation: "",
        DepartmentID: "",
        OfficeID: "",
        Status: "",
        ImagePath: "",
        Password: "123456789",
        InsertedBy: `${props.loginData[0]?.UserID}`,
    }
    const [formData, setFormData] = useState(initialFormData)
    const resetFormData = () => {setFormData(initialFormData)}

    useEffect( () => {
       getStaffData();
    }, [""]);

  const  showTable = () => {
        try {
            return staffList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.FirstName+ ' ' + item.MiddleName+ ' ' + item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.Gender}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.EmailAddress}</td>
                        <td className="text-xs font-weight-bold">{item.DesignationName}</td>
                        <td className="text-xs font-weight-bold">{item.DepartmentName}</td>
                        <td className="text-xs font-weight-bold">{item.OfficeName}</td>
                        <td className="text-xs font-weight-bold">{item.Status}</td>
                        <td className="text-xs font-weight-bold d-flex">
                            <a href="#" className={"btn btn-primary btn-sm"} data-bs-toggle="modal" data-bs-target="#modal-report"
                            onClick={() => {
                                setFormData({
                                    ...formData,
                                    CustodianID: item.CustodianID,
                                    Title: item.Title,
                                    FirstName: item.FirstName,
                                    MiddleName: item.MiddleName,
                                    Surname: item.Surname,
                                    Gender: item.Gender,
                                    PhoneNumber: item.PhoneNumber,
                                    EmailAddress: item.EmailAddress,
                                    Designation: item.Designation,
                                    DepartmentID: item.DepartmentID,
                                    Status: item.Status,
                                    OfficeID: item.OfficeID,
                                    Password: decryptData(item.Password),
                                });
                            }}>
                            Edit
                        </a>
                            <p>&nbsp;</p>
                            <a href="#" className={"btn btn-danger btn-sm "}
                               onClick={() => {
                                   delete_item(item.CustodianID);
                               }}>
                                Delate
                            </a>
                        </td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}project/custodian/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getStaffData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }


    const getStaffData = async () => {
        await axios
            .get(`${serverLink}project/custodian/list`)
            .then((response) => {
                if (response.data.message === "success") {
                    setStaffList(response.data.data);
                    setDepartmentList(response.data.department);
                    setDesignationList(response.data.designation);
                    setOfficeList(response.data.office);
                }
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
    }

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.Title.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select title", "error");
            return false;
        }
        if (formData.FirstName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }
        if (formData.Surname.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter surname", "error");
            return false;
        }
        if (formData.PhoneNumber.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter phone number", "error");
            return false;
        }
        if (formData.EmailAddress.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter email address", "error");
            return false;
        }
        if (formData.Gender.toString() === "") {
            showAlert("EMPTY FIELD", "Please enter gender", "error");
            return false;
        }
        if (formData.Designation.toString() === "") {
            showAlert("EMPTY FIELD", "Please enter designation", "error");
            return false;
        }
        if (formData.DepartmentID.toString() === "") {
            showAlert("EMPTY FIELD", "Please enter designation", "error");
            return false;
        }

        if (formData.Password.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter password", "error");
            return false;
        }


        if (formData.CustodianID === "") {
            let sendData = {
                ...formData,
                Password: encryptData(formData.Password.toString())
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}project/custodian/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Custodian Added Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        resetFormData();
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("CUSTODIAN EXIST", "Custodian already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                Password: encryptData(formData.Password)
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}project/custodian/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Custodian Updated Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        resetFormData();
                    } else if (result.data.message === "exist") {
                        showAlert("CUSTODIAN EXIST", "Custodian already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Custodian
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           resetFormData();
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Custodian
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className=" row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Staff Report</h3>
                                                <div className="table-responsive">
                                                <DataTable header={header} body={showTable()} title="Staff Report" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ManageStaffForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    departmentList={departmentList}
                    designationList={designationList}
                    officeList={officeList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(ManageStaff);
