import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {MaleAvatar, projectName, WomanAvatar} from "../../../resources/constants";
function Header(props) {
    const user = props.loginData[0];
    const avatar = user?.Gender === "Male" ? `url(${MaleAvatar})` : `url(${WomanAvatar})`;
    const username = user?.FirstName+" "+user?.MiddleName+" "+user?.Surname;

    const dismissClick = () => {
        document.getElementById("toggle-nav").click();
    }

    return (
        <>
            <header className="navbar navbar-expand-md navbar-light d-print-none">
                <div className="container-xl">
                    <button className="navbar-toggler" id="toggle-nav" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbar-menu">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                        <a href=".">
                            <img src={require('../../../images/logo.png')} width="150" height="150" alt="Farmer Enumeration"
                                 className="navbar-brand-image"/>

                        </a>  <span style={{marginLeft: '10px'}}> {projectName} </span>
                    </h1>
                    <div className="navbar-nav flex-row order-md-last">
                        <div className="nav-item d-none d-md-flex me-3"/>
                        <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode"
                           data-bs-toggle="tooltip" data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"/>
                            </svg>
                        </a>
                        <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode"
                           data-bs-toggle="tooltip" data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24"
                                 strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="12" r="4"/>
                                <path
                                    d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"/>
                            </svg>
                        </a>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                               aria-label="Open user menu">
                                <span className="avatar avatar-sm"
                                      style={{backgroundImage: `${avatar}`}}/>
                                <div className="d-none d-xl-block ps-2">
                                    <div>{props.loginData.length > 0 ? props.loginData[0]?.name : "User"}</div>
                                    <div className="mt-1 small text-muted">{username}</div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                {/*<Link to="/user/account/staff-profile" className="dropdown-item">Profile</Link>*/}
                                <Link to="/logout" className="dropdown-item">Logout</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="navbar-expand-md">
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <div className="navbar navbar-light">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                           viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                           strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><polyline
                                          points="5 12 3 12 12 3 21 12 19 12"/><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/><path
                                          d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/></svg>
                                    </span>
                                        <span className="nav-link-title"> Home </span>
                                    </Link>
                                </li>
                                <li className="nav-item dropdown ">
                                    <a className="nav-link dropdown-toggle" href="#navbar-base"
                                       data-bs-toggle="dropdown"
                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="icon icon-tabler icon-tabler-user-check" width="24"
                                                     height="24" viewBox="0 0 24 24" stroke-width="2"
                                                     stroke="currentColor" fill="none" stroke-linecap="round"
                                                     stroke-linejoin="round">
                                                       <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                       <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"/>
                                                       <path d="M6 21v-2a4 4 0 0 1 4 -4h4"/>
                                                       <path d="M15 19l2 2l4 -4"/>
                                                    </svg>
                                            </span>
                                        <span className="nav-link-title">Human Resources</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-columns">
                                            <div className="dropdown-menu-column">
                                                <div className="dropend">
                                                    <a className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                                         Staff (Custodian)
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/hr/manage-staff">
                                                            Manage Custodian
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="dropend">
                                                    <a className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                                        Department
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/hr/manage-department">
                                                            Manage Department
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="dropend">
                                                    <a className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                                        Office
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/hr/manage-office">
                                                            Manage Office
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="dropend">
                                                    <a className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                                        Block
                                                    </a>
                                                    <div className="dropdown-menu">
                                                        <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/hr/manage-block">
                                                            Manage Block
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown ">
                                    <a className="nav-link dropdown-toggle" href="#navbar-base"
                                       data-bs-toggle="dropdown"
                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="icon icon-tabler icon-tabler-users-group" width="24"
                                                     height="24" viewBox="0 0 24 24" stroke-width="2"
                                                     stroke="currentColor" fill="none" stroke-linecap="round"
                                                     stroke-linejoin="round">
                                                       <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                       <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                                                       <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1"/>
                                                       <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                                                       <path d="M17 10h2a2 2 0 0 1 2 2v1"/>
                                                       <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"/>
                                                       <path d="M3 13v-1a2 2 0 0 1 2 -2h2"/>
                                                    </svg>
                                                                                                </span>
                                        <span className="nav-link-title">Visitor</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-columns">
                                            <div className="dropdown-menu-column">
                                                <div className="dropend">
                                                    <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/hr/manage-visitors">
                                                        Manage Visitor
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown ">
                                    <a className="nav-link dropdown-toggle" href="#navbar-base"
                                       data-bs-toggle="dropdown"
                                       data-bs-auto-close="outside" role="button" aria-expanded="false">
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="icon icon-tabler icon-tabler-report-analytics"
                                                     width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                                     stroke="currentColor" fill="none" stroke-linecap="round"
                                                     stroke-linejoin="round">
                                                       <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                       <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/>
                                                       <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"/>
                                                       <path d="M9 17v-5"/>
                                                       <path d="M12 17v-1"/>
                                                       <path d="M15 17v-3"/>
                                                    </svg>
                                            </span>
                                        <span className="nav-link-title">Report</span>
                                    </a>
                                    <div className="dropdown-menu">
                                        <div className="dropdown-menu-columns">
                                            <div className="dropdown-menu-column">
                                                <div className="dropend">
                                                    <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/report/visitors-submission">
                                                        Visitor Submission
                                                    </Link>
                                                    <Link onClick={()=>{ dismissClick() }} className="dropdown-item" id="elementId" to="/report/analytics">
                                                        Analytics
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
                                <form action="." method="get">
                                    <div className="input-icon">
                                        <span className="input-icon-addon">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                               viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                               strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="10"  cy="10" r="7"/><line x1="21" y1="21" x2="15" y2="15"/></svg>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        permissionData: state.permissionData,
    };
};
export default connect(mapStateToProps, null)(Header);
