import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {formatDateAndTime} from "../../../resources/constants";
import ProgrammeSelectOption from "../../common/dynamic-select-option/progrmme-select-option";

function StudentReportByProgramme(props) {
    const [IsLoading, setIsLoading] = useState(false)
    const [showTBL, setShowTBL] = useState(false)
    const [studentList, setStudentList] = useState([])
    const header = ["S/N", "STUDENTID", "Name","Gender",  "Nationality", "State_Of_Origin", "DATE_OF_BIRTH", "Programme", "Admission_Year"];
    const [formData, setFormData] = useState({
        PROG_ID: "",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    useEffect( () => {
        if (formData.PROG_ID.toString() !== ""){
            getData(formData.PROG_ID)
        }

    }, [formData.PROG_ID.toString()]);

    const getData = async (programme) => {
        setIsLoading(true)
        await axios.get(`${serverLink}staff/report/registration/student/${programme}`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }
                setIsLoading(false)
                setShowTBL(true)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENTID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.NATIONALITY}</td>
                        <td className="text-xs font-weight-bold">{item.STATE_OF_ORIGIN}</td>
                        <td className="text-xs font-weight-bold">{item.DOB !== "" ? formatDateAndTime(item.DOB, "date") : "N/A"}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.ADMISSION_YEAR}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Student Report By Programme
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="hr-text hr-text-left" style={{padding: '0px', marginTop: '15px', marginBottom: '15px'}}><h5>Select Programme</h5></div>
                                                <div className="form-group mb-3">
                                                    <ProgrammeSelectOption  formData={formData} setFormData={setFormData} />
                                                </div>
                                                {
                                                    showTBL ? <DataTable header={header} body={showTable()} title="Student Report By Programme" /> :
                                                        <div className="alert alert-important alert-info alert-dismissible" role="alert">
                                                            <div className="d-flex">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                         className="icon alert-icon" width="24"
                                                                         height="24" viewBox="0 0 24 24"
                                                                         strokeWidth="2" stroke="currentColor"
                                                                         fill="none" strokeLinecap="round"
                                                                         strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z"
                                                                              fill="none"/>
                                                                        <circle cx="12" cy="12" r="9"/>
                                                                        <line x1="12" y1="8" x2="12.01" y2="8"/>
                                                                        <polyline points="11 12 12 12 12 16 13 16"/>
                                                                    </svg>
                                                                </div>
                                                                <div> Please select programme to show report </div>
                                                            </div>
                                                            <a className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="close"/>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(StudentReportByProgramme);
