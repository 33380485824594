import React from "react";
import JoditEditor from "jodit-react";

export default function DepartmentForm(props) {
    const editorConfig = {
        readonly: false,
        height: 200
    };
    return <>
        <div className="modal modal-blur fade" id="modal-report" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Department Form</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Department Name</label>
                                <input
                                    name="DepartmentName"
                                    className="form-control"
                                    id="DepartmentName"
                                    value={props.formData.DepartmentName}
                                    onChange={props.onEdit}
                                    placeholder="Department Name"
                                />

                            </div>
                            <div className="mb-3 form-group col-md-12">
                                <label className="form-label">Status</label>
                                <select
                                    className="form-control form-control-solid"
                                    name="Status"
                                    id="Status"
                                    onChange={props.onEdit}
                                    value={props.formData.Status}
                                >
                                    <option value="">Select Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">In-Active</option>
                                </select>
                            </div>

                            <div className="mb-3 col-md-12 form-group">
                                <label className="form-label">Description </label>
                                <JoditEditor
                                    value={props.formData.Description}
                                    config={editorConfig}
                                    onBlur={props.descriptionHandler}
                                    onChange={(newContent) => {}}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="closeModal" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        {/*data-bs-dismiss="modal"*/}
                        <button type="button" onClick={props.onSubmit} className="btn btn-primary ms-auto">
                            {
                                props.IsFormLoading ?
                                    <span><span className="spinner-border spinner-border-sm me-2" role="status"/> Please wait...</span>
                                    :
                                    <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                 height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                             Submit
                                        </span>
                            }

                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}