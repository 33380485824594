import React from "react";

export default function ListTile(props) {
    return (
        <div className="list-group-item mb-2 border-top">
            <div className="row align-items-center">
                <div className="col-auto">{props.caption ?? "Item Key"}: </div>
                <div className="col text-truncate">
                    <span className="text-reset d-block">{props.name ?? "Item Value"}</span>
                </div>
            </div>
        </div>
    )
}