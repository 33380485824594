import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import CollageForm from "./collage-form";

function Collage(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [collageList, setCollageList] = useState([])
    const header = ["S/N", "Collage Name", "Code", "Location", "Phone Number", "Status", "Action"];
    const [formData, setFormData] = useState({
        COL_ID: "",
        COLLEGE: "",
        COL_CODE: "",
        COL_LOCATION: "",
        COL_PHONE: "",
        COL_STATUS: "",
        INSERTED_BY: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/academics/collage/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setCollageList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return collageList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.COLLEGE}</td>
                        <td className="text-xs font-weight-bold">{item.COL_CODE}</td>
                        <td className="text-xs font-weight-bold">{item.COL_LOCATION}</td>
                        <td className="text-xs font-weight-bold">{!item.COL_PHONE ? "N/A" : item.COL_PHONE}</td>
                        <td className={`text-xs font-weight-bold ${item.COL_STATUS?.toString() ==="1" ? "text-success" : "text-danger"}`}>{item.COL_STATUS?.toString() ==="1" ? "Active" : "In-Active"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       COL_ID: item.COL_ID,
                                       COLLEGE: item.COLLEGE,
                                       COL_CODE: item.COL_CODE,
                                       COL_LOCATION: item.COL_LOCATION,
                                       COL_PHONE: item.COL_PHONE,
                                       COL_STATUS: item.COL_STATUS,
                                       INSERTED_BY: item.INSERTED_BY,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        if (formData.COLLEGE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter collage name", "error");
            return false;
        }

        if (formData.COL_CODE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter collage code", "error");
            return false;
        }

        if (formData.COL_STATUS.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select collage status", "error");
            return false;
        }

        if (formData.COL_ID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/academics/collage/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Collage Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            COL_ID: "",
                            COLLEGE: "",
                            COL_CODE: "",
                            COL_LOCATION: "",
                            COL_PHONE: "",
                            COL_STATUS: "",
                        });

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/academics/collage/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Collage Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            COL_ID: "",
                            COLLEGE: "",
                            COL_CODE: "",
                            COL_LOCATION: "",
                            COL_PHONE: "",
                            COL_STATUS: "",
                        });
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Collage
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               COL_ID: "",
                                               COLLEGE: "",
                                               COL_CODE: "",
                                               COL_LOCATION: "",
                                               COL_PHONE: "",
                                               COL_STATUS: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Collage
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Collage(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Collage(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CollageForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Collage);
