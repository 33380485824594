import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import Chart from "react-google-charts";

import ApexChart from "react-apexcharts";
import Skeleton from "../common/skeleton/skeleton";
import {StateData} from "../../resources/state_data";
import { MaleAvatar, WomanAvatar} from "../../resources/constants";

function Dashboard(props) {

    const user = props.loginData[0];
    const [IsLoading, setIsLoading] = useState(true)
    const [appliedCourse, setAppliedCourse] = useState([])
    const [programmeCategory, setProgrammeCategory] = useState([])
    const [loginCount, setLoginCount] = useState([])
    const [genderType, setGenderType] = useState([])
    const [staffGenderType, setStaffGenderType] = useState([])
    const [staffDistribution, setStaffDistribution] = useState([]);
    const [stateDistribution, setStateDistribution] = useState([]);

    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);
    const [apexChartOption2, setApexChartOption2] = useState({});
    const [apexSeries2, setApexSeries2] = useState([]);

    const [visitorsCount, setVisitorsCount] = useState(0);
    const [submissionCount, setSubmissionCount] = useState(0);
    const [pendingSubmissionCount, setPendingSubmissionCount] = useState(0);
    const [staffCount, setStaffCount] = useState(0);



    const programmeObj = (prog_id, study_type) => {
        let result = [];
        for (let i = 0; i < prog_id.length; i++) {
            for (let j = 0; j < study_type.length; j++) {
                result.push( {PROG_ID: study_type[i], STUDY_TYPE: study_type[j]})
            }

        }
        return result;
    }

    const getDashboardAnalyticsData = async () => {
        await axios.get(`${serverLink}project/dashboard/data`)
            .then((result) => {
                if (result.data.message === 'success') {
                    let visitors = result.data.Visitors;
                    let submissions = result.data.Submission;
                    let Staff = result.data.Staff;
                    let Department = result.data.Department;
                    let Programme = [];


                    let gender = ['Male', 'Female'];
                    let distribution = [1, 0];
                    const SubmissionTypes = ["Complaint", "Suggestion", "Recommendation", "Assistance", "Others"];
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    let result0 = [];
                    let result1 = [];
                    let result2 = [];
                    let result3 = [];
                    let stateTypes = [];
                    let visitorsCount = [];

                    let staffGenderResult = [];
                    let staffDistributionResult = [];


                    [ ...new Set(StateData.filter(j=>j.StateName === "Kaduna").map(x=>x.LGAName))].map((e, i) => {
                        let count = visitors.filter(item => item.Lga.toString() === e.toString()).length
                        visitorsCount.push(count)
                        stateTypes.push(e)
                        result1.push([e, count]);
                    })

                    gender.map((e, i) => {
                        let count = visitors.filter(item => item.Gender === e).length
                        result2.push([e, count]);
                    })

                    gender.map((e, i) => {
                        let count = Staff.filter(item => item.Gender === e).length
                        staffGenderResult.push([e, count]);
                    })

                        SubmissionTypes.map((e, i) => {
                            let  count = submissions.filter(item => item.Purpose.toString() === e.toString()).length
                            result0.push([e, count]);
                        })


                    monthNames.map((e, i) => {
                        let count = submissions.filter(item => item.MonthName.toString() === e.toString()).length
                        result3.push([e, count]);
                    })

                    Department.map((e, i) => {
                        let count = Staff.filter(item => item.DepartmentID === e.DepartmentID).length
                        staffDistributionResult.push([e.DepartmentName, count]);
                    })
                    //
                    // let result00 = [];
                    // user.userLoginCount.map((e, i) => {
                    //     result00.push([e.Days+"\n"+formatDateAndTime(e.LogDate, 'date'), e.Count]);
                    // })

                    // setLoginCount(result00)


                    setApexChartOption({
                        ...apexChartOption,
                        chart: {
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: stateTypes
                        }
                    })
                    setApexSeries([{
                        name: "Count",
                        data: visitorsCount
                    }])

                    setApexChartOption2({
                        ...apexChartOption,
                        chart: {
                            id: "basic-line"
                        },
                        xaxis: {
                            categories: ["Mapped", "Not Mapped"]
                        }
                    })
                    setApexSeries2([{
                        name: "Count",
                        data: [0]
                        // data: [farmersData.filter(e=>e.farm_statuss.toString() === "1").length, farmersData.filter(e=>e.farm_statuss.toString() === "0").length]
                    }])


                    setProgrammeCategory(result0)
                    setStateDistribution(result1)
                    setGenderType(result2)
                    setAppliedCourse(result3)
                    setStaffDistribution(staffDistributionResult)
                    setStaffGenderType(staffGenderResult)

                    setVisitorsCount(visitors.length)
                    setSubmissionCount(submissions.length)
                    setPendingSubmissionCount(submissions.filter(e=>e.Status === "Pending").length)
                    setStaffCount(Staff.length)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    useEffect(() => {
        getDashboardAnalyticsData();
    }, [""]);

    const data = [["Programmes", ""], ...programmeCategory];
    const options = {
        chart: {
            title: "Submission Trend By Purpose",
            subtitle: "Count",
            width: '100%',
        },
    };


    const data2 = [["Gender", "Count"], ...genderType];
    const options2 = {
        title: "Visitors Trend By Gender",
        is3D: true,
    };

    const data4 = [["Category", ""], ...staffDistribution];
    const options4 = {
        chart: {
            title: "Staff Distribution By Departments",
            subtitle: "Count",
            width: '100%',
        },
    };

    const data5 = [["Gender", "Count"], ...staffGenderType];
    const options5 = {
        title: "Staff Category By Gender",
        is3D: true,
    };


    const data3 = [["Count", "Courses"], ...appliedCourse];
    const options3 = {
        title: "Submission Trend By Month",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
    };

    const dataLogin = [["Days", ""], ...loginCount];
    const optionsLogin = {
        chart: {
            title: "Portal Usage",
            subtitle: "Count",
            width: '100%',
        },
    };


    return (
        IsLoading ?

            <Skeleton/>
            :
        <>
            <div className="container-xl">
                <div className="page-header d-print-none">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-deck row-cards">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-auto">
                                        <span className="avatar avatar-lg img-thumbnail rounded" style={{backgroundImage: user?.Gender === "Male" ? `url(${MaleAvatar})` : `url(${WomanAvatar})`}}/>
                                    </div>
                                    <div className="col">
                                        <h2 className="fw-bold">{user?.FirstName} {user?.MiddleName} {user?.Surname} </h2>
                                        <div className="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="icon me-2 text-muted" width="24" height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path
                                                    d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"/>
                                                <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"/>
                                                <path d="M12 12l0 .01"/>
                                                <path d="M3 13a20 20 0 0 0 18 0"/>
                                            </svg>
                                            <span className="text-reset">Administrator </span>
                                        </div>
                                        <div className="list-inline-item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="icon me-2 text-muted" width="24" height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M5 12l-2 0l9 -9l9 9l-2 0"/>
                                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/>
                                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/>
                                            </svg>
                                            <span className="text-reset">{user?.DepartmentName} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Visitors</div>
                                        <div className="ms-auto lh-1">
                                        </div>
                                    </div>
                                    <div className="h1 mb-3">{visitorsCount}</div>
                                    <div className="d-flex mb-2">
                                        <div>Total Visitors</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Visitors Submission</div>

                                    </div>
                                    <div className="h1  mb-3">{submissionCount}</div>
                                    <div>Total Visitors Submission</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Submissions </div>

                                    </div>
                                    <div className="h1  mb-3">{pendingSubmissionCount}</div>
                                    <div>Total Pending Submissions</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex align-items-center">
                                        <div className="subheader">Staff</div>
                                    </div>
                                    <div className="h1  mb-3">{staffCount}</div>
                                    <div>Total Active Staff</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                stateDistribution.length > 0 &&
                                                <Chart
                                                    chartType="Bar"
                                                    width="100%"
                                                    height="400px"
                                                    data={data}
                                                    options={options}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                {
                                    genderType.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="400px"
                                        data={data2}
                                        options={options2}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                appliedCourse.length > 0 &&
                                                <Chart
                                                    chartType="LineChart"
                                                    data={data3}
                                                    options={options3}
                                                    width={"100%"}
                                                    height={"400px"}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4>Visitors Trend By Local Government Area</h4>
                                            {

                                                <ApexChart
                                                    options={apexChartOption}
                                                    series={apexSeries}
                                                    type="bar"
                                                    width="100%"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                {
                                    staffGenderType.length > 0 &&
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="400px"
                                        data={data5}
                                        options={options5}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row row-cards">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            {
                                                staffDistribution.length > 0 &&
                                                <Chart
                                                    chartType="Bar"
                                                    width="100%"
                                                    height="400px"
                                                    data={data4}
                                                    options={options4}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
        permissionData: state.permissionData,
    };
};

export default connect(mapStateToProps, null)(Dashboard);
