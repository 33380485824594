import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import {useLocation} from "react-router";
import DepartmentForm from "./department-form";

function Departments(props) {
    let location = useLocation();
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [departmentList, setDepartmentList] = useState([])
    const header = ["S/N", "Department Name", "Status", "AddedBy", "Edit", "Delete"];
    const [formData, setFormData] = useState({
        DepartmentID: "",
        DepartmentName: "",
        Description: "",
        Status: "",
        InsertedBy: `${props.loginData[0]?.UserID}`,
    })


    useEffect( () => {
        getData();
    }, [""]);

    const scrollTop = async () => {
        await window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollTop();
    }, [location]);


    const getData = async () => {
        await axios.get(`${serverLink}project/department/list`)
            .then((result) => {
                if (result.data.message === "success") {
                    setDepartmentList(result.data.data);
                }else{
                    setDepartmentList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const delete_item = async (id) => {
        toast.info("please wait...");
        await axios.delete(`${serverLink}project/department/delete/${id}`).then((res) => {
            if (res.data.message === "success") {
                toast.success("Deleted Successfully");
                getData();
            } else {
                toast.error("NETWORK ERROR. Please try again!");
            }
        }).catch((err) => {
            console.log(err);
            toast.error("NETWORK ERROR. Please try again!");
        });
    }

    const  showTable = () => {
        try {
            return departmentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.DepartmentName}</td>
                        <td className="text-xs font-weight-bold">{item.Status}</td>
                        <td className="text-xs font-weight-bold">{item.UserName}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       DepartmentID: item.DepartmentID,
                                       DepartmentName: item.DepartmentName,
                                       Description: item.Description,
                                       Status: item.Status,
                                   });
                               }}>
                                Edit
                            </a></td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-danger btn-sm "} onClick={() => delete_item(item.DepartmentID)}>
                                Delete
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Description: event,
        });
    }

    const onSubmit = async () => {
        if (formData.DepartmentName.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter office name", "error");
            return false;
        }

        if (formData.Status.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select office block", "error");
            return false;
        }

        if (formData.DepartmentID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}project/department/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Department Added Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            DepartmentID: "",
                            DepartmentName: "",
                            Description: "",
                            Status: "",
                        });

                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("DEPARTMENT EXIST", "Department with the same name already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}project/department/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Department Updated Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            DepartmentID: "",
                            DepartmentName: "",
                            Description: "",
                            Status: "",
                        });
                    } else if (result.data.message === "exist") {
                        showAlert("DEPARTMENT EXIST", "Department with the same title already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    {/*Agent*/}
                                </div>
                                <h2 className="page-title">
                                    Manage Departments
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>{
                                           setFormData({
                                               ...formData,
                                               DepartmentID: "",
                                               DepartmentName: "",
                                               Description: "",
                                               Status: "",
                                           });
                                       }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Department
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Departments</h3>
                                                <div className="table-responsive">
                                                <DataTable header={header} body={showTable()} title="All Blocks" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DepartmentForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    descriptionHandler={descriptionHandler}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Departments);
