import React from "react";
export default function Skeleton() {
    return (
        <>
            <div className="container-xl">
                <div className="page-header d-print-none">
                    <div className="row align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row row-cards">
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="skeleton-heading"></div>
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="skeleton-heading"></div>
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="skeleton-heading"></div>
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="skeleton-heading"></div>
                                <div className="skeleton-line"></div>
                                <div className="skeleton-line"></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="card">
                                    <div className="ratio ratio-21x9 card-img-top">
                                        <div className="skeleton-image"></div>
                                    </div>
                                    <div className="card-body">
                                        <div className="skeleton-heading"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row row-cards">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body text-end">
                                        <div className="skeleton-heading"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="skeleton-avatar"></div>
                                                </div>
                                                <div className="col-7">
                                                    <div className="skeleton-line"></div>
                                                    <div className="skeleton-line"></div>
                                                </div>
                                                <div className="col-2 ms-auto text-end">
                                                    <div className="skeleton-line"></div>
                                                    <div className="skeleton-line"></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="skeleton-avatar"></div>
                                                </div>
                                                <div className="col-7">
                                                    <div className="skeleton-line"></div>
                                                    <div className="skeleton-line"></div>
                                                </div>
                                                <div className="col-2 ms-auto text-end">
                                                    <div className="skeleton-line"></div>
                                                    <div className="skeleton-line"></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <div className="skeleton-avatar"></div>
                                                </div>
                                                <div className="col-7">
                                                    <div className="skeleton-line"></div>
                                                    <div className="skeleton-line"></div>
                                                </div>
                                                <div className="col-2 ms-auto text-end">
                                                    <div className="skeleton-line"></div>
                                                    <div className="skeleton-line"></div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="row g-0 align-items-center">
                                <div className="col-3">
                                    <div className="ratio ratio-1x1 card-img-left">
                                        <div className="skeleton-image"></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card-body">
                                        <div className="skeleton-heading"></div>
                                        <div className="skeleton-line"></div>
                                        <div className="skeleton-line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
