export const generalDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_GENERALDETAILS_DETAILS":
            return action.payload;

        default:
            return state;
    }
};

export const permissionDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_PERMISSION_DATA":
            return action.payload;

        default:
            return state;
    }
};

export const loginDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case "SET_LOGIN_DETAILS":
            return action.payload;

        default:
            return state;
    }
};

export const visitor_submission_data_reducer = (state = [], action) => {
    switch (action.type) {
        case "SET_SUBMISSION_DATA":
            return action.payload;

        default:
            return state;
    }
};

export const visitor_data_reducer = (state = [], action) => {
    switch (action.type) {
        case "SET_VISITOR_DATA":
            return action.payload;

        default:
            return state;
    }
};