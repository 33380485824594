import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {decryptData, encryptData, serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import {formatDate, formatDateAndTime, isValidUrl} from "../../../resources/constants";
import StateData from "../../../resources/state_and_lga.json";
import CountryData from "../../../resources/country.json";
import UpdateStaffForm from "./staff-profile-form";
import {setLoginDetails} from "../../../actions/actions";

function StaffProfile(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [stateList, setStateList] = useState([]);
    const [lgaList, setLgaList] = useState([]);
    const [departmentList, setDepartmentList] = useState([])
    const [designationList, setDesignationList] = useState([])
    const [staffTypeList, setStaffTypeList] = useState([])
    const [staffList, setStaffList] = useState({})
    const [formData, setFormData] = useState({
        EntryID: props.loginData[0]?.EntryID,
        StaffID: props.loginData[0]?.StaffID,
        Title: props.loginData[0]?.Title,
        FirstName: props.loginData[0]?.FirstName,
        MiddleName: props.loginData[0]?.MiddleName,
        Surname: props.loginData[0]?.Surname,
        Gender: props.loginData[0]?.Gender,
        DateOfBirth: formatDate(props.loginData[0]?.DateOfBirth),
        MaritalStatus: props.loginData[0]?.MaritalStatus,
        Nationality: props.loginData[0]?.Nationality,
        State: props.loginData[0]?.State,
        Lga: props.loginData[0]?.Lga,
        Religion: props.loginData[0]?.Religion,
        PhoneNumber: props.loginData[0]?.PhoneNumber,
        EmailAddress: props.loginData[0]?.EmailAddress,
        StaffType: props.loginData[0]?.StaffType,
        Designation: props.loginData[0]?.Designation,
        DepartmentCode: props.loginData[0]?.DepartmentCode,
        IsActive: props.loginData[0]?.IsActive,
        IsAcademicStaff: props.loginData[0]?.IsAcademicStaff,
        IsAdmin: props.loginData[0]?.IsAdmin,
        ContactAddress: props.loginData[0]?.ContactAddress,
        Research: props.loginData[0]?.Research,
        Facebook: props.loginData[0]?.Facebook,
        Linkedin: props.loginData[0]?.Linkedin,
        Twitter: props.loginData[0]?.Twitter,
        Scholar: props.loginData[0]?.Scholar,
        Researchgate: props.loginData[0]?.Researchgate,
        Academia: props.loginData[0]?.Academia,
        Orcid: props.loginData[0]?.Orcid,
        Image: props.loginData[0]?.Image,
        Biography: props.loginData[0]?.Biography,
        Password: decryptData(props.loginData[0]?.Password),
        token: props.loginData[0]?.token,
        AddedBy: `${props.loginData[0]?.StaffID}`,
    })



    useEffect( () => {
        getStaffData();
    }, [""]);

    const  showTable = () => {
        try {
            return staffList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.StaffID}</td>
                        <td className="text-xs font-weight-bold">{item.FirstName+ ' ' + item.MiddleName+ ' ' + item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.Gender}</td>
                        <td className="text-xs font-weight-bold">{item.PhoneNumber}</td>
                        <td className="text-xs font-weight-bold">{item.EmailAddress}</td>
                        <td className="text-xs font-weight-bold">{item.Designation}</td>
                        <td className="text-xs font-weight-bold">{item.IsActive.toString() ==="1" ? "Active" : "Not Active"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       EntryID: item.EntryID,
                                       StaffID: item.StaffID,
                                       Title: item.Title,
                                       FirstName: item.FirstName,
                                       MiddleName: item.MiddleName,
                                       Surname: item.Surname,
                                       Gender: item.Gender,
                                       DateOfBirth: item.DateOfBirth,
                                       MaritalStatus: item.MaritalStatus,
                                       Nationality: item.Nationality,
                                       State: item.State,
                                       Lga: item.Lga,
                                       Religion: item.Religion,
                                       PhoneNumber: item.PhoneNumber,
                                       EmailAddress: item.EmailAddress,
                                       StaffType: item.StaffType,
                                       Designation: item.Designation,
                                       DepartmentCode: item.DepartmentCode,
                                       IsActive: item.IsActive,
                                       IsAcademicStaff: item.IsAcademicStaff,
                                       IsAdmin: item.IsAdmin,
                                       ContactAddress: item.ContactAddress,
                                       Research: item.Research,
                                       Facebook: item.Facebook,
                                       Linkedin: item.Linkedin,
                                       Twitter: item.Twitter,
                                       Scholar: item.Scholar,
                                       Researchgate: item.Researchgate,
                                       Academia: item.Academia,
                                       Orcid: item.Orcid,
                                       Image: item.Image,
                                       Biography: item.Biography,
                                       Hits: item.Hits,
                                       Password: decryptData(item.Password),
                                       AddedBy: item.AddedBy,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const stateData = () => {
        StateData.map(state => {
            if (state.state !== 'Non-Nigerian')
                setStateList(stateList => [...stateList, state.state])
        });
    }


    const getStaffData = async () => {
        await axios
            .get(`${serverLink}staff/hr/staff/data`)
            .then((response) => {
                setDepartmentList(response.data.department);
                setDesignationList(response.data.designation);
                setStaffTypeList(response.data.staffType);
            })
            .catch((error) => {
                console.log("NETWORK ERROR", error);
            });
        await axios.get(`${serverLink}staff/hr/staff/details/${formData.StaffID}`)
            .then((result) => {
                if (result.data.length > 0) {
                    let data = result.data[0];
                    setStaffList(result.data[0]);
                    props.setOnLoginDetails([{...result.data[0], token: formData.token}]);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const onEdit = (e) => {
        if (e.target.name === 'Nationality') {
            setStateList([])
            setLgaList([])
            setFormData({
                ...formData,
                State: "",
            })
            if (e.target.value === 'Nigeria') {
                stateData();
            } else {
                StateData.map(state => {
                    if (state.state === 'Non-Nigerian')
                        setStateList(stateList => [...stateList, state.state])
                });
            }
        }

        if(e.target.name === 'State') {
            setLgaList([])
            StateData.map(state => {
                if(state.state === e.target.value) {
                    state.lgas.map(lga => {
                        setLgaList(lgaList => [...lgaList, lga])
                    })
                }
            })
        }

        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onSubmit = async () => {
        console.log(formData)
        if (formData.Title.trim() === "") {
            showAlert("EMPTY FIELD", "Please select title", "error");
            return false;
        }
        if (formData.FirstName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter first name", "error");
            return false;
        }
        if (formData.Surname.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter surname", "error");
            return false;
        }
        if (formData.PhoneNumber.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter phone number", "error");
            return false;
        }
        if (formData.EmailAddress === "") {
            showAlert("EMPTY FIELD", "Please enter email address", "error");
            return false;
        }
        if (formData.Gender === "") {
            showAlert("EMPTY FIELD", "Please enter gender", "error");
            return false;
        }
        if (formData.DateOfBirth === "") {
            showAlert("EMPTY FIELD", "Please enter date of birth", "error");
            return false;
        }
        if (formData.Password.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter password", "error");
            return false;
        }


        if (formData.EntryID === "") {
            let sendData = {
                ...formData,
                Password: encryptData(formData.Password)
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/hr/staff/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Agent Added Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            StaffID: "",
                            Title: "",
                            FirstName: "",
                            MiddleName: "",
                            Surname: "",
                            Gender: "",
                            DateOfBirth: "",
                            MaritalStatus: "",
                            Nationality: "",
                            State: "",
                            Lga: "",
                            Religion: "",
                            PhoneNumber: "",
                            EmailAddress: "",
                            StaffType: "",
                            Designation: "",
                            DepartmentCode: "",
                            IsActive: "0",
                            IsAcademicStaff: "0",
                            IsAdmin: "0",
                            ContactAddress: "",
                            Research: "#",
                            Facebook: "#",
                            Linkedin: "#",
                            Twitter: "#",
                            Scholar: "#",
                            Researchgate: "#",
                            Academia: "#",
                            Orcid: "#",
                            Image: "",
                            Biography: "",
                            Password: "123456789",
                        });
                    } else if (result.data.message === "exist") {
                        setIsFormLoading(false);
                        showAlert("STAFF EXIST", "Staff already exist!", "error");
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
                Password: encryptData(formData.Password)
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/hr/staff/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Staff Updated Successfully");
                        setIsFormLoading(false);
                        getStaffData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            EntryID: "",
                            StaffID: "",
                            Title: "",
                            FirstName: "",
                            MiddleName: "",
                            Surname: "",
                            Gender: "",
                            DateOfBirth: "",
                            MaritalStatus: "",
                            Nationality: "",
                            State: "",
                            Lga: "",
                            Religion: "",
                            PhoneNumber: "",
                            EmailAddress: "",
                            StaffType: "",
                            Designation: "",
                            DepartmentCode: "",
                            IsActive: "0",
                            IsAcademicStaff: "0",
                            IsAdmin: "0",
                            ContactAddress: "",
                            Research: "#",
                            Facebook: "#",
                            Linkedin: "#",
                            Twitter: "#",
                            Scholar: "#",
                            Researchgate: "#",
                            Academia: "#",
                            Orcid: "#",
                            Image: "",
                            Biography: "",
                            Password: "123456789",
                        });
                    } else if (result.data.message === "exist") {
                        showAlert("STAFF EXIST", "Staff already exist!", "error");
                        setIsFormLoading(false);
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }


    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
            // let reader = new FileReader();
            //     reader.onload = (e) => {
            //         this.setState({
            //             htmlelement: (
            //                 <div className="addpersonpage">
            //                     <img
            //                         className="netimage"
            //                         srcSet={e.target.result}
            //                         alt="profileImage"
            //                     />
            //                 </div>
            //             ),
            //         });
            //     };
            //     reader.readAsDataURL(event.target.files[0]);
        }
    };



    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    {/*Overview*/}
                                </div>
                                <h2 className="page-title">
                                    Staff Profile
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row g-2 align-items-center">
                                            <div className="col-auto">
                                                <span className="avatar avatar-lg"
                                                      style={{backgroundImage: `url(${serverLink}public/uploads/staff_uploads/${formData.Image})`}}/>
                                            </div>
                                            <div className="col">
                                                <h4 className="card-title m-0">
                                                    <a href="#">{formData.Title} {formData.FirstName} {formData.MiddleName} {formData.Surname}</a>
                                                </h4>
                                                <div className="text-muted">
                                                    {formData.Designation}
                                                </div>
                                                <div className="small mt-1">
                                                    <span className="badge bg-green"/> Active
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <a className={"btn btn-primary btn-sm "} onClick={()=>{
                                                    stateData();
                                                    StateData.map(state => {
                                                        if(state.state === formData.State) {
                                                            state.lgas.map(lga => {
                                                                setLgaList(lgaList => [...lgaList, lga])
                                                            })
                                                        }
                                                    })
                                                }} data-bs-toggle="modal" data-bs-target="#modal-report">Edit Profile</a>
                                            </div>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">

                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Gender
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formData.Gender}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Date Of Birth
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formatDateAndTime(formData.DateOfBirth, 'date')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Marital Status
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formData.MaritalStatus}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Job Type
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formData.StaffType}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">

                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Phone Number
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formData.PhoneNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    Email Address
                                                                </div>
                                                                <div className="text-muted text-lowercase">
                                                                    {formData.EmailAddress}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    State
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formData.State}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xl-3 mt-3">
                                                <div className="card card-sm">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                            </div>
                                                            <div className="col">
                                                                <div className="fw-bold">
                                                                    LGA
                                                                </div>
                                                                <div className="text-muted">
                                                                    {formData.Lga}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                                <>
                                                    <div className="row mt-3">
                                                        <div className="hr-text hr-text-left">Social Network Visibility</div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">

                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Facebook
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {isValidUrl(formData.Facebook) ?
                                                                                    <div className="text-muted mt-1">
                                                                                        <a href={formData.Facebook} target="_blank" referrerPolicy="no-referrer"
                                                                                           className="btn btn-primary btn-sm">
                                                                                           <span>
                                                                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                   <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"/>
                                                                                                   <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"/>
                                                                                                </svg>
                                                                                           </span> Visit Link
                                                                                                            </a>
                                                                                    </div>
                                                                                    :
                                                                                    "N/A"
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Twitter
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {isValidUrl(formData.Twitter) ?
                                                                                    <div className="text-muted mt-1">
                                                                                        <a href={formData.Twitter} target="_blank" referrerPolicy="no-referrer"
                                                                                           className="btn btn-primary btn-sm">
                                                                                           <span>
                                                                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                   <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"/>
                                                                                                   <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"/>
                                                                                                </svg>
                                                                                           </span> Visit Link
                                                                                        </a>
                                                                                    </div>
                                                                                    :
                                                                                    "N/A"
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Linkedin
                                                                            </div>
                                                                            <div className="text-muted">
                                                                                {isValidUrl(formData.Linkedin) ?
                                                                                    <div className="text-muted mt-1">
                                                                                        <a href={formData.Linkedin} target="_blank" referrerPolicy="no-referrer"
                                                                                           className="btn btn-primary btn-sm">
                                                                                           <span>
                                                                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                   <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"/>
                                                                                                   <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"/>
                                                                                                </svg>
                                                                                           </span> Visit Link
                                                                                        </a>
                                                                                    </div>
                                                                                    :
                                                                                    "N/A"
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Google Scholar
                                                                            </div>
                                                                            {isValidUrl(formData.Scholar) ?
                                                                                <div className="text-muted mt-1">
                                                                                    <a href={formData.Scholar} target="_blank" referrerPolicy="no-referrer"
                                                                                       className="btn btn-primary btn-sm">
                                                                                           <span>
                                                                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                   <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"/>
                                                                                                   <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"/>
                                                                                                </svg>
                                                                                           </span> Visit Link
                                                                                    </a>
                                                                                </div>
                                                                                :
                                                                                "N/A"
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-md-6 col-xl-6 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Research gate
                                                                            </div>
                                                                            {isValidUrl(formData.Researchgate) ?
                                                                                <div className="text-muted mt-1">
                                                                                    <a href={formData.Researchgate} target="_blank" referrerPolicy="no-referrer"
                                                                                       className="btn btn-primary btn-sm">
                                                                                           <span>
                                                                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                   <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"/>
                                                                                                   <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"/>
                                                                                                </svg>
                                                                                           </span> Visit Link
                                                                                    </a>
                                                                                </div>
                                                                                :
                                                                                "N/A"
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-6 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Academia
                                                                            </div>
                                                                            {isValidUrl(formData.Academia) ?
                                                                                <div className="text-muted mt-1">
                                                                                    <a href={formData.Academia} target="_blank" referrerPolicy="no-referrer"
                                                                                       className="btn btn-primary btn-sm">
                                                                                           <span>
                                                                                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                                                   <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"/>
                                                                                                   <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"/>
                                                                                                </svg>
                                                                                           </span> Visit Link
                                                                                    </a>
                                                                                </div>
                                                                                :
                                                                                "N/A"
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-6 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Biography
                                                                            </div>
                                                                            <div className="text-muted" dangerouslySetInnerHTML={{__html: formData.Biography}}>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-xl-6 mt-3">
                                                            <div className="card card-sm">
                                                                <div className="card-body">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-auto">
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="fw-bold">
                                                                                Research
                                                                            </div>
                                                                            <div className="text-muted"  dangerouslySetInnerHTML={{__html: formData.Research}}>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UpdateStaffForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setShowPassword={setShowPassword}
                    showPassword={showPassword}
                    CountryData={CountryData}
                    stateList={stateList}
                    lgaList={lgaList}
                    departmentList={departmentList}
                    designationList={designationList}
                    staffTypeList={staffTypeList}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDisptachToProps)(StaffProfile);
