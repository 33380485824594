import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import DepartmentForm from "./department-form";

function Department(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [departmentList, setDepartmentList] = useState([])
    const header = ["S/N", "Department Name", "Code", "Image", "College", "School", "Location", "Active", "Action"];
    const [formData, setFormData] = useState({
        DEPT_ID: "",
        DEPARTMENT: "",
        DEPT_CODE: "",
        STATUS: "",
        COL_ID: "",
        SCH_ID: "",
        LOCATION: "",
        TELEPHONE: "",
        ImagePath: "",
        ImageData: "",
        INSERTED_BY: `${props.loginData[0]?.FirstName} ${props.loginData[0]?.MiddleName} ${props.loginData[0]?.Surname}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            DEPT_ID: "",
            DEPARTMENT: "",
            DEPT_CODE: "",
            STATUS: "",
            COL_ID: "",
            SCH_ID: "",
            LOCATION: "",
            TELEPHONE: "",
            ImagePath: "",
            ImageData: "",
        });
    }


    useEffect( () => {
        getData();
    }, [""]);


    const getData = async () => {
        await axios.get(`${serverLink}staff/academics/department/list`)
            .then((result) => {
                if (result.data.length > 0) {
                    setDepartmentList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return departmentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.DEPARTMENT}</td>
                        <td className="text-xs font-weight-bold">{item.DEPT_CODE}</td>
                        <td className="text-xs font-weight-bold"> <img src={`${serverLink}public/uploads/course_uploads/${item.ImagePath}`} width={80} height={80} className="img-thumbnail" alt=""/></td>
                        <td className="text-xs font-weight-bold">{item.COLLEGE_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.SCHOOL_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.LOCATION}</td>
                        <td className={`text-xs font-weight-bold ${item.STATUS?.toString() ==="1" ? "text-success" : "text-danger"}`}>{item.STATUS?.toString() ==="1" ? "Active" : "InActive"}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       DEPT_ID: item.DEPT_ID,
                                       DEPARTMENT: item.DEPARTMENT,
                                       DEPT_CODE: item.DEPT_CODE,
                                       COL_ID: item.COL_ID,
                                       SCH_ID: item.SCH_ID,
                                       LOCATION: item.LOCATION,
                                       TELEPHONE: item.TELEPHONE,
                                       STATUS: item.STATUS,
                                       ImagePath: item.ImagePath,
                                   });
                               }}>
                                Edit
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onImageChange = (event) => {

        if (event.target.files && event.target.files[0]) {
            setFormData({
                ...formData,
                ImagePath: event.target.files[0],
                ImageData: event.target.files[0],
            });
        }
    };

    const onSubmit = async () => {
        if (formData.DEPARTMENT.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter department name", "error");
            return false;
        }

        if (formData.DEPT_CODE.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter department code", "error");
            return false;
        }

        if (formData.COL_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select college", "error");
            return false;
        }

        if (formData.SCH_ID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select school", "error");
            return false;
        }

        if (formData.STATUS.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select status", "error");
            return false;
        }

        if (formData.ImagePath.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select course image", "error");
            return false;
        }


        if (formData.DEPT_ID === "") {
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}staff/academics/department/add`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', result.data.entry_id)
                            axios.patch(`${serverLink}staff/academics/uploadCoursePhoto`, formData2)
                                .then(result => {
                                    toast.success("Department Added Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    resetFormData()
                                })
                                .catch(err => {
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }

                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }else{
            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .patch(`${serverLink}staff/academics/department/update`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.ImagePath !== "") {
                            const formData2 = new FormData();
                            formData2.append('photo', formData.ImagePath);
                            formData2.append('entry_id', sendData.DEPT_ID)
                            axios.patch(`${serverLink}staff/academics/uploadCoursePhoto`, formData2)
                                .then(result => {
                                    toast.success("Department Updated Successfully");
                                    setIsFormLoading(false);
                                    getData();
                                    document.getElementById("closeModal").click();
                                    resetFormData();
                                })
                                .catch(err => {
                                    setIsFormLoading(false);
                                    showAlert(
                                        "ERROR",
                                        "Something went wrong uploading the image. Please try again!",
                                        "error"
                                    );
                                });
                        }
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    }

    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Manage Department
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>resetFormData()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                        Add Department
                                    </a>
                                    <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                       data-bs-target="#modal-report" aria-label="Create new report">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="12" y1="5" x2="12" y2="19"/>
                                            <line x1="5" y1="12" x2="19" y2="12"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Department(s)</h3>
                                                <DataTable header={header} body={showTable()} title="Department(s)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DepartmentForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    onImageChange={onImageChange}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(Department);
