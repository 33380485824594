import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {showAlert} from "../../common/sweetalert/sweetalert";
import {serverLink} from "../../../resources/url";
import {toast} from "react-toastify";
import DataLoader from "../../common/dataLoader/dataLoader";
import DataTable from "../../common/data-table/data-table";
import NewStudentEnrolmentForm from "./new-student-enrolment-form";

function NewStudentEnrolment(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [studentList, setStudentList] = useState([])
    const header = ["S/N", "APPID", "Name", "Email", "Phone", "Gender", "Programme", "Admission_Year", "Action"];
    const [formData, setFormData] = useState({
        APPID: "",
        STUDENTID: "",
        STUDENT_NAME: "",
        GENDER: "",
        TELEPHONE: "",
        EMAIL: "",
        PROG_ID: "",
        ADMISSION_YEAR: "",
        GENERATE_PAYMENT: "0",
        INSERTED_BY: `${props.loginData[0]?.StaffID}`,
    })

    const resetFormData = () => {
        return setFormData({
            ...formData,
            APPID: "",
            STUDENTID: "",
            STUDENT_NAME: "",
            GENDER: "",
            TELEPHONE: "",
            EMAIL: "",
            PROG_ID: "",
            ADMISSION_YEAR: "",
            GENERATE_PAYMENT: "0",
        });
    }

    useEffect( () => {
        getData();
    }, [""]);

    const getData = async () => {
        await axios.get(`${serverLink}staff/registration/student/not/enrolled`)
            .then((result) => {
                if (result.data.length > 0) {
                    setStudentList(result.data);
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return studentList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.APPID}</td>
                        <td className="text-xs font-weight-bold">{item.STUDENT_NAME}</td>
                        <td className="text-xs font-weight-bold">{item.EMAIL}</td>
                        <td className="text-xs font-weight-bold">{item.TELEPHONE}</td>
                        <td className="text-xs font-weight-bold">{item.GENDER}</td>
                        <td className="text-xs font-weight-bold">{item.PROGRAMME}</td>
                        <td className="text-xs font-weight-bold">{item.ADMISSION_YEAR}</td>
                        <td className="text-xs font-weight-bold">
                            <a href="#" className={"btn btn-primary btn-sm "} data-bs-toggle="modal" data-bs-target="#modal-report"
                               onClick={() => {
                                   setFormData({
                                       ...formData,
                                       PROG_ID: item.PROG_ID,
                                       APPID: item.APPID,
                                       STUDENTID: item.STUDENTID,
                                       PROGRAMME: item.PROGRAMME,
                                       STUDENT_NAME: item.STUDENT_NAME,
                                       EMAIL: item.EMAIL,
                                       TELEPHONE: item.TELEPHONE,
                                       GENDER: item.GENDER,
                                       ADMISSION_YEAR: item.ADMISSION_YEAR,
                                   });
                               }}>
                                Enrol
                            </a></td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        if (e.target.id === "GENERATE_PAYMENT"){
            if(e.target.checked){
                setFormData({ ...formData, [e.target.id]: "1",  });
            }else{
                setFormData({ ...formData, [e.target.id]: "0",  });
            }
        }else{
            setFormData({
                ...formData,
                [e.target.id]: e.target.value,
            });
        }
    }

    const onSubmit = async () => {
        if (formData.APPID.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Application Number Not Found", "error");
            return false;
        }

            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios.get(`${serverLink}staff/registration/student/enrolment/${formData.APPID}/${formData.GENERATE_PAYMENT}`)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Student Enrolled Successfully");
                        setIsFormLoading(false);
                        document.getElementById("closeModal").click();
                        setTimeout(()=>{
                            window.location.reload();
                        }, 3000)
                        resetFormData()
                    } else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong enrolling the student. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }


    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    New Student Enrolment
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                    <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                       onClick={()=>resetFormData()}> </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">New Student Not Enrolled</h3>
                                                <DataTable header={header} body={showTable()} title="New Student Not Enrolled" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewStudentEnrolmentForm
                    formData={formData}
                    onEdit={onEdit}
                    onSubmit={onSubmit}
                    IsFormLoading={IsFormLoading}
                    setFormData={setFormData}
                />
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(NewStudentEnrolment);
