import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import axios from "axios";
import { encryptData, serverLink} from "../../resources/url";
import { setPermissionDetails, setVisitorData} from "../../actions/actions";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {StateData} from "../../resources/state_data";
import {useNavigate} from "react-router";
import {formatDateAndTime, MaleAvatar, WomanAvatar} from "../../resources/constants";
import DataTable from "../common/data-table/data-table";
import NewsForm from "../website/news/news-form";
import SubmissionForm from "./submission-form";
import DataLoader from "../common/dataLoader/dataLoader";
import {Link} from "react-router-dom";

function VisitorForm(props) {
    const navigate = useNavigate();
    const data = props.loginData[0];
    const [IsLoading, setIsLoading] = useState(true)
    const [IsFormLoading, setIsFormLoading] = useState(false)
    const [submissionList, setSubmissionList] = useState([])
    const header = ["S/N", "Purpose", "Description", "Status", "Date Submitted"];

    const [formData, setFormData] = useState({
        FormID: "",
        VisitorID: "",
        CustodianID: "",
        Subject: "",
        Purpose: "",
        Description: "",
        Status: "",
        InsertedBy: `${props.loginData[0]?.VisitorID}`,
    })

    const getData = async () => {
        await axios.get(`${serverLink}project/visitor/submission/${data?.VisitorID}`)
            .then((result) => {
                if (result.data.message === "success") {
                    setSubmissionList(result.data.data);
                }else{
                    setSubmissionList([])
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const  showTable = () => {
        try {
            return submissionList.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.Purpose}</td>
                        <td className="text-xs font-weight-bold"><span className='blog-content-truncate' dangerouslySetInnerHTML={{__html: item.Description?.slice(0, 110) + (item.Description?.length > 110 ? "..." : "")}}/></td>
                        <td className="text-xs font-weight-bold">{item.Status}</td>
                        <td className="text-xs font-weight-bold">{formatDateAndTime(item.InsertedDate, 'date')}</td>
                    </tr>
                );
            });
        } catch (e) {
            alert(e.message);
        }
    };

    const onEdit = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const descriptionHandler = (event) => {
        setFormData({
            ...formData,
            Description: event,
        });
    }

    const onSubmit = async () => {
        if (formData.Purpose.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please select purpose", "error");
            return false;
        }

        if (formData.Subject.toString().trim() === "") {
            showAlert("EMPTY FIELD", "Please enter subject", "error");
            return false;
        }

        if (formData.Description.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter description", "error");
            return false;
        }

            let sendData = {
                ...formData,
            }
            setIsFormLoading(true);
            await axios
                .post(`${serverLink}project/visitor/submit-form`, sendData)
                .then((result) => {
                    if (result.data.message === "success") {
                        toast.success("Form Submitted Successfully");
                        setIsFormLoading(false);
                        getData();
                        document.getElementById("closeModal").click();
                        setFormData({
                            ...formData,
                            FormID: "",
                            VisitorID: "",
                            CustodianID: "",
                            Subject: "",
                            Purpose: "",
                            Description: "",
                            Status: "",
                        });

                    }  else {
                        setIsFormLoading(false);
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false);
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });



    }

    useEffect( () => {
        getData();
    }, [""]);
    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
        <>
            <div className="page-wrapper">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <h2 className="page-title">
                                    Dashboard
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-cards">
                            <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body p-4 text-center">
                                                    <span className="avatar avatar-xl mb-3 rounded img-thumbnail"
                                                          style={{backgroundImage: data?.Gender === "Male" ? `url(${MaleAvatar})` : `url(${WomanAvatar})`}}/>
                                                    <h3 className="m-0 mb-1"><a href="#">{data.Title}. {data.VisitorName}</a></h3>
                                                    <small className="text-muted">{data.EmailAddress}</small>
                                                    <div className="text-muted">{data.PhoneNumber}</div>

                                                    <div className="mt-2">
                                                        <span className="badge bg-purple-lt">Visitor</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mt-2">
                                            <div className="card-body">
                                                <div className="card-title">Basic info</div>
                                                <div className="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon me-2 text-muted" width="24" height="24"
                                                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                                        <path
                                                            d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                                                    </svg>
                                                    From: <strong>
                                                    {data?.State} - {data?.Lga} - {data?.Ward}</strong>
                                                </div>
                                                <div className="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon icon-tabler icon-tabler-checks" width="24"
                                                         height="24" viewBox="0 0 24 24" stroke-width="2"
                                                         stroke="currentColor" fill="none" stroke-linecap="round"
                                                         stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M7 12l5 5l10 -10"/>
                                                        <path d="M2 12l5 5m5 -5l5 -5"/>
                                                    </svg>
                                                    Total Submitted: <strong>{submissionList.length}</strong>
                                                </div>
                                                <div className="mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon me-2 text-muted" width="24" height="24"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"/>
                                                        <path d="M12 7v5l3 3"/>
                                                    </svg>
                                                    Last Login: <strong>{ formatDateAndTime(data?.LastLoginDate, 'date_and_time')}</strong>
                                                </div>
                                                <Link to={"/logout"} className={"btn  btn-primary w-100 mt-3"}>Logout</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="card card-lg">
                                    <div className="card-body">
                                        <div className="col-auto ms-auto d-print-none">
                                            <div className="btn-list justify-content-between">
                                                <h3 className="card-title">My Submissions</h3>
                                                <a href="#" className="btn btn-primary d-none d-sm-inline-block"  data-bs-toggle="modal" data-bs-target="#modal-report"
                                                   onClick={()=>{
                                                       setFormData({
                                                           ...formData,
                                                           FormID: "",
                                                           VisitorID: "",
                                                           CustodianID: "",
                                                           Subject: "",
                                                           Purpose: "",
                                                           Description: "",
                                                           Status: "",
                                                       });
                                                   }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                         strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <line x1="12" y1="5" x2="12" y2="19"/>
                                                        <line x1="5" y1="12" x2="19" y2="12"/>
                                                    </svg>
                                                    Add Submission
                                                </a>
                                                <a href="#" className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal"
                                                   data-bs-target="#modal-report" aria-label="Create new report">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                         strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <line x1="12" y1="5" x2="12" y2="19"/>
                                                        <line x1="5" y1="12" x2="19" y2="12"/>
                                                    </svg>
                                                </a>
                                            </div>
                                            <hr style={{marginTop: '8px'}} />
                                        </div>
                                        <div className="table-responsive mt-3">
                                            <DataTable header={header} body={showTable()} title="My Submissions" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SubmissionForm
                formData={formData}
                onEdit={onEdit}
                onSubmit={onSubmit}
                IsFormLoading={IsFormLoading}
                descriptionHandler={descriptionHandler}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.visitorData,
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        setOnVisitorDetails: (p) => {
            dispatch(setVisitorData(p));
        },
        setOnPermissionDetails: (p) => {
            dispatch(setPermissionDetails(p));
        },
    };
};


export default connect(mapStateToProps, mapDisptachToProps)(VisitorForm);
