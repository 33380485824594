import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { serverLink} from "../../../resources/url";
import DataLoader from "../../common/dataLoader/dataLoader";
import Chart from "react-google-charts";
import ApexChart from "react-apexcharts";
import {StateData} from "../../../resources/state_data";

function VisitorsSubmissionAnalytics(props) {
    const [IsLoading, setIsLoading] = useState(true)
    const [appliedCourse, setAppliedCourse] = useState([])
    const [programmeCategory, setProgrammeCategory] = useState([])
    const [genderType, setGenderType] = useState([])
    const [staffGenderType, setStaffGenderType] = useState([])
    const [staffDistribution, setStaffDistribution] = useState([]);
    const [stateDistribution, setStateDistribution] = useState([]);

    const [apexChartOption, setApexChartOption] = useState({});
    const [apexSeries, setApexSeries] = useState([]);

    const [visitorsCount, setVisitorsCount] = useState(0);
    const [submissionCount, setSubmissionCount] = useState(0);
    const [pendingSubmissionCount, setPendingSubmissionCount] = useState(0);
    const [staffCount, setStaffCount] = useState(0);


    useEffect( () => {
        getStaffData();
    }, [""]);



    const getStaffData = async () => {
        await axios.get(`${serverLink}project/dashboard/data`)
            .then((result) => {
                if (result.data.message === 'success') {
                    let visitors = result.data.Visitors;
                    let submissions = result.data.Submission;
                    let Staff = result.data.Staff;
                    let Department = result.data.Department;


                    let gender = ['Male', 'Female'];
                    const SubmissionTypes = ["Complaint", "Suggestion", "Recommendation", "Assistance", "Others"];
                    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                    let result0 = [];
                    let result1 = [];
                    let result2 = [];
                    let result3 = [];
                    let stateTypes = [];
                    let visitorsCount = [];

                    let staffGenderResult = [];
                    let staffDistributionResult = [];


                    [ ...new Set(StateData.filter(j=>j.StateName === "Kaduna").map(x=>x.LGAName))].map((e, i) => {
                        let count = visitors.filter(item => item.Lga.toString() === e.toString()).length
                        visitorsCount.push(count)
                        stateTypes.push(e)
                        result1.push([e, count]);
                    })

                    gender.map((e, i) => {
                        let count = visitors.filter(item => item.Gender === e).length
                        result2.push([e, count]);
                    })

                    gender.map((e, i) => {
                        let count = Staff.filter(item => item.Gender === e).length
                        staffGenderResult.push([e, count]);
                    })

                    SubmissionTypes.map((e, i) => {
                        let  count = submissions.filter(item => item.Purpose.toString() === e.toString()).length
                        result0.push([e, count]);
                    })


                    monthNames.map((e, i) => {
                        let count = submissions.filter(item => item.MonthName.toString() === e.toString()).length
                        result3.push([e, count]);
                    })

                    Department.map((e, i) => {
                        let count = Staff.filter(item => item.DepartmentID === e.DepartmentID).length
                        staffDistributionResult.push([e.DepartmentName, count]);
                    })

                    setApexChartOption({
                        ...apexChartOption,
                        chart: {
                            id: "basic-bar"
                        },
                        xaxis: {
                            categories: stateTypes
                        }
                    })
                    setApexSeries([{
                        name: "Count",
                        data: visitorsCount
                    }])

                    setProgrammeCategory(result0)
                    setStateDistribution(result1)
                    setGenderType(result2)
                    setAppliedCourse(result3)
                    setStaffDistribution(staffDistributionResult)
                    setStaffGenderType(staffGenderResult)

                    setVisitorsCount(visitors.length)
                    setSubmissionCount(submissions.length)
                    setPendingSubmissionCount(submissions.filter(e=>e.Status === "Pending").length)
                    setStaffCount(Staff.length)
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    }

    const data = [["Programmes", ""], ...programmeCategory];
    const options = {
        chart: {
            title: "Submission Trend By Purpose",
            subtitle: "Count",
            width: '100%',
        },
    };


    const data2 = [["Gender", "Count"], ...genderType];
    const options2 = {
        title: "Visitors Trend By Gender",
        is3D: true,
    };

    const data4 = [["Category", ""], ...staffDistribution];
    const options4 = {
        chart: {
            title: "Staff Distribution By Departments",
            subtitle: "Count",
            width: '100%',
        },
    };

    const data5 = [["Gender", "Count"], ...staffGenderType];
    const options5 = {
        title: "Staff Category By Gender",
        is3D: true,
    };


    const data3 = [["Count", "Courses"], ...appliedCourse];
    const options3 = {
        title: "Submission Trend By Month",
        curveType: "function",
        legend: {position: "bottom"},
        width: '100%',
    };
    return (
        IsLoading ?
            <div className="container-xl" style={{height: '700px'}}>
                <div className="page-header d-print-none">
                    <DataLoader/>
                </div>
            </div>
            :
            <>
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                </div>
                                <h2 className="page-title">
                                    Analytics
                                </h2>
                            </div>
                            <div className="col-auto ms-auto d-print-none">
                                <div className="btn-list">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-deck row-cards">
                            <div className="col-lg-12">
                                <div className="row row-cards">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <h3 className="card-title">Report <span><small>(Analytics)</small></span></h3>
                                                <div className="row">
                                                    <div className="col-sm-6 col-lg-3 mb-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="subheader">Visitors</div>
                                                                    <div className="ms-auto lh-1">
                                                                    </div>
                                                                </div>
                                                                <div className="h1 mb-3">{visitorsCount}</div>
                                                                <div>Total Visitors</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-3 mb-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="subheader">Visitors Submission</div>

                                                                </div>
                                                                <div className="h1  mb-3">{submissionCount}</div>
                                                                <div>Total Visitors Submission</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-3 mb-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="subheader">Submissions </div>

                                                                </div>
                                                                <div className="h1  mb-3">{pendingSubmissionCount}</div>
                                                                <div>Total Pending Submissions</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-lg-3 mb-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="subheader">Staff</div>
                                                                </div>
                                                                <div className="h1  mb-3">{staffCount}</div>
                                                                <div>Total Active Staff</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="row row-cards">
                                                            <div className="col-12">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        {
                                                                            stateDistribution.length > 0 &&
                                                                            <Chart
                                                                                chartType="Bar"
                                                                                width="100%"
                                                                                height="400px"
                                                                                data={data}
                                                                                options={options}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="card">
                                                            {
                                                                genderType.length > 0 &&
                                                                <Chart
                                                                    chartType="PieChart"
                                                                    width="100%"
                                                                    height="400px"
                                                                    data={data2}
                                                                    options={options2}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="row row-cards">
                                                            <div className="col-12">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        {
                                                                            appliedCourse.length > 0 &&
                                                                            <Chart
                                                                                chartType="LineChart"
                                                                                data={data3}
                                                                                options={options3}
                                                                                width={"100%"}
                                                                                height={"400px"}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="row row-cards">
                                                            <div className="col-12">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h4>Visitors Trend By Local Government Area</h4>
                                                                        {

                                                                            <ApexChart
                                                                                options={apexChartOption}
                                                                                series={apexSeries}
                                                                                type="bar"
                                                                                width="100%"
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="card">
                                                            {
                                                                staffGenderType.length > 0 &&
                                                                <Chart
                                                                    chartType="PieChart"
                                                                    width="100%"
                                                                    height="400px"
                                                                    data={data5}
                                                                    options={options5}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="row row-cards">
                                                            <div className="col-12">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        {
                                                                            staffDistribution.length > 0 &&
                                                                            <Chart
                                                                                chartType="Bar"
                                                                                width="100%"
                                                                                height="400px"
                                                                                data={data4}
                                                                                options={options4}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.loginDetails,
    };
};
export default connect(mapStateToProps, null)(VisitorsSubmissionAnalytics);
