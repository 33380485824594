export const setgeneralDetails = (p) => {
    return {
        type: "SET_GENERALDETAILS_DETAILS",
        payload: p,
    };
};

export const setPermissionDetails = (p) => {
    return {
        type: "SET_PERMISSION_DATA",
        payload: p,
    };
};

export const setLoginDetails = (p) => {
    return {
        type: "SET_LOGIN_DETAILS",
        payload: p,
    };
};


export const setVisitorSubmissionData = (p) => {
    return {
        type: "SET_SUBMISSION_DATA",
        payload: p,
    };
};

export const setVisitorData = (p) => {
    return {
        type: "SET_VISITOR_DATA",
        payload: p,
    };
};